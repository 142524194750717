import React from "react";
import { Box, Button, styled, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import Loader from "react-js-loader";

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  "& .MuiButton-root": {
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    backgroundColor: "transparent",
    textTransform: "none",
    border: `1px solid ${theme.palette.colors.brand.primary}`,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

const LoadingBox = styled(Box)({
  height: "25px",
  justifyContent: "center",
  display: "flex",
  alignItems: "center",
});

function BorderButton({ children, onClickButton, isLoading, width, height }) {
  const theme = useTheme();
  return (
    <ButtonContainer sx={{ height }}>
      {isLoading ? (
        <Button variant="contained" disabled sx={{ width, height }}>
          <LoadingBox>
            <Loader type="bubble-loop" bgColor={theme.palette.colors.brand.secondary} size={30} />
          </LoadingBox>
        </Button>
      ) : (
        <Button variant="contained" onClick={onClickButton} sx={{ width, boxShadow: 0, height }}>
          {children}
        </Button>
      )}
    </ButtonContainer>
  );
}

BorderButton.defaultProps = {
  width: "100%",
  isLoading: false,
  onClickButton: null,
  height: null,
};

BorderButton.propTypes = {
  onClickButton: PropTypes.func,
  isLoading: PropTypes.bool,
  children: PropTypes.node.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default BorderButton;
