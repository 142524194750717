import React from "react";
import { Table, TableBody, TableRow, TableCell, Grid } from "@mui/material";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import TableHeader from "../../../components/table/table-header.component";

function TagTableLoader() {
  return (
    <Table sx={{ minWidth: 650 }} aria-label="collapsible table">
      <TableHeader headerCells={["ID", "Tag Name", "Date Created", "Experiences", ""]} />
      <TableBody>
        <TableRow>
          <TableCell sx={{ width: "100px" }}>
            <Skeleton width="100%" />
          </TableCell>
          <TableCell>
            <Skeleton width="100%" />
          </TableCell>
          <TableCell>
            <Skeleton width="100%" />
          </TableCell>
          <TableCell>
            <Skeleton width="100%" />
          </TableCell>

          <TableCell sx={{ width: "150px" }}>
            <Grid container spacing={1} sx={{ alignItems: "center" }}>
              <Grid item>
                <Skeleton height={30} width={30} circle={true} />
              </Grid>
              <Grid item>
                <Skeleton height={30} width={30} circle={true} />
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

export default TagTableLoader;
