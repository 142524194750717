import React from "react";
import { Box, styled, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Link } from "react-router-dom";
import Text from "../../../components/text.component";
import PaddedView from "../../../components/padded-view.component";
import Spacer from "../../../components/spacer.component";
import routes from "../../../navigation/routes";

const CardContainer = styled(Box)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  width: "100%",
  backgroundColor: theme.palette.colors.bg.white,
  boxShadow: `1px 2px 5px ${theme.palette.colors.loading.foregroundColor}`,
  height: "270px",
  display: "flex",
  alignItems: "center",
}));

const CenterRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const StyledCalendarMonthIcon = styled(CalendarMonthIcon)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size18,
  color: theme.palette.colors.text.secondary,
}));

function MerchantExperiencesCard({ cardDetails }) {
  const theme = useTheme();

  return (
    <CardContainer>
      <PaddedView multiples={3}>
        <Text
          sx={{
            fontSize: theme.fonts.fontSizes.size20,
            textAlign: "start",
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
          }}
        >
          {cardDetails.title}
        </Text>
        <CenterRowBox>
          <Text>{`RM${cardDetails.price}`}</Text>
          <Spacer size="s" position="left" />
          <Text type="GreyColor">/ pax</Text>
        </CenterRowBox>
        <Spacer size="l" position="top" />
        <Text
          type="GreyColor"
          sx={{
            textAlign: "start",
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 3,
          }}
        >
          {cardDetails.location}
        </Text>
        <Spacer size="l" position="top" />
        <Link
          to={{ pathname: `${routes.EXPERIENCEDETAIL}`, search: `experienceId=${cardDetails.id}` }}
          style={{ textDecoration: "none" }}
        >
          <CenterRowBox>
            <StyledCalendarMonthIcon />
            <Spacer size="s" position="left" />
            <Text sx={{ fontSize: theme.fonts.fontSizes.size14 }} type="GreyColor">
              View Experience
            </Text>
          </CenterRowBox>
        </Link>
      </PaddedView>
    </CardContainer>
  );
}

MerchantExperiencesCard.defaultProps = {
  cardDetails: {
    id: null,
    title: "",
    price: "",
    type: "",
    location: "",
  },
};

MerchantExperiencesCard.propTypes = {
  cardDetails: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    price: PropTypes.string,
    type: PropTypes.string,
    location: PropTypes.string,
  }),
};

export default MerchantExperiencesCard;
