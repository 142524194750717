import React from "react";
import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import { useHistory } from "react-router-dom";
import PaddedView from "../../../../components/padded-view.component";
import Spacer from "../../../../components/spacer.component";
import Text from "../../../../components/text.component";
import routes from "../../../../navigation/routes";
import GreenLock from "../../../../assets/images/green_lock";
import CtaButton from "../../../../components/buttons/cta-button.component";

const CardContainer = styled(Box)(({ theme, isMobile }) => ({
  backgroundColor: theme.palette.colors.bg.white,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  flex: 1,
  maxWidth: 500,
  minWidth: isMobile ? 340 : 450,
  alignItems: "center",
  justifyContent: "center",
  overflow: "hidden",
}));

const TitleContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "80px",
  backgroundColor: theme.palette.colors.bg.primary,
  justifyContent: "center",
  alignItems: "center",
}));

const CenterBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

function ResetPasswordSuccessful() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();

  return (
    <CardContainer isMobile={isMobile} sx={{ boxShadow: 2 }}>
      <TitleContainer>
        <Text variant="h5">Reset Password Successfully</Text>
      </TitleContainer>

      <PaddedView multiples={isMobile ? 3 : 4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Spacer size="xl" position="top" />
            <CenterBox>
              <GreenLock />
            </CenterBox>
          </Grid>
          <Grid item xs={12}>
            <Text sx={{ textAlign: "center" }}>You can sign in with your new password now.</Text>
            <Spacer size="xl" position="top" />
          </Grid>
          <Grid item xs={12}>
            <CtaButton onClickButton={() => history.push(routes.LOGIN)}>
              <Text type="WhiteColor">Sign In</Text>
            </CtaButton>
          </Grid>
        </Grid>
      </PaddedView>
    </CardContainer>
  );
}

export default ResetPasswordSuccessful;
