import React from "react";
import { useTheme } from "@mui/material";
import PropTypes from "prop-types";

function BookingIcon({ isSelected }) {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.494"
      height="23.778"
      viewBox="0 0 18.494 23.778"
    >
      <path
        id="Icon"
        d="M429.494,203.642v21.136l-9.247-3.963L411,224.778V203.642A2.65,2.65,0,0,1,413.642,201h13.21A2.65,2.65,0,0,1,429.494,203.642Z"
        transform="translate(-411 -201)"
        fill={
          isSelected ? theme.palette.colors.brand.primary : theme.palette.colors.brand.secondary
        }
      />
    </svg>
  );
}

BookingIcon.defaultProps = {
  isSelected: false,
};

BookingIcon.propTypes = {
  isSelected: PropTypes.bool,
};

export default BookingIcon;
