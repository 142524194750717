import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../../api-services";

export const getFestivalSectionList = createAsyncThunk(
  "marketing/festival-section/list",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/marketings/festival_section?q=${payload.q}&page=${payload.page}`,
    );
    return response;
  },
);

export const deleteFestivalSection = createAsyncThunk(
  "marketing/festival-section/delete",
  async (festivalSectionId) => {
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/marketings/festival_section/${festivalSectionId}`,
    );
    return response;
  },
);

export const createFestivalSection = createAsyncThunk(
  "marketing/festival-section/create",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/marketings/festival_section`,
      payload,
    );
    return response;
  },
);

export const getFestivalSectionDetail = createAsyncThunk(
  "marketing/festival-section/detail",
  async (festivalSectionId) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/marketings/festival_section/${festivalSectionId}`,
    );
    return response;
  },
);

export const updateFestivalSection = createAsyncThunk(
  "marketing/festival-section/update",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/marketings/festival_section/${payload.festivalSectionId}`,
      payload,
    );
    return response;
  },
);

export const getFestivalCategory = createAsyncThunk(
  "marketing/festival-category/list",
  async (festivalSectionId) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/marketings/festival_section/${festivalSectionId}/festival_category`,
    );
    return response;
  },
);

export const deleteFestivalCategory = createAsyncThunk(
  "marketing/festival-category/delete",
  async ({ festivalSectionId, festivalCategoryId }) => {
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/marketings/festival_section/${festivalSectionId}/festival_category/${festivalCategoryId}`,
    );
    return response;
  },
);

export const createFestivalCategory = createAsyncThunk(
  "marketing/festival-category/create",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/marketings/festival_section/${payload.festivalSectionId}/festival_category/`,
      payload,
    );
    return response;
  },
);

export const updateFestivalCategory = createAsyncThunk(
  "marketing/festival-category/update",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/marketings/festival_section/${payload.festivalSectionId}/festival_category/${payload.festivalCategoryId}`,
      payload,
    );
    return response;
  },
);

const festivalSectionSlice = createSlice({
  name: "festivalSection",
  initialState: {
    getFestivalSectionListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteFestivalSectionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createFestivalSectionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getFestivalSectionDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateFestivalSectionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getFestivalCategoryObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteFestivalCategoryObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createFestivalCategoryObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateFestivalCategoryObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getFestivalSectionList.pending]: (state) => {
      state.getFestivalSectionListObj.status = "pending";
    },
    [getFestivalSectionList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getFestivalSectionListObj.status = "succeeded";
      state.getFestivalSectionListObj.data = data;
      state.getFestivalSectionListObj.successMessage = message;
    },
    [getFestivalSectionList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getFestivalSectionListObj.status = "failed";
      state.getFestivalSectionListObj.errorMessage = message;
    },
    [deleteFestivalSection.pending]: (state) => {
      state.deleteFestivalSectionObj.status = "pending";
    },
    [deleteFestivalSection.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteFestivalSectionObj.status = "succeeded";
      state.deleteFestivalSectionObj.data = data;
      state.deleteFestivalSectionObj.successMessage = message;

      state.getFestivalSectionListObj.data = data;
    },
    [deleteFestivalSection.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteFestivalSectionObj.status = "failed";
      state.deleteFestivalSectionObj.errorMessage = message;
    },
    [createFestivalSection.pending]: (state) => {
      state.createFestivalSectionObj.status = "pending";
    },
    [createFestivalSection.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createFestivalSectionObj.status = "succeeded";
      state.createFestivalSectionObj.data = data;
      state.createFestivalSectionObj.successMessage = message;

      state.getFestivalSectionListObj.data = data;
    },
    [createFestivalSection.rejected]: (state, action) => {
      const { message } = action.error;

      state.createFestivalSectionObj.status = "failed";
      state.createFestivalSectionObj.errorMessage = message;
    },
    [getFestivalSectionDetail.pending]: (state) => {
      state.getFestivalSectionDetailObj.status = "pending";
    },
    [getFestivalSectionDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getFestivalSectionDetailObj.status = "succeeded";
      state.getFestivalSectionDetailObj.data = data;
      state.getFestivalSectionDetailObj.successMessage = message;
    },
    [getFestivalSectionDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.getFestivalSectionDetailObj.status = "failed";
      state.getFestivalSectionDetailObj.errorMessage = message;
    },
    [updateFestivalSection.pending]: (state) => {
      state.updateFestivalSectionObj.status = "pending";
    },
    [updateFestivalSection.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateFestivalSectionObj.status = "succeeded";
      state.updateFestivalSectionObj.data = data;
      state.updateFestivalSectionObj.successMessage = message;

      state.getFestivalSectionDetailObj.data = data;
    },
    [updateFestivalSection.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateFestivalSectionObj.status = "failed";
      state.updateFestivalSectionObj.errorMessage = message;
    },
    [getFestivalCategory.pending]: (state) => {
      state.getFestivalCategoryObj.status = "pending";
    },
    [getFestivalCategory.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getFestivalCategoryObj.status = "succeeded";
      state.getFestivalCategoryObj.data = data;
      state.getFestivalCategoryObj.successMessage = message;
    },
    [getFestivalCategory.rejected]: (state, action) => {
      const { message } = action.error;

      state.getFestivalCategoryObj.status = "failed";
      state.getFestivalCategoryObj.errorMessage = message;
    },
    [deleteFestivalCategory.pending]: (state) => {
      state.deleteFestivalCategoryObj.status = "pending";
    },
    [deleteFestivalCategory.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteFestivalCategoryObj.status = "succeeded";
      state.deleteFestivalCategoryObj.data = data;
      state.deleteFestivalCategoryObj.successMessage = message;

      state.getFestivalCategoryObj.data = data;
    },
    [deleteFestivalCategory.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteFestivalCategoryObj.status = "failed";
      state.deleteFestivalCategoryObj.errorMessage = message;
    },
    [createFestivalCategory.pending]: (state) => {
      state.createFestivalCategoryObj.status = "pending";
    },
    [createFestivalCategory.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createFestivalCategoryObj.status = "succeeded";
      state.createFestivalCategoryObj.data = data;
      state.createFestivalCategoryObj.successMessage = message;

      state.getFestivalCategoryObj.data = data;
    },
    [createFestivalCategory.rejected]: (state, action) => {
      const { message } = action.error;

      state.createFestivalCategoryObj.status = "failed";
      state.createFestivalCategoryObj.errorMessage = message;
    },
    [updateFestivalCategory.pending]: (state) => {
      state.updateFestivalCategoryObj.status = "pending";
    },
    [updateFestivalCategory.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateFestivalCategoryObj.status = "succeeded";
      state.updateFestivalCategoryObj.data = data;
      state.updateFestivalCategoryObj.successMessage = message;

      state.getFestivalCategoryObj.data = data;
    },
    [updateFestivalCategory.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateFestivalCategoryObj.status = "failed";
      state.updateFestivalCategoryObj.errorMessage = message;
    },
  },
});

export default festivalSectionSlice.reducer;

// state
export const festivalSectionSelector = (state) => state.festivalSection;
