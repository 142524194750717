import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
    Box,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Spacer from "../../../components/spacer.component";
import TableHeader from "../../../components/table/table-header.component";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";

function LatestList({ record }) {
    const [collapse, setCollapse] = useState(false);
    const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
    const theme = useTheme();

    return (
        <>
        <TableHeader>
        <Text variant="body2" sx={{ textAlign: "left" }}>
            Latest Bookings  </Text>
        </TableHeader>
            <TableRow
                key={record.id}
                sx={{
                    "& .MuiTableCell-root": {
                        borderBottom: "0px",
                    },
                }}
            >
                <TableCell width="100px">
                    <Text variant="body2" sx={{ textAlign: "center" }}>
                        {record.id}
                    </Text>
                </TableCell>
                <TableCell>
                    <Link
                        style={{ textDecorationColor: theme.palette.colors.text.primary }}
                        to={{
                            pathname: routes.EXPERIENCEDETAIL,
                            search: `experienceId=${record.experience.id}`,
                        }}
                    >
                        <Text variant="body2" sx={{ textAlign: "center" }}>
                            {record.experience.title}
                        </Text>
                    </Link>
                </TableCell>
                <TableCell>
                    <Text variant="body2" sx={{ textAlign: "center", minWidth: "40px" }}>
                        {record.startAt || "-"}
                    </Text>
                </TableCell>
                <TableCell>
                    <Text variant="body2" sx={{ textAlign: "center", minWidth: "40px" }}>
                        {record.endAt || "-"}
                    </Text>
                </TableCell>
                <TableCell>
                    <Text variant="body2" sx={{ textAlign: "center" }}>
                        {capitalize(record.status.toString())}
                    </Text>
                </TableCell>
                <TableCell sx={{ width: "100px" }}>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <IconButton aria-label="expand row" size="small" onClick={() => setCollapse(!collapse)}>
                            {collapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </Box>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={collapse} unmountOnExit timeout="auto">
                        <Spacer size="m" />
                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <Text>Latest Booking Details</Text>
                        </Box>
                        <Spacer />
                        <Table size="small">
                            <TableHeader headerCells={["Name", "Contact No.", "Quantity", "Type", ""]} />
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Text variant="body2" noWrap={true} sx={{ textAlign: "center" }}>
                                            {`${record.recipient.firstName} ${record.recipient.lastName}`}
                                        </Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text variant="body2" noWrap={true} sx={{ textAlign: "center" }}>
                                            {record.recipient.contactNo ? `+${record.recipient.contactNo}` : "-"}
                                        </Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text variant="body2" noWrap={true} sx={{ textAlign: "center" }}>
                                            {record.noOfPax || "-"}
                                        </Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text variant="body2" noWrap={true} sx={{ textAlign: "center" }}>
                                            {record.type === "multiple"
                                                ? "Standard"
                                                : capitalize(record.type.replace("_", " "))}
                                        </Text>
                                    </TableCell>
                                    <TableCell>
                                        <Link
                                            style={{ textDecorationColor: theme.palette.colors.brand.primary }}
                                            to={{
                                                pathname: routes.BOOKING_DETAIL,
                                                search: `bookingId=${record.id}`,
                                            }}
                                        >
                                            <Text
                                                variant="body2"
                                                noWrap={true}
                                                type="BrandColor"
                                                sx={{ textAlign: "center" }}
                                            >
                                                View Detail
                                            </Text>
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Spacer size="m" />
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

LatestList.defaultProps = {
    record: {
        id: null,
        type: "",
        startAt: PropTypes.string,
        endAt: PropTypes.string,
        experience: PropTypes.shape({
            id: null,
            title: PropTypes.string,
        }),
        recipient: PropTypes.shape({
            isGuest: PropTypes.bool,
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            contactNo: PropTypes.string,
        }),
        order: PropTypes.shape({
            price: PropTypes.string,
        }),
        noOfPax: null,
        cancellable: PropTypes.bool,
        status: PropTypes.string,
    },
};

LatestList.propTypes = {
    record: PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
        startAt: PropTypes.string,
        endAt: PropTypes.string,
        experience: PropTypes.shape({
            id: PropTypes.number,
            title: PropTypes.string,
        }),
        recipient: PropTypes.shape({
            isGuest: PropTypes.bool,
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            contactNo: PropTypes.string,
        }),
        order: PropTypes.shape({
            price: PropTypes.string,
        }),
        noOfPax: PropTypes.number,
        cancellable: PropTypes.bool,
        status: PropTypes.string,
    }),
};

export default LatestList;
