import React from "react";
import { styled, Typography } from "@mui/material";
import PropTypes from "prop-types";

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
}));

const GreyColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
}));

const BrandColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.brand.primary,
}));

const WhiteColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.white,
}));

const PlaceholderText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.placeholder,
}));

const DarkGreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.darkGrey,
}));

const RejectText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.reject,
}));

const BlueColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.defaultFocusedBorder,
}));

const GreenColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.ui.success,
}));

function Text({ type, children, ...props }) {
  if (type === "RejectText") {
    return <RejectText {...props}>{children}</RejectText>;
  }
  if (type === "GreyColor") {
    return <GreyColorText {...props}>{children}</GreyColorText>;
  }
  if (type === "DarkGreyColor") {
    return <DarkGreyText {...props}>{children}</DarkGreyText>;
  }
  if (type === "BrandColor") {
    return <BrandColorText {...props}>{children}</BrandColorText>;
  }
  if (type === "WhiteColor") {
    return <WhiteColorText {...props}>{children}</WhiteColorText>;
  }
  if (type === "Placeholder") {
    return <PlaceholderText {...props}>{children}</PlaceholderText>;
  }
  if (type === "BlueColor") {
    return <BlueColorText {...props}>{children}</BlueColorText>;
  }
  if (type === "GreenColor") {
    return <GreenColorText {...props}>{children}</GreenColorText>;
  }
  return <PrimaryColorText {...props}>{children}</PrimaryColorText>;
}

Text.defaultProps = {
  type: "",
  children: null,
};

Text.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
};

export default Text;
