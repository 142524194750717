import React from "react";
import { Box, IconButton, styled, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useFormikContext } from "formik";
import Text from "../../../components/text.component";
import { experienceSelector } from "../../../services/experience/experience-slice.services";
import CenteredRowBox from "../../../components/centered-row-box.component";
import DetailCardContainer from "../../../components/detail-card-container.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import BorderButton from "../../../components/buttons/border-button.component";
import Spacer from "../../../components/spacer.component";

const FlexEndBox = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
});

export default function ExperienceTitleForm({ setIsEditing, isEditing }) {
  const { getExperienceDetailObj } = useSelector(experienceSelector);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { resetForm, values } = useFormikContext();

  if (isEditing.title) {
    return (
      <Box sx={{ width: isMobile ? "100%" : "70%" }}>
        <DetailCardContainer>
          <FormFieldText name="title" placeholder="Experience's title" />
          <Spacer position="top" size="m" />
          <FlexEndBox>
            <BorderButton
              width="100px"
              onClickButton={() => {
                resetForm();
                setIsEditing((i) => ({ ...i, title: false }));
              }}
            >
              <Text type="BrandColor">Cancel</Text>
            </BorderButton>
            <Spacer position="left" size="m" />
            <FormSubmitButton width="100px">
              <Text type="WhiteColor">Save</Text>
            </FormSubmitButton>
          </FlexEndBox>
        </DetailCardContainer>
      </Box>
    );
  }

  return (
    <Box>
      <CenteredRowBox>
        <Text variant="h4">{values.title}</Text>
        <Spacer size="s" position="left" />
        <IconButton
          sx={{ color: theme.palette.colors.text.primary }}
          disabled={isEditing.title}
          onClick={() => setIsEditing((i) => ({ ...i, title: true }))}
        >
          <ModeEditIcon />
        </IconButton>
      </CenteredRowBox>
      <Text variant="body2">Listed by {getExperienceDetailObj.data.merchant.name}</Text>
    </Box>
  );
}

ExperienceTitleForm.propTypes = {
  setIsEditing: PropTypes.func.isRequired,
  isEditing: PropTypes.shape({
    title: PropTypes.bool,
  }).isRequired,
};
