import React, { useContext, useRef, useState } from "react";
import { Box, Grid, IconButton, Modal, styled, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import {
  experienceCreateSlot,
  experienceRemoveSlot,
  experienceSelector,
  experienceUpdateSlot,
} from "../../../services/experience/experience-slice.services";
import SkeletonLoader from "../../../components/skeleton.component";
import Form from "../../../components/forms/form.component";
import TimeSlotFormModal from "./time-slot-form-modal.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import FlexiSlotList from "./flexi-slot-list.component";
import SlotList from "./slot-list.component";
import CenteredRowBox from "../../../components/centered-row-box.component";
import DeleteConfirmationModal from "../../../components/notifications/delete-confirmation-modal.component";

const addTimeslotValidationSchema = Yup.object().shape({
  startDate: Yup.date().label("Start Date").nullable().when("bookingType", {
    is: "multiple",
    then: Yup.date().required(),
  }),
  startTime: Yup.date().label("Check-in Time").nullable().when("bookingType", {
    is: "multiple",
    then: Yup.date().required(),
  }),
  endDate: Yup.date()
    .label("End Date")
    .nullable()
    .when("bookingType", {
      is: "multiple",
      then: Yup.date()
        .min(Yup.ref("startDate"), "End date can't earlier than start date")
        .required(),
    }),
  endTime: Yup.date().label("Check-out Time").nullable().when("bookingType", {
    is: "multiple",
    then: Yup.date().required(),
  }),
  experienceId: Yup.string().required().label("Experience Id"),
  bookingType: Yup.string().required(),
  capacity: Yup.number()
    .nullable()
    .label("Capacity")
    .when("bookingType", {
      is: "multiple",
      then: Yup.number().min(1).max(30).required(),
    })
    .when("bookingType", {
      is: "flexi",
      then: Yup.number().min(1).max(30).required(),
    }),
  flexiTitle: Yup.string()
    .label("Title")
    .when("bookingType", {
      is: "flexi",
      then: Yup.string().min(2).required(),
    }),
});

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.brand.white,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  padding: theme.padding.paddingX[0],
  maxHeight: "90%",
  maxWidth: "95%",
  outline: "none",
  display: "flex",
  flexDirection: "column",
  minWidth: "350px",
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  right: "30px",
  top: "20px",
  position: "absolute",
  color: theme.palette.colors.brand.secondary,
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "1px",
  backgroundColor: theme.palette.colors.ui.border,
}));

const AddIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: "30px",
  padding: "0px",
  color: theme.palette.colors.brand.primary,
}));

export default function ExperienceTimeslotModal({ showModal, setShowModal }) {
  const theme = useTheme();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { getExperienceTimeslotObj, experienceRemoveSlotObj } = useSelector(experienceSelector);
  const [showTimeSlotModal, setShowTimeSlotModal] = useState(false);
  const location = useLocation();
  const experienceId = new URLSearchParams(location.search).get("experienceId");
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const formRef = useRef();

  const handleCancel = () => {
    setShowModal(false);
  };

  const renderLoader = (title) => (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Text
          sx={{
            fontSize: theme.fonts.fontSizes.size18,
            fontWeight: theme.fonts.fontWeights.medium,
          }}
        >
          {title}
        </Text>
      </Grid>
      <Grid item xs={12}>
        {title === "Standard Timeslot" && (
          <>
            <SkeletonLoader width="150px" height="30px" />
            <Spacer size="xs" position="top" />
          </>
        )}

        <Grid container spacing={2}>
          <Grid item>
            <SkeletonLoader width="285px" height="100px" />
          </Grid>
          <Grid item>
            <SkeletonLoader width="285px" height="100px" />
          </Grid>
          <Grid item>
            <SkeletonLoader width="285px" height="100px" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const onSubmitForm = (values, { resetForm }) => {
    let startAt = "";
    let endAt = "";
    if (values.bookingType !== "flexi") {
      startAt = `${format(new Date(values.startDate), "yyyy-MM-dd")} ${format(
        new Date(values.startTime),
        "HH:mm:ss",
      )}`;
      endAt = `${format(new Date(values.endDate), "yyyy-MM-dd")} ${format(
        new Date(values.endTime),
        "HH:mm:ss",
      )}`;
    }

    if (values.formType === "delete") {
      dispatch(experienceRemoveSlot(values)).then(({ meta, error, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          setShowDeleteModal(false);
          resetForm();
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      });
      return;
    }

    if (values.formType === "edit") {
      dispatch(experienceUpdateSlot({ startAt, endAt, ...values })).then(
        ({ meta, error, payload }) => {
          if (meta.requestStatus === "fulfilled") {
            setShowTimeSlotModal(false);
            resetForm();
            createSnackBar({
              message: payload.message,
              type: "success",
            });
          }
          if (meta.requestStatus === "rejected") {
            createSnackBar({
              message: error.message,
              type: "error",
            });
          }
        },
      );
      return;
    }

    dispatch(experienceCreateSlot({ startAt, endAt, ...values })).then(
      ({ meta, error, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          setShowTimeSlotModal(false);
          resetForm();
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      },
    );
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox sx={{ position: "absolute" }}>
        <Form
          innerRef={formRef}
          validationSchema={addTimeslotValidationSchema}
          initialValues={{
            startDate: new Date(),
            startTime: new Date(new Date().setHours(new Date().getHours() + 1)),
            endDate: new Date(new Date().setDate(new Date().getDate() + 1)),
            endTime: new Date(new Date().setHours(new Date().getHours() + 1)),
            bookingType: "multiple",
            capacity: "",
            flexiTitle: "",
            massSlotId: "",
            slotId: "",
            formType: "create",
            experienceId,
          }}
          onSubmit={onSubmitForm}
        >
          <DeleteConfirmationModal
            showModal={showDeleteModal}
            setShowModal={setShowDeleteModal}
            label="Are you sure to delete this slot?"
            onConfirmClicked={() => formRef.current.handleSubmit()}
            isLoading={experienceRemoveSlotObj.status === "pending"}
          />
          <CloseIconButton onClick={handleCancel}>
            <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
          </CloseIconButton>
          <TimeSlotFormModal
            isShowModal={showTimeSlotModal}
            setIsShowModal={setShowTimeSlotModal}
          />

          <CenteredRowBox justifyContent="space-between">
            <Box sx={{ width: "32px", marginLeft: "30px" }} />
            <Text variant="h5" textAlign="center" sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
              Timeslot
            </Text>

            <AddIconButton onClick={() => setShowTimeSlotModal(true)}>
              <AddBoxOutlinedIcon sx={{ fontSize: theme.fonts.fontSizes.size32 }} />
            </AddIconButton>
          </CenteredRowBox>

          <Spacer size="l" position="top" />
          <SeperateLine />
          <Spacer size="l" position="top" />

          <Box sx={{ display: "flex", flex: 1, overflowY: "auto" }}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {getExperienceTimeslotObj.status === "succeeded" ? (
                  <FlexiSlotList
                    setShowTimeSlotModal={setShowTimeSlotModal}
                    setShowDeleteModal={setShowDeleteModal}
                  />
                ) : (
                  renderLoader("Flexi TimeSlot")
                )}
              </Grid>
              <Grid item xs={12}>
                {getExperienceTimeslotObj.status === "succeeded" ? (
                  <SlotList
                    setShowTimeSlotModal={setShowTimeSlotModal}
                    setShowDeleteModal={setShowDeleteModal}
                  />
                ) : (
                  renderLoader("Standard Timeslot")
                )}
              </Grid>
            </Grid>
          </Box>
        </Form>
      </ModalBox>
    </Modal>
  );
}

ExperienceTimeslotModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};
