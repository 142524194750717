import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Pagination,
  styled,
  Table,
  TableBody,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { format } from "date-fns";
import PaddedView from "../../../components/padded-view.component";
import Spacer from "../../../components/spacer.component";
import TableTitle from "../../../components/table/table-title.component";
import { bookingSelector, getBookingList } from "../../../services/booking/booking.slice.services";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import Form from "../../../components/forms/form.component";
import DetailCardContainer from "../../../components/detail-card-container.component";
import FormDatePicker from "../../../components/forms/form-date-picker.component";
import FormMultipleValueSelect from "../../../components/forms/form-multiple-value-select.component";
import SearchBar from "../components/search-bar.component";
import BorderButton from "../../../components/buttons/border-button.component";
import Text from "../../../components/text.component";
import CtaButton from "../../../components/buttons/cta-button.component";
import TableHeader from "../../../components/table/table-header.component";
import TableWrapper from "../../../components/table/table-wrapper.component";
import TableLoader from "../loader/table-loader.component";
import BookingRow from "../components/booking-row.component";

const validationSchema = Yup.object().shape({
  startDate: Yup.date().nullable().label("Start Date"),
  endDate: Yup.date()
    .min(Yup.ref("startDate"), "End date must be after start date")
    .nullable()
    .label("End at"),

  experience: Yup.string().label("Experience"),
  status: Yup.array().of(Yup.string()).label("Status"),
});

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

export default function BookingListScreen() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const createSnackBar = useContext(SnackbarContext);
  const formRef = useRef();
  const { getBookingListObj } = useSelector(bookingSelector);
  const [page, setPage] = useState(1);

  const onSearchBooking = (values) => {
    setPage(1);
    let startAt = "";
    let endAt = "";
    if (values.startDate !== null) {
      startAt = format(new Date(values.startDate), "yyyy-MM-dd 00:00:00");
    }
    if (values.endDate !== null) {
      endAt = format(new Date(values.endDate), "yyyy-MM-dd 23:59:59");
    }
    dispatch(
      getBookingList({
        ...values,
        startAt,
        endAt,
        page: 1,
      }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onPageChange = (e, newPage) => {
    setPage(newPage);
    let startAt = "";
    let endAt = "";
    if (formRef.current.values.startDate !== null) {
      startAt = format(new Date(formRef.current.values.startDate), "yyyy-MM-dd 00:00:00");
    }
    if (formRef.current.values.endDate !== null) {
      endAt = format(new Date(formRef.current.values.endDate), "yyyy-MM-dd 23:59:59");
    }
    dispatch(
      getBookingList({
        ...formRef.current.values,
        startAt,
        endAt,
        page: newPage,
      }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  useEffect(() => {
    onPageChange(1);
  }, []);

  const renderContent = () => {
    if (getBookingListObj.status === "succeeded") {
      return (
        <>
          <Table>
            <TableHeader
              headerCells={["ID", "Experience Name", "Start At", "End At", "Status", "Action"]}
            />
            <TableBody>
              {getBookingListObj.data.items.map((item) => (
                <BookingRow record={item} key={item.id} />
              ))}
            </TableBody>
          </Table>
          <CustomFooter>
            <Pagination
              sx={{
                "&& .Mui-selected": {
                  backgroundColor: theme.palette.colors.brand.primary,
                  color: theme.palette.colors.text.white,
                },
              }}
              page={page}
              onChange={onPageChange}
              count={getBookingListObj.data.pagination.totalPages}
              variant="outlined"
            />
          </CustomFooter>
        </>
      );
    }

    return <TableLoader />;
  };

  return (
    <PaddedView multiples={3}>
      <Spacer position="top" size="m" />
      <TableTitle title="Bookings" />
      <Spacer position="top" size="xl" />
      <Form
        innerRef={formRef}
        onSubmit={onSearchBooking}
        validationSchema={validationSchema}
        initialValues={{
          startDate: null,
          endDate: null,
          experience: "",
          status: ["open", "pending", "confirmed", "cancelled", "reschedule", "completed"],
        }}
      >
        <DetailCardContainer>
          <Grid container spacing={2}>
            <Grid item sx={{ display: "flex", flex: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={isMobile ? 12 : 3.5}>
                  <FormDatePicker
                    name="startDate"
                    disablePast={false}
                    width="100%"
                    placeholder="Start At"
                  />
                </Grid>
                <Grid item xs={isMobile ? 12 : 3.5}>
                  <FormDatePicker
                    name="endDate"
                    disablePast={false}
                    width="100%"
                    placeholder="End At"
                  />
                </Grid>
                <Grid item xs={isMobile ? 12 : 5}>
                  <FormMultipleValueSelect
                    itemList={[
                      { label: "Open", value: "open" },
                      { label: "Pending", value: "pending" },
                      { label: "Confirmed", value: "confirmed" },
                      { label: "Cancelled", value: "cancelled" },
                      { label: "Reschedule", value: "reschedule" },
                      { label: "Completed", value: "completed" },
                    ]}
                    name="status"
                    placeholder="Select Status"
                    disablePast={false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SearchBar width="100%" name="experience" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item width={useMediaQuery(theme.breakpoints.down("990")) ? "100%" : "150px"}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <BorderButton
                    height={isMobile ? "40px" : "56px"}
                    onClickButton={() => {
                      formRef.current.resetForm();
                      setTimeout(() => {
                        formRef.current.handleSubmit();
                      }, 50);
                    }}
                  >
                    <Text type="BrandColor">Clear</Text>
                  </BorderButton>
                </Grid>
                <Grid item xs={12}>
                  <CtaButton
                    height={isMobile ? "40px" : "56px"}
                    onClickButton={() => formRef.current.handleSubmit()}
                  >
                    <Text type="WhiteColor">Search</Text>
                  </CtaButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DetailCardContainer>
      </Form>
      <Spacer position="top" size="l" />
      <TableWrapper>{renderContent()}</TableWrapper>
    </PaddedView>
  );
}
