import React from "react";
import { Box, Grid } from "@mui/material";
import Text from "../../../components/text.component";
import Spacer from "../../../components/spacer.component";
import SkeletonLoader from "../../../components/skeleton.component";
import CtaButton from "../../../components/buttons/cta-button.component";

function MerchantCommissionLoader() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Text variant="h5">Commission Rate (%)</Text>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <SkeletonLoader width="175px" height="59px" />
          <Spacer size="m" position="left" />
          <CtaButton width="125px" isLoading={true}>
            <Text type="WhiteColor">Save</Text>
          </CtaButton>
        </Box>
      </Grid>
    </Grid>
  );
}

export default MerchantCommissionLoader;
