import React from "react";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Checkbox, ListItemText, OutlinedInput, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import Text from "../text.component";

export default function FormMultipleSelect({ itemList, name, placeholder, width, isTransparent }) {
  const { values, setFieldValue, touched, errors, setFieldTouched } = useFormikContext("");
  const theme = useTheme();
  const showError = touched[name] && typeof errors[name] === "string";

  const handleChange = (event) => {
    setFieldValue(
      name,
      typeof event.target.value === "string" ? event.target.value.split(",") : event.target.value,
    );
  };

  return (
    <FormControl sx={{ width }}>
      <Select
        multiple
        onBlur={() => setFieldTouched(name)}
        value={values[name]}
        onChange={handleChange}
        sx={{
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          backgroundColor: isTransparent ? "transparent" : theme.palette.colors.text.white,
        }}
        input={<OutlinedInput />}
        error={showError}
        displayEmpty
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <Text type="GreyColor">{placeholder}</Text>;
          }

          return selected.join(", ");
        }}
      >
        {itemList.map((item) => (
          <MenuItem key={item.id} value={item.label}>
            <Checkbox checked={values[name].indexOf(item.label) > -1} />
            <ListItemText primary={item.label} />
          </MenuItem>
        ))}
      </Select>
      {showError && (
        <FormHelperText error sx={{ marginLeft: "0px" }}>
          {errors[name]}
        </FormHelperText>
      )}
    </FormControl>
  );
}

FormMultipleSelect.defaultProps = {
  width: "100%",
  isTransparent: false,
};

FormMultipleSelect.propTypes = {
  width: PropTypes.string,
  itemList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  isTransparent: PropTypes.bool,
};
