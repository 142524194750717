import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Typography, TextField, Paper, LinearProgress } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useFormikContext } from "formik";
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import PaddedView from "../padded-view.component";
import Spacer from "../spacer.component";

const searchOptions = {
  componentRestrictions: { country: "MY" },
};

function FormAddressAutoComplete({ name, width }) {
  const { setFieldValue, values, touched, errors, setFieldTouched } = useFormikContext();
  const [address, setAddress] = useState(values[name]);
  const theme = useTheme();
  const showError = touched[name] && typeof errors[name] === "string";

  const handleSelect = async (value, placeID) => {
    const result = await geocodeByAddress(value);
    const latLng = await getLatLng(result[0]);
    const [place] = await geocodeByPlaceId(placeID);

    const { long_name: postcode = "" } =
      place.address_components.find((c) => c.types.includes("postal_code")) || {};
    const { long_name: city = "" } =
      place.address_components.find(
        (c) => c.types.includes("sublocality_level_1") || c.types.includes("locality"),
      ) || {};
    const { long_name: state = "" } =
      place.address_components.find((c) => c.types.includes("administrative_area_level_1")) || {};
    const { long_name: country = "" } =
      place.address_components.find((c) => c.types.includes("country")) || {};

    setAddress(value);
    setFieldValue("address", value);
    setFieldValue("locationLat", latLng.lat.toString());
    setFieldValue("locationLong", latLng.lng.toString());
    setFieldValue("state", state);
    setFieldValue("city", city);
    setFieldValue("country", country);
    setFieldValue("postCode", postcode);
  };

  return (
    <PlacesAutocomplete
      value={address || ""}
      onChange={(text) => {
        setAddress(text);
        setFieldValue("address", "");
        setFieldValue("locationLat", "");
        setFieldValue("locationLong", "");
        setFieldValue("state", "");
        setFieldValue("city", "");
        setFieldValue("country", "");
        setFieldValue("postCode", "");
      }}
      searchOptions={searchOptions}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <Box
          sx={{
            "& .MuiTextField-root": {
              borderTopLeftRadius: theme.shape.borderRadius[0],
              borderTopRightRadius: theme.shape.borderRadius[0],
              borderBottomLeftRadius: theme.shape.borderRadius[0],
              borderBottomRightRadius: theme.shape.borderRadius[0],
              width,
              "& .MuiOutlinedInput-root": {
                borderTopLeftRadius: theme.shape.borderRadius[0],
                borderTopRightRadius: theme.shape.borderRadius[0],
                borderBottomLeftRadius: theme.shape.borderRadius[0],
                borderBottomRightRadius: theme.shape.borderRadius[0],
                backgroundColor: theme.palette.colors.bg.white,
              },
            },
            position: "relative",
          }}
        >
          <TextField
            {...getInputProps()}
            multiline
            placeholder="Address"
            value={address || ""}
            error={showError}
            onBlur={() => setFieldTouched(name)}
            helperText={showError ? errors[name] : null}
          />

          {loading && (
            <>
              <Spacer />
              <LinearProgress />
            </>
          )}

          <Box
            sx={{
              position: "absolute",
              top: 56,
              left: 0,
              background: "#fff",
              zIndex: 2,
              marginTop: 1,
            }}
          >
            {suggestions.map((suggestion) => {
              const style = {
                backgroundColor: suggestion.active ? "#41b6e6" : "#ffffff",
                cursor: "pointer",
              };
              return (
                <Paper
                  {...getSuggestionItemProps(suggestion, { style })}
                  key={`${suggestion.description}`}
                >
                  <PaddedView>
                    <Typography>{suggestion.description}</Typography>
                  </PaddedView>
                </Paper>
              );
            })}
          </Box>
        </Box>
      )}
    </PlacesAutocomplete>
  );
}
FormAddressAutoComplete.defaultProps = {
  width: "100%",
};

FormAddressAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
};
export default FormAddressAutoComplete;
