import { Box, Pagination, styled, Tab, Table, TableBody, Tabs, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Form from "../../../components/forms/form.component";
import DeleteConfirmationModal from "../../../components/notifications/delete-confirmation-modal.component";
import Loading from "../../../components/notifications/loading.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import PaddedView from "../../../components/padded-view.component";
import Spacer from "../../../components/spacer.component";
import TableHeader from "../../../components/table/table-header.component";
import TableTitle from "../../../components/table/table-title.component";
import TableWrapper from "../../../components/table/table-wrapper.component";
import {
    createCreator,
    creatorSelector,
    deleteCreator,
    getCreatorList
} from "../../../services/creator/creator-slice.services";
import CreatorCreateModal from "../components/creator-create-modal.component";
import CreatorRow from "../components/creator-row.component";
import CreatorUnableDeleteModal from "../components/creator-unable-delete-modal.component";
import TableLoader from "../loaders/table-loader.component";

const AntTabs = styled(Tabs)(({ theme }) => ({
    "& .MuiTabs-indicator": {
        backgroundColor: theme.palette.colors.brand.primary,
        height: "2px",
    },
}));

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none",
    color: theme.palette.colors.text.secondary,
    fontSize: theme.fonts.fontSizes.size18,
    fontWeight: theme.fonts.fontWeights.regular,
    "&.Mui-selected": {
        color: theme.palette.colors.brand.primary,
    },
}));

const CustomFooter = styled(Box)({
    display: "flex",
    justifyContent: "center",
    width: "100%",
    minHeight: "70px",
    alignItems: "center",
});

const validationSchema = Yup.object().shape({
    email: Yup.string().required().email().label("Email"),
    firstName: Yup.string().required().label("FirstName"),
    lastName: Yup.string().required().label("LastName"),
    nickName: Yup.string().optional().label("NickName"),
    gender: Yup.string().required().label("Gender"),
    phone: Yup.string()
        .nullable()
        .required()
        .when({
            is: (value) => value?.length,
            then: (rule) => rule.min(10),
        })
        .label("Phone"),
    bankAccountNo: Yup.number().required().label("Account Number"),
    bankName: Yup.string().required().label("Bank Name"),
    bankHolderName: Yup.string().required().label("Account Holder Name"),
    commission: Yup.number().min(0).max(100).required().label("Commission Rate"),
});

function CreatorListScreen() {
    const dispatch = useDispatch();
    const [searchKeyword, setSearchKeyword] = useState("");
    const [currentStatus, setCurrentStatus] = useState("all");
    const [page, setPage] = useState(1);
    const { getCreatorListObj, deleteCreatorObj } = useSelector(creatorSelector);
    const createSnackBar = useContext(SnackbarContext);
    const theme = useTheme();
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [deleteCreatorId, setDeleteCreatorId] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showUnableDeleteModal, setShowUnableDeleteModal] = useState(false);

    useEffect(() => {
    console.log("useEffect called on creator");
        dispatch(getCreatorList({ q: searchKeyword, status: currentStatus, page })).then(
            ({ meta, error }) => {
                if (meta.requestStatus === "rejected") {
                    createSnackBar({
                        message: error.message,
                        type: "error",
                    });
                }
            },
        );
    }, []);

    const resetSearch = () => {
        dispatch(getCreatorList({ q: "", status: "all", page: 1 })).then(({ meta, error }) => {
            if (meta.requestStatus === "rejected") {
                createSnackBar({
                    message: error.message,
                    type: "error",
                });
            }
        });
        setSearchKeyword("");
        setCurrentStatus("all");
        setPage(1);
    };

    const onChangeSearch = (q) => {
        dispatch(getCreatorList({ q, status: currentStatus, page: 1 })).then(({ meta, error }) => {
            if (meta.requestStatus === "rejected") {
                createSnackBar({
                    message: error.message,
                    type: "error",
                });
            }
        });
        setSearchKeyword(q);
    };

    const onPageChange = (e, newPage) => {
        dispatch(getCreatorList({ q: searchKeyword, status: currentStatus, page: newPage })).then(
            ({ meta, error }) => {
                if (meta.requestStatus === "rejected") {
                    createSnackBar({
                        message: error.message,
                        type: "error",
                    });
                }
            },
        );
        setPage(newPage);
    };

    const onChangeStatus = (e, status) => {
        dispatch(getCreatorList({ q: searchKeyword, status, page: 1 })).then(({ meta, error }) => {
            if (meta.requestStatus === "rejected") {
                createSnackBar({
                    message: error.message,
                    type: "error",
                });
            }
        });
        setCurrentStatus(status);
    };

    const onCreateCreator = (value, { resetForm }) => {
        const params = {
            firstName: value.firstName,
            lastName: value.lastName,
            nickName: value.nickName,
            email: value.email,
            phone: value.phone,
            gender: value.gender,
            website: value.website !== "" ? `https://${value.website}` : "",
            commission: value.commission,
            bank: {
                name: value.bankName,
                holderName: value.bankHolderName,
                accountNo: value.bankAccountNo,
            },
        };
        setIsLoading(true);
        dispatch(createCreator({ ...params })).then(({ meta, error, payload }) => {
            setIsLoading(false);
            if (meta.requestStatus === "fulfilled") {
                setShowCreateModal(false);
                resetForm();
                resetSearch();
                createSnackBar({
                    message: payload.message,
                    type: "success",
                });
            }
            if (meta.requestStatus === "rejected") {
                createSnackBar({
                    message: error.message,
                    type: "error",
                });
            }
        });
    };

    const onTriggerDelete = (deleteable, creatorId) => {
        if (deleteable) {
            setDeleteCreatorId(creatorId);
            setShowDeleteModal(true);
            return;
        }
        setShowUnableDeleteModal(true);
    };

    const onConfirmDelete = () => {
        setIsLoading(true);
        dispatch(deleteCreator(deleteCreatorId)).then(({ meta, error, payload }) => {
            setIsLoading(false);
            if (meta.requestStatus === "fulfilled") {
                setShowDeleteModal(false);
                setDeleteCreatorId(null);
                resetSearch();
                createSnackBar({
                    message: payload.message,
                    type: "success",
                });
            } else if (meta.requestStatus === "rejected") {
                createSnackBar({
                    message: error.message,
                    type: "error",
                });
            }
        });
    };

    const renderContent = () => {
        if (getCreatorListObj.status === "succeeded") {
            return (
                <>
                    <Table>
                        <TableHeader
                            headerCells={[
                                "Creator ID",
                                "First Name",
                                "Last Name",
                                "Nick Name",
                                "Commission (%)",
                                "Date Registered",
                                "Status",
                                "",
                            ]}
                        />
                        <TableBody>
                            {getCreatorListObj.data.items.map((item) => (
                                <CreatorRow record={item} key={item.id} onTriggerDelete={onTriggerDelete} />
                            ))}
                        </TableBody>
                    </Table>
                    <CustomFooter>
                        <Pagination
                            sx={{
                                "&& .Mui-selected": {
                                    backgroundColor: theme.palette.colors.brand.primary,
                                    color: theme.palette.colors.text.white,
                                },
                            }}
                            page={page}
                            onChange={onPageChange}
                            count={getCreatorListObj.data.pagination.totalPages}
                            variant="outlined"
                        />
                    </CustomFooter>
                </>
            );
        }

        return <TableLoader />;
    };

    return (
        <PaddedView multiples={3}>
            <CreatorUnableDeleteModal
                showModal={showUnableDeleteModal}
                setShowModal={setShowUnableDeleteModal}
            />
            <DeleteConfirmationModal
                showModal={showDeleteModal}
                setShowModal={setShowDeleteModal}
                label="Are you sure to delete this creator?"
                isLoading={deleteCreatorObj.status === "pending"}
                onConfirmClicked={onConfirmDelete}
            />
            <Loading isLoading={isLoading} />
            <Form
                onSubmit={onCreateCreator}
                validationSchema={validationSchema}
                initialValues={{
                    email: "",
                    firstName: "",
                    lastName: "",
                    nickName: "",
                    website: "",
                    phone: null,
                    gender: "",
                    commission: "",
                    bankHolderName: "",
                    bankName: "",
                    bankAccountNo: "",
                }}
            >
                <CreatorCreateModal isShowModal={showCreateModal} setIsShowModal={setShowCreateModal} />
            </Form>
            <Spacer position="top" size="m" />
            <TableTitle
                title="Creators"
                onChangeSearch={onChangeSearch}
                searchKeyword={searchKeyword}
                placeholder="Search Creators"
                onClickCreateButton={() => setShowCreateModal(true)}
            />
            <Spacer position="top" size="xl" />
            <TableWrapper>
                <Spacer position="top" size="m" />
                <AntTabs value={currentStatus} onChange={onChangeStatus}>
                    <AntTab value="all" label="All" />
                    <AntTab value="approved" label="Approved" />
                    <AntTab value="pending" label="Pending" />
                    <AntTab value="rejected" label="Rejected" />
                </AntTabs>
                <Spacer position="top" size="xl" />
                {renderContent()}
            </TableWrapper>
        </PaddedView>
    );
}

export default CreatorListScreen;
