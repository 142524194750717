import React from "react";
import PropTypes from "prop-types";
import { Box, IconButton, styled, useMediaQuery, useTheme } from "@mui/material";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useFormikContext } from "formik";
import PaddedView from "../../../components/padded-view.component";
import Text from "../../../components/text.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import AddOnsFormFieldText from "./add-ons-form-field-text.component";
import Spacer from "../../../components/spacer.component";
import BorderButton from "../../../components/buttons/border-button.component";

const FieldContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

const BorderContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "white",
  width: "100%",
  border: `1px solid ${theme.palette.colors.ui.border}`,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
}));

const FlexEndBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
});

export default function ExperienceAddOnsItem({
  disabled,
  editIndex,
  setEditIndex,
  isEmptyForm,
  onRemoveAddOns,
}) {
  const theme = useTheme();
  const { resetForm, values } = useFormikContext();
  const isEditing = editIndex === (values.uniqueId !== null ? values.uniqueId : values.id);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (isEmptyForm) {
    return (
      <BorderContainer>
        <PaddedView multiples={isMobile ? 2 : 3}>
          <Spacer size="m" position="top" />
          <FieldContainer sx={{ flexDirection: isMobile ? "column" : "row" }}>
            <Text type="GreyColor" sx={{ width: "110px" }}>
              Label
            </Text>
            <AddOnsFormFieldText name="label" placeholder="Label" disabled={false} />
          </FieldContainer>
          <Spacer position="top" size="s" />
          <FieldContainer sx={{ flexDirection: isMobile ? "column" : "row" }}>
            <Text type="GreyColor" sx={{ width: "110px" }}>
              Sub-Label
            </Text>
            <AddOnsFormFieldText name="sublabel" placeholder="Sub-Label" disabled={false} />
          </FieldContainer>
          <Spacer position="top" size="s" />
          <FieldContainer sx={{ flexDirection: isMobile ? "column" : "row" }}>
            <Text type="GreyColor" sx={{ width: "110px" }}>
              Price (RM)
            </Text>
            <AddOnsFormFieldText name="price" placeholder="Price" disabled={false} />
          </FieldContainer>

          <FlexEndBox sx={{ marginTop: "15px" }}>
            <FormSubmitButton width="100px">
              <Text type="WhiteColor">Add</Text>
            </FormSubmitButton>
          </FlexEndBox>
        </PaddedView>
      </BorderContainer>
    );
  }

  return (
    <BorderContainer>
      <PaddedView multiples={3}>
        {!disabled && (
          <FlexEndBox>
            <IconButton
              disabled={editIndex !== null}
              sx={{ padding: "0px", color: theme.palette.colors.text.primary }}
              onClick={() => setEditIndex(values.uniqueId ? values.uniqueId : values.id)}
            >
              <ModeEditIcon />
            </IconButton>
            <Spacer size="s" position="left" />
            <IconButton
              onClick={() => onRemoveAddOns(values.uniqueId ? values.uniqueId : values.id)}
              disabled={editIndex !== null}
              sx={{ padding: "0px", color: theme.palette.colors.ui.notification }}
            >
              <IndeterminateCheckBoxOutlinedIcon />
            </IconButton>
          </FlexEndBox>
        )}
        <Spacer size="m" position="top" />
        <FieldContainer sx={{ flexDirection: isMobile ? "column" : "row" }}>
          <Text type="GreyColor" sx={{ width: "110px" }}>
            Label
          </Text>
          <AddOnsFormFieldText name="label" placeholder="Label" disabled={!isEditing} />
        </FieldContainer>
        <Spacer position="top" size="s" />
        <FieldContainer sx={{ flexDirection: isMobile ? "column" : "row" }}>
          <Text type="GreyColor" sx={{ width: "110px" }}>
            Sub-Label
          </Text>
          <AddOnsFormFieldText name="sublabel" placeholder="Sub-Label" disabled={!isEditing} />
        </FieldContainer>
        <Spacer position="top" size="s" />
        <FieldContainer sx={{ flexDirection: isMobile ? "column" : "row" }}>
          <Text type="GreyColor" sx={{ width: "110px" }}>
            Price (RM)
          </Text>
          <AddOnsFormFieldText name="price" placeholder="Price" disabled={!isEditing} />
        </FieldContainer>
        {isEditing && (
          <FlexEndBox sx={{ marginTop: "15px" }}>
            <BorderButton
              width="100px"
              onClickButton={() => {
                resetForm();
                setEditIndex(null);
              }}
            >
              <Text type="BrandColor">Cancel</Text>
            </BorderButton>
            <Spacer size="s" position="left" />
            <FormSubmitButton width="100px">
              <Text type="WhiteColor">Save</Text>
            </FormSubmitButton>
          </FlexEndBox>
        )}
      </PaddedView>
    </BorderContainer>
  );
}

ExperienceAddOnsItem.defaultProps = {
  disabled: false,
  editIndex: null,
  setEditIndex: null,
  isEmptyForm: false,
  onRemoveAddOns: null,
};

ExperienceAddOnsItem.propTypes = {
  onRemoveAddOns: PropTypes.func,
  disabled: PropTypes.bool,
  editIndex: PropTypes.number,
  setEditIndex: PropTypes.func,
  isEmptyForm: PropTypes.bool,
};
