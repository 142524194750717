import React from "react";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import PaddedView from "../../../components/padded-view.component";
import Text from "../../../components/text.component";
import Spacer from "../../../components/spacer.component";
import SkeletonLoader from "../../../components/skeleton.component";
import CtaButton from "../../../components/buttons/cta-button.component";

export default function GiftVoucherItemLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Grid item xs={12}>
      <Box sx={{ backgroundColor: "white" }}>
        <PaddedView multiples={3}>
          <Grid container spacing={2}>
            <Grid item xs={isMobile ? 12 : 6}>
              <Text>Label</Text>
              <Spacer size="s" />
              <SkeletonLoader height="56px" />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <Text>Value</Text>
              <Spacer size="s" />
              <SkeletonLoader height="56px" />
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <CtaButton isLoading width="125px">
                <Text>Save</Text>
              </CtaButton>
            </Grid>
          </Grid>
        </PaddedView>
      </Box>
    </Grid>
  );
}
