import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  TableCell,
  IconButton,
  TableRow,
  Box,
  Collapse,
  Table,
  TableBody,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import Spacer from "../../../components/spacer.component";
import TableHeader from "../../../components/table/table-header.component";

function BookingRow({ record }) {
  const [collapse, setCollapse] = useState(false);
  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
  const theme = useTheme();

  return (
    <>
      <TableRow
        key={record.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "0px",
          },
        }}
      >
        <TableCell width="100px">
          <Text variant="body2" sx={{ textAlign: "center" }}>
            {record.id}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" sx={{ textAlign: "center", textTransform: "capitalize" }}>
            {record.type.replace("_", " ")}
          </Text>
        </TableCell>
        <TableCell width="300px">
          <Link
            style={{ textDecorationColor: theme.palette.colors.text.primary }}
            to={{
              pathname: routes.EXPERIENCEDETAIL,
              search: `experienceId=${record.experience.id}`,
            }}
          >
            <Text width="300px" noWrap={true} variant="body2" sx={{ textAlign: "center" }}>
              {record.experience.title}
            </Text>
          </Link>
        </TableCell>
        <TableCell>
          <Text variant="body2" sx={{ textAlign: "center" }}>
            {capitalize(record.status.toString())}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" sx={{ textAlign: "center" }}>
            {record.createdAt}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" sx={{ textAlign: "center" }}>
            {record.order.price}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "100px" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton aria-label="expand row" size="small" onClick={() => setCollapse(!collapse)}>
              {collapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={collapse} unmountOnExit timeout="auto">
            <Spacer size="m" />
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <Text>Booking Details</Text>
            </Box>
            <Spacer />
            <Table size="small">
              <TableHeader headerCells={["Start At", "End At", "Quantity", ""]} />
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Text variant="body2" noWrap={true} sx={{ textAlign: "center" }}>
                      {record.startAt || "-"}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text variant="body2" noWrap={true} sx={{ textAlign: "center" }}>
                      {record.endAt || "-"}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text variant="body2" noWrap={true} sx={{ textAlign: "center" }}>
                      {record.noOfPax || "-"}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Link
                      style={{ textDecorationColor: theme.palette.colors.brand.primary }}
                      to={{
                        pathname: routes.BOOKING_DETAIL,
                        search: `bookingId=${record.id}`,
                      }}
                    >
                      <Text
                        variant="body2"
                        noWrap={true}
                        type="BrandColor"
                        sx={{ textAlign: "center" }}
                      >
                        View Detail
                      </Text>
                    </Link>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Spacer size="m" />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

BookingRow.defaultProps = {
  record: {
    id: null,
    type: "",
    startAt: PropTypes.string,
    endAt: PropTypes.string,
    createdAt: PropTypes.string,
    experience: PropTypes.shape({
      id: null,
      title: PropTypes.string,
    }),
    recipient: PropTypes.shape({
      isGuest: PropTypes.bool,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      contactNo: PropTypes.string,
    }),
    order: PropTypes.shape({
      price: PropTypes.string,
    }),
    noOfPax: null,
    cancellable: PropTypes.bool,
    status: PropTypes.string,
  },
};

BookingRow.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    startAt: PropTypes.string,
    endAt: PropTypes.string,
    createdAt: PropTypes.string,
    experience: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    }),
    recipient: PropTypes.shape({
      isGuest: PropTypes.bool,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      contactNo: PropTypes.string,
    }),
    order: PropTypes.shape({
      price: PropTypes.string,
    }),
    noOfPax: PropTypes.number,
    cancellable: PropTypes.bool,
    status: PropTypes.string,
  }),
};

export default BookingRow;
