import React from "react";
import { Box, CircularProgress, IconButton, styled, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import StarRateIcon from "@mui/icons-material/StarRate";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useSelector } from "react-redux";
import Spacer from "../../../../components/spacer.component";
import { exploreSectionSelector } from "../../../../services/marketing/explore-section/explore-section-slice.services";

const StyledBox = styled(Box)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  padding: "10px",
  backgroundColor: theme.palette.colors.bg.recommendationsBg,
}));

const FullWidthBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
});

const BorderLine = styled(Box)(({ theme }) => ({
  width: "95%",
  height: "1px",
  backgroundColor: theme.palette.colors.ui.border,
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.brand.secondary,
  fontWeight: theme.fonts.fontWeights.bold,
  lineHeight: "22px",
  textAlign: "start",
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
  overflowWrap: "anywhere",
}));

const StyledPriceText = styled(Typography)(({ theme }) => ({
  textAlign: "start",
  color: theme.palette.colors.brand.primary,
  fontWeight: theme.fonts.fontWeights.bold,
}));

const StyledRatingText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size13,
  textAlign: "start",
}));

const StyledStateText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size12,
  textAlign: "start",
}));

const StyledBottomBox = styled(Box)({
  marginTop: "5px",
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

const SpaceBetweenRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-start",
});

const StyledExperienceDetailsBox = styled(Box)({
  width: "100%",
  "& .MuiButton-root": {
    textTransform: "none",
    padding: "0px",
    "&:hover": {
      backgroundColor: "transparent",
      borderColor: "transparent",
      boxShadow: "none",
    },
  },
});

const StyledAddIcon = styled(IconButton)({
  padding: "0px",
  ":hover": {
    backgroundColor: "transparent",
  },
});

const AbsoluteButtonBox = styled(Box)({
  position: "absolute",
  bottom: 0,
  right: 0,
});

const StyledRatingStar = styled(StarRateIcon)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.body,
  marginTop: "-5px",
  color: theme.palette.colors.brand.primary,
}));

const ReviewGreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size14,
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
}));

const StyledRatingBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
});

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.brand.primary,
  fontSize: theme.fonts.fontSizes.size14,
}));

function ExperienceCard({ experience, onRemoveExploreExperience, onAddExploreExperience }) {
  const theme = useTheme();
  const { getExploreExperienceListObj, removeExploreExperienceObj, addExploreExperienceObj } =
    useSelector(exploreSectionSelector);

  const renderRating = () => (
    <StyledRatingBox>
      <StyledRatingStar />
      <PrimaryColorText>{experience.ratings}</PrimaryColorText>
      <Spacer size="xs" position="left" />
      <ReviewGreyText>{`(${experience.noReviews})`}</ReviewGreyText>
    </StyledRatingBox>
  );

  const renderPrice = () => (
    <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="flex-end">
      <StyledRatingText>From</StyledRatingText>
      <Spacer size="xs" position="left" />
      <StyledPriceText>{`RM${experience.priceCents}`}</StyledPriceText>
      <Spacer size="xs" position="left" />
      <StyledRatingText>
        / {experience.type === "group" ? `group of ${experience.groupOfPeople}` : "person"}
      </StyledRatingText>
    </Box>
  );

  const renderIconButton = () => {
    const isChecked = getExploreExperienceListObj.data.some((item) => item.id === experience.id);
    if (
      removeExploreExperienceObj.status === "pending" ||
      addExploreExperienceObj.status === "pending"
    ) {
      return (
        <Box sx={{ position: "relative" }}>
          <CircularProgress
            size={32}
            sx={{
              color: theme.palette.colors.brand.primary,
              position: "absolute",
              top: "0px",
              left: "0px",
            }}
          />
          <StyledAddIcon disabled>
            {isChecked ? (
              <CheckCircleIcon sx={{ fontSize: theme.fonts.fontSizes.size32 }} />
            ) : (
              <AddCircleIcon sx={{ fontSize: theme.fonts.fontSizes.size32 }} />
            )}
          </StyledAddIcon>
        </Box>
      );
    }
    return isChecked ? (
      <StyledAddIcon
        sx={{ color: theme.palette.colors.button.addedButton }}
        onClick={() => onRemoveExploreExperience(experience.id)}
      >
        <CheckCircleIcon sx={{ fontSize: theme.fonts.fontSizes.size32 }} />
      </StyledAddIcon>
    ) : (
      <StyledAddIcon
        sx={{ color: theme.palette.colors.brand.primary }}
        onClick={() => onAddExploreExperience(experience.id)}
      >
        <AddCircleIcon sx={{ fontSize: theme.fonts.fontSizes.size32 }} />
      </StyledAddIcon>
    );
  };

  return (
    <>
      <StyledBox>
        <Box sx={{ position: "relative" }}>
          <AbsoluteButtonBox>{renderIconButton()}</AbsoluteButtonBox>

          <img src={experience.imagePath} alt="experience-img" style={{ width: "100%" }} />
          <StyledBottomBox>
            <StyledExperienceDetailsBox>
              <SpaceBetweenRowBox>
                <StyledTitle>{experience.title}</StyledTitle>
                {renderRating()}
              </SpaceBetweenRowBox>
              {experience.location.area !== "" && (
                <StyledStateText>{experience.location.area}</StyledStateText>
              )}
            </StyledExperienceDetailsBox>
            <Spacer size="s" position="top" />
            {renderPrice()}
            <Spacer size="xl" position="top" />
          </StyledBottomBox>
        </Box>
      </StyledBox>

      <Spacer size="xs" position="top" />
      <FullWidthBox>
        <BorderLine />
      </FullWidthBox>
    </>
  );
}

ExperienceCard.defaultProps = {
  onRemoveExploreExperience: null,
  onAddExploreExperience: null,
};

ExperienceCard.propTypes = {
  onRemoveExploreExperience: PropTypes.func,
  onAddExploreExperience: PropTypes.func,
  experience: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    priceCents: PropTypes.string,
    type: PropTypes.string,
    groupOfPeople: PropTypes.number,
    imagePath: PropTypes.string,
    location: PropTypes.shape({
      area: PropTypes.string,
    }),
    exploreExperince: PropTypes.shape({
      id: PropTypes.number,
    }),
    ratings: PropTypes.number,
    noReviews: PropTypes.number,
  }).isRequired,
};

export default ExperienceCard;
