import React from "react";
import { Box, Grid, IconButton, Modal, styled, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import Text from "../../../../components/text.component";
import FormFieldTextWithCounter from "../../../../components/forms/form-field-text-with-counter.component";
import Spacer from "../../../../components/spacer.component";
import FormImageCropper from "../../../../components/forms/form-image-cropper.component";
import FormDatePicker from "../../../../components/forms/form-date-picker.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";

const ModalBox = styled(Box)(({ theme, isMobile }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.brand.white,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  padding: isMobile ? theme.padding.paddingX[1] : theme.padding.paddingX[0],
  width: isMobile ? "95%" : "600px",
  outline: "none",
  maxHeight: "95%",
  overflow: "scroll",
  maxWidth: "90%",
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.colors.brand.secondary,
}));

const SpaceBetweenBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-end",
});

export default function FestivalSectionCreateModal({ showModal, setShowModal, isLoading }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { resetForm } = useFormikContext();
  const cropSetting = {
    unit: "px",
    aspect: 1,
    width: 50,
    height: 50,
    x: 0,
    y: 0,
  };
  const aspectRatio = 1;

  const handleCancel = () => {
    resetForm();
    setShowModal(false);
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox isMobile={isMobile}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SpaceBetweenBox>
              <Box sx={{ width: "24px", height: "24px" }} />
              <Text
                variant="h5"
                sx={{
                  textAlign: "center",
                  fontWeight: theme.fonts.fontWeights.bold,
                }}
              >
                Create Festival Section
              </Text>
              <CloseIconButton onClick={handleCancel}>
                <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
              </CloseIconButton>
            </SpaceBetweenBox>
          </Grid>
          <Grid item xs={6}>
            <Text>Start from</Text>
            <Spacer position="top" size="xs" />
            <FormDatePicker name="startAt" />
          </Grid>
          <Grid item xs={6}>
            <Text>End at</Text>
            <Spacer position="top" size="xs" />
            <FormDatePicker name="endAt" />
          </Grid>

          <Grid item>
            <FormImageCropper
              name="iconPath"
              label="Icon"
              width="100px"
              height="100px"
              cropSetting={cropSetting}
              aspectRatio={aspectRatio}
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Text>Title</Text>
            <Spacer position="top" size="xs" />
            <FormFieldTextWithCounter
              name="label"
              maxLength={50}
              placeholder="Enter title here..."
            />
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={12}>
            <FormSubmitButton isLoading={isLoading}>
              <Text type="WhiteColor">Create</Text>
            </FormSubmitButton>
          </Grid>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

FestivalSectionCreateModal.defaultProps = {
  isLoading: false,
};

FestivalSectionCreateModal.propTypes = {
  isLoading: PropTypes.bool,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};
