import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { delReq, getReq, postReq } from "../api-services";

export const getBestSellerList = createAsyncThunk("employees/best-seller/list", async () => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/marketings/best_seller`,
  );
  return response;
});

export const addBestSeller = createAsyncThunk("employees/best-seller/add", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/marketings/best_seller`,
    payload,
  );
  return response;
});

export const removeBestSeller = createAsyncThunk(
  "employees/best-seller/remove",
  async (bestSellerID) => {
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/marketings/best_seller/${bestSellerID}`,
    );
    return response;
  },
);

const bestSellerSlice = createSlice({
  name: "bestSeller",
  initialState: {
    getBestSellerListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    addBestSellerObj: { status: "idle", data: null, successMessage: null, errorMessage: null },
    removeBestSellerObj: { status: "idle", data: null, successMessage: null, errorMessage: null },
  },
  reducers: {},
  extraReducers: {
    [getBestSellerList.pending]: (state) => {
      state.getBestSellerListObj.status = "pending";
    },
    [getBestSellerList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getBestSellerListObj.status = "succeeded";
      state.getBestSellerListObj.data = data;
      state.getBestSellerListObj.successMessage = message;
    },
    [getBestSellerList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getBestSellerListObj.status = "failed";
      state.getBestSellerListObj.errorMessage = message;
    },
    [addBestSeller.pending]: (state) => {
      state.addBestSellerObj.status = "pending";
    },
    [addBestSeller.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.addBestSellerObj.status = "succeeded";
      state.addBestSellerObj.data = data;
      state.addBestSellerObj.successMessage = message;

      state.getBestSellerListObj.data = data;
    },
    [addBestSeller.rejected]: (state, action) => {
      const { message } = action.error;

      state.addBestSellerObj.status = "failed";
      state.addBestSellerObj.errorMessage = message;
    },
    [removeBestSeller.pending]: (state) => {
      state.removeBestSellerObj.status = "pending";
    },
    [removeBestSeller.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.removeBestSellerObj.status = "succeeded";
      state.removeBestSellerObj.data = data;
      state.removeBestSellerObj.successMessage = message;

      state.getBestSellerListObj.data = data;
    },
    [removeBestSeller.rejected]: (state, action) => {
      const { message } = action.error;

      state.removeBestSellerObj.status = "failed";
      state.removeBestSellerObj.errorMessage = message;
    },
  },
});

export default bestSellerSlice.reducer;

// state
export const bestSellerSelector = (state) => state.bestSeller;
