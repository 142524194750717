import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Grid,
  Link,
  useMediaQuery,
  useTheme,
  styled,
  Table,
  TableBody,
  Pagination,
} from "@mui/material";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import PaddedView from "../../../components/padded-view.component";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import Spacer from "../../../components/spacer.component";
import Form from "../../../components/forms/form.component";
import {
  categorySelector,
  deleteCategoryImage,
  deleteSubcategory,
  getCategoryDetail,
  getSubcategoryIndex,
  getCategoryExperienceList,
  updateCategory,
  updateCategoryImageOrder,
  uploadCategoryImage,
  createSubcategory,
} from "../../../services/category/category-slice.services";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import Loading from "../../../components/notifications/loading.component";
import CategoryImageGrid from "../components/category-image-grid.component";
import CategoryDetailLoader from "../loader/category-detail-loader.component";
import FormFieldTextWithCounter from "../../../components/forms/form-field-text-with-counter.component";
import FormFieldTextMultiline from "../../../components/forms/form-field-text-with-multiline.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import BorderButton from "../../../components/buttons/border-button.component";
import DetailCardContainer from "../../../components/detail-card-container.component";
import TableHeader from "../../../components/table/table-header.component";
import CategoryExperienceRow from "../components/category-experience-row.component";
import SubcategoryRow from "../components/subcategory-row.component";
import DeleteConfirmationModal from "../../../components/notifications/delete-confirmation-modal.component";
import SubcategoryCreateModal from "../components/subcategory-create-modal.component";

const validationSchema = Yup.object().shape({
  description: Yup.string().required().label("Category Description"),
  label: Yup.string().required().label("Category Name"),
});

const createSubcategoryValidationSchema = Yup.object().shape({
  description: Yup.string().required().label("Subcategory Description"),
  label: Yup.string().required().label("Subcategory Name"),
});

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

export default function CategoryEditScreen() {
  const dispatch = useDispatch();
  const location = useLocation();
  const categoryId = new URLSearchParams(location.search).get("categoryId");

  const createSnackBar = useContext(SnackbarContext);
  const {
    getCategoryDetailObj,
    updateCategoryObj,
    getCategoryExperienceListObj,
    getSubcategoryIndexObj,
    createSubcategoryObj,
    deleteSubcategoryObj,
  } = useSelector(categorySelector);
  const [isLoading, setIsLoading] = useState(false);
  const [showCropperModal, setShowCropperModal] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [page, setPage] = useState(1);
  const [subcategoryListPage, setSubcategoryListPage] = useState(1);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteSubcategoryId, setDeleteSubcategoryId] = useState("");

  useEffect(() => {
    dispatch(getCategoryDetail(categoryId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });

    dispatch(getSubcategoryIndex({ categoryId })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });

    dispatch(getCategoryExperienceList({ categoryId, page })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  const onPageChange = (e, newPage) => {
    dispatch(getCategoryExperienceList({ categoryId, page: newPage })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
    setPage(newPage);
  };

  const onSubcategoryListPageChange = (e, newPage) => {
    dispatch(getSubcategoryIndex({ categoryId, page: newPage })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
    setSubcategoryListPage(newPage);
  };

  const onUpdateCategory = (values) => {
    setIsLoading(true);
    dispatch(updateCategory(values)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onUploadCategoryImage = (imagePath) => {
    setIsLoading(true);
    dispatch(uploadCategoryImage({ categoryId, imagePath })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setShowCropperModal(false);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onImageOrderUpdate = ({ imageId, orderType }) => {
    setIsLoading(true);
    dispatch(updateCategoryImageOrder({ categoryId, imageId, orderType })).then(
      ({ meta, error, payload }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          setShowCropperModal(false);
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      },
    );
  };

  const onDeleteCategoryImage = (imageId) => {
    setIsLoading(true);
    dispatch(deleteCategoryImage({ categoryId, imageId })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onConfirmDelete = () => {
    setIsLoading(true);
    dispatch(deleteSubcategory({ categoryId, deleteSubcategoryId })).then(
      ({ meta, error, payload }) => {
        setIsLoading(false);
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
        if (meta.requestStatus === "fulfilled") {
          setPage(1);
          setShowDeleteModal(false);
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        }
      },
    );
  };

  const onToggleDelete = (delSubcategoryId) => {
    setDeleteSubcategoryId(delSubcategoryId);
    setShowDeleteModal(true);
  };

  const onCreateSubcategory = (values, { resetForm }) => {
    setIsLoading(true);
    dispatch(createSubcategory({ categoryId, ...values })).then(({ meta, error, payload }) => {
      setIsLoading(false);

      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
      if (meta.requestStatus === "fulfilled") {
        setShowCreateModal(false);
        resetForm();
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
    });
  };

  const renderContent = () => {
    if (
      getCategoryDetailObj.status === "succeeded" &&
      getCategoryExperienceListObj.status === "succeeded" &&
      getSubcategoryIndexObj.status === "succeeded"
    ) {
      return (
        <Form
          validationSchema={validationSchema}
          initialValues={{
            categoryId,
            description: getCategoryDetailObj.data.description,
            label: getCategoryDetailObj.data.label,
          }}
          onSubmit={onUpdateCategory}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Text variant="h4">Edit Category</Text>
            </Grid>
            <Grid item xs={12}>
              <DetailCardContainer>
                <Grid container>
                  <Grid item xs={12}>
                    <Text>Category Name</Text>
                    <Spacer size="xs" position="top" />
                    <FormFieldTextWithCounter
                      maxLength={50}
                      name="label"
                      placeholder="Enter Banner Name here"
                      isLoading={updateCategoryObj.status === "pending"}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", flexDirection: isMobile ? "column" : "row" }}>
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <Text>Category Description</Text>
                        <Spacer size="xs" position="top" />
                        <FormFieldTextMultiline
                          name="description"
                          placeholder="Enter category description here..."
                          multiline
                          rows={5}
                          isLoading={updateCategoryObj.status === "pending"}
                        />
                      </Box>
                      <Spacer position={isMobile ? "top" : "left"} size="m" />
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <FormSubmitButton width="125px">
                          <Text type="WhiteColor">Save</Text>
                        </FormSubmitButton>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </DetailCardContainer>
            </Grid>

            <Grid item xs={12}>
              <Text>Category Images</Text>
              <Spacer size="xs" position="top" />
              <CategoryImageGrid
                showCropperModal={showCropperModal}
                setShowCropperModal={setShowCropperModal}
                imageList={getCategoryDetailObj.data.images}
                onUploadCategoryImage={onUploadCategoryImage}
                onDeleteCategoryImage={onDeleteCategoryImage}
                onImageOrderUpdate={onImageOrderUpdate}
              />
            </Grid>

            <Grid item xs={12}>
              <DetailCardContainer>
                <Grid item xs={12}>
                  <Text variant="h5">Experience List</Text>
                </Grid>
                <Table>
                  <TableHeader headerCells={["ID", "Experience Name", ""]} />
                  <TableBody>
                    {getCategoryExperienceListObj.data.items.map((item) => (
                      <CategoryExperienceRow record={item} key={item.id} />
                    ))}
                  </TableBody>
                </Table>
                <CustomFooter>
                  <Pagination
                    sx={{
                      "&& .Mui-selected": {
                        backgroundColor: theme.palette.colors.brand.primary,
                        color: theme.palette.colors.text.white,
                      },
                    }}
                    page={page}
                    onChange={onPageChange}
                    count={getCategoryExperienceListObj.data.pagination.totalPages}
                    variant="outlined"
                  />
                </CustomFooter>
              </DetailCardContainer>
            </Grid>

            <Grid item xs={12}>
              <DetailCardContainer>
                <Grid container>
                  <Grid item xs={10}>
                    <Text variant="h5">Subcategory List</Text>
                  </Grid>
                  <Grid item xs={2}>
                    <BorderButton onClickButton={() => setShowCreateModal(true)}>
                      <Text type="BrandColor">Create</Text>
                    </BorderButton>
                  </Grid>
                </Grid>
                <Table>
                  <TableHeader
                    headerCells={[
                      "ID",
                      "Subcategory Name",
                      "Date Created",
                      "Active Experience",
                      "",
                    ]}
                  />
                  <TableBody>
                    {getSubcategoryIndexObj.data.items.map((item) => (
                      <SubcategoryRow record={item} onToggleDelete={onToggleDelete} key={item.id} />
                    ))}
                  </TableBody>
                </Table>
                <CustomFooter>
                  <Pagination
                    sx={{
                      "&& .Mui-selected": {
                        backgroundColor: theme.palette.colors.brand.primary,
                        color: theme.palette.colors.text.white,
                      },
                    }}
                    page={subcategoryListPage}
                    onChange={onSubcategoryListPageChange}
                    count={getSubcategoryIndexObj.data.pagination.totalPages}
                    variant="outlined"
                  />
                </CustomFooter>
              </DetailCardContainer>
            </Grid>

            <Grid item xs={12} />
          </Grid>
        </Form>
      );
    }
    return <CategoryDetailLoader />;
  };

  return (
    <PaddedView multiples={isMobile ? 1 : 3}>
      <Form
        initialValues={{ label: "", description: "" }}
        onSubmit={onCreateSubcategory}
        validationSchema={createSubcategoryValidationSchema}
      >
        <SubcategoryCreateModal
          isLoading={createSubcategoryObj.status === "pending"}
          showModal={showCreateModal}
          setShowModal={setShowCreateModal}
        />
      </Form>
      <DeleteConfirmationModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        label="Are you sure to delete this subcategory?"
        isLoading={deleteSubcategoryObj.status === "pending"}
        onConfirmClicked={onConfirmDelete}
      />
      <Loading isLoading={isLoading} />
      <Link href={routes.CATEGORY_LIST} style={{ textDecoration: "none" }}>
        <Text type="GreyColor">{`< Back`}</Text>
      </Link>
      <Spacer size="xl" position="top" />
      {renderContent()}
    </PaddedView>
  );
}
