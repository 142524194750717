import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  TableCell,
  IconButton,
  TableRow,
  Box,
  Collapse,
  Table,
  TableBody,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import Spacer from "../../../components/spacer.component";
import TableHeader from "../../../components/table/table-header.component";

function PromoCodeRow({ record }) {
  const [collapse, setCollapse] = useState(false);
  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
  const theme = useTheme();

  return (
    <>
      <TableRow
        key={record.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "0px",
          },
        }}
      >
        <TableCell width="25px">
          <Text variant="body2" sx={{ textAlign: "center" }}>
            {record.id}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" sx={{ textAlign: "center" }}>
            {record.title}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" sx={{ textAlign: "center" }}>
            {record.code}
          </Text>
        </TableCell>
        <TableCell width="100px">
          <Text variant="body2" sx={{ textAlign: "center" }}>
            {record.type === "percentage" ? `${record.discount}%` : `RM ${record.discount}`}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" sx={{ textAlign: "center", minWidth: "140px" }}>
            {record.startAt}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" sx={{ textAlign: "center", minWidth: "140px" }}>
            {record.endAt}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" sx={{ textAlign: "center" }}>
            {record.redeemedCount}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "100px" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton aria-label="expand row" size="small" onClick={() => setCollapse(!collapse)}>
              {collapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={collapse} unmountOnExit timeout="auto">
            <Spacer size="m" />
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <Text>Promo Code Details</Text>
            </Box>
            <Spacer />
            <Table size="small">
              <TableHeader
                headerCells={[
                  "Type",
                  "Max cap amount",
                  "Max no. claims",
                  "Max no. claims per user",
                  "New user only",
                  "Users specific",
                  "Experiences specific",
                  "",
                ]}
              />
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Text variant="body2" noWrap={true} sx={{ textAlign: "center" }}>
                      {capitalize(record.type.replace("_", " "))}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text variant="body2" noWrap={true} sx={{ textAlign: "center" }}>
                      {`RM ${record.maxCapAmount}`}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text variant="body2" noWrap={true} sx={{ textAlign: "center" }}>
                      {record.maxNoClaims}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text variant="body2" noWrap={true} sx={{ textAlign: "center" }}>
                      {record.maxNoClaimsPerUser}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text variant="body2" noWrap={true} sx={{ textAlign: "center" }}>
                      {record.newUserOnly ? "Yes" : "No"}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text variant="body2" noWrap={true} sx={{ textAlign: "center" }}>
                      {record.usersSpecific ? "Yes" : "No"}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text variant="body2" noWrap={true} sx={{ textAlign: "center" }}>
                      {record.experiencesSpecific ? "Yes" : "No"}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Link
                      style={{ textDecorationColor: theme.palette.colors.brand.primary }}
                      to={{
                        pathname: routes.PROMO_CODE_DETAIL,
                        search: `promoCodeId=${record.id}`,
                      }}
                    >
                      <Text
                        variant="body2"
                        noWrap={true}
                        type="BrandColor"
                        sx={{ textAlign: "center" }}
                      >
                        View Detail
                      </Text>
                    </Link>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Spacer size="m" />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

PromoCodeRow.defaultProps = {
  record: {
    id: null,
    title: "",
    code: "",
    discount: "",
    startAt: PropTypes.string,
    endAt: PropTypes.string,
    type: "",
    maxCapAmount: "",
    maxNoClaims: "",
    maxNoClaimsPerUser: "",
    newUserOnly: "",
    usersSpecific: "",
    experiencesSpecific: "",
    redeemedCount: "",
  },
};

PromoCodeRow.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    code: PropTypes.string,
    discount: PropTypes.string,
    startAt: PropTypes.string,
    endAt: PropTypes.string,
    type: PropTypes.string,
    maxCapAmount: PropTypes.string,
    maxNoClaims: PropTypes.number,
    maxNoClaimsPerUser: PropTypes.number,
    newUserOnly: PropTypes.bool,
    usersSpecific: PropTypes.bool,
    experiencesSpecific: PropTypes.bool,
    redeemedCount: PropTypes.number,
  }),
};

export default PromoCodeRow;
