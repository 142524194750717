import { Box, styled, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import Text from "../text.component";
import CtaButton from "../buttons/cta-button.component";
import Spacer from "../spacer.component";
import SearchBar from "./search-bar.component";

const SpaceBetweenFlexEndBox = styled(Box)({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "flex-end",
});

export default function TableTitle({
  title,
  onClickCreateButton,
  searchKeyword,
  onChangeSearch,
  placeholder,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (isMobile) {
    return (
      <Box>
        <Text variant={isMobile ? "h5" : "h3"}>{title}</Text>
        <Spacer size="s" position="top" />
        {onClickCreateButton !== null && (
          <CtaButton width="125px" onClickButton={onClickCreateButton}>
            <Text type="WhiteColor">Create</Text>
          </CtaButton>
        )}
        <Spacer size="s" position="top" />
        {onChangeSearch !== null && (
          <SearchBar
            searchKeyword={searchKeyword}
            setSearchKeyword={onChangeSearch}
            placeholder={placeholder}
          />
        )}
      </Box>
    );
  }

  return (
    <SpaceBetweenFlexEndBox>
      <Text variant="h3">{title}</Text>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        {onClickCreateButton !== null && (
          <>
            <CtaButton width="125px" onClickButton={onClickCreateButton}>
              <Text type="WhiteColor">Create</Text>
            </CtaButton>
            <Spacer size="m" position="left" />
          </>
        )}
        {onChangeSearch !== null && (
          <SearchBar
            searchKeyword={searchKeyword}
            setSearchKeyword={onChangeSearch}
            placeholder={placeholder}
          />
        )}
      </Box>
    </SpaceBetweenFlexEndBox>
  );
}

TableTitle.defaultProps = {
  onClickCreateButton: null,
  onChangeSearch: null,
  placeholder: "",
  searchKeyword: "",
};

TableTitle.propTypes = {
  title: PropTypes.string.isRequired,
  onClickCreateButton: PropTypes.func,
  onChangeSearch: PropTypes.func,
  placeholder: PropTypes.string,
  searchKeyword: PropTypes.string,
};
