import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import StarRateIcon from "@mui/icons-material/StarRate";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useSelector } from "react-redux";
import Spacer from "../../../../components/spacer.component";
import { featuredExperienceSelector } from "../../../../services/marketing/featured-experience/featured-experience-slice.services";
import Text from "../../../../components/text.component";

const NumberingBox = styled(Box)({
  position: "absolute",
  height: "25px",
  width: "25px",
  backgroundColor: "rgba(0,0,0,0.5)",
  top: "10px",
  left: "10px",
  borderRadius: "25px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const StyledButtonIcon = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  padding: 0,
  top: "10px",
  right: "10px",
  backgroundColor: theme.palette.colors.brand.white,
  width: "30px",
  height: "30px",
  ":hover": { backgroundColor: theme.palette.colors.brand.white },
}));

const StyledMoreHorizIcon = styled(MoreHorizIcon)(({ theme }) => ({
  fontSize: "25px",
  color: theme.palette.colors.brand.secondary,
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 4,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  padding: "10px",
  backgroundColor: theme.palette.colors.bg.recommendationsBg,
}));

const FullWidthBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
});

const BorderLine = styled(Box)(({ theme }) => ({
  width: "95%",
  height: "1px",
  backgroundColor: theme.palette.colors.ui.border,
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.brand.secondary,
  fontWeight: theme.fonts.fontWeights.bold,
  lineHeight: "22px",
  textAlign: "start",
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
  overflowWrap: "anywhere",
}));

const StyledPriceText = styled(Typography)(({ theme }) => ({
  textAlign: "start",
  color: theme.palette.colors.brand.primary,
  fontWeight: theme.fonts.fontWeights.bold,
}));

const StyledRatingText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size13,
  textAlign: "start",
}));

const StyledStateText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size12,
  textAlign: "start",
}));

const StyledBottomBox = styled(Box)({
  marginTop: "5px",
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

const SpaceBetweenRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-start",
});

const StyledExperienceDetailsBox = styled(Box)({
  width: "100%",
  "& .MuiButton-root": {
    textTransform: "none",
    padding: "0px",
    "&:hover": {
      backgroundColor: "transparent",
      borderColor: "transparent",
      boxShadow: "none",
    },
  },
});

const AbsoluteButtonBox = styled(Box)({
  position: "absolute",
  bottom: 0,
  right: 0,
});

const StyledAddIcon = styled(IconButton)({
  padding: "0px",
  ":hover": {
    backgroundColor: "transparent",
  },
});

const StyledRatingStar = styled(StarRateIcon)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.body,
  marginTop: "-5px",
  color: theme.palette.colors.brand.primary,
}));

const ReviewGreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size14,
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
}));

const StyledRatingBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
});

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.brand.primary,
  fontSize: theme.fonts.fontSizes.size14,
}));

function ExperiencesCard({
  experience,
  onHandleRemove,
  onHandleAdd,
  isLast,
  index,
  isSelectedList,
  onHandleUpdateOrder,
}) {
  const theme = useTheme();
  const { getFeaturedExperienceListObj, addFeaturedExperienceObj, removeFeaturedExperienceObj } =
    useSelector(featuredExperienceSelector);
  const [showMenu, setShowMenu] = useState(null);

  const getFeaturedId = () =>
    getFeaturedExperienceListObj.data.find((i) => i.experience.id === experience.id).id;

  const handleOpenMenu = (event) => {
    setShowMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setShowMenu(null);
  };

  const renderMenu = () => (
    <StyledMenu
      sx={{ mt: "30px" }}
      anchorEl={showMenu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(showMenu)}
      onClose={handleCloseMenu}
    >
      {!isLast && (
        <MenuItem
          key="1"
          onClick={() => {
            onHandleUpdateOrder({ featuredExperienceId: getFeaturedId(), type: "backward" });
            handleCloseMenu();
          }}
        >
          <Text textAlign="center">Move Backward</Text>
        </MenuItem>
      )}

      {index !== 1 && (
        <MenuItem
          key="2"
          onClick={() => {
            onHandleUpdateOrder({ featuredExperienceId: getFeaturedId(), type: "forward" });
            handleCloseMenu();
          }}
        >
          <Text textAlign="center">Move Foward</Text>
        </MenuItem>
      )}
    </StyledMenu>
  );

  const renderRating = () => (
    <StyledRatingBox>
      <StyledRatingStar />
      <PrimaryColorText>{experience.ratings}</PrimaryColorText>
      <Spacer size="xs" position="left" />
      <ReviewGreyText>{`(${experience.noReviews})`}</ReviewGreyText>
    </StyledRatingBox>
  );

  const renderPrice = () => (
    <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="flex-end">
      <StyledRatingText>From</StyledRatingText>
      <Spacer size="xs" position="left" />
      <StyledPriceText>{`RM${experience.priceCents}`}</StyledPriceText>
      <Spacer size="xs" position="left" />
      <StyledRatingText>
        / {experience.type === "group" ? `group of ${experience.groupOfPeople}` : "person"}
      </StyledRatingText>
    </Box>
  );

  const renderIconButton = () => {
    const isChecked = getFeaturedExperienceListObj.data.some(
      (checkedCheckbox) => checkedCheckbox.experience.id === experience.id,
    );
    if (
      removeFeaturedExperienceObj.status === "pending" ||
      addFeaturedExperienceObj.status === "pending"
    ) {
      return (
        <Box sx={{ position: "relative" }}>
          <CircularProgress
            size={32}
            sx={{
              color: theme.palette.colors.brand.primary,
              position: "absolute",
              top: "0px",
              left: "0px",
            }}
          />
          <StyledAddIcon disabled>
            {isChecked ? (
              <CheckCircleIcon sx={{ fontSize: theme.fonts.fontSizes.size32 }} />
            ) : (
              <AddCircleIcon sx={{ fontSize: theme.fonts.fontSizes.size32 }} />
            )}
          </StyledAddIcon>
        </Box>
      );
    }
    return isChecked ? (
      <StyledAddIcon
        sx={{ color: theme.palette.colors.button.addedButton }}
        onClick={() => onHandleRemove(getFeaturedId())}
      >
        <CheckCircleIcon sx={{ fontSize: theme.fonts.fontSizes.size32 }} />
      </StyledAddIcon>
    ) : (
      <StyledAddIcon
        sx={{ color: theme.palette.colors.brand.primary }}
        onClick={() => onHandleAdd(experience.id)}
      >
        <AddCircleIcon sx={{ fontSize: theme.fonts.fontSizes.size32 }} />
      </StyledAddIcon>
    );
  };

  return (
    <>
      <StyledBox>
        <Box sx={{ position: "relative" }}>
          {isSelectedList && (
            <>
              <NumberingBox>
                <Text type="WhiteColor">{index}</Text>
              </NumberingBox>
              <StyledButtonIcon
                onClick={(event) => {
                  handleOpenMenu(event);
                }}
              >
                <StyledMoreHorizIcon />
              </StyledButtonIcon>
              {renderMenu()}
            </>
          )}
          <AbsoluteButtonBox>{renderIconButton()}</AbsoluteButtonBox>
          <img src={experience.imagePath} alt="experience-img" style={{ width: "100%" }} />
          <StyledBottomBox>
            <StyledExperienceDetailsBox>
              <SpaceBetweenRowBox>
                <StyledTitle>{experience.title}</StyledTitle>
                {renderRating()}
              </SpaceBetweenRowBox>
              {experience.location.area !== "" && (
                <StyledStateText>{experience.location.area}</StyledStateText>
              )}
            </StyledExperienceDetailsBox>
            <Spacer size="s" position="top" />
            {renderPrice()}
            <Spacer size="xl" position="top" />
          </StyledBottomBox>
        </Box>
      </StyledBox>

      <Spacer size="xs" position="top" />
      <FullWidthBox>
        <BorderLine />
      </FullWidthBox>
    </>
  );
}

ExperiencesCard.defaultProps = {
  isLast: false,
  index: null,
  isSelectedList: false,
  onHandleUpdateOrder: null,
};

ExperiencesCard.propTypes = {
  onHandleUpdateOrder: PropTypes.func,
  isSelectedList: PropTypes.bool,
  isLast: PropTypes.bool,
  index: PropTypes.number,
  onHandleAdd: PropTypes.func.isRequired,
  onHandleRemove: PropTypes.func.isRequired,
  experience: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    priceCents: PropTypes.string,
    type: PropTypes.string,
    groupOfPeople: PropTypes.number,
    imagePath: PropTypes.string,
    location: PropTypes.shape({
      area: PropTypes.string,
    }),
    ratings: PropTypes.number,
    noReviews: PropTypes.number,
  }).isRequired,
};

export default ExperiencesCard;
