import React from "react";
import PropTypes from "prop-types";
import { TableCell, TableHead, TableRow, useTheme } from "@mui/material";
import Text from "../text.component";

function TableHeader({ headerCells }) {
  const theme = useTheme();
  return (
    <TableHead>
      <TableRow>
        {headerCells &&
          headerCells.length > 0 &&
          headerCells.map((headCell) =>
            headCell.toUpperCase().includes("ACTIVE BANNER") ? (
              <TableCell key={`${headCell}`} sx={{ textAlign: "center" }}>
                <Text sx={{ fontSize: theme.fonts.fontSizes.size14 }}>{headCell}</Text>
              </TableCell>
            ) : (
              <TableCell key={`${headCell}`} sx={{ textAlign: "center" }}>
                {headCell}
              </TableCell>
            ),
          )}
      </TableRow>
    </TableHead>
  );
}

TableHeader.propTypes = {
  headerCells: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TableHeader;
