import {
  Box,
  Grid,
  Pagination,
  styled,
  Table,
  TableBody,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import React, { useContext, useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import BorderButton from "../../../components/buttons/border-button.component";
import CtaButton from "../../../components/buttons/cta-button.component";
import DetailCardContainer from "../../../components/detail-card-container.component";
import FormDatePicker from "../../../components/forms/form-date-picker.component";
import Form from "../../../components/forms/form.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import PaddedView from "../../../components/padded-view.component";
import Spacer from "../../../components/spacer.component";
import TableHeader from "../../../components/table/table-header.component";
import TableTitle from "../../../components/table/table-title.component";
import TableWrapper from "../../../components/table/table-wrapper.component";
import Text from "../../../components/text.component";
import {
  earningSelector,
  getEarningList,
  getEarningSummary,
} from "../../../services/earning/earning-slice.services";
import EarningRow from "../components/earning-row.component";
import TableLoader from "../loader/table-loader.component";

const validationSchema = Yup.object().shape({
  startDate: Yup.date().nullable().label("Start At"),
  endDate: Yup.date().label("End At").nullable(),
});

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

export default function EarningListScreen() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const createSnackBar = useContext(SnackbarContext);
  const formRef = useRef();
  const { getEarningListObj, getEarningSummaryObj } = useSelector(earningSelector);
  const [page, setPage] = useState(1);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const onSearchEarning = (values) => {
    setPage(1);
    let startAt = "";
    let endAt = "";
    if (values.startDate !== null) {
      startAt = format(new Date(values.startDate), "yyyy-MM-dd 00:00:00");
    }
    if (values.endDate !== null) {
      endAt = format(new Date(values.endDate), "yyyy-MM-dd 23:59:59");
    }

    dispatch(getEarningSummary({ startAt, endAt })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });

    dispatch(
      getEarningList({
        startAt,
        endAt,
        page: 1,
      }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onPageChange = (e, newPage) => {
    setPage(newPage);
    let startAt = "";
    let endAt = "";
    if (formRef.current.values.startDate !== null) {
      startAt = format(new Date(formRef.current.values.startDate), "yyyy-MM-dd 00:00:00");
    }
    if (formRef.current.values.endDate !== null) {
      endAt = format(new Date(formRef.current.values.endDate), "yyyy-MM-dd 23:59:59");
    }
    dispatch(
      getEarningList({
        startAt,
        endAt,
        page: newPage,
      }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  useEffect(() => {
    onSearchEarning(formRef.current.values);
  }, []);

  const renderSummary = () => {
    if (getEarningSummaryObj.status === "succeeded") {
      return (
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Grid container spacing={0}>
              <Grid item xs={isMobile ? 12 : 4}>
                <Box>
                  <Text variant="h6">Revenue: </Text>
                  <Text type="DarkGreyColor" variant="h6">
                    RM {getEarningSummaryObj.data.revenue}
                  </Text>
                </Box>
              </Grid>
              <Grid item xs={isMobile ? 12 : 4}>
                <Box>
                  <Text variant="h6">Merchant Sum: </Text>
                  <Text type="DarkGreyColor" variant="h6">
                    RM {getEarningSummaryObj.data.merchantSum}
                  </Text>
                </Box>
              </Grid>

              <Grid item xs={isMobile ? 12 : 4}>
                <Box>
                  <Text variant="h6">Promo Codes: </Text>
                  <Text type="DarkGreyColor" variant="h6">
                    RM {getEarningSummaryObj.data.promoCodes}
                  </Text>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={isMobile ? 12 : 4}>
                <Box>
                  <Text variant="h6">Platform Commission: </Text>
                  <Text type="DarkGreyColor" variant="h6">
                    RM {getEarningSummaryObj.data.platformCommission}
                  </Text>
                </Box>
              </Grid>
              <Grid item xs={isMobile ? 12 : 4}>
                <Box>
                  <Text variant="h6">Gross Profit: </Text>
                  {getEarningSummaryObj.data.grossProfit &&
                  getEarningSummaryObj.data.grossProfit.includes("-") ? (
                    <Text variant="h6" type="RejectText">
                      - RM {getEarningSummaryObj.data.grossProfit.replace("-", "")}
                    </Text>
                  ) : (
                    <Text variant="h6" type="GreenColor">
                      RM {getEarningSummaryObj.data.grossProfit}
                    </Text>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={isMobile ? 12 : 4}>
              <Box>
                <Skeleton />
                <Text type="DarkGreyColor">
                  <Skeleton />
                </Text>
              </Box>
            </Grid>
            <Grid item xs={isMobile ? 12 : 4}>
              <Box>
                <Skeleton />
                <Text type="DarkGreyColor">
                  <Skeleton />
                </Text>
              </Box>
            </Grid>
            <Grid item xs={isMobile ? 12 : 4}>
              <Box>
                <Skeleton />
                <Text type="DarkGreyColor">
                  <Skeleton />
                </Text>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={isMobile ? 12 : 4}>
              <Box>
                <Skeleton />
                <Text variant="h6" type="DarkGreyColor">
                  <Skeleton />
                </Text>
              </Box>
            </Grid>
            <Grid item xs={isMobile ? 12 : 4}>
              <Box>
                <Skeleton />
                <Text variant="h6" type="DarkGreyColor">
                  <Skeleton />
                </Text>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderContent = () => {
    if (getEarningListObj.status === "succeeded") {
      return (
        <>
          <Table>
            <TableHeader
              headerCells={[
                "Statement ID",
                "Revenue (RM)",
                "Merchant Sum (RM)",
                "Platform Commission (RM)",
                "Promo Codes (RM)",
                "Gross Profit (RM)",
              ]}
            />
            <TableBody>
              {getEarningListObj.data.items.map((item) => (
                <EarningRow record={item} key={item.statementId} />
              ))}
            </TableBody>
          </Table>
          <CustomFooter>
            <Pagination
              sx={{
                "&& .Mui-selected": {
                  backgroundColor: theme.palette.colors.brand.primary,
                  color: theme.palette.colors.text.white,
                },
              }}
              page={page}
              onChange={onPageChange}
              count={getEarningListObj.data.pagination.totalPages}
              variant="outlined"
            />
          </CustomFooter>
        </>
      );
    }

    return <TableLoader />;
  };

  return (
    <PaddedView multiples={3}>
      <Spacer position="top" size="m" />
      <TableTitle title="Earnings" />
      <Spacer position="top" size="xl" />
      <DetailCardContainer>{renderSummary()}</DetailCardContainer>
      <Spacer position="top" size="xl" />
      <Form
        innerRef={formRef}
        onSubmit={onSearchEarning}
        validationSchema={validationSchema}
        initialValues={{
          startDate: format(new Date().setMonth(new Date().getMonth() - 6), "yyyy-MM-dd"),
          endDate: new Date(),
        }}
      >
        <DetailCardContainer>
          <Grid container spacing={2}>
            <Grid item sx={{ display: "flex", flex: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <FormDatePicker
                    name="startDate"
                    disablePast={false}
                    width="100%"
                    placeholder="Start At"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormDatePicker
                    name="endDate"
                    disablePast={false}
                    width="100%"
                    placeholder="End At"
                  />
                </Grid>
                <Grid
                  item
                  xs={4}
                  width={useMediaQuery(theme.breakpoints.down("990")) ? "100%" : "150px"}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <BorderButton
                        height="56px"
                        onClickButton={() => {
                          formRef.current.resetForm();
                          setTimeout(() => {
                            formRef.current.handleSubmit();
                          }, 50);
                        }}
                      >
                        <Text type="BrandColor">Clear</Text>
                      </BorderButton>
                    </Grid>
                    <Grid item xs={6}>
                      <CtaButton height="56px" onClickButton={() => formRef.current.handleSubmit()}>
                        <Text type="WhiteColor">Search</Text>
                      </CtaButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DetailCardContainer>
      </Form>
      <Spacer position="top" size="l" />
      <TableWrapper>{renderContent()}</TableWrapper>
    </PaddedView>
  );
}
