import React from "react";
import { useTheme } from "@mui/material";
import PropTypes from "prop-types";

function GiftVoucherIcon({ isSelected }) {
  const theme = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="15" viewBox="0 0 22 15">
      <path
        id="voucher"
        d="M38,114.183v4.088a.717.717,0,0,1-.687.74h-13.8V117.25a.551.551,0,1,0-1.1,0v1.761h-5.72a.717.717,0,0,1-.687-.74v-4.088a2.73,2.73,0,0,0,0-5.344v-4.088a.717.717,0,0,1,.687-.74h5.72v1.761a.551.551,0,1,0,1.1,0v-1.761h13.8a.717.717,0,0,1,.688.74v4.088a2.73,2.73,0,0,0,0,5.344Zm-10.418-6.834a1.716,1.716,0,0,0-1.261.562,2.025,2.025,0,0,0,0,2.715,1.695,1.695,0,0,0,2.522,0,2.025,2.025,0,0,0,0-2.715A1.716,1.716,0,0,0,27.582,107.349Zm4.867,4.484a1.717,1.717,0,0,0-1.261.562,2.025,2.025,0,0,0,0,2.715,1.695,1.695,0,0,0,2.522,0,2.025,2.025,0,0,0,0-2.715A1.716,1.716,0,0,0,32.449,111.833Zm.483,1.4a.649.649,0,0,0-.966,0,.776.776,0,0,0,0,1.04.649.649,0,0,0,.966,0,.776.776,0,0,0,0-1.04Zm-1.3-5.41-4.162,6.624a.62.62,0,0,0,.148.821.526.526,0,0,0,.763-.16l4.162-6.624a.62.62,0,0,0-.148-.821A.526.526,0,0,0,31.631,107.823Zm-3.566.926a.649.649,0,0,0-.966,0,.776.776,0,0,0,0,1.04.649.649,0,0,0,.966,0,.776.776,0,0,0,0-1.04Zm-5.657,4.088V114.6a.552.552,0,1,0,1.1,0v-1.761a.552.552,0,1,0-1.1,0Zm0-4.413v1.761a.552.552,0,1,0,1.1,0v-1.761a.552.552,0,1,0-1.1,0Z"
        transform="translate(-16 -104.012)"
        fill={
          isSelected ? theme.palette.colors.brand.primary : theme.palette.colors.brand.secondary
        }
      />
    </svg>
  );
}

GiftVoucherIcon.defaultProps = {
  isSelected: false,
};

GiftVoucherIcon.propTypes = {
  isSelected: PropTypes.bool,
};

export default GiftVoucherIcon;
