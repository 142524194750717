import CloseIcon from "@mui/icons-material/Close";
import ShareIcon from '@mui/icons-material/Share';
import { IconButton, styled, TableCell, TableRow } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Text from "../../../components/text.component";

function UniqueLinkRow({ record }) {

    const CloseIconButton = styled(IconButton)(({ theme }) => ({
        height: "25px",
        width: "25px",
        color: theme.palette.colors.brand.secondary,
    }));

    const popupStyles = {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '20px',
        backgroundColor: 'white',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '5px',
        zIndex: 1000
    };

    const [showPopup, setShowPopup] = useState(false);

    const closePopup = () => {
        setShowPopup(false);
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(`https://yolo.my/creator/${record.uniqueLink}`)
            .then(() => {
                setShowPopup(true); // Show the popup after copying
            })
            .catch(err => {
                console.error('Failed to copy link: ', err);
            });
    };

    return (
        <TableRow
            key={record.id}
            sx={{
                "& .MuiTableCell-root": {
                    borderBottom: "no-set",
                },
            }}
        >
            <TableCell>
                <Text variant="body2" sx={{ textAlign: "center" }}>
                    {record.id}
                </Text>
            </TableCell>
            <TableCell>
                <Text variant="body2" sx={{ textAlign: "center" }}>
                    {`https://yolo.my/creator/${record.uniqueLink}`}
                </Text>
            </TableCell>
            <TableCell>
                <Text variant="body2" sx={{ textAlign: "center" }}>
                    {record.hitCounts}
                </Text>
            </TableCell>
            <TableCell>
                <Text variant="body2" sx={{ textAlign: "center" }}>
                    {record.status}
                </Text>
            </TableCell>
            {/* <TableCell>
                <Text variant="body2" sx={{ textAlign: "center" }}>
                    {record.amount}
                </Text>
            </TableCell> */}
            <TableCell>
                {/* <Link to={{ pathname: `${routes.UNIQUE_LINK_DETAIL}`, search: `uniqueLinkId=${record.id}` }}>
                    <IconButton>
                        <EditIcon />
                    </IconButton>
                </Link> */}
                {/* <Link to={{ pathname: `${routes.UNIQUE_LINK_DETAIL}`, search: `uniqueLinkId=${record.uniqueLink}` }}> */}
                <IconButton onClick={copyToClipboard}>
                    <ShareIcon />
                </IconButton>
                {/* </Link> */}
                {showPopup && (
                        <div style={popupStyles}>
                            <p>Link copied to clipboard!</p>
                    <CloseIconButton onClick={closePopup}>
                        <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
                    </CloseIconButton>
                        </div>
                    )}
            </TableCell>
        </TableRow>
    );
}

UniqueLinkRow.defaultProps = {
    record: {
        id: null,
        uniqueLink: "",
        status: "",
        hitCounts: null,
        // amount: "",
    },
};

UniqueLinkRow.propTypes = {
    record: PropTypes.shape({
        id: PropTypes.number,
        uniqueLink: PropTypes.string,
        status: PropTypes.string,
        hitCounts: PropTypes.number,
        // amount: PropTypes.string,
    }),
};

export default UniqueLinkRow;
