import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, IconButton, Modal, styled, useMediaQuery, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import { creatorSelector } from "../../../services/creator/creator-slice.services";
import SpecificListAutoComplete from "../../promo-code/components/specific-list-autocomplete.component";

const ModalBox = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: theme.palette.colors.brand.white,
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    boxShadow: 24,
    paddingTop: "25px",
    paddingBottom: "25px",
    outline: "none",
    maxHeight: "90%",
    display: "flex",
    flexDirection: "column",
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
    height: "25px",
    width: "25px",
    color: theme.palette.colors.brand.secondary,
}));

const SeperateLine = styled(Box)(({ theme }) => ({
    width: "100%",
    height: "1px",
    backgroundColor: theme.palette.colors.ui.border,
}));

export default function CreatorUniqueLinksCreateModal({ isShowModal, setIsShowModal }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { resetForm } = useFormikContext();
    const { createCreatorObj } = useSelector(creatorSelector);

    const handleCancel = () => {
        resetForm();
        setIsShowModal(false);
    };

    return (
        <Modal open={isShowModal} onClose={handleCancel}>
            <ModalBox
                sx={{
                    width: isMobile ? "350px" : "450px",
                    paddingLeft: isMobile ? "20px" : "50px",
                    paddingRight: isMobile ? "20px" : "50px",
                }}
            >
                <Grid container justifyContent="space-between" alignItems="center">
                    <Box width="25px" />
                    <Text variant="h5">Create Unique Links</Text>
                    <CloseIconButton onClick={handleCancel}>
                        <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
                    </CloseIconButton>
                </Grid>
                <Spacer size="m" position="top" />
                <SeperateLine />
                <Spacer size="m" position="top" />
                <Box
                    sx={{
                        display: "flex",
                        flex: 1,
                        overflowY: "auto",
                    }}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Text>Specific Experience List</Text>
                            <Spacer size="xs" position="top" />
                            <SpecificListAutoComplete
                                //   disabled={forCreateScreen ? false : !isEditing.promoRules}
                                disabled={false}
                                name="experiencesList"
                                placeholder="Experience's title"
                                isSpecific={true}
                            />
                        </Grid>
                    </Grid>
                </Box>

                <Spacer size="l" position="top" />
                <FormSubmitButton isLoading={createCreatorObj.status === "pending"}>
                    <Text type="WhiteColor">Create</Text>
                </FormSubmitButton>
            </ModalBox>
        </Modal>
    );
}

CreatorUniqueLinksCreateModal.propTypes = {
    isShowModal: PropTypes.bool.isRequired,
    setIsShowModal: PropTypes.func.isRequired,
};
