import React from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import Text from "../text.component";
import Spacer from "../spacer.component";

const StyledButtonBox = styled(Box)(({ theme }) => ({
  "& .MuiButton-root": {
    width: "100%",
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    textTransform: "none",
    backgroundColor: theme.palette.colors.brand.primary,
    "&:hover": {
      backgroundColor: theme.palette.colors.brand.primary,
    },
    "&:disabled": {
      backgroundColor: theme.palette.colors.ui.disabled,
    },
  },
}));

const ModalBox = styled(Box)(({ theme, isMobile }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.brand.white,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  padding: isMobile ? theme.padding.paddingX[1] : theme.padding.paddingX[0],
  width: isMobile ? "350px" : "450px",
  outline: "none",
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.brand.secondary,
}));

export default function DialogModal({
  showModal,
  title,
  message,
  hint,
  setShowModal,
  onConfirm,
  buttonText,
  disabled,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox isMobile={isMobile}>
        <Grid container>
          <Grid container justifyContent="flex-end">
            <CloseIconButton onClick={handleCancel}>
              <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
            </CloseIconButton>
          </Grid>
          <Grid item xs={12} paddingTop="20px" paddingBottom="10px">
            <Text variant="h5" textAlign="center">
              {title}
            </Text>
          </Grid>
          <Grid item xs={12}>
            <Text sx={{ textAlign: "center" }}>{message}</Text>
          </Grid>
          {hint && (
            <Grid item xs={12}>
              <Spacer size="m" position="top" />
              <Text
                type="GreyColor"
                variant="body2"
                sx={{ textAlign: "center", lineHeight: "15px" }}
              >
                {hint}
              </Text>
            </Grid>
          )}
          <Grid item xs={12} paddingTop="50px">
            <StyledButtonBox>
              <Button variant="contain" disabled={disabled} onClick={handleConfirm}>
                <Text type="WhiteColor" variant="h6">
                  {disabled ? "Loading" : buttonText}
                </Text>
              </Button>
            </StyledButtonBox>
          </Grid>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

DialogModal.defaultProps = {
  title: "",
  message: "",
  buttonText: "",
  disabled: false,
  hint: null,
};

DialogModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  disabled: PropTypes.bool,
  hint: PropTypes.string,
};
