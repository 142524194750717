import { Button, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { useFormikContext } from "formik";
import Text from "../../../../components/text.component";
import { festivalSectionSelector } from "../../../../services/marketing/festival-section/festival-section-slice.services";
import FestivalCategoryCard from "./festival-category-card.component";
import FestivalCategoryModal from "./festival-category-modal.component";

const UploadButton = styled(Button)(({ theme }) => ({
  width: "100%",
  minHeight: "200px",
  height: "100%",
  backgroundColor: "transparent",
  borderTopLeftRadius: theme.shape.borderRadius[1],
  borderTopRightRadius: theme.shape.borderRadius[1],
  borderBottomLeftRadius: theme.shape.borderRadius[1],
  borderBottomRightRadius: theme.shape.borderRadius[1],
  border: `1px dashed ${theme.palette.colors.ui.disabled}`,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: theme.palette.colors.ui.disabled,
  padding: "0px",
  "&:hover": {
    background: "transparent",
  },
}));

export default function FestivalCategoryList({
  onDeleteFestivalCategory,
  showModal,
  setShowModal,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { getFestivalCategoryObj } = useSelector(festivalSectionSelector);
  const { resetForm } = useFormikContext();
  const onClickCreateCategory = () => {
    resetForm();
    setShowModal(true);
  };

  return (
    <Grid container spacing={1}>
      <FestivalCategoryModal showModal={showModal} setShowModal={setShowModal} />
      <Grid item xs={12}>
        <Text>Featured Groups</Text>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          {getFestivalCategoryObj.data.map((item) => (
            <Grid item xs={isMobile ? 12 : 3} key={item.id}>
              <FestivalCategoryCard
                item={item}
                onDeleteFestivalCategory={onDeleteFestivalCategory}
                setShowModal={setShowModal}
              />
            </Grid>
          ))}
          {getFestivalCategoryObj.data.length < 8 && (
            <Grid item xs={isMobile ? 12 : 3}>
              <UploadButton onClick={onClickCreateCategory}>
                <AddCircleOutlineRoundedIcon />
              </UploadButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

FestivalCategoryList.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  onDeleteFestivalCategory: PropTypes.func.isRequired,
};
