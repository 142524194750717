import React from "react";
import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Text from "../../../../components/text.component";
import CtaButton from "../../../../components/buttons/cta-button.component";
import Spacer from "../../../../components/spacer.component";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-end",
});

const FlexStartRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
});

export default function BannerDetailLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <SpaceBetweenBox>
          <Text variant="h4">Create Banner</Text>
          <CtaButton width="125px" isLoading={true}>
            <Text type="WhiteColor">Confirm</Text>
          </CtaButton>
        </SpaceBetweenBox>
      </Grid>
      <Grid item xs={12}>
        <Text>Banner Title</Text>
        <Spacer size="xs" position="top" />
        <Skeleton height="56px" width="350px" />
      </Grid>
      <Grid item xs={12}>
        <Skeleton height="150px" width={isMobile ? "100%" : "600px"} />
      </Grid>
      <Grid item xs={12}>
        <FlexStartRowBox>
          <Box>
            <Text>Start from</Text>
            <Spacer size="xs" position="top" />
            <Skeleton height="56px" width={isMobile ? "130px" : "300px"} />
          </Box>
          <Spacer size="xl" position="left" />
          <Box>
            <Text>End at</Text>
            <Spacer size="xs" position="top" />
            <Skeleton height="56px" width={isMobile ? "130px" : "300px"} />
          </Box>
        </FlexStartRowBox>
      </Grid>
      <Grid item xs={12}>
        <Text>Link to (Pick 1 only)</Text>
        <Spacer size="s" position="top" />
        <Grid container spacing={2}>
          <Grid item>
            <Skeleton height="45px" width="125px" />
          </Grid>
          <Grid item>
            <Skeleton height="45px" width="125px" />
          </Grid>
          <Grid item>
            <Skeleton height="45px" width="125px" />
          </Grid>
        </Grid>
        <Spacer size="m" position="top" />
        <Skeleton height="56px" width="200px" />
      </Grid>
      <Grid item xs={12} />
    </Grid>
  );
}
