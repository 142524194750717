import React, { useContext, useState } from "react";
import { Box, Button, Grid, Modal, styled, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { useSelector } from "react-redux";
import Spacer from "../../../components/spacer.component";
import CtaButton from "../../../components/buttons/cta-button.component";
import Text from "../../../components/text.component";
import BorderButton from "../../../components/buttons/border-button.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import { categorySelector } from "../../../services/category/category-slice.services";
import CategoryImage from "./category-image.component";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.primary,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  paddingTop: "25px",
  paddingBottom: "25px",
  paddingLeft: "20px",
  paddingRight: "20px",
  maxWidth: "600px",
  outline: "none",
  display: "flex",
  maxHeight: "80%",
  flexDirection: "column",
}));

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png", "image/webp"];

const UploadButton = styled(Button)(({ theme }) => ({
  backgroundColor: "transparent",
  borderTopLeftRadius: theme.shape.borderRadius[1],
  borderTopRightRadius: theme.shape.borderRadius[1],
  borderBottomLeftRadius: theme.shape.borderRadius[1],
  borderBottomRightRadius: theme.shape.borderRadius[1],
  border: `1px dashed ${theme.palette.colors.ui.disabled}`,
  minHeight: "160px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: theme.palette.colors.ui.disabled,
  padding: "0px",
  "&:hover": {
    background: "transparent",
  },
}));

const ImageCropperContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  height: "80%",
  flex: 1,
  overflow: "scroll",
});

const BottomButtonWrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-evenly",
  height: "20%",
  alignItems: "center",
});

export default function CategoryImageGrid({
  imageList,
  onUploadCategoryImage,
  onDeleteCategoryImage,
  showCropperModal,
  setShowCropperModal,
  onImageOrderUpdate,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { uploadCategoryImageObj } = useSelector(categorySelector);
  const createSnackBar = useContext(SnackbarContext);
  const [selectedImage, setSelectedImage] = useState("");
  const [cropImage, setCropImage] = useState(null);
  const aspectRatio = 16 / 9;
  const cropSetting = {
    unit: "px",
    aspect: aspectRatio,
    width: 100,
    height: 56.25,
    x: 0,
    y: 0,
  };
  const [crop, setCrop] = useState(cropSetting);

  const resetCrop = () => {
    setCrop(cropSetting);
  };

  const handleCancel = () => {
    resetCrop();
    setShowCropperModal(false);
  };

  const getCroppedImage = () => {
    const canvas = document.createElement("canvas");
    const scaleX = cropImage.naturalWidth / cropImage.width;
    const scaleY = cropImage.naturalHeight / cropImage.height;
    const ctx = canvas.getContext("2d");
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    ctx.imageSmoothingQuality = "high";
    ctx.drawImage(
      cropImage,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );
    const base64ImageURL = canvas.toDataURL("image/webp");

    onUploadCategoryImage(base64ImageURL);
  };

  const cropperModal = () => (
    <Modal
      open={showCropperModal}
      onClose={() => {
        setSelectedImage("");
        handleCancel();
      }}
    >
      <ModalBox>
        {selectedImage && (
          <ImageCropperContainer>
            <ReactCrop
              crop={crop}
              onChange={setCrop}
              aspect={aspectRatio}
              keepSelection={true}
              style={{ height: "100%", overflow: "scroll" }}
            >
              <img
                src={selectedImage}
                alt="test"
                onLoad={(o) => {
                  setCropImage(o.target);
                }}
              />
            </ReactCrop>
          </ImageCropperContainer>
        )}
        <Spacer size="m" position="top" />
        <BottomButtonWrapper>
          <Box sx={{ width: "45%" }}>
            <CtaButton
              onClickButton={getCroppedImage}
              isLoading={uploadCategoryImageObj.status === "pending"}
            >
              <Text type="WhiteColor">Upload</Text>
            </CtaButton>
          </Box>
          <Box sx={{ width: "45%" }}>
            <BorderButton
              isLoading={uploadCategoryImageObj.status === "pending"}
              onClickButton={() => {
                setSelectedImage("");
                handleCancel();
              }}
            >
              <Text type="BrandColor">Cancel</Text>
            </BorderButton>
          </Box>
        </BottomButtonWrapper>
      </ModalBox>
    </Modal>
  );

  const renderUploadButton = () => (
    <UploadButton component="label" sx={{ width: "100%", height: "100%" }}>
      <AddCircleOutlineRoundedIcon />
      <input
        onClick={(event) => {
          event.target.value = null;
        }}
        type="file"
        hidden
        accept="image/*"
        onChange={(e) => {
          const { files } = e.target;
          if (files) {
            const selectedFIles = files[0];
            const withinFileSizeLimit = selectedFIles.size <= 5000000; // 1mb = 1^6 bytes
            const allowedFileType = SUPPORTED_FORMATS.includes(selectedFIles.type);

            if (withinFileSizeLimit && allowedFileType) {
              setSelectedImage(URL.createObjectURL(selectedFIles));
              setShowCropperModal(true);
            } else if (!withinFileSizeLimit) {
              createSnackBar({
                message: "Image file cannot be more than 5mb",
                type: "error",
                open: true,
              });
            } else if (!allowedFileType) {
              createSnackBar({
                message: `Only ${SUPPORTED_FORMATS} are allowed`,
                type: "error",
                open: true,
              });
            }
          }
        }}
      />
    </UploadButton>
  );

  return (
    <Grid container spacing={3}>
      {cropperModal()}
      {imageList.map((item, index) => (
        <Grid item xs={isMobile ? 12 : 4} key={item.id}>
          <CategoryImage
            index={index + 1}
            isLast={index === imageList.length - 1}
            item={item}
            onDeleteCategoryImage={onDeleteCategoryImage}
            onImageOrderUpdate={onImageOrderUpdate}
          />
        </Grid>
      ))}
      {imageList.length < 6 && (
        <Grid item xs={isMobile ? 12 : 4}>
          {renderUploadButton()}
        </Grid>
      )}
    </Grid>
  );
}

CategoryImageGrid.defaultProps = {
  imageList: [{ id: null, orderBy: null, imagePath: "s" }],
};

CategoryImageGrid.propTypes = {
  showCropperModal: PropTypes.bool.isRequired,
  setShowCropperModal: PropTypes.func.isRequired,
  onDeleteCategoryImage: PropTypes.func.isRequired,
  onUploadCategoryImage: PropTypes.func.isRequired,
  onImageOrderUpdate: PropTypes.func.isRequired,
  imageList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      orderBy: PropTypes.number,
      imagePath: PropTypes.string,
    }),
  ),
};
