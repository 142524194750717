import React from "react";
import PropTypes from "prop-types";
import { TableCell, IconButton, TableRow } from "@mui/material";
import { Link } from "react-router-dom";
import Text from "../../../components/text.component";
import EditIcon from "../../../assets/images/edit-icon";
import routes from "../../../navigation/routes";

function StatementRow({ record }) {
  return (
    <TableRow
      key={record.id}
      sx={{
        "& .MuiTableCell-root": {
          borderBottom: "no-set",
        },
      }}
    >
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center" }}>
          {record.id}
        </Text>
      </TableCell>
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center" }}>
          {record.status}
        </Text>
      </TableCell>
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center" }}>
          {record.amount}
        </Text>
      </TableCell>
      <TableCell>
        <Link to={{ pathname: `${routes.STATEMENT_DETAIL}`, search: `statementId=${record.id}` }}>
          <IconButton>
            <EditIcon />
          </IconButton>
        </Link>
      </TableCell>
    </TableRow>
  );
}

StatementRow.defaultProps = {
  record: {
    id: null,
    status: "",
    amount: "",
  },
};

StatementRow.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    amount: PropTypes.string,
  }),
};

export default StatementRow;
