import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../../api-services";

export const getExploreSectionList = createAsyncThunk(
  "marketing/explore-section/list",
  async () => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/marketings/explore_section`,
    );
    return response;
  },
);

export const updateExploreSectionLabel = createAsyncThunk(
  "marketing/explore-section/update-label",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/marketings/explore_section`,
      payload,
    );
    return response;
  },
);

export const getExploreExperienceList = createAsyncThunk(
  "marketing/explore-section/experience/list",
  async () => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/marketings/explore_section/explore_experience`,
    );
    return response;
  },
);

export const removeExploreExperience = createAsyncThunk(
  "marketing/explore-section/experience/remove",
  async (experienceId) => {
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/marketings/explore_section/explore_experience/${experienceId}`,
    );
    return response;
  },
);

export const addExploreExperience = createAsyncThunk(
  "marketing/explore-section/experience/add",
  async (experienceId) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/marketings/explore_section/explore_experience`,
      experienceId,
    );
    return response;
  },
);

const exploreSectionSlice = createSlice({
  name: "exploreSection",
  initialState: {
    getExploreSectionListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateExploreSectionLabelObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getExploreExperienceListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    removeExploreExperienceObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    addExploreExperienceObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getExploreSectionList.pending]: (state) => {
      state.getExploreSectionListObj.status = "pending";
    },
    [getExploreSectionList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getExploreSectionListObj.status = "succeeded";
      state.getExploreSectionListObj.data = data;
      state.getExploreSectionListObj.successMessage = message;
    },
    [getExploreSectionList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getExploreSectionListObj.status = "failed";
      state.getExploreSectionListObj.errorMessage = message;
    },
    [updateExploreSectionLabel.pending]: (state) => {
      state.updateExploreSectionLabelObj.status = "pending";
    },
    [updateExploreSectionLabel.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateExploreSectionLabelObj.status = "succeeded";
      state.updateExploreSectionLabelObj.data = data;
      state.updateExploreSectionLabelObj.successMessage = message;

      state.getExploreSectionListObj.data = data;
    },
    [updateExploreSectionLabel.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateExploreSectionLabelObj.status = "failed";
      state.updateExploreSectionLabelObj.errorMessage = message;
    },
    [getExploreExperienceList.pending]: (state) => {
      state.getExploreExperienceListObj.status = "pending";
    },
    [getExploreExperienceList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getExploreExperienceListObj.status = "succeeded";
      state.getExploreExperienceListObj.data = data;
      state.getExploreExperienceListObj.successMessage = message;
    },
    [getExploreExperienceList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getExploreExperienceListObj.status = "failed";
      state.getExploreExperienceListObj.errorMessage = message;
    },
    [removeExploreExperience.pending]: (state) => {
      state.removeExploreExperienceObj.status = "pending";
    },
    [removeExploreExperience.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.removeExploreExperienceObj.status = "succeeded";
      state.removeExploreExperienceObj.data = data;
      state.removeExploreExperienceObj.successMessage = message;

      state.getExploreExperienceListObj.data = data;
    },
    [removeExploreExperience.rejected]: (state, action) => {
      const { message } = action.error;

      state.removeExploreExperienceObj.status = "failed";
      state.removeExploreExperienceObj.errorMessage = message;
    },
    [addExploreExperience.pending]: (state) => {
      state.addExploreExperienceObj.status = "pending";
    },
    [addExploreExperience.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.addExploreExperienceObj.status = "succeeded";
      state.addExploreExperienceObj.data = data;
      state.addExploreExperienceObj.successMessage = message;

      state.getExploreExperienceListObj.data = data;
    },
    [addExploreExperience.rejected]: (state, action) => {
      const { message } = action.error;

      state.addExploreExperienceObj.status = "failed";
      state.addExploreExperienceObj.errorMessage = message;
    },
  },
});

export default exploreSectionSlice.reducer;

// state
export const exploreSectionSelector = (state) => state.exploreSection;
