import React, { useContext, useEffect, useState } from "react";
import { Box, Pagination, styled, Table, TableBody, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PaddedView from "../../../components/padded-view.component";
import Spacer from "../../../components/spacer.component";
import TableWrapper from "../../../components/table/table-wrapper.component";
import TableHeader from "../../../components/table/table-header.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import Loading from "../../../components/notifications/loading.component";
import DeleteConfirmationModal from "../../../components/notifications/delete-confirmation-modal.component";
import { deleteTag, searchTag, tagSelector } from "../../../services/tag/tag-slice.services";
import TagRow from "../components/tag-row.component";
import TagTableLoader from "../loader/tag-table-loader.component";
import routes from "../../../navigation/routes";
import TableTitle from "../../../components/table/table-title.component";

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

export default function TagListScreen() {
  const dispatch = useDispatch();
  const history = useHistory();
  const createSnackBar = useContext(SnackbarContext);
  const { searchTagObj, deleteTagObj } = useSelector(tagSelector);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteTagId, setDeleteTagId] = useState("");

  useEffect(() => {
    dispatch(searchTag({ q: searchKeyword, page: 1 })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  }, []);

  const refreshTagList = (newPage) => {
    dispatch(searchTag({ q: searchKeyword, page: newPage })).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onChangeSearch = (q) => {
    setPage(1);
    setSearchKeyword(q);
    dispatch(searchTag({ q, page: 1 })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onPageChange = (e, newPage) => {
    setPage(newPage);
    refreshTagList(newPage);
  };

  const onConfirmDelete = () => {
    setIsLoading(true);
    dispatch(deleteTag(deleteTagId)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
      if (meta.requestStatus === "fulfilled") {
        setPage(1);
        setShowDeleteModal(false);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
    });
  };

  const onToggleDelete = (tagId) => {
    setDeleteTagId(tagId);
    setShowDeleteModal(true);
  };

  const renderContent = () => {
    if (searchTagObj.status === "succeeded") {
      return (
        <>
          <Table>
            <TableHeader headerCells={["ID", "Tag Name", "Date Created", "Experiences", ""]} />
            <TableBody>
              {searchTagObj.data.items.map((item) => (
                <TagRow record={item} onToggleDelete={onToggleDelete} key={item.id} />
              ))}
            </TableBody>
          </Table>
          <CustomFooter>
            <Pagination
              sx={{
                "&& .Mui-selected": {
                  backgroundColor: theme.palette.colors.brand.primary,
                  color: theme.palette.colors.text.white,
                },
              }}
              page={page}
              onChange={onPageChange}
              count={searchTagObj.data.pagination.totalPages}
              variant="outlined"
            />
          </CustomFooter>
        </>
      );
    }
    return <TagTableLoader />;
  };

  return (
    <PaddedView multiples={3}>
      <DeleteConfirmationModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        label="Are you sure to delete this tag?"
        isLoading={deleteTagObj.status === "pending"}
        onConfirmClicked={onConfirmDelete}
      />
      <Loading isLoading={isLoading} />
      <Spacer position="top" size="m" />
      <TableTitle
        title="Tags"
        onClickCreateButton={() => history.push(routes.TAG_CREATE)}
        searchKeyword={searchKeyword}
        onChangeSearch={onChangeSearch}
        placeholder="Search Tag"
      />
      <Spacer position="top" size="xl" />
      <TableWrapper>{renderContent()}</TableWrapper>
    </PaddedView>
  );
}
