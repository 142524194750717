import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import TableHeader from "../../../components/table/table-header.component";

function TableLoader() {
  return (
    <Table sx={{ minWidth: 650 }} aria-label="collapsible table">
      <TableHeader
        headerCells={[
          "Statement ID",
          "Revenue (RM)",
          "Merchant Sum (RM)",
          "Platform Commission (RM)",
          "Promo Codes (RM)",
          "Gross Profit (RM)",
        ]}
      />
      <TableBody>
        <TableRow>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

export default TableLoader;
