import React from "react";
import PropTypes from "prop-types";
import { FormHelperText } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useFormikContext } from "formik";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

function FormPhoneNumber({ name, placeholder, isTransparent }) {
  const theme = useTheme();
  const { setFieldValue, errors, setFieldTouched, touched, values } = useFormikContext();
  const showError = touched[name] && typeof errors[name] === "string";

  return (
    <>
      <PhoneInput
        placeholder={placeholder}
        specialLabel=""
        value={values[name]}
        isValid={() => !showError}
        country="my"
        onBlur={() => setFieldTouched(name)}
        onChange={(e) => setFieldValue(name, e)}
        inputStyle={{
          width: "100%",
          borderRadius: theme.shape.borderRadius[0],
          backgroundColor: isTransparent ? "transparent" : theme.palette.colors.bg.primary,
        }}
      />
      {showError && (
        <FormHelperText
          sx={{
            color: theme.palette.colors.text.error,
            margin: "3px 14px 0px 14px",
          }}
        >
          {errors[name]}
        </FormHelperText>
      )}
    </>
  );
}

FormPhoneNumber.defaultProps = {
  placeholder: "",
  isTransparent: false,
};

FormPhoneNumber.propTypes = {
  isTransparent: PropTypes.bool,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

export default FormPhoneNumber;
