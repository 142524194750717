import { TableCell, TableRow } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import Text from "../../../components/text.component";

function EarningRow({ record }) {
  return (
    <TableRow
      key={record.statementId}
      sx={{
        "& .MuiTableCell-root": {
          borderBottom: "no-set",
        },
      }}
    >
      <TableCell width="100px">
        <Text variant="body2" sx={{ textAlign: "center" }}>
          {record.statementId}
        </Text>
      </TableCell>
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center" }}>
          {record.revenue}
        </Text>
      </TableCell>
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center" }}>
          ({record.merchantSum})
        </Text>
      </TableCell>
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center" }}>
          {record.platformCommission}
        </Text>
      </TableCell>
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center" }}>
          ({record.promoCode})
        </Text>
      </TableCell>
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center" }}>
          {record.grossProfit}
        </Text>
      </TableCell>
    </TableRow>
  );
}

EarningRow.defaultProps = {
  record: {
    statementId: null,
    revenue: 0,
    merchantSum: 0,
    platformCommission: 0,
    promoCode: 0,
    grossProfit: 0,
  },
};

EarningRow.propTypes = {
  record: PropTypes.shape({
    statementId: PropTypes.number,
    revenue: PropTypes.string,
    merchantSum: PropTypes.string,
    platformCommission: PropTypes.string,
    promoCode: PropTypes.string,
    grossProfit: PropTypes.string,
  }),
};

export default EarningRow;
