import React, { useContext, useEffect, useState } from "react";
import { Box, Pagination, styled, Table, TableBody, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import PaddedView from "../../../components/padded-view.component";
import Spacer from "../../../components/spacer.component";
import TableWrapper from "../../../components/table/table-wrapper.component";
import TableHeader from "../../../components/table/table-header.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import Loading from "../../../components/notifications/loading.component";
import {
  categorySelector,
  createCategory,
  deleteCategory,
  searchCategory,
} from "../../../services/category/category-slice.services";
import CategoryRow from "../components/category-row.component";
import DeleteConfirmationModal from "../../../components/notifications/delete-confirmation-modal.component";
import CategoryTableLoader from "../loader/category-table-loader.component";
import Form from "../../../components/forms/form.component";
import CategoryCreateModal from "../components/category-create-modal.component";
import TableTitle from "../../../components/table/table-title.component";

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const validationSchema = Yup.object().shape({
  label: Yup.string().required().label("Category Name"),
  description: Yup.string().required().label("Category Description"),
});

export default function CategoryListScreen() {
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const { searchCategoryObj, deleteCategoryObj } = useSelector(categorySelector);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteCategoryId, setDeleteCategoryId] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);

  useEffect(() => {
    dispatch(searchCategory({ q: searchKeyword, page: 1 })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  }, []);

  const refreshCategoryList = (newPage) => {
    dispatch(searchCategory({ q: searchKeyword, page: newPage })).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onChangeSearch = (q) => {
    setPage(1);
    setSearchKeyword(q);
    dispatch(searchCategory({ q, page: 1 })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onPageChange = (e, newPage) => {
    setPage(newPage);
    refreshCategoryList(newPage);
  };

  const onConfirmDelete = () => {
    setIsLoading(true);
    dispatch(deleteCategory(deleteCategoryId)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
      if (meta.requestStatus === "fulfilled") {
        setPage(1);
        setShowDeleteModal(false);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
    });
  };

  const onToggleDelete = (categoryId) => {
    setDeleteCategoryId(categoryId);
    setShowDeleteModal(true);
  };

  const onCreateCategory = (values, { resetForm }) => {
    setIsLoading(true);
    dispatch(createCategory(values)).then(({ meta, error, payload }) => {
      setIsLoading(false);

      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
      if (meta.requestStatus === "fulfilled") {
        setShowCreateModal(false);
        resetForm();
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
    });
  };

  const renderContent = () => {
    if (searchCategoryObj.status === "succeeded") {
      return (
        <>
          <Table>
            <TableHeader
              headerCells={["ID", "Category Name", "Date Created", "Active Experience", ""]}
            />
            <TableBody>
              {searchCategoryObj.data.items.map((item) => (
                <CategoryRow record={item} onToggleDelete={onToggleDelete} key={item.id} />
              ))}
            </TableBody>
          </Table>
          <CustomFooter>
            <Pagination
              sx={{
                "&& .Mui-selected": {
                  backgroundColor: theme.palette.colors.brand.primary,
                  color: theme.palette.colors.text.white,
                },
              }}
              page={page}
              onChange={onPageChange}
              count={searchCategoryObj.data.pagination.totalPages}
              variant="outlined"
            />
          </CustomFooter>
        </>
      );
    }
    return <CategoryTableLoader />;
  };

  return (
    <PaddedView multiples={3}>
      <Form
        initialValues={{ label: "", description: "" }}
        onSubmit={onCreateCategory}
        validationSchema={validationSchema}
      >
        <CategoryCreateModal showModal={showCreateModal} setShowModal={setShowCreateModal} />
      </Form>
      <DeleteConfirmationModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        label="Are you sure to delete this category?"
        isLoading={deleteCategoryObj.status === "pending"}
        onConfirmClicked={onConfirmDelete}
      />
      <Loading isLoading={isLoading} />
      <Spacer position="top" size="m" />
      <TableTitle
        title="Category"
        onClickCreateButton={() => setShowCreateModal(true)}
        searchKeyword={searchKeyword}
        onChangeSearch={onChangeSearch}
        placeholder="Search Category"
      />
      <Spacer position="top" size="xl" />
      <TableWrapper>{renderContent()}</TableWrapper>
    </PaddedView>
  );
}
