import { FormControl, FormHelperText, MenuItem, Select, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";
import Text from "../text.component";

const SOCIALLIST = [
    { label: "Facebook", value: "facebook" },
    { label: "Instagram", value: "instagram" },
    { label: "Youtube", value: "youtube" },
    { label: "Twitter(X)", value: "twitter(X)" },
    { label: "Tiktok)", value: "tiktok" },
];

export default function FormSocialDropDown({ name, width, placeholder }) {
    const theme = useTheme();
    const { setFieldValue, values, touched, errors, setFieldTouched } = useFormikContext();
    const handleChange = (event) => {
        setFieldValue(name, event.target.value);
    };
    const showError = touched[name] && typeof errors[name] === "string";

    return (
        <FormControl
            sx={{
                width,
            }}
        >
            <Select
                sx={{
                    borderTopLeftRadius: theme.shape.borderRadius[0],
                    borderTopRightRadius: theme.shape.borderRadius[0],
                    borderBottomLeftRadius: theme.shape.borderRadius[0],
                    borderBottomRightRadius: theme.shape.borderRadius[0],
                }}
                error={showError}
                onBlur={() => setFieldTouched(name)}
                displayEmpty
                renderValue={(i) =>
                    i === "" ? <Text type="Placeholder">{placeholder}</Text> : <Text>{values[name]}</Text>
                }
                value={values[name]}
                onChange={handleChange}
            >
                {SOCIALLIST.map((item) => (
                    <MenuItem value={item.value} key={item.value}>
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
            {showError && <FormHelperText error>{errors[name]}</FormHelperText>}
        </FormControl>
    );
}

FormSocialDropDown.defaultProps = {
    width: "100%",
};

FormSocialDropDown.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    width: PropTypes.string,
};
