import React from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Text from "../../../../components/text.component";
import Spacer from "../../../../components/spacer.component";
import CtaButton from "../../../../components/buttons/cta-button.component";
import DetailCardContainer from "../../../../components/detail-card-container.component";

export default function ExploreSectionLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <DetailCardContainer>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Text>Main Title</Text>
              <Spacer position="top" size="xs" />
              <Skeleton width="100%" height="56px" />
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <CtaButton width="125px" isLoading={true}>
                <Text type="WhiteColor">Save</Text>
              </CtaButton>
            </Grid>
          </Grid>
        </DetailCardContainer>
      </Grid>
      <Grid item xs={12}>
        <Text>Featured Experience</Text>
        <Spacer position="top" size="xs" />
        <Grid container spacing={3}>
          <Grid item xs={isMobile ? 12 : 4}>
            <Skeleton width="100%" height="300px" />
          </Grid>
          <Grid item xs={isMobile ? 12 : 4}>
            <Skeleton width="100%" height="300px" />
          </Grid>
          <Grid item xs={isMobile ? 12 : 4}>
            <Skeleton width="100%" height="300px" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
