import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq, postReq, putReq } from "../api-services";

export const getBookingList = createAsyncThunk("employees/booking/list", async (payload) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/bookings?userIds=${
      payload.userIds ? payload.userIds : ""
    }&startAt=${payload.startAt}&endAt=${payload.endAt}&experience=${payload.experience}&status=${
      payload.status
    }&page=${payload.page}`,
  );
  return response;
});

export const getBookingDetail = createAsyncThunk("employees/booking/detail", async (bookingId) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/bookings/${bookingId}`,
  );
  return response;
});

export const updateBooking = createAsyncThunk("employees/booking/update", async (payload) => {
  const response = await putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/bookings/${payload.bookingId}`,
    payload,
  );
  return response;
});

export const confirmBooking = createAsyncThunk("employees/booking/confirm", async (bookingId) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/bookings/${bookingId}/confirm`,
  );
  return response;
});

export const cancelBooking = createAsyncThunk("employees/booking/cancel", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/bookings/${payload.bookingId}/cancel`,
    payload,
  );
  return response;
});

const bookingSlice = createSlice({
  name: "booking",
  initialState: {
    getBookingListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getBookingDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateBookingObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    confirmBookingObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    cancelBookingObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getBookingList.pending]: (state) => {
      state.getBookingListObj.status = "pending";
    },
    [getBookingList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getBookingListObj.status = "succeeded";
      state.getBookingListObj.data = data;
      state.getBookingListObj.successMessage = message;
    },
    [getBookingList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getBookingListObj.status = "failed";
      state.getBookingListObj.errorMessage = message;
    },
    [getBookingDetail.pending]: (state) => {
      state.getBookingDetailObj.status = "pending";
    },
    [getBookingDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getBookingDetailObj.status = "succeeded";
      state.getBookingDetailObj.data = data;
      state.getBookingDetailObj.successMessage = message;
    },
    [getBookingDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.getBookingDetailObj.status = "failed";
      state.getBookingDetailObj.errorMessage = message;
    },
    [updateBooking.pending]: (state) => {
      state.updateBookingObj.status = "pending";
    },
    [updateBooking.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateBookingObj.status = "succeeded";
      state.updateBookingObj.data = data;
      state.updateBookingObj.successMessage = message;

      state.getBookingDetailObj.data = data;
    },
    [updateBooking.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateBookingObj.status = "failed";
      state.updateBookingObj.errorMessage = message;
    },
    [confirmBooking.pending]: (state) => {
      state.confirmBookingObj.status = "pending";
    },
    [confirmBooking.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.confirmBookingObj.status = "succeeded";
      state.confirmBookingObj.data = data;
      state.confirmBookingObj.successMessage = message;

      state.getBookingDetailObj.data = data;
    },
    [confirmBooking.rejected]: (state, action) => {
      const { message } = action.error;

      state.confirmBookingObj.status = "failed";
      state.confirmBookingObj.errorMessage = message;
    },
    [cancelBooking.pending]: (state) => {
      state.cancelBookingObj.status = "pending";
    },
    [cancelBooking.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.cancelBookingObj.status = "succeeded";
      state.cancelBookingObj.data = data;
      state.cancelBookingObj.successMessage = message;

      state.getBookingDetailObj.data = data;
    },
    [cancelBooking.rejected]: (state, action) => {
      const { message } = action.error;

      state.cancelBookingObj.status = "failed";
      state.cancelBookingObj.errorMessage = message;
    },
  },
});

export default bookingSlice.reducer;

// state
export const bookingSelector = (state) => state.booking;
