import React from "react";
import { Box, Grid, IconButton, Modal, styled, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import Text from "../../../components/text.component";
import Spacer from "../../../components/spacer.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import FormPhoneNumber from "../../../components/forms/form-phone-number.component";
import FormBankDropDown from "../../../components/forms/form-bank-dropdown.component";
import { merchantSelector } from "../../../services/merchant/merchant-slice.services";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.brand.white,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  paddingTop: "25px",
  paddingBottom: "25px",
  outline: "none",
  maxHeight: "90%",
  display: "flex",
  flexDirection: "column",
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.brand.secondary,
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "1px",
  backgroundColor: theme.palette.colors.ui.border,
}));

export default function MerchantCreateModal({ isShowModal, setIsShowModal }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { resetForm } = useFormikContext();
  const { createMerchantObj } = useSelector(merchantSelector);

  const handleCancel = () => {
    resetForm();
    setIsShowModal(false);
  };

  return (
    <Modal open={isShowModal} onClose={handleCancel}>
      <ModalBox
        sx={{
          width: isMobile ? "350px" : "450px",
          paddingLeft: isMobile ? "20px" : "50px",
          paddingRight: isMobile ? "20px" : "50px",
        }}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Box width="25px" />
          <Text variant="h5">Create Merchant</Text>
          <CloseIconButton onClick={handleCancel}>
            <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
          </CloseIconButton>
        </Grid>
        <Spacer size="m" position="top" />
        <SeperateLine />
        <Spacer size="m" position="top" />
        <Box
          sx={{
            display: "flex",
            flex: 1,
            overflowY: "auto",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Text>Email</Text>
              <FormFieldText name="email" placeholder="Email" isShowIcon={false} />
            </Grid>

            <Grid item xs={12}>
              <Text>Business Name</Text>
              <FormFieldText name="businessName" placeholder="Business Name" />
            </Grid>

            <Grid item xs={12}>
              <Text>Business Registration Number</Text>
              <FormFieldText name="businessRegNumber" placeholder="Business Registration Number" />
            </Grid>

            <Grid item xs={12}>
              <Text>Company Website</Text>
              <FormFieldText name="website" placeholder="Website" />
            </Grid>

            <Grid item xs={12}>
              <Text>Contact No</Text>
              <FormPhoneNumber name="contactNo" placeholder="Contact Number" isTransparent />
            </Grid>

            <Grid item xs={12}>
              <Text>Commission</Text>
              <FormFieldText name="commission" placeholder="Commission" type="number" />
            </Grid>

            <Grid item xs={12}>
              <Text>Bank Name</Text>
              <FormBankDropDown name="bankName" placeholder="Bank Name" />
            </Grid>

            <Grid item xs={12}>
              <Text>Account Holder Name</Text>
              <FormFieldText name="bankHolderName" placeholder="Account Holder Name" />
            </Grid>

            <Grid item xs={12}>
              <Text>Account Number</Text>
              <FormFieldText name="bankAccountNo" placeholder="Account Number" />
            </Grid>
          </Grid>
        </Box>

        <Spacer size="l" position="top" />
        <FormSubmitButton isLoading={createMerchantObj.status === "pending"}>
          <Text type="WhiteColor">Create</Text>
        </FormSubmitButton>
      </ModalBox>
    </Modal>
  );
}

MerchantCreateModal.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  setIsShowModal: PropTypes.func.isRequired,
};
