export const colors = {
  brand: {
    primary: "#9C6BDE",
    secondary: "#25273C",
    white: "#FFFFFF",
    linear: ["#F65DB0", "#996CE0"],
  },
  ui: {
    primary: "#262626",
    secondary: "#6B6B6B",
    disabled: "#AAAAAA",
    error: "#D0421B",
    success: "#138000",
    white: "#FFFFFF",
    border: "#C2C2C2",
    indicator: "#AAAAAA",
    pending: "#FFCC00",
    favourite: "#FF7777",
    notification: "#FF2525",
    redDot: "#E41313",
    greenDot: "#1CCD1B",
  },
  bg: {
    primary: "#F7F7F7",
    secondary: "#F6F6F6",
    white: "#FFFFFF",
    recommendationsBg: "#EFEFF3",
    rejectReasonBg: "#E2E2E2",
    disabledField: "#EAEAEA",
  },
  timeSlot: {
    selected: "#E60013",
    unselected: "#AAAAAA",
  },
  tab: {
    selected: "#E60013",
    unselected: "#AAAAAA",
    bg: "#FAF8F8",
  },
  loading: {
    backgroundColor: "#FFFFFF",
    foregroundColor: "#AAAAAA",
    imageBg: "#AAAAAA",
    dot1: "#262626",
    dot2: "#6B6B6B",
    dot3: "#FFFFFF",
  },
  text: {
    primary: "#25273C",
    secondary: "#6B6B6B",
    tertiary: "#707070",
    placeholder: "#AAAAAA",
    white: "#FFFFFF",
    disabled: "#AAAAAA",
    error: "#d32f2f",
    success: "#138000",
    inputField: "#F6F6F9",
    darkGrey: "#4F535F",
    reject: "#FF7373",
    defaultFocusedBorder: "#146CC8",
    defaultFieldBorder: "#898989",
  },
  button: {
    indentityBox: "#F0F3F9",
    rejectButton: "#FF7373",
    addedButton: "#6DE9A7",
  },
};
