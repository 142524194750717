import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Menu,
  MenuItem,
  styled,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PropTypes from "prop-types";
import Text from "../../../components/text.component";
import Spacer from "../../../components/spacer.component";
import { experienceSelector } from "../../../services/experience/experience-slice.services";

const FIELDHEIGHT = "40px";

const StyledMenu = styled((props) => <Menu elevation={0} {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 4,
    marginTop: FIELDHEIGHT,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 20,
        color: theme.palette.colors.brand.secondary,
      },
    },
  },
  " .MuiMenuItem-root": {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  " .MuiCheckbox-root": {
    paddingLeft: "0px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

const FilterContainer = styled(Button)(({ theme }) => ({
  width: "100%",
  textTransform: "none",
  border: `1px solid ${theme.palette.colors.ui.border}`,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  height: FIELDHEIGHT,
  ":hover": {
    backgroundColor: "transparent",
  },
}));

const CenterRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

function StatesFilter({ states, setStates }) {
  const theme = useTheme();
  const [showMenu, setShowMenu] = useState(null);
  const { getExperienceLocationListObj } = useSelector(experienceSelector);

  const handleOpenMenu = (event) => {
    setShowMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setShowMenu(null);
  };

  const handleCheckboxChange = (data) => {
    const isChecked = states.some((checkedCheckbox) => checkedCheckbox === data);
    if (isChecked) {
      setStates(states.filter((checkedCheckbox) => checkedCheckbox !== data));
    } else {
      setStates(states.concat(data));
    }
  };

  return (
    <>
      <FilterContainer disableRipple onClick={handleOpenMenu}>
        <CenterRowBox sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
          <Text sx={{ paddingLeft: "15px", paddingRight: "15px" }}>States</Text>
          <Spacer size="s" position="left" />
          {showMenu ? (
            <KeyboardArrowUpIcon sx={{ color: theme.palette.colors.brand.secondary }} />
          ) : (
            <KeyboardArrowDownIcon sx={{ color: theme.palette.colors.brand.secondary }} />
          )}
        </CenterRowBox>
      </FilterContainer>
      <StyledMenu
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorEl={showMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={Boolean(showMenu)}
        onClose={handleCloseMenu}
      >
        <FormControl>
          {getExperienceLocationListObj.status === "succeeded" &&
            getExperienceLocationListObj.data.map((item) => (
              <MenuItem key={item} value={item} disableRipple>
                <FormControlLabel
                  control={
                    <Checkbox
                      disableRipple
                      onChange={() => handleCheckboxChange(item)}
                      checked={states.some((checkedCheckbox) => checkedCheckbox === item)}
                    />
                  }
                  label={item}
                />
              </MenuItem>
            ))}
        </FormControl>
      </StyledMenu>
    </>
  );
}

StatesFilter.propTypes = {
  states: PropTypes.arrayOf(PropTypes.string).isRequired,
  setStates: PropTypes.func.isRequired,
};

export default StatesFilter;
