import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import Text from "../../../components/text.component";
import {
    corporateSelector,
    updateCorporate,
} from "../../../services/corporate/corporate-slice.services";
import CorporateBankDetailsFormFieldText from "./corporate-details-form-field-text.component";

const FlexEndButtonContainer = styled(Box)({
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
});

const CorporateBankDetailsValidationSchema = Yup.object().shape({
    bankAccountNo: Yup.number().required().label("Bank Account Number"),
    bankName: Yup.string().required().label("Bank Name"),
    name: Yup.string().required().min(2).label("Name"),
});

function CorporateBankDetails() {
    const location = useLocation();
    const corporateId = new URLSearchParams(location.search).get("corporateId");
    const [isNameEditing, setIsNameEditing] = useState(false);
    const [isBankNameEditing, setIsBankNameEditing] = useState(false);
    const [isBankAccNoEditing, setIsBankAccNoEditing] = useState(false);
    const { getCorporateDetailsObj, updateCorporateObj } = useSelector(corporateSelector);
    const dispatch = useDispatch();
    const createSnackBar = useContext(SnackbarContext);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const resetState = () => {
        setIsNameEditing(false);
        setIsBankNameEditing(false);
        setIsBankAccNoEditing(false);
    };

    const onUpdateCorporateBankDetails = (values) => {
        dispatch(updateCorporate({ corporateId, type: "bankDetails", ...values })).then(
            ({ meta, error, payload }) => {
                if (meta.requestStatus === "fulfilled") {
                    resetState();
                    createSnackBar({
                        message: payload.message,
                        type: "success",
                        open: true,
                    });
                }
                if (meta.requestStatus === "rejected") {
                    createSnackBar({
                        message: error.message,
                        type: "error",
                        open: true,
                    });
                }
            },
        );
    };

    return (
        <Form
            validationSchema={CorporateBankDetailsValidationSchema}
            onSubmit={onUpdateCorporateBankDetails}
            initialValues={{
                id: getCorporateDetailsObj.data.corporate.id,
                bankAccountNo: getCorporateDetailsObj.data.bankDetails.bankAccountNo,
                bankName: getCorporateDetailsObj.data.bankDetails.bankName,
                name: getCorporateDetailsObj.data.bankDetails.name,
            }}
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Text variant="h5">Bank Details</Text>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={isMobile ? 12 : 3}>
                            <CorporateBankDetailsFormFieldText
                                name="name"
                                label="Name"
                                isShowIcon
                                editing={isNameEditing}
                                setEditing={setIsNameEditing}
                            />
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 3}>
                            <CorporateBankDetailsFormFieldText
                                name="bankName"
                                label="Bank Name"
                                isShowIcon
                                editing={isBankNameEditing}
                                setEditing={setIsBankNameEditing}
                            />
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 3}>
                            <CorporateBankDetailsFormFieldText
                                name="bankAccountNo"
                                label="Bank Account No."
                                isShowIcon
                                editing={isBankAccNoEditing}
                                setEditing={setIsBankAccNoEditing}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <FlexEndButtonContainer sx={{ marginTop: "-30px" }}>
                        <FormSubmitButton width="125px" isLoading={updateCorporateObj.status === "pending"}>
                            <Text type="WhiteColor">Save</Text>
                        </FormSubmitButton>
                    </FlexEndButtonContainer>
                </Grid>
            </Grid>
        </Form>
    );
}

export default CorporateBankDetails;
