import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

function CenteredRowBox({ children, ...props }) {
  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", ...props }}>
      {children}
    </Box>
  );
}

CenteredRowBox.defaultProps = {
  children: null,
};

CenteredRowBox.propTypes = {
  children: PropTypes.node,
};

export default CenteredRowBox;
