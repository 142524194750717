import React from "react";
import { Table, TableBody, TableRow, TableCell, Grid } from "@mui/material";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import TableHeader from "../../../../components/table/table-header.component";
import CtaButton from "../../../../components/buttons/cta-button.component";
import Text from "../../../../components/text.component";

function BannerTableLoader() {
  return (
    <Table sx={{ minWidth: 650 }} aria-label="collapsible table">
      <TableHeader
        headerCells={["Thumbnail", "Banner Name", "Date Modified", "Status", "Duration", ""]}
      />
      <TableBody>
        <TableRow>
          <TableCell sx={{ width: "300px" }}>
            <Skeleton width="100%" height="50px" />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell sx={{ width: "200px" }}>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell sx={{ width: "200px" }}>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Grid container spacing={1} sx={{ alignItems: "center" }}>
              <Grid item>
                <CtaButton isLoading={true}>
                  <Text type="WhiteColor">Publish</Text>
                </CtaButton>
              </Grid>
              <Grid item>
                <Skeleton height={30} width={30} circle={true} />
              </Grid>
              <Grid item>
                <Skeleton height={30} width={30} circle={true} />
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

export default BannerTableLoader;
