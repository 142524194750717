import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, IconButton, styled, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import PaddedView from "../../../components/padded-view.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import Loading from "../../../components/notifications/loading.component";
import {
  getGiftVoucherList,
  giftVoucherSelectore,
  removeGiftVoucher,
  updateGiftVoucher,
} from "../../../services/gift-voucher/gift-voucher-slice.services";
import Form from "../../../components/forms/form.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import GiftVoucherItemLoader from "../loader/gift-voucher-item-loader.component";
import CreateGiftVoucherModal from "../components/create-gift-voucher-modal.component";
import DeleteConfirmationModal from "../../../components/notifications/delete-confirmation-modal.component";
import TableTitle from "../../../components/table/table-title.component";

const validationSchema = Yup.object().shape({
  label: Yup.string().required().label("Label"),
  amount: Yup.number().required().min(50).label("Value"),
});

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: "10px",
  top: "10px",
  color: theme.palette.colors.brand.secondary,
}));

export default function GiftVoucherScreen() {
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const { getGiftVoucherListObj, updateGiftVoucherObj, removeGiftVoucherObj } =
    useSelector(giftVoucherSelectore);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [updateId, setUpdateId] = useState(null);

  const onToggleDelete = (id) => {
    setIdToDelete(id);
    setShowDeleteModal(true);
  };

  const onConfirmDelete = () => {
    setIsLoading(true);
    dispatch(removeGiftVoucher(idToDelete)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setShowDeleteModal(false);
        setIdToDelete(null);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  useEffect(() => {
    dispatch(getGiftVoucherList()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  }, []);

  const getUpdateId = () => updateId;

  const onGiftVoucherUpdate = (values) => {
    setIsLoading(true);
    setUpdateId(values.id);
    dispatch(updateGiftVoucher({ value: parseFloat(values.amount) * 100, ...values })).then(
      ({ meta, error, payload }) => {
        setUpdateId(null);
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      },
    );
  };

  return (
    <PaddedView multiples={3}>
      <DeleteConfirmationModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        label="Are you sure to delete this voucher gift?"
        isLoading={removeGiftVoucherObj.status === "pending"}
        onConfirmClicked={onConfirmDelete}
      />
      <CreateGiftVoucherModal showModal={showCreateModal} setShowModal={setShowCreateModal} />
      <Loading isLoading={isLoading} />
      <Spacer position="top" size="m" />
      <TableTitle title="Gift Voucher" onClickCreateButton={() => setShowCreateModal(true)} />
      <Spacer position="top" size="xl" />
      {getGiftVoucherListObj.status === "succeeded" ? (
        <Grid container spacing={2}>
          {getGiftVoucherListObj.data.map((item) => (
            <Grid item xs={12} key={item.id}>
              <Box sx={{ backgroundColor: "white", position: "relative" }}>
                <StyledIconButton onClick={() => onToggleDelete(item.id)}>
                  <CloseIcon />
                </StyledIconButton>
                <PaddedView multiples={3}>
                  <Form
                    validationSchema={validationSchema}
                    onSubmit={onGiftVoucherUpdate}
                    initialValues={{
                      type: "credit",
                      label: item.label,
                      amount: (item.value / 100).toFixed(2),
                      id: item.id,
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={isMobile ? 12 : 6}>
                        <Text>Label</Text>
                        <Spacer size="s" />
                        <FormFieldText
                          name="label"
                          placeholder="Label"
                          disabled={
                            item.label.toUpperCase() === "OTHER" ||
                            item.label.toUpperCase() === "OTHERS"
                          }
                        />
                      </Grid>
                      <Grid item xs={isMobile ? 12 : 6}>
                        <Text>Value</Text>
                        <Spacer size="s" />
                        <FormFieldText
                          name="amount"
                          placeholder="Value"
                          disabled={
                            item.label.toUpperCase() === "OTHER" ||
                            item.label.toUpperCase() === "OTHERS"
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <FormSubmitButton
                          isLoading={
                            updateGiftVoucherObj.status === "pending" && getUpdateId() === item.id
                          }
                          width="125px"
                          disabled={
                            item.label.toUpperCase() === "OTHER" ||
                            item.label.toUpperCase() === "OTHERS"
                          }
                        >
                          <Text type="WhiteColor">Save</Text>
                        </FormSubmitButton>
                      </Grid>
                    </Grid>
                  </Form>
                </PaddedView>
              </Box>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <GiftVoucherItemLoader />
          <GiftVoucherItemLoader />
          <GiftVoucherItemLoader />
          <GiftVoucherItemLoader />
          <GiftVoucherItemLoader />
        </Grid>
      )}
    </PaddedView>
  );
}
