import React from "react";
import { useTheme } from "@mui/material";
import PropTypes from "prop-types";

function TagIcon({ isSelected }) {
  const theme = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
      <path
        id="Icon_awesome-layer-group"
        data-name="Icon awesome-layer-group"
        d="M.533,6.36,10.542,10.9a1.1,1.1,0,0,0,.915,0L21.466,6.36a.96.96,0,0,0,0-1.72L11.457.1a1.1,1.1,0,0,0-.915,0L.533,4.639a.96.96,0,0,0,0,1.72Zm20.933,3.793-2.5-1.131L12.025,12.17a2.477,2.477,0,0,1-2.05,0L3.03,9.022l-2.5,1.131a.959.959,0,0,0,0,1.719l10.009,4.537a1.1,1.1,0,0,0,.915,0l10.009-4.537a.959.959,0,0,0,0-1.719Zm0,5.491L18.98,14.517,12.025,17.67a2.477,2.477,0,0,1-2.05,0L3.02,14.517.533,15.644a.959.959,0,0,0,0,1.719L10.542,21.9a1.1,1.1,0,0,0,.915,0l10.009-4.537a.959.959,0,0,0,0-1.719Z"
        transform="translate(0 0.001)"
        fill={
          isSelected ? theme.palette.colors.brand.primary : theme.palette.colors.brand.secondary
        }
      />
    </svg>
  );
}

TagIcon.defaultProps = {
  isSelected: false,
};

TagIcon.propTypes = {
  isSelected: PropTypes.bool,
};

export default TagIcon;
