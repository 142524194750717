import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq } from "../api-services";

// Define the async thunk
export const getDashboard = createAsyncThunk("employees/dashboard", async () => {
    const response = await getReq(
        `${process.env.REACT_APP_API_PRIVATE_V1}/employees/dashboard`,
    );
    return response;
});

// Create the slice
const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: {
        getDashboardObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDashboard.pending, (state) => {
                state.getDashboardObj.status = "pending";
            })
            .addCase(getDashboard.fulfilled, (state, action) => {
                const { data, message } = action.payload;

                state.getDashboardObj.status = "succeeded";
                state.getDashboardObj.data = data;
                state.getDashboardObj.successMessage = message;
            })
            .addCase(getDashboard.rejected, (state, action) => {
                const { message } = action.error;

                state.getDashboardObj.status = "failed";
                state.getDashboardObj.errorMessage = message;
            });
    },
});

export default dashboardSlice.reducer;

// Selector
export const dashboardSelector = (state) => state.dashboard;
