import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  styled,
  useMediaQuery,
  useTheme,
  Pagination,
} from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import Spacer from "../../../components/spacer.component";
import SearchBar from "../../../components/table/search-bar.component";
import Text from "../../../components/text.component";
import { getExperienceLocationList } from "../../../services/experience/experience-slice.services";
import {
  searchExperience,
  searchTagSelector,
} from "../../../services/search-tag/search-tag-slice.services";
import ExperienceCardLoader from "../loader/experience-card-loader.component";
import ExperienceCard from "./experience-card.component";
import StatesFilter from "./states-filter.component";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.primary,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  padding: "40px",
  paddingBottom: "0px",
  width: "90%",
  outline: "none",
  display: "flex",
  maxHeight: "90%",
  flexDirection: "column",
  maxWidth: "1000px",
}));

const ExperienceListContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  flex: 1,
  overflow: "scroll",
  marginTop: "20px",
});

const TopBarContainer = styled(Box)({
  display: "flex",
  height: "20%",
  alignItems: "center",
  width: "100%",
});

const TextButton = styled(Button)(({ theme }) => ({
  padding: "0px",
  textTransform: "none",
  textDecoration: "underline",
  textDecorationColor: theme.palette.colors.brand.primary,
  ":hover": {
    textDecoration: "underline",
    textDecorationColor: theme.palette.colors.brand.primary,
    backgroundColor: "transparent",
  },
}));

const FlexEndButtonBox = styled(Box)({
  height: "100%",
  justifyContent: "flex-end",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const EmptyBox = styled(Box)({
  width: "100%",
  height: "350px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
  stroke: "black",
  strokeWidth: 2,
  color: theme.palette.colors.brand.secondary,
}));

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

export default function ExperienceSelectionModal({ showModal, setShowModal }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [q, setQ] = useState("");
  const [states, setStates] = useState([]);
  const { searchExperienceObj } = useSelector(searchTagSelector);
  const { values, setFieldValue } = useFormikContext();
  const [page, setPage] = useState(1);
  const name = "experiencesObj";

  const resetFilter = () => {
    setQ("");
    setStates([]);
    dispatch(searchExperience({ q, states: "" })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onFilterApply = () => {
    dispatch(
      searchExperience({
        q,
        states: states.toString(),
      }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onChangeSearch = (keyWord) => {
    setQ(keyWord);
    dispatch(
      searchExperience({
        q: keyWord,
        states: states.toString(),
      }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  useEffect(() => {
    dispatch(searchExperience({ q, states: "", page: 1 })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
    dispatch(getExperienceLocationList()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  }, []);

  const onPageChange = (e, newPage) => {
    setPage(newPage);
    dispatch(searchExperience({ q, states: "", page: newPage })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const onToggleExperienceAdd = (experience) => {
    const isChecked = values[name].some((checkedCheckbox) => checkedCheckbox.id === experience.id);
    if (isChecked) {
      setFieldValue(
        name,
        values[name].filter((checkedCheckbox) => checkedCheckbox.id !== experience.id),
      );
      values.experiencesId = values.experiencesId.filter(
        (experienceId) => experienceId !== experience.id,
      );
      if (values.originalExperiencesId) {
        values.newAddedExperiencesObj = values.newAddedExperiencesObj.filter(
          (checkedCheckbox) => checkedCheckbox.id !== experience.id,
        );
      }
    } else {
      setFieldValue(name, values[name].concat(experience));
      values.experiencesId = values.experiencesId.concat(experience.id);
      if (values.originalExperiencesId && !values.originalExperiencesId.includes(experience.id)) {
        values.newAddedExperiencesObj = values.newAddedExperiencesObj.concat(experience);
      }
    }
  };

  const renderExperienceList = () => {
    if (searchExperienceObj.status === "succeeded") {
      if (searchExperienceObj.data.length <= 0) {
        return (
          <Grid item xs={12}>
            <EmptyBox>
              <Text>No data found</Text>
            </EmptyBox>
          </Grid>
        );
      }
      return searchExperienceObj.data.items.map((item) => (
        <Grid item xs={isMobile ? 12 : 4} key={item.id}>
          <ExperienceCard
            experience={item}
            onToggleExperienceAdd={onToggleExperienceAdd}
            isShowAddIcon
          />
        </Grid>
      ));
    }

    return (
      <>
        <Grid item xs={isMobile ? 12 : 4}>
          <ExperienceCardLoader />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <ExperienceCardLoader />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <ExperienceCardLoader />
        </Grid>
      </>
    );
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox>
        <IconButton onClick={handleCancel} sx={{ position: "absolute", top: "5px", right: "15px" }}>
          <StyledCloseIcon />
        </IconButton>
        <TopBarContainer>
          <Grid container spacing={3} rowSpacing={1}>
            <Grid item xs={isMobile ? 12 : null}>
              <SearchBar
                searchKeyword={q}
                setSearchKeyword={onChangeSearch}
                placeholder="Search Experience"
                width="100%"
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : null}>
              <StatesFilter states={states} setStates={setStates} />
            </Grid>
            <Grid item xs={isMobile ? 12 : null}>
              <FlexEndButtonBox sx={{ justifyContent: isMobile ? "space-between" : "flex-end" }}>
                <TextButton onClick={onFilterApply}>
                  <Text type="BrandColor">Apply Filter</Text>
                </TextButton>
                <Spacer position="left" size="m" />
                <TextButton onClick={resetFilter}>
                  <Text type="BrandColor">Clear Filter</Text>
                </TextButton>
              </FlexEndButtonBox>
            </Grid>
          </Grid>
        </TopBarContainer>
        <ExperienceListContainer>
          <Grid container spacing={3}>
            {renderExperienceList()}
          </Grid>
        </ExperienceListContainer>
        {searchExperienceObj.status === "succeeded" && (
          <CustomFooter>
            <Pagination
              sx={{
                "&& .Mui-selected": {
                  backgroundColor: theme.palette.colors.brand.primary,
                  color: theme.palette.colors.text.white,
                },
              }}
              page={page}
              onChange={onPageChange}
              count={searchExperienceObj.data.pagination.totalPages}
              variant="outlined"
            />
          </CustomFooter>
        )}
      </ModalBox>
    </Modal>
  );
}

ExperienceSelectionModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};
