import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../api-services";

export const getSearchTags = createAsyncThunk("employees/search_tags/search", async (payload) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/search_tags?q=${payload.q}&page=${payload.page}`,
  );
  return response;
});

export const deleteTag = createAsyncThunk("employees/search_tags/delete", async (tagId) => {
  const response = await delReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/search_tags/${tagId}`,
  );
  return response;
});

export const createTag = createAsyncThunk("employees/search_tags/create", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/search_tags`,
    payload,
  );
  return response;
});

export const getTagDetail = createAsyncThunk("employees/search_tags/detail", async (tagId) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/search_tags/${tagId}`,
  );
  return response;
});

export const updateTag = createAsyncThunk("employees/search_tags/update", async (payload) => {
  const response = await putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/search_tags/${payload.tagId}`,
    payload,
  );
  return response;
});

export const searchExperience = createAsyncThunk(
  "employees/search_tags/search_experience",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/search_tags/search?q=${payload.q}&states=${
        payload.states
      }&${payload.page ? `page=${payload.page}` : "page=1"}`,
    );
    return response;
  },
);

export const getTagExperiences = createAsyncThunk(
  "employees/search_tags/experiences",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/search_tags/${payload.tagId}/experiences?${
        payload.page ? `page=${payload.page}` : "page=1"
      }`,
    );
    return response;
  },
);

const searchTagSlice = createSlice({
  name: "search_tag",
  initialState: {
    getSearchTagsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteTagObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createTagObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    searchExperienceObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getTagDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getTagExperiencesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateTagObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getSearchTags.pending]: (state) => {
      state.getSearchTagsObj.status = "pending";
    },
    [getSearchTags.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getSearchTagsObj.status = "succeeded";
      state.getSearchTagsObj.data = data;
      state.getSearchTagsObj.successMessage = message;
    },
    [getSearchTags.rejected]: (state, action) => {
      const { message } = action.error;

      state.getSearchTagsObj.status = "failed";
      state.getSearchTagsObj.errorMessage = message;
    },
    [deleteTag.pending]: (state) => {
      state.deleteTagObj.status = "pending";
    },
    [deleteTag.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteTagObj.status = "succeeded";
      state.deleteTagObj.data = data;
      state.deleteTagObj.successMessage = message;

      state.getSearchTagsObj.data = data;
    },
    [deleteTag.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteTagObj.status = "failed";
      state.deleteTagObj.errorMessage = message;
    },
    [createTag.pending]: (state) => {
      state.createTagObj.status = "pending";
    },
    [createTag.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createTagObj.status = "succeeded";
      state.createTagObj.data = data;
      state.createTagObj.successMessage = message;
    },
    [createTag.rejected]: (state, action) => {
      const { message } = action.error;

      state.createTagObj.status = "failed";
      state.createTagObj.errorMessage = message;
    },
    [searchExperience.pending]: (state) => {
      state.searchExperienceObj.status = "pending";
    },
    [searchExperience.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.searchExperienceObj.status = "succeeded";
      state.searchExperienceObj.data = data;
      state.searchExperienceObj.successMessage = message;
    },
    [searchExperience.rejected]: (state, action) => {
      const { message } = action.error;

      state.searchExperienceObj.status = "failed";
      state.searchExperienceObj.errorMessage = message;
    },
    [getTagDetail.pending]: (state) => {
      state.getTagDetailObj.status = "pending";
    },
    [getTagDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getTagDetailObj.status = "succeeded";
      state.getTagDetailObj.data = data;
      state.getTagDetailObj.successMessage = message;
    },
    [getTagDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.getTagDetailObj.status = "failed";
      state.getTagDetailObj.errorMessage = message;
    },
    [getTagExperiences.pending]: (state) => {
      state.getTagExperiencesObj.status = "pending";
    },
    [getTagExperiences.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getTagExperiencesObj.status = "succeeded";
      state.getTagExperiencesObj.data = data;
      state.getTagExperiencesObj.successMessage = message;
    },
    [getTagExperiences.rejected]: (state, action) => {
      const { message } = action.error;

      state.getTagExperiencesObj.status = "failed";
      state.getTagExperiencesObj.errorMessage = message;
    },
    [updateTag.pending]: (state) => {
      state.updateTagObj.status = "pending";
    },
    [updateTag.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateTagObj.status = "succeeded";
      state.updateTagObj.data = data;
      state.updateTagObj.successMessage = message;

      state.getTagDetailObj.data = data;
    },
    [updateTag.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateTagObj.status = "failed";
      state.updateTagObj.errorMessage = message;
    },
  },
});

export default searchTagSlice.reducer;

// state
export const searchTagSelector = (state) => state.searchTag;
