import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import EditIcon from "../../../assets/images/edit-icon";
import Spacer from "../../../components/spacer.component";
import TableHeader from "../../../components/table/table-header.component";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";

function UserRow({ record }) {
  const theme = useTheme();
  const [collapse, setCollapse] = useState(false);

  return (
    <>
      <TableRow
        key={record.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "no-set",
          },
        }}
      >
        <TableCell>
          <Text variant="body2" sx={{ textAlign: "center" }}>
            {record.id}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" sx={{ textAlign: "center" }}>
            {record.firstName} {record.lastName}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" sx={{ textAlign: "center" }}>
            {record.email}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" sx={{ textAlign: "center" }}>
            {record.phoneNumber}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" sx={{ textAlign: "center", minWidth: "100px" }}>
            {record.registeredAt}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" sx={{ textAlign: "center" }}>
            {record.emailVerified ? "Verified" : "Non-verified"}
          </Text>
        </TableCell>
        <TableCell>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Link to={{ pathname: `${routes.USERDETAIL}`, search: `userId=${record.id}` }}>
              <IconButton>
                <EditIcon />
              </IconButton>
            </Link>
            <IconButton aria-label="expand row" size="small" onClick={() => setCollapse(!collapse)}>
              {collapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={collapse} unmountOnExit timeout="auto">
            <Spacer size="m" />
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <Text>Booking List</Text>
            </Box>
            <Spacer />
            <Table size="small">
              <TableHeader
                headerCells={[
                  "Booking ID",
                  "Type",
                  "Experience",
                  "Status",
                  "Date Created",
                  "Amount (RM)",
                ]}
              />
              <TableBody>
                {record.bookings.map((item, index) => {
                  if (index > 4) {
                    return (
                      <TableRow key={item.id}>
                        <TableCell colSpan={7}>
                          <Link
                            style={{ textDecorationColor: theme.palette.colors.brand.primary }}
                            to={{
                              pathname: routes.USERDETAIL,
                              search: `userId=${record.id}`,
                            }}
                          >
                            <Text
                              variant="body2"
                              noWrap={true}
                              type="BrandColor"
                              sx={{ textAlign: "center" }}
                            >
                              View Detail for more bookings
                            </Text>
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  }
                  return (
                    <TableRow key={item.id}>
                      <TableCell>
                        <Text variant="body2" noWrap={true} sx={{ textAlign: "center" }}>
                          {item.id}
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text
                          variant="body2"
                          noWrap={true}
                          sx={{ textAlign: "center", textTransform: "capitalize" }}
                        >
                          {item.type.replace("_", " ")}
                        </Text>
                      </TableCell>
                      <TableCell width="300px">
                        <Text
                          width="300px"
                          variant="body2"
                          noWrap={true}
                          sx={{ textAlign: "center" }}
                        >
                          {item.experience.title}
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text variant="body2" noWrap={true} sx={{ textAlign: "center" }}>
                          {item.status}
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text variant="body2" noWrap={true} sx={{ textAlign: "center" }}>
                          {item.createdAt}
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text variant="body2" noWrap={true} sx={{ textAlign: "center" }}>
                          {item.order.price}
                        </Text>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Spacer size="m" />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

UserRow.defaultProps = {
  record: {
    id: null,
    email: "",
    firstName: "",
    lastName: "",
    registeredAt: "",
    phoneNumber: "",
    phoneVerified: false,
    emailVerified: false,
    gender: "",
    dateOfBirth: "",
  },
};

UserRow.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    registeredAt: PropTypes.string,
    phoneNumber: PropTypes.string,
    phoneVerified: PropTypes.bool,
    emailVerified: PropTypes.bool,
    gender: PropTypes.string,
    dateOfBirth: PropTypes.string,
    bookings: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
        startAt: PropTypes.string,
        endAt: PropTypes.string,
      }),
    ),
  }),
};

export default UserRow;
