import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq, putReq } from "../api-services";

export const getStatementList = createAsyncThunk("employees/statements/search", async (payload) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/statements?merchant=${payload.merchant}&statementId=${payload.statementId}&status=${payload.status}&page=${payload.page}`,
  );
  return response;
});

export const getStatementDetail = createAsyncThunk(
  "employees/statements/detail",
  async (statementId) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/statements/${statementId}`,
    );
    return response;
  },
);

export const updateStatement = createAsyncThunk("employees/statements/update", async (payload) => {
  const response = await putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/statements/${payload.statementId}`,
    payload,
  );
  return response;
});

export const getStatementTransactionList = createAsyncThunk(
  "employees/statements/transactions",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/statements/${payload.statementId}/transactions?page=${payload.page}`,
    );
    return response;
  },
);

const statementSlice = createSlice({
  name: "statement",
  initialState: {
    getStatementListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getStatementDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateStatementObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getStatementTransactionListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getStatementList.pending]: (state) => {
      state.getStatementListObj.status = "pending";
    },
    [getStatementList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getStatementListObj.status = "succeeded";
      state.getStatementListObj.data = data;
      state.getStatementListObj.successMessage = message;
    },
    [getStatementList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getStatementListObj.status = "failed";
      state.getStatementListObj.errorMessage = message;
    },
    [getStatementDetail.pending]: (state) => {
      state.getStatementDetailObj.status = "pending";
    },
    [getStatementDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getStatementDetailObj.status = "succeeded";
      state.getStatementDetailObj.data = data;
      state.getStatementDetailObj.successMessage = message;
    },
    [getStatementDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.getStatementDetailObj.status = "failed";
      state.getStatementDetailObj.errorMessage = message;
    },
    [updateStatement.pending]: (state) => {
      state.updateStatementObj.status = "pending";
    },
    [updateStatement.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateStatementObj.status = "succeeded";
      state.updateStatementObj.data = data;
      state.updateStatementObj.successMessage = message;

      state.getStatementDetailObj.data = data;
    },
    [updateStatement.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateStatementObj.status = "failed";
      state.updateStatementObj.errorMessage = message;
    },
    [getStatementTransactionList.pending]: (state) => {
      state.getStatementTransactionListObj.status = "pending";
    },
    [getStatementTransactionList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getStatementTransactionListObj.status = "succeeded";
      state.getStatementTransactionListObj.data = data;
      state.getStatementTransactionListObj.successMessage = message;
    },
    [getStatementTransactionList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getStatementTransactionListObj.status = "failed";
      state.getStatementTransactionListObj.errorMessage = message;
    },
  },
});

export default statementSlice.reducer;

// state
export const statementSelector = (state) => state.statement;
