import { Grid, Table, TableBody } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import DetailCardContainer from "../../../components/detail-card-container.component";
import TableHeader from "../../../components/table/table-header.component";
import Text from "../../../components/text.component";
import { statementSelector } from "../../../services/statement/statement-slice.services";
import DisputeRow from "./dispute-row.component";
import RedBorderButton from "../../../components/buttons/red-border-button.component";

export default function DisputeLog({ setShowModal, setRejectReason, setShowRejectStatementModal }) {
  const { getStatementDetailObj } = useSelector(statementSelector);

  return (
    <DetailCardContainer>
      <Grid container>
        <Grid item xs={9}>
          <Text variant="h5">Dispute Log</Text>
        </Grid>
        {(getStatementDetailObj.data.status === "dispute" ||
          getStatementDetailObj.data.status === "pending") && (
          <Grid item xs={3}>
            <RedBorderButton width="80%" onClickButton={() => setShowRejectStatementModal(true)}>
              <Text type="RejectText">Add Dispute Log</Text>
            </RedBorderButton>
          </Grid>
        )}
      </Grid>
      <Table>
        <TableHeader headerCells={["Dispute Id", "Employee Id", "Date Created", ""]} />
        <TableBody>
          {getStatementDetailObj.data.disputeLog.map((item) => (
            <DisputeRow
              record={item}
              key={item.id}
              setShowModal={setShowModal}
              setRejectReason={setRejectReason}
            />
          ))}
        </TableBody>
      </Table>
    </DetailCardContainer>
  );
}

DisputeLog.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  setRejectReason: PropTypes.func.isRequired,
  setShowRejectStatementModal: PropTypes.func.isRequired,
};
