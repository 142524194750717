import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { TextField } from "@mui/material";

function BookingDetailDisabledField({ value, width }) {
  const theme = useTheme();

  return (
    <TextField
      sx={{
        borderTopLeftRadius: theme.shape.borderRadius[0],
        borderTopRightRadius: theme.shape.borderRadius[0],
        borderBottomLeftRadius: theme.shape.borderRadius[0],
        borderBottomRightRadius: theme.shape.borderRadius[0],
        maxWidth: "450px",
        width,
        "& .MuiOutlinedInput-root": {
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          backgroundColor: theme.palette.colors.bg.disabledField,
        },
      }}
      disabled
      variant="outlined"
      value={value === null ? "" : value}
    />
  );
}

BookingDetailDisabledField.defaultProps = {
  width: "100%",
  value: "",
};

BookingDetailDisabledField.propTypes = {
  value: PropTypes.string,
  width: PropTypes.string,
};

export default BookingDetailDisabledField;
