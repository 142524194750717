import React from "react";
import { useTheme } from "@mui/material";

function EditIcon() {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.514"
      height="21.515"
      viewBox="0 0 21.514 21.515"
    >
      <g id="Group_5038" data-name="Group 5038" transform="translate(-1684.722 -498.25)">
        <g id="Group_9" data-name="Group 9" transform="translate(1684.722 498.25)">
          <path
            id="Path_3"
            data-name="Path 3"
            d="M18.611,24.425H4.813A2.816,2.816,0,0,1,2,21.611V7.813A2.816,2.816,0,0,1,4.813,5h6.9a.842.842,0,1,1,0,1.684h-6.9A1.131,1.131,0,0,0,3.684,7.813v13.8a1.131,1.131,0,0,0,1.129,1.129h13.8a1.131,1.131,0,0,0,1.129-1.129v-6.9a.842.842,0,1,1,1.684,0v6.9A2.816,2.816,0,0,1,18.611,24.425Z"
            transform="translate(-2 -2.909)"
            fill={theme.palette.colors.text.tertiary}
          />
          <path
            id="Path_4"
            data-name="Path 4"
            d="M23.669,1.818a2.933,2.933,0,0,1,2.074,5.006L16.38,16.188a.842.842,0,0,1-.391.221l-3.942.986a.842.842,0,0,1-1.021-1.021l.986-3.942a.842.842,0,0,1,.221-.391L21.6,2.677A2.914,2.914,0,0,1,23.669,1.818ZM15.354,14.832l9.2-9.2a1.249,1.249,0,0,0-1.766-1.766l-9.2,9.2L13,15.421Z"
            transform="translate(-5.086 -1.818)"
            fill={theme.palette.colors.text.tertiary}
          />
        </g>
      </g>
    </svg>
  );
}

export default EditIcon;
