import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq, putReq } from "../api-services";

export const getReviewList = createAsyncThunk("employees/reviews/search", async (payload) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/reviews${payload.params}&page=${payload.page}`,
  );
  return response;
});

export const updateReview = createAsyncThunk("employees/reviews/update", async (payload) => {
  const response = await putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/reviews/${payload.reviewId}`,
    payload,
  );
  return response;
});

const reviewSlice = createSlice({
  name: "review",
  initialState: {
    getReviewListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateReviewObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getReviewList.pending]: (state) => {
      state.getReviewListObj.status = "pending";
    },
    [getReviewList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getReviewListObj.status = "succeeded";
      state.getReviewListObj.data = data;
      state.getReviewListObj.successMessage = message;
    },
    [getReviewList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getReviewListObj.status = "failed";
      state.getReviewListObj.errorMessage = message;
    },
    [updateReview.pending]: (state) => {
      state.updateReviewObj.status = "pending";
    },
    [updateReview.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateReviewObj.status = "succeeded";
      state.updateReviewObj.data = data;
      state.updateReviewObj.successMessage = message;

      state.updateReviewObj.data = data;
    },
    [updateReview.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateReviewObj.status = "failed";
      state.updateReviewObj.errorMessage = message;
    },
  },
});

export default reviewSlice.reducer;

// state
export const reviewSelector = (state) => state.review;
