import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  Modal,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import CloseIcon from "@mui/icons-material/Close";
import Text from "../text.component";
import Spacer from "../spacer.component";
import CtaButton from "../buttons/cta-button.component";
import BorderButton from "../buttons/border-button.component";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.primary,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  paddingTop: "25px",
  paddingBottom: "25px",
  paddingLeft: "20px",
  paddingRight: "20px",
  maxWidth: "600px",
  outline: "none",
  display: "flex",
  maxHeight: "80%",
  flexDirection: "column",
}));

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png", "image/webp"];

const StyledButtonIcon = styled(IconButton)((props) => ({
  position: "absolute",
  padding: 0,
  top: 10,
  right: 10,
  backgroundColor: props.theme.palette.colors.brand.primary,
  width: 25,
  height: 25,
  ":hover": { backgroundColor: props.theme.palette.colors.brand.primary },
}));

const StyledCloseIcon = styled(CloseIcon)((props) => ({
  width: 17,
  height: 17,
  stroke: props.theme.palette.colors.brand.white,
  strokeWidth: 3,
}));

const UploadButton = styled(Button)(({ theme }) => ({
  backgroundColor: "transparent",
  borderTopLeftRadius: theme.shape.borderRadius[1],
  borderTopRightRadius: theme.shape.borderRadius[1],
  borderBottomLeftRadius: theme.shape.borderRadius[1],
  borderBottomRightRadius: theme.shape.borderRadius[1],
  border: `1px dashed ${theme.palette.colors.ui.disabled}`,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: theme.palette.colors.ui.disabled,
  padding: "0px",
  "&:hover": {
    background: "transparent",
  },
}));

const ImageCropperContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  height: "80%",
  flex: 1,
  overflow: "scroll",
});

const BottomButtonWrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-evenly",
  height: "20%",
  alignItems: "center",
});

function FormImageCropper({ name, label, cropSetting, aspectRatio, width, height }) {
  const { setFieldValue, setFieldError, setFieldTouched, values, touched, errors } =
    useFormikContext();
  const showError = touched[name] && typeof errors[name] === "string" && !values[name];
  const [image, setImage] = useState(null);
  const [showCropperModal, setShowCropperModal] = useState(false);
  const [crop, setCrop] = useState(cropSetting);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const resetCrop = () => {
    setCrop(cropSetting);
  };

  const renderUploadButton = () => (
    <UploadButton onClick={() => setFieldError(name, "")} component="label" sx={{ width, height }}>
      <AddCircleOutlineRoundedIcon />
      <input
        type="file"
        name={name}
        hidden
        accept="image/*"
        onChange={(e) => {
          setFieldTouched(name);
          const { files } = e.target;
          if (files) {
            const selectedFIles = files[0];
            const withinFileSizeLimit = selectedFIles.size <= 5000000; // 1mb = 1^6 bytes
            const allowedFileType = SUPPORTED_FORMATS.includes(selectedFIles.type);

            if (withinFileSizeLimit && allowedFileType) {
              setFieldValue(name, URL.createObjectURL(selectedFIles));
              setShowCropperModal(true);
            } else if (!withinFileSizeLimit) {
              setFieldError(name, "Image file cannot be more than 5mb");
            } else if (!allowedFileType) {
              setFieldError(name, `Only ${SUPPORTED_FORMATS} are allowed`);
            }
          }
        }}
      />
    </UploadButton>
  );

  const renderContent = () => {
    if (values[name]) {
      return (
        <Box sx={{ position: "relative", width }}>
          <img
            src={values[name]}
            alt="course_img"
            style={{
              width,
              borderTopLeftRadius: theme.shape.borderRadius[1],
              borderTopRightRadius: theme.shape.borderRadius[1],
              borderBottomLeftRadius: theme.shape.borderRadius[1],
              borderBottomRightRadius: theme.shape.borderRadius[1],
            }}
          />

          <StyledButtonIcon onClick={() => setFieldValue(name, "")}>
            <StyledCloseIcon />
          </StyledButtonIcon>
        </Box>
      );
    }
    return renderUploadButton();
  };

  useEffect(() => {
    resetCrop();
  }, [values[name]]);

  const handleCancel = () => {
    resetCrop();
    setShowCropperModal(false);
  };

  const getCroppedImage = () => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    ctx.imageSmoothingQuality = "medium";
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );
    const base64ImageURL = canvas.toDataURL("image/webp");
    setFieldValue(`${name}Changed`, "1");
    setFieldValue(name, base64ImageURL);
    handleCancel();
  };

  const cropperModal = () => (
    <Modal
      open={showCropperModal}
      onClose={() => {
        setFieldValue(name, "");
        handleCancel();
      }}
    >
      <ModalBox isMobile={isMobile}>
        {values[name] && (
          <ImageCropperContainer>
            <ReactCrop
              crop={crop}
              onChange={setCrop}
              aspect={aspectRatio}
              keepSelection={true}
              style={{ height: "100%", overflow: "scroll" }}
            >
              <img
                src={values[name]}
                alt="test"
                onLoad={(o) => {
                  setImage(o.target);
                }}
              />
            </ReactCrop>
          </ImageCropperContainer>
        )}
        <Spacer size="m" position="top" />
        <BottomButtonWrapper>
          <Box sx={{ width: "45%" }}>
            <CtaButton onClickButton={getCroppedImage}>
              <Text type="WhiteColor">Upload</Text>
            </CtaButton>
          </Box>
          <Box sx={{ width: "45%" }}>
            <BorderButton
              onClickButton={() => {
                setFieldValue(name, "");
                handleCancel();
              }}
            >
              <Text type="BrandColor">Cancel</Text>
            </BorderButton>
          </Box>
        </BottomButtonWrapper>
      </ModalBox>
    </Modal>
  );

  return (
    <>
      {cropperModal()}
      <Grid container flexDirection="column">
        <Grid item xs={12}>
          <Text variant="formLabel">{label}</Text>
          <Spacer size="s" position="bottom" />
        </Grid>
        <Grid item xs={12}>
          {renderContent()}
        </Grid>
        <Grid item xs={12}>
          {showError && <FormHelperText error>{errors[name]}</FormHelperText>}
        </Grid>
      </Grid>
    </>
  );
}

FormImageCropper.defaultProps = {
  width: "100%",
  height: "100%",
};

FormImageCropper.propTypes = {
  cropSetting: PropTypes.shape({}).isRequired,
  aspectRatio: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default FormImageCropper;
