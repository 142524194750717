import { TableCell, TableRow } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import Text from "../../../components/text.component";

function DisputeRow({ record, setShowModal, setRejectReason }) {
  return (
    <TableRow
      key={record.id}
      sx={{
        "& .MuiTableCell-root": {
          borderBottom: "no-set",
        },
      }}
    >
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center" }}>
          {record.id}
        </Text>
      </TableCell>
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center" }}>
          {record.employeeId}
        </Text>
      </TableCell>
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center", minWidth: "100px" }}>
          {record.createdAt}
        </Text>
      </TableCell>
      <TableCell>
        <Text
          variant="body2"
          noWrap={true}
          type="BrandColor"
          sx={{ textAlign: "center", cursor: "pointer" }}
          onClick={() => {
            setRejectReason(record.rejectReason);
            setShowModal(true);
          }}
        >
          View Detail
        </Text>
      </TableCell>
    </TableRow>
  );
}

DisputeRow.defaultProps = {
  record: {
    id: null,
    employeeId: null,
    rejectReason: "",
    createdAt: "",
  },
};

DisputeRow.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  setRejectReason: PropTypes.func.isRequired,
  record: PropTypes.shape({
    id: PropTypes.number,
    employeeId: PropTypes.number,
    rejectReason: PropTypes.string,
    createdAt: PropTypes.string,
  }),
};

export default DisputeRow;
