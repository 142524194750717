import React from "react";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Checkbox, ListItemText, OutlinedInput, useMediaQuery, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import Text from "../text.component";
import { capitalize } from "../../infrastructure/utils/capitalize";

export default function FormMultipleValueSelect({
  itemList,
  name,
  placeholder,
  width,
  isTransparent,
}) {
  const { values, setFieldValue, touched, errors, setFieldTouched } = useFormikContext("");
  const theme = useTheme();
  const showError = touched[name] && typeof errors[name] === "string";
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (event) => {
    setFieldValue(
      name,
      typeof event.target.value === "string" ? event.target.value.split(",") : event.target.value,
    );
  };

  const capitalizeStringArr = (arr) => {
    const arrString = [];
    const tempArr = isMobile ? arr.slice(0, 3) : arr;
    tempArr.map((item) => arrString.push(capitalize(item)));

    if (isMobile && arr.length > 3) {
      return `${arrString.join(" ,")}, ...`;
    }
    return arrString.join(" ,");
  };

  return (
    <FormControl sx={{ width }}>
      <Select
        multiple
        onBlur={() => setFieldTouched(name)}
        value={values[name]}
        onChange={handleChange}
        sx={{
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          backgroundColor: isTransparent ? "transparent" : theme.palette.colors.text.white,
        }}
        input={<OutlinedInput />}
        error={showError}
        displayEmpty
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <Text type="GreyColor">{placeholder}</Text>;
          }

          return capitalizeStringArr(selected);
        }}
      >
        {itemList.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            <Checkbox checked={values[name].some((checked) => checked === item.value)} />
            <ListItemText primary={item.label} />
          </MenuItem>
        ))}
      </Select>
      {showError && (
        <FormHelperText error sx={{ marginLeft: "0px" }}>
          {errors[name]}
        </FormHelperText>
      )}
    </FormControl>
  );
}

FormMultipleValueSelect.defaultProps = {
  width: "100%",
  isTransparent: false,
};

FormMultipleValueSelect.propTypes = {
  width: PropTypes.string,
  itemList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  isTransparent: PropTypes.bool,
};
