import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import DetailCardContainer from "../../../components/detail-card-container.component";
import Text from "../../../components/text.component";
import { statementSelector } from "../../../services/statement/statement-slice.services";

export default function StatementDetails() {
  const theme = useTheme();
  const { getStatementDetailObj } = useSelector(statementSelector);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <DetailCardContainer>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={isMobile ? 12 : 3}>
              <Box>
                <Text>Business Name: </Text>
                <Text type="DarkGreyColor">{getStatementDetailObj.data.merchantBusinessName}</Text>
              </Box>
            </Grid>
            <Grid item xs={isMobile ? 12 : 3}>
              <Box>
                <Text>Bank Account Name: </Text>
                <Text type="DarkGreyColor">
                  {getStatementDetailObj.data.merchantBankAccountName}
                </Text>
              </Box>
            </Grid>
            <Grid item xs={isMobile ? 12 : 3}>
              <Box>
                <Text>Bank Name: </Text>
                <Text type="DarkGreyColor">{getStatementDetailObj.data.merchantBankName}</Text>
              </Box>
            </Grid>
            <Grid item xs={isMobile ? 12 : 3}>
              <Box>
                <Text>Bank Account No: </Text>
                <Text type="DarkGreyColor">{getStatementDetailObj.data.merchantBankAccountNo}</Text>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={isMobile ? 12 : 4}>
              <Box>
                <Text variant="h6">Amount to be paid: </Text>
                <Text variant="h6" type="DarkGreyColor">
                  RM {getStatementDetailObj.data.amount}
                </Text>
              </Box>
            </Grid>
            <Grid item xs={isMobile ? 12 : 4}>
              <Box>
                <Text variant="h6">Paid On: </Text>
                <Text variant="h6" type="DarkGreyColor">
                  {getStatementDetailObj.data.completedAt
                    ? getStatementDetailObj.data.completedAt
                    : "-"}
                </Text>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DetailCardContainer>
  );
}
