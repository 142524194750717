import { Box, Grid, Link, styled, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import CtaButton from "../../../components/buttons/cta-button.component";
import RedBorderButton from "../../../components/buttons/red-border-button.component";
import DetailCardContainer from "../../../components/detail-card-container.component";
import Form from "../../../components/forms/form.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import PaddedView from "../../../components/padded-view.component";
import SkeletonLoader from "../../../components/skeleton.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import {
    createCreatorSocial,
    createCreatorUniqueLinks,
    creatorSelector,
    getCreatorDetails,
    updateCreator
} from "../../../services/creator/creator-slice.services";
import CreatorBankDetails from "../components/creator-bank-details.component";
import CreatorCommission from "../components/creator-commission.component";
import CreatorDetails from "../components/creator-details.component";
import CreatorSocialList from '../components/creator-social-list.component';
import CreatorStatements from "../components/creator-statements.component";
import CreatorUniqueLinks from "../components/creator-unique-links.component";
import RejectCreatorModal from "../components/reject-creator-modal.component";
import CreatorBankDetailsLoader from "../loaders/creator-bank-details-loader.component";
import CreatorCommissionLoader from "../loaders/creator-commission-loader.component";
import CreatorDetailsLoader from "../loaders/creator-details-loader.component";

const SpaceBetweenBox = styled(Box)({
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
});

const RowBox = styled(Box)({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
});

const RejectedReasonBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.colors.bg.rejectReasonBg,
    padding: theme.padding.paddingX[2],
}));

const validationSchema = Yup.object().shape({
    statusType: Yup.string().required(),
    reason: Yup.string().required().label("Reason"),
});

const linksValidationSchema = Yup.object().shape({
    experienceID: Yup.string().required(),
});

const socialValidationSchema = Yup.object().shape({
    social_id: Yup.string().required(),
    social_type: Yup.string().required(),
});

function CreatorDetailScreen() {
    const location = useLocation();
    const creatorId = new URLSearchParams(location.search).get("creatorId");
    const dispatch = useDispatch();
    const createSnackBar = useContext(SnackbarContext);
    const { getCreatorDetailsObj, updateCreatorObj } = useSelector(creatorSelector);
    const theme = useTheme();
    const [showRejectCreatorModal, setShowRejectCreatorModal] = useState(false);
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showSocialCreateModal, setShowSocialCreateModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getCreatorDetails(creatorId)).then(({ meta, error }) => {
            if (meta.requestStatus === "rejected") {
                createSnackBar({
                    message: error.message,
                    type: "error",
                    open: true,
                });
            }
        });

    }, []);

    // dispatch(getCreatorExperienceList({ creatorId, page: 1 })).then(({ meta, error }) => {
    //     if (meta.requestStatus === "rejected") {
    //         createSnackBar({
    //             message: error.message,
    //             type: "error",
    //             open: true,
    //         });
    //     }
    // });

    const onApproveCreator = () => {
        dispatch(updateCreator({ creatorId, type: "status", statusType: "approved" })).then(
            ({ meta, error, payload }) => {
                if (meta.requestStatus === "fulfilled") {
                    createSnackBar({
                        message: payload.message,
                        type: "success",
                        open: true,
                    });
                }
                if (meta.requestStatus === "rejected") {
                    createSnackBar({
                        message: error.message,
                        type: "error",
                        open: true,
                    });
                }
            },
        );
    };

    const onRejectCreator = (values, { resetForm }) => {
        dispatch(updateCreator({ creatorId, type: "status", ...values })).then(
            ({ meta, error, payload }) => {
                if (meta.requestStatus === "fulfilled") {
                    resetForm();
                    setShowRejectCreatorModal(false);
                    createSnackBar({
                        message: payload.message,
                        type: "success",
                        open: true,
                    });
                }
                if (meta.requestStatus === "rejected") {
                    createSnackBar({
                        message: error.message,
                        type: "error",
                        open: true,
                    });
                }
            },
        );
    };

    const renderStatusButton = () => (
        <>
            {getCreatorDetailsObj.data.creator.status === "rejected" && (
                <CtaButton
                    width="125px"
                    onClickButton={onApproveCreator}
                    isLoading={updateCreatorObj.status === "pending"}
                >
                    <Text type="WhiteColor">Approve</Text>
                </CtaButton>
            )}
            {getCreatorDetailsObj.data.creator.status === "approved" && (
                <RedBorderButton
                    width="125px"
                    isLoading={updateCreatorObj.status === "pending"}
                    onClickButton={() => setShowRejectCreatorModal(true)}
                >
                    <Text type="RejectText">Reject</Text>
                </RedBorderButton>
            )}
            {getCreatorDetailsObj.data.creator.status === "pending" && (
                <RowBox>
                    <RedBorderButton
                        width="125px"
                        isLoading={updateCreatorObj.status === "pending"}
                        onClickButton={() => setShowRejectCreatorModal(true)}
                    >
                        <Text type="RejectText">Reject</Text>
                    </RedBorderButton>
                    <Spacer size="s" position="left" />
                    <CtaButton
                        width="125px"
                        onClickButton={onApproveCreator}
                        isLoading={updateCreatorObj.status === "pending"}
                    >
                        <Text type="WhiteColor">Approve</Text>
                    </CtaButton>
                </RowBox>
            )}
        </>
    );

    const renderTitleAndStatus = () => {
        if (getCreatorDetailsObj.status === "succeeded") {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <SpaceBetweenBox>
                            <Box>
                                <Text variant="h4">{getCreatorDetailsObj.data.creator.name}</Text>
                                {getCreatorDetailsObj.data.creator.status === "rejected" ? (
                                    <Text type="RejectText">
                                        {capitalize(getCreatorDetailsObj.data.creator.status)}
                                    </Text>
                                ) : (
                                    <Text type="GreyColor">
                                        {capitalize(getCreatorDetailsObj.data.creator.status)}
                                    </Text>
                                )}
                            </Box>
                            {!isMobile && renderStatusButton()}
                        </SpaceBetweenBox>
                    </Grid>
                    {isMobile && (
                        <Grid item xs={12}>
                            <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                                {renderStatusButton()}
                            </Box>
                        </Grid>
                    )}
                    {getCreatorDetailsObj.data.creator.reason &&
                        getCreatorDetailsObj.data.creator.status === "rejected" && (
                            <Grid item xs={12}>
                                <RejectedReasonBox>
                                    <Text
                                        sx={{
                                            fontWeight: theme.fonts.fontWeights.medium,
                                            fontSize: theme.fonts.fontSizes.size18,
                                        }}
                                    >
                                        Reason for rejection
                                    </Text>
                                    <Text sx={{ fontSize: theme.fonts.fontSizes.size14 }}>
                                        {getCreatorDetailsObj.data.creator.reason}
                                    </Text>
                                </RejectedReasonBox>
                            </Grid>
                        )}
                </Grid>
            );
        }
        return (
            <>
                <SpaceBetweenBox>
                    <Box>
                        <SkeletonLoader height="40px" width="200px" />
                        <SkeletonLoader height="20px" width="125px" />
                    </Box>
                    {!isMobile && (
                        <RowBox>
                            <RedBorderButton width="125px" isLoading={true}>
                                <Text type="RejectText">Reject</Text>
                            </RedBorderButton>
                            <Spacer size="s" position="left" />
                            <CtaButton width="125px" isLoading={true}>
                                <Text type="WhiteColor">Approve</Text>
                            </CtaButton>
                        </RowBox>
                    )}
                </SpaceBetweenBox>
                {isMobile && (
                    <RowBox sx={{ marginTop: "25px" }}>
                        <RedBorderButton width="125px" isLoading={true}>
                            <Text type="RejectText">Reject</Text>
                        </RedBorderButton>
                        <Spacer size="s" position="left" />
                        <CtaButton width="125px" isLoading={true}>
                            <Text type="WhiteColor">Approve</Text>
                        </CtaButton>
                    </RowBox>
                )}
            </>
        );
    };

    const renderCreatorDetails = () => {
        if (getCreatorDetailsObj.status === "succeeded") {
            return <CreatorDetails />;
        }
        return <CreatorDetailsLoader />;
    };

    const renderCreatorBankDetails = () => {
        if (getCreatorDetailsObj.status === "succeeded") {
            return <CreatorBankDetails />;
        }
        return <CreatorBankDetailsLoader />;
    };

    const renderCreatorCommission = () => {
        if (getCreatorDetailsObj.status === "succeeded") {
            return <CreatorCommission />;
        }
        return <CreatorCommissionLoader />;
    };

    const onUniqueLinkCreate = (value, { resetForm }) => {
        const params = {
            experienceID: value.experienceID,
        };
        setIsLoading(true);
        dispatch(createCreatorUniqueLinks({ ...params })).then(({ meta, error, payload }) => {
            setIsLoading(false);
            if (meta.requestStatus === "fulfilled") {
                setShowCreateModal(false);
                resetForm();
                createSnackBar({
                    message: payload.message,
                    type: "success",
                });
            }
            if (meta.requestStatus === "rejected") {
                createSnackBar({
                    message: error.message,
                    type: "error",
                });
            }
        });
    };

    const onSocialMediaCreate = (value, { resetForm }) => {
        const params = {
            social_type: value.social_type,
            social_id: value.social_id
        };
        setIsLoading(true);
        dispatch(createCreatorSocial({ ...params })).then(({ meta, error, payload }) => {
            setIsLoading(false);
            if (meta.requestStatus === "fulfilled") {
                setShowCreateModal(false);
                resetForm();
                createSnackBar({
                    message: payload.message,
                    type: "success",
                });
            }
            if (meta.requestStatus === "rejected") {
                createSnackBar({
                    message: error.message,
                    type: "error",
                });
            }
        });
    };

    // const renderCreatorExperiences = () => {
    //     if (getCreatorDetailsObj.status === "succeeded") {
    //         return <CreatorExperiences creatorId={creatorId} />;
    //     }
    //     return <CreatorExperiencesLoader />;
    // };

    const renderAllStatementDetails = () => {
        if (getCreatorDetailsObj.status === "succeeded") {
            return (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Text variant="h5">Statement Details</Text>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={isMobile ? 12 : 3}>
                                <Box>
                                    <Text variant="h6">Open: </Text>
                                    <Text type="DarkGreyColor" variant="h5">
                                        RM {getCreatorDetailsObj.data.statementDetails.open}
                                    </Text>
                                </Box>
                            </Grid>
                            <Grid item xs={isMobile ? 12 : 3}>
                                <Box>
                                    <Text variant="h6">To Be Paid: </Text>
                                    <Text type="DarkGreyColor" variant="h5">
                                        RM {getCreatorDetailsObj.data.statementDetails.pending}
                                    </Text>
                                </Box>
                            </Grid>
                            <Grid item xs={isMobile ? 12 : 3}>
                                <Box>
                                    <Text variant="h6">Dispute: </Text>
                                    <Text type="DarkGreyColor" variant="h5">
                                        RM {getCreatorDetailsObj.data.statementDetails.dispute}
                                    </Text>
                                </Box>
                            </Grid>
                            <Grid item xs={isMobile ? 12 : 3}>
                                <Box>
                                    <Text variant="h6">Closed: </Text>
                                    <Text type="DarkGreyColor" variant="h5">
                                        RM {getCreatorDetailsObj.data.statementDetails.closed}
                                    </Text>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            );
        }

        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Text variant="h5">Statement Details</Text>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={isMobile ? 12 : 3}>
                            <Box>
                                <Skeleton width="100px" height="30px" />
                                <Skeleton width="150px" height="40px" />
                            </Box>
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 3}>
                            <Box>
                                <Skeleton width="100px" height="30px" />
                                <Skeleton width="150px" height="40px" />{" "}
                            </Box>
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 3}>
                            <Box>
                                <Skeleton width="100px" height="30px" />
                                <Skeleton width="150px" height="40px" />{" "}
                            </Box>
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 3}>
                            <Box>
                                <Skeleton width="100px" height="30px" />
                                <Skeleton width="150px" height="40px" />{" "}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    return (
        <PaddedView multiples={isMobile ? 1 : 3}>
            <Form
                initialValues={{ statusType: "rejected", reason: "" }}
                onSubmit={onRejectCreator}
                validationSchema={validationSchema}
            >
                <RejectCreatorModal
                    showModal={showRejectCreatorModal}
                    setShowModal={setShowRejectCreatorModal}
                />
            </Form>
            <Link href={routes.CREATORLIST} style={{ textDecoration: "none" }}>
                <Text type="GreyColor">{`< Back`}</Text>
            </Link>
            <Spacer size="xl" position="top" />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {renderTitleAndStatus()}
                </Grid>
                <Grid item xs={12}>
                    <DetailCardContainer>{renderCreatorDetails()}</DetailCardContainer>
                </Grid>
                <Grid item xs={12}>
                    <DetailCardContainer>{renderCreatorBankDetails()}</DetailCardContainer>
                </Grid>
                <Grid item xs={12}>
                    <DetailCardContainer>{renderCreatorCommission()}</DetailCardContainer>
                </Grid>
                {/* <Grid item xs={12}>
                    <DetailCardContainer>{renderCreatorExperiences()}</DetailCardContainer>
                </Grid> */}
                <Grid item xs={12}>
                    <DetailCardContainer>
                        <CreatorSocialList />
                    </DetailCardContainer>
                </Grid>
                <Grid item xs={12}>
                {/* <Form
                    onSubmit={onUniqueLinkCreate}
                    validationSchema={linksValidationSchema}
                    initialValues={{
                        experienceID: null,
                    }}
                >
                    <CreatorUniqueLinksCreateModal isShowModal={showCreateModal} setIsShowModal={setShowCreateModal} />
                </Form> */}
                    <DetailCardContainer>
                        <CreatorUniqueLinks />
                    </DetailCardContainer>
                </Grid>
                <Grid item xs={12}>
                    <DetailCardContainer>{renderAllStatementDetails()}</DetailCardContainer>
                </Grid>
                <Grid item xs={12}>
                    <DetailCardContainer>
                        <CreatorStatements />
                    </DetailCardContainer>
                </Grid>
            </Grid>
        </PaddedView>
    );
}

export default CreatorDetailScreen;
