import React, { useContext, useEffect, useState } from "react";
import { Box, Pagination, styled, Table, TableBody, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import PaddedView from "../../../../components/padded-view.component";
import Spacer from "../../../../components/spacer.component";
import TableWrapper from "../../../../components/table/table-wrapper.component";
import TableHeader from "../../../../components/table/table-header.component";
import { SnackbarContext } from "../../../../components/notifications/snackbar.context";
import Loading from "../../../../components/notifications/loading.component";
import DeleteConfirmationModal from "../../../../components/notifications/delete-confirmation-modal.component";
import {
  createFestivalSection,
  deleteFestivalSection,
  festivalSectionSelector,
  getFestivalSectionList,
} from "../../../../services/marketing/festival-section/festival-section-slice.services";
import FestivalSectionRow from "../components/festival-section-row.component";
import FestivalSectionTableLoader from "../loader/festival-section-table-loader.component";
import Form from "../../../../components/forms/form.component";
import FestivalSectionCreateModal from "../components/festival-section-create-modal.component";
import TableTitle from "../../../../components/table/table-title.component";

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const validationSchema = Yup.object().shape({
  label: Yup.string().required().max(80).label("Title"),
  iconPath: Yup.string().required().label("Festival Icon"),
  startAt: Yup.date().required().label("Start from"),
  endAt: Yup.date()
    .min(Yup.ref("startAt"), "End date must be after start date")
    .required()
    .label("End at"),
});

export default function FestivalSectionListScreen() {
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const { getFestivalSectionListObj, deleteFestivalSectionObj, createFestivalSectionObj } =
    useSelector(festivalSectionSelector);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteFestivalSectionId, setDeleteFestivalSectionId] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);

  useEffect(() => {
    dispatch(getFestivalSectionList({ q: searchKeyword, page: 1 })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  }, []);

  const refreshBannerList = (newPage) => {
    dispatch(getFestivalSectionList({ q: searchKeyword, page: newPage })).then(
      ({ meta, error }) => {
        setIsLoading(false);
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      },
    );
  };

  const onChangeSearch = (q) => {
    setPage(1);
    setSearchKeyword(q);
    dispatch(getFestivalSectionList({ q, page: 1 })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onPageChange = (e, newPage) => {
    setPage(newPage);
    refreshBannerList(newPage);
  };

  const onToggleDelete = (festivalSectionId) => {
    setDeleteFestivalSectionId(festivalSectionId);
    setShowDeleteModal(true);
  };

  const onConfirmDelete = () => {
    setIsLoading(true);
    dispatch(deleteFestivalSection(deleteFestivalSectionId)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
      if (meta.requestStatus === "fulfilled") {
        setShowDeleteModal(false);
        setPage(1);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
    });
  };

  const renderContent = () => {
    if (getFestivalSectionListObj.status === "succeeded") {
      return (
        <>
          <Table>
            <TableHeader headerCells={["Icon", "Section Name", "Duration", "Status", ""]} />
            <TableBody>
              {getFestivalSectionListObj.data.items.map((item) => (
                <FestivalSectionRow record={item} key={item.id} onToggleDelete={onToggleDelete} />
              ))}
            </TableBody>
          </Table>
          <CustomFooter>
            <Pagination
              sx={{
                "&& .Mui-selected": {
                  backgroundColor: theme.palette.colors.brand.primary,
                  color: theme.palette.colors.text.white,
                },
              }}
              page={page}
              onChange={onPageChange}
              count={getFestivalSectionListObj.data.pagination.totalPages}
              variant="outlined"
            />
          </CustomFooter>
        </>
      );
    }
    return <FestivalSectionTableLoader />;
  };

  const onCreateFestivalSection = (values) => {
    setIsLoading(true);
    dispatch(createFestivalSection(values)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
      if (meta.requestStatus === "fulfilled") {
        setShowCreateModal(false);
        setPage(1);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
    });
  };

  return (
    <PaddedView multiples={3}>
      <DeleteConfirmationModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        label="Are you sure to delete this festival?"
        isLoading={deleteFestivalSectionObj.status === "pending"}
        onConfirmClicked={onConfirmDelete}
      />
      <Form
        validationSchema={validationSchema}
        initialValues={{ label: "", iconPath: "", startAt: null, endAt: null }}
        onSubmit={onCreateFestivalSection}
      >
        <FestivalSectionCreateModal
          showModal={showCreateModal}
          setShowModal={setShowCreateModal}
          isLoading={createFestivalSectionObj.status === "pending"}
        />
      </Form>
      <Loading isLoading={isLoading} />
      <Spacer position="top" size="m" />
      <TableTitle
        title="Festival Section"
        onChangeSearch={onChangeSearch}
        searchKeyword={searchKeyword}
        onClickCreateButton={() => setShowCreateModal(true)}
        placeholder="Search Festival"
      />
      <Spacer position="top" size="xl" />
      <TableWrapper>{renderContent()}</TableWrapper>
    </PaddedView>
  );
}
