import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../api-services";

export const getCorporateList = createAsyncThunk("employees/corporates", async (payload) => {
    const response = await getReq(
        `${process.env.REACT_APP_API_PRIVATE_V1}/employees/corporates?status=${payload.status}&userTypes=admin&q=${payload.q}&page=${payload.page}`,
        payload,
    );
    return response;
});

export const getCorporateStaffList = createAsyncThunk("employees/corporates/staffs", async (payload) => {
    const response = await getReq(
        `${process.env.REACT_APP_API_PRIVATE_V1}/employees/corporates/${payload.corporateId}/staffs?status=${payload.status}&userTypes=staff&q=${payload.q}&page=${payload.page}`,
        payload,
    );
    return response;
});

export const getCorporateDetails = createAsyncThunk(
    "employees/corporates-details",
    async (payload) => {
        const response = await getReq(
            `${process.env.REACT_APP_API_PRIVATE_V1}/employees/corporates/${payload}`,
        );
        return response;
    },
);

export const updateCorporate = createAsyncThunk("employees/corporates-update", async (payload) => {
    const response = await putReq(
        `${process.env.REACT_APP_API_PRIVATE_V1}/employees/corporates/${payload.corporateId}?params=${payload.type}`,
        payload,
    );
    return response;
});

export const addCorporatePoints = createAsyncThunk("employees/corporates/points", async (payload) => {
    const response = await putReq(
        `${process.env.REACT_APP_API_PRIVATE_V1}/employees/corporates/${payload.corporateId}/topup?params=${payload.type}&amount=${payload.balance}`,
        payload,
    );
    return response;
});

export const deleteCorporate = createAsyncThunk("employees/corporates-delete", async (corporateId) => {
    const response = await delReq(
        `${process.env.REACT_APP_API_PRIVATE_V1}/employees/corporates/${corporateId}`,
    );
    return response;
});

export const getCorporateExperienceList = createAsyncThunk(
    "employees/corporates-experiences-list",
    async (payload) => {
        const response = await getReq(
            `${process.env.REACT_APP_API_PRIVATE_V1}/employees/corporates/${payload.corporateId}/experiences?page=${payload.page}`,
        );
        return response;
    },
);

export const searchCorporate = createAsyncThunk("employees/corporates/search", async (q) => {
    const response = await getReq(
        `${process.env.REACT_APP_API_PRIVATE_V1}/employees/corporates/search?q=${q}`,
    );
    return response;
});

export const createCorporate = createAsyncThunk("employees/corporates/create", async (payload) => {
    const response = await postReq(
        `${process.env.REACT_APP_API_PRIVATE_V1}/employees/corporates`,
        payload,
    );
    return response;
});

export const createCorporateStaff = createAsyncThunk("employees/corporates/staffs/create", async (payload) => {
    const response = await postReq(
        `${process.env.REACT_APP_API_PRIVATE_V1}/employees/corporates/${payload.userID}/staffs`,
        payload,
    );
    return response;
});

export const getCorporateStatementList = createAsyncThunk(
    "employees/corporates-statements-list",
    async (payload) => {
        const response = await getReq(
            `${process.env.REACT_APP_API_PRIVATE_V1}/employees/corporates/${payload.corporateId}/statements?q=${payload.q}&page=${payload.page}`,
        );
        return response;
    },
);

export const getCorporatePointTransferLogs = createAsyncThunk(
    "employees/corporates/topupLogs",
    async (payload) => {
        const response = await getReq(
            `${process.env.REACT_APP_API_PRIVATE_V1}/employees/corporates/${payload.corporateId}/topupLogs?q=${payload.q}&page=${payload.page}`,
        );
        return response;
    },
);

export const createCorporateSocial = createAsyncThunk("employees/corporates/social/create", async (payload) => {
    const response = await postReq(
        `${process.env.REACT_APP_API_PRIVATE_V1}/employees/corporates/${payload.corporateId}/socials`,
        payload,
    );
    return response;
});

export const getCorporateSocialList = createAsyncThunk(
    "employees/corporates-social-list",
    async (payload) => {
        const response = await getReq(
            `${process.env.REACT_APP_API_PRIVATE_V1}/employees/corporates/${payload.corporateId}/socials?q=${payload.q}&page=${payload.page}`,
        );
        return response;
    },
);

export const createCorporateUniqueLinks = createAsyncThunk("employees/corporates/uniqueLinks", async (payload) => {
    const response = await postReq(
        `${process.env.REACT_APP_API_PRIVATE_V1}/employees/corporates/${payload.corporateId}/unique-links`,
        payload,
    );
    return response;
});

export const getCorporateLinksList = createAsyncThunk(
    "employees/corporates/links",
    async (payload) => {
        const response = await getReq(
            `${process.env.REACT_APP_API_PRIVATE_V1}/employees/corporates/${payload.corporateId}/unique-links?q=${payload.q}&page=${payload.page}`,
        );
        return response;
    },
);

const corporateSlice = createSlice({
    name: "corporate",
    initialState: {
        getCorporateListObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
        getCorporateStaffListObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
        getCorporateDetailsObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
        updateCorporateObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
        addCorporatePointsObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
        deleteCorporateObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
        getCorporateExperienceListObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
        searchCorporateObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
        createCorporateObj: { status: "idle", data: null, successMessage: null, errorMessage: null },
        createCorporateStaffObj: { status: "idle", data: null, successMessage: null, errorMessage: null },
        getCorporateStatementListObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
        getCorporatePointTransferLogsObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
        getCorporateSocialListObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
        createCorporateUniqueLinksObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
        getCorporateLinksObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null
        },
    },
    reducers: {},
    extraReducers: {
        [getCorporateList.pending]: (state) => {
            state.getCorporateListObj.status = "pending";
        },
        [getCorporateList.fulfilled]: (state, action) => {
            const { data, message } = action.payload;

            state.getCorporateListObj.status = "succeeded";
            state.getCorporateListObj.data = data;
            state.getCorporateListObj.successMessage = message;
        },
        [getCorporateList.rejected]: (state, action) => {
            const { message } = action.error;

            state.getCorporateListObj.status = "failed";
            state.getCorporateListObj.errorMessage = message;
        },
        
        [getCorporateStaffList.pending]: (state) => {
            state.getCorporateStaffListObj.status = "pending";
        },
        [getCorporateStaffList.fulfilled]: (state, action) => {
            const { data, message } = action.payload;

            state.getCorporateStaffListObj.status = "succeeded";
            state.getCorporateStaffListObj.data = data;
            state.getCorporateStaffListObj.successMessage = message;
        },
        [getCorporateStaffList.rejected]: (state, action) => {
            const { message } = action.error;

            state.getCorporateStaffListObj.status = "failed";
            state.getCorporateStaffListObj.errorMessage = message;
        },
        [getCorporateDetails.pending]: (state) => {
            state.getCorporateDetailsObj.status = "pending";
        },
        [getCorporateDetails.fulfilled]: (state, action) => {
            const { data, message } = action.payload;

            state.getCorporateDetailsObj.status = "succeeded";
            state.getCorporateDetailsObj.data = data;
            state.getCorporateDetailsObj.successMessage = message;
        },
        [getCorporateDetails.rejected]: (state, action) => {
            const { message } = action.error;

            state.getCorporateDetailsObj.status = "failed";
            state.getCorporateDetailsObj.errorMessage = message;
        },
        [updateCorporate.pending]: (state) => {
            state.updateCorporateObj.status = "pending";
        },
        [updateCorporate.fulfilled]: (state, action) => {
            const { data, message } = action.payload;
            state.updateCorporateObj.status = "succeeded";
            state.updateCorporateObj.data = data;
            state.updateCorporateObj.successMessage = message;

            state.getCorporateDetailsObj.data = data;
        },
        [updateCorporate.rejected]: (state, action) => {
            const { message } = action.error;

            state.updateCorporateObj.status = "failed";
            state.updateCorporateObj.errorMessage = message;
        },
        [addCorporatePoints.pending]: (state) => {
            state.addCorporatePointsObj.status = "pending";
        },
        [addCorporatePoints.fulfilled]: (state, action) => {
            const { data, message } = action.payload;
            state.addCorporatePointsObj.status = "succeeded";
            state.addCorporatePointsObj.data = data;
            state.addCorporatePointsObj.successMessage = message;

            state.getCorporateDetailsObj.data = data;
        },
        [addCorporatePoints.rejected]: (state, action) => {
            const { message } = action.error;

            state.addCorporatePointsObj.status = "failed";
            state.addCorporatePointsObj.errorMessage = message;
        },
        [deleteCorporate.pending]: (state) => {
            state.deleteCorporateObj.status = "pending";
        },
        [deleteCorporate.fulfilled]: (state, action) => {
            const { data, message } = action.payload;
            state.deleteCorporateObj.status = "succeeded";
            state.deleteCorporateObj.data = data;
            state.deleteCorporateObj.successMessage = message;

            state.getCorporateDetailsObj.data = data;
        },
        [deleteCorporate.rejected]: (state, action) => {
            const { message } = action.error;

            state.deleteCorporateObj.status = "failed";
            state.deleteCorporateObj.errorMessage = message;
        },
        [getCorporateExperienceList.pending]: (state) => {
            state.getCorporateExperienceListObj.status = "pending";
        },
        [getCorporateExperienceList.fulfilled]: (state, action) => {
            const { data, message } = action.payload;
            state.getCorporateExperienceListObj.status = "succeeded";
            state.getCorporateExperienceListObj.data = data;
            state.getCorporateExperienceListObj.successMessage = message;
        },
        [getCorporateExperienceList.rejected]: (state, action) => {
            const { message } = action.error;

            state.getCorporateExperienceListObj.status = "failed";
            state.getCorporateExperienceListObj.errorMessage = message;
        },
        [searchCorporate.pending]: (state) => {
            state.searchCorporateObj.status = "pending";
        },
        [searchCorporate.fulfilled]: (state, action) => {
            const { data, message } = action.payload;
            state.searchCorporateObj.status = "succeeded";
            state.searchCorporateObj.data = data;
            state.searchCorporateObj.successMessage = message;
        },
        [searchCorporate.rejected]: (state, action) => {
            const { message } = action.error;

            state.searchCorporateObj.status = "failed";
            state.searchCorporateObj.errorMessage = message;
        },
        [createCorporate.pending]: (state) => {
            state.createCorporateObj.status = "pending";
        },
        [createCorporate.fulfilled]: (state, action) => {
            const { data, message } = action.payload;
            state.createCorporateObj.status = "succeeded";
            state.createCorporateObj.data = data;
            state.createCorporateObj.successMessage = message;
        },
        [createCorporate.rejected]: (state, action) => {
            const { message } = action.error;

            state.createCorporateObj.status = "failed";
            state.createCorporateObj.errorMessage = message;
        },
        [createCorporateStaff.pending]: (state) => {
            state.createCorporateStaffObj.status = "pending";
        },
        [createCorporateStaff.fulfilled]: (state, action) => {
            const { data, message } = action.payload;
            state.createCorporateStaffObj.status = "succeeded";
            state.createCorporateStaffObj.data = data;
            state.createCorporateStaffObj.successMessage = message;
        },
        [createCorporateStaff.rejected]: (state, action) => {
            const { message } = action.error;

            state.createCorporateStaffObj.status = "failed";
            state.createCorporateStaffObj.errorMessage = message;
        },
        [getCorporateStatementList.pending]: (state) => {
            state.getCorporateStatementListObj.status = "pending";
        },
        [getCorporateStatementList.fulfilled]: (state, action) => {
            const { data, message } = action.payload;
            state.getCorporateStatementListObj.status = "succeeded";
            state.getCorporateStatementListObj.data = data;
            state.getCorporateStatementListObj.successMessage = message;
        },
        [getCorporateStatementList.rejected]: (state, action) => {
            const { message } = action.error;

            state.getCorporateStatementListObj.status = "failed";
            state.getCorporateStatementListObj.errorMessage = message;
        },
        [getCorporatePointTransferLogs.pending]: (state) => {
            state.getCorporatePointTransferLogsObj.status = "pending";
        },
        [getCorporatePointTransferLogs.fulfilled]: (state, action) => {
            // Log the action payload for debugging
            console.log('Action Payload:', action.payload);
            const { data, message } = action.payload;
            console.log('Data:', data, 'Message:', message);
            state.getCorporatePointTransferLogsObj.status = "succeeded";
            state.getCorporatePointTransferLogsObj.data = data;
            state.getCorporatePointTransferLogsObj.successMessage = message;
        },
        [getCorporatePointTransferLogs.rejected]: (state, action) => {
            // Log the error for debugging
            console.log("getCorporatePointTransferLogs rejected", action.error);
            const { message } = action.error;
            state.getCorporatePointTransferLogsObj.status = "failed";
            state.getCorporatePointTransferLogsObj.errorMessage = message;
        },
        [getCorporateSocialList.pending]: (state) => {
            state.getCorporateSocialListObj.status = "pending";
        },
        [getCorporateSocialList.fulfilled]: (state, action) => {
            const { data, message } = action.payload;
            state.getCorporateSocialListObj.status = "succeeded";
            state.getCorporateSocialListObj.data = data;
            state.getCorporateSocialListObj.successMessage = message;
        },
        [getCorporateSocialList.rejected]: (state, action) => {
            const { message } = action.error;

            state.getCorporateSocialListObj.status = "failed";
            state.getCorporateSocialListObj.errorMessage = message;
        },
        [createCorporateUniqueLinks.pending]: (state) => {
            state.createCorporateUniqueLinksObj.status = "pending";
        },
        [createCorporateUniqueLinks.fulfilled]: (state, action) => {
            const { data, message } = action.payload;
            state.createCorporateUniqueLinksObj.status = "succeeded";
            state.createCorporateUniqueLinksObj.data = data;
            state.createCorporateUniqueLinksObj.successMessage = message;
        },
        [createCorporateUniqueLinks.rejected]: (state, action) => {
            const { message } = action.error;
            state.createCorporateUniqueLinksObj.status = "failed";
            state.createCorporateUniqueLinksObj.errorMessage = message;
        },
        [getCorporateLinksList.pending]: (state) => {
            state.getCorporateLinksObj.status = "pending";
        },
        [getCorporateLinksList.fulfilled]: (state, action) => {
            const { data, message } = action.payload;
            state.getCorporateLinksObj.status = "succeeded";
            state.getCorporateLinksObj.data = data;
            state.getCorporateLinksObj.successMessage = message;
        },
        [getCorporateLinksList.rejected]: (state, action) => {
            const { message } = action.error;
            state.getCorporateLinksObj.status = "failed";
            state.getCorporateLinksObj.errorMessage = message;
        },
    },
});

export default corporateSlice.reducer;

// state
export const corporateSelector = (state) => state.corporate;
