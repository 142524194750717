import React from "react";
import {
  Autocomplete,
  Box,
  FormHelperText,
  Grid,
  IconButton,
  Modal,
  styled,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { useFormikContext } from "formik";
import { DebounceInput } from "react-debounce-input";
import { useSelector } from "react-redux";
import Text from "../../../components/text.component";
import Spacer from "../../../components/spacer.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import { merchantSelector } from "../../../services/merchant/merchant-slice.services";
import { experienceSelector } from "../../../services/experience/experience-slice.services";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.brand.white,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  paddingTop: "25px",
  paddingBottom: "25px",
  paddingLeft: "50px",
  paddingRight: "50px",
  outline: "none",
  maxHeight: "90%",
  display: "flex",
  flexDirection: "column",
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.brand.secondary,
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "1px",
  backgroundColor: theme.palette.colors.ui.border,
}));

export default function ExperienceCreateModal({
  isShowModal,
  setIsShowModal,
  onSearchKeywordChange,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { resetForm, values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext();
  const showMerchantError = touched.merchant && typeof errors.merchant === "string";
  const { searchMerchantObj } = useSelector(merchantSelector);
  const { createExperienceObj } = useSelector(experienceSelector);

  const handleCancel = () => {
    resetForm();
    setIsShowModal(false);
  };

  return (
    <Modal open={isShowModal} onClose={handleCancel}>
      <ModalBox sx={{ width: isMobile ? "350px" : "450px" }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Box width="25px" />
          <Text variant="h5">Create Experience</Text>
          <CloseIconButton onClick={handleCancel}>
            <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
          </CloseIconButton>
        </Grid>
        <Spacer size="l" position="top" />
        <SeperateLine />
        <Spacer size="l" position="top" />
        <Box
          sx={{
            display: "flex",
            flex: 1,
            overflowY: "auto",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Text>Title</Text>
              <FormFieldText name="title" placeholder="Experience Title" />
            </Grid>
            <Grid item xs={12}>
              <Text>Merchant</Text>
              <Autocomplete
                sx={{
                  "& .MuiTextField-root": {
                    borderTopLeftRadius: theme.shape.borderRadius[0],
                    borderTopRightRadius: theme.shape.borderRadius[0],
                    borderBottomLeftRadius: theme.shape.borderRadius[0],
                    borderBottomRightRadius: theme.shape.borderRadius[0],
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      borderTopLeftRadius: theme.shape.borderRadius[0],
                      borderTopRightRadius: theme.shape.borderRadius[0],
                      borderBottomLeftRadius: theme.shape.borderRadius[0],
                      borderBottomRightRadius: theme.shape.borderRadius[0],
                    },
                  },
                }}
                options={searchMerchantObj.data || []}
                ListboxProps={{
                  style: { maxHeight: "200px" },
                }}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={values.merchant}
                onChange={(event, value) => setFieldValue("merchant", value)}
                renderInput={(params) => (
                  <DebounceInput
                    onBlur={() => setFieldTouched("merchant")}
                    error={showMerchantError}
                    {...params}
                    debounceTimeout={500}
                    element={TextField}
                    placeholder="Select Merchant"
                    variant="outlined"
                    onChange={(e) => onSearchKeywordChange(e.target.value)}
                  />
                )}
              />
              {showMerchantError && (
                <FormHelperText error sx={{ margin: "3px 14px 0px" }}>
                  {errors.merchant}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </Box>
        <Spacer size="xxl" position="top" />
        <Spacer size="xxl" position="top" />
        <FormSubmitButton isLoading={createExperienceObj.status === "pending"}>
          <Text type="WhiteColor">Create</Text>
        </FormSubmitButton>
      </ModalBox>
    </Modal>
  );
}

ExperienceCreateModal.propTypes = {
  onSearchKeywordChange: PropTypes.func.isRequired,
  isShowModal: PropTypes.bool.isRequired,
  setIsShowModal: PropTypes.func.isRequired,
};
