import React from "react";
import PropTypes from "prop-types";
import { TableCell, IconButton, TableRow } from "@mui/material";
import { Link } from "react-router-dom";
import Text from "../../../components/text.component";
import EditIcon from "../../../assets/images/edit-icon";
import routes from "../../../navigation/routes";
import DeleteIcon from "../../../assets/images/delete-icon";

function ExperienceRow({ record, onTriggerDelete }) {
  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  const getCategoryList = () => {
    const categoryString = [];
    if (record.categories && record.categories !== null) {
      record.categories.map((item) => categoryString.push(item.label));
    }

    return categoryString.toString().replace(/,/g, ", ");
  };

  return (
    <TableRow
      key={record.id}
      sx={{
        "& .MuiTableCell-root": {
          borderBottom: "no-set",
        },
      }}
    >
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center" }}>
          {record.id}
        </Text>
      </TableCell>
      <TableCell>
        <Text
          variant="body2"
          sx={{
            textAlign: "center",
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
          }}
        >
          {record.merchant.name}
        </Text>
      </TableCell>
      <TableCell sx={{ maxWidth: "220px" }}>
        <Text
          variant="body2"
          sx={{
            textAlign: "center",
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
          }}
        >
          {getCategoryList()}
        </Text>
      </TableCell>
      <TableCell>
        <Text
          variant="body2"
          sx={{
            textAlign: "center",
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
          }}
        >
          {record.name}
        </Text>
      </TableCell>
      <TableCell>
        <Text variant="body2" noWrap={true} sx={{ textAlign: "center" }}>
          {record.createdAt}
        </Text>
      </TableCell>
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center" }}>
          {capitalize(record.status.toString())}
        </Text>
      </TableCell>
      <TableCell>
        <Link to={{ pathname: `${routes.EXPERIENCEDETAIL}`, search: `experienceId=${record.id}` }}>
          <IconButton>
            <EditIcon />
          </IconButton>
        </Link>
        <IconButton onClick={() => onTriggerDelete(record.deletable, record.id)} disabled={!record.deletable}>
          <DeleteIcon disabled={!record.deletable}/>
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

ExperienceRow.defaultProps = {
  record: {
    categories: { categoryId: null, id: null, label: "" },
    createdAt: "",
    id: null,
    merchant: {
      id: null,
      name: "",
    },
    name: "",
    status: "",
    deletable: true,
  },
};

ExperienceRow.propTypes = {
  onTriggerDelete: PropTypes.func.isRequired,
  record: PropTypes.shape({
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        categoryId: PropTypes.number,
        id: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
    createdAt: PropTypes.string,
    id: PropTypes.number,
    merchant: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    name: PropTypes.string,
    status: PropTypes.string,
    deletable: PropTypes.bool,
  }),
};

export default ExperienceRow;
