import React from "react";
import { Box, InputAdornment, styled, TextField } from "@mui/material";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import Text from "../../../components/text.component";

const QuantityBox = styled(Box)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  padding: "0px",
  "& .MuiTextField-root": {
    padding: "0px",
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    width: "100%",
    backgroundColor: theme.palette.colors.text.white,
    "& .MuiOutlinedInput-root": {
      padding: "0px",
      borderTopLeftRadius: theme.shape.borderRadius[0],
      borderTopRightRadius: theme.shape.borderRadius[0],
      borderBottomLeftRadius: theme.shape.borderRadius[0],
      borderBottomRightRadius: theme.shape.borderRadius[0],
      " &.Mui-focused fieldset": {
        borderColor: theme.palette.colors.brand.secondary,
      },
    },
  },
}));

function PromoFormFieldQuantity({ name, placeholder, disabled, width, isPrice, selectedType }) {
  const { setFieldValue, values, touched, errors, setFieldTouched } = useFormikContext();

  const showError = touched[name] && typeof errors[name] === "string";

  const onBlurHandle = (event) => {
    setFieldTouched(name);
    if (event.target.value === "") {
      setFieldValue(name, 1);
      return;
    }
    setFieldValue(name, event.target.value);
  };

  return (
    <QuantityBox sx={{ width }}>
      <TextField
        onWheel={(e) => e.target.blur()}
        disabled={disabled}
        error={showError}
        helperText={showError ? errors[name] : null}
        onBlur={onBlurHandle}
        variant="outlined"
        type="number"
        placeholder={placeholder}
        onChange={(e) => {
          setFieldValue(name, parseInt(e.target.value, 10));
        }}
        value={selectedType === "fixed amount" ? values.discount : values[name]}
        InputProps={
          isPrice
            ? {
                inputMode: "decimal",
                startAdornment: (
                  <InputAdornment position="start" sx={{ paddingLeft: "14px" }}>
                    <Text type="DarkGreyColor">RM</Text>
                  </InputAdornment>
                ),
              }
            : {}
        }
      />
    </QuantityBox>
  );
}

PromoFormFieldQuantity.defaultProps = {
  isPrice: false,
  width: "100%",
  disabled: false,
  selectedType: "",
};

PromoFormFieldQuantity.propTypes = {
  isPrice: PropTypes.bool,
  width: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  selectedType: PropTypes.string,
};

export default PromoFormFieldQuantity;
