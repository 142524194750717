import React from "react";
import PropTypes from "prop-types";
import { TableCell, IconButton, TableRow } from "@mui/material";
import { Link } from "react-router-dom";
import Text from "../../../components/text.component";
import EditIcon from "../../../assets/images/edit-icon";
import routes from "../../../navigation/routes";

function PromoCodeRedemptionRow({ record }) {
  return (
    <TableRow
      key={record.id}
      sx={{
        "& .MuiTableCell-root": {
          borderBottom: "no-set",
        },
      }}
    >
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center" }}>
          {record.id}
        </Text>
      </TableCell>
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center" }}>
          {record.userId}
        </Text>
      </TableCell>
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center" }}>
          {record.username}
        </Text>
      </TableCell>
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center" }}>
          {record.status.toUpperCase().replace("_", " ")}
        </Text>
      </TableCell>
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center" }}>
          {record.dateRedeemed}
        </Text>
      </TableCell>
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center" }}>
          {record.bookingId}
        </Text>
      </TableCell>
      <TableCell>
        <Link
          to={{ pathname: `${routes.BOOKING_DETAIL}`, search: `bookingId=${record.bookingId}` }}
        >
          <IconButton>
            <EditIcon />
          </IconButton>
        </Link>
      </TableCell>
    </TableRow>
  );
}

PromoCodeRedemptionRow.defaultProps = {
  record: {
    id: "",
    status: "",
    dateRedeemed: "",
    userId: "",
    username: "",
    bookingId: "",
  },
};

PromoCodeRedemptionRow.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    dateRedeemed: PropTypes.string,
    userId: PropTypes.number,
    username: PropTypes.string,
    bookingId: PropTypes.number,
  }),
};

export default PromoCodeRedemptionRow;
