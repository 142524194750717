import React from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  styled,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Text from "../../../components/text.component";
import Spacer from "../../../components/spacer.component";
import CenteredRowBox from "../../../components/centered-row-box.component";
import BorderButton from "../../../components/buttons/border-button.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";

const ViewSlotBox = styled(Box)({
  height: "56px",
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
});

const ButtonContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  marginTop: "20px",
});

function ExperiencePriceForm({ name, onShowTimeslotModal, setIsEditing, isEditing }) {
  const theme = useTheme();
  const { touched, errors, values, setFieldValue, setFieldTouched, resetForm } = useFormikContext();
  const showError = touched[name] && typeof errors[name] === "string";
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (evt) => {
    const { value } = evt.target;
    if (value.match(/\./g)) {
      const [, decimal] = value.split(".");
      if (decimal?.length > 2) {
        return;
      }
    }
    setFieldValue("price", value);
  };

  return (
    <>
      <CenteredRowBox>
        <Text sx={{ fontSize: theme.fonts.fontSizes.size18 }}>Price</Text>
        <Spacer size="s" position="left" />
        <IconButton
          sx={{ color: theme.palette.colors.text.primary }}
          disabled={isEditing.price}
          onClick={() => setIsEditing((i) => ({ ...i, price: true }))}
        >
          <ModeEditIcon />
        </IconButton>
      </CenteredRowBox>
      <Spacer size="s" position="top" />
      <Grid container width={isMobile ? "100%" : "60%"} spacing={3}>
        <Grid item xs={isMobile ? 12 : 4}>
          <TextField
            disabled={!isEditing.price}
            sx={{
              borderTopLeftRadius: theme.shape.borderRadius[0],
              borderTopRightRadius: theme.shape.borderRadius[0],
              borderBottomLeftRadius: theme.shape.borderRadius[0],
              borderBottomRightRadius: theme.shape.borderRadius[0],
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderTopLeftRadius: theme.shape.borderRadius[0],
                borderTopRightRadius: theme.shape.borderRadius[0],
                borderBottomLeftRadius: theme.shape.borderRadius[0],
                borderBottomRightRadius: theme.shape.borderRadius[0],
                backgroundColor: theme.palette.colors.bg.white,
                height: "56px",
              },
            }}
            error={showError}
            variant="outlined"
            helperText={showError ? errors[name] : null}
            onBlur={() => setFieldTouched(name)}
            name="price"
            placeholder="Price"
            onChange={handleChange}
            value={values[name] === null ? "" : values[name]}
            InputProps={{
              style: {},
              startAdornment: (
                <InputAdornment position="start">
                  <Text type="DarkGreyColor">RM</Text>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item>
          <ViewSlotBox>
            <Button
              onClick={onShowTimeslotModal}
              sx={{ textTransform: "none", ":hover": { backgroundColor: "transparent" } }}
            >
              <CalendarMonthIcon sx={{ color: theme.palette.colors.text.darkGrey }} />
              <Spacer position="left" size="xs" />
              <Text type="DarkGreyColor">View Timeslots</Text>
            </Button>
          </ViewSlotBox>
        </Grid>
      </Grid>
      {isEditing.price && (
        <ButtonContainer>
          <BorderButton
            width="100px"
            onClickButton={() => {
              resetForm();
              setIsEditing((i) => ({ ...i, price: false }));
            }}
          >
            <Text type="BrandColor">Cancel</Text>
          </BorderButton>
          <Spacer size="s" position="left" />
          <FormSubmitButton width="100px">
            <Text type="WhiteColor">Save</Text>
          </FormSubmitButton>
        </ButtonContainer>
      )}
    </>
  );
}

ExperiencePriceForm.propTypes = {
  name: PropTypes.string.isRequired,
  onShowTimeslotModal: PropTypes.func.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  isEditing: PropTypes.shape({
    price: PropTypes.bool,
  }).isRequired,
};

export default ExperiencePriceForm;
