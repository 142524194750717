import { Box, Grid, Link, styled, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import CtaButton from "../../../components/buttons/cta-button.component";
import RedBorderButton from "../../../components/buttons/red-border-button.component";
import DetailCardContainer from "../../../components/detail-card-container.component";
import Form from "../../../components/forms/form.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import PaddedView from "../../../components/padded-view.component";
import SkeletonLoader from "../../../components/skeleton.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import {
  getMerchantDetails,
  getMerchantExperienceList,
  merchantSelector,
  updateMerchant,
} from "../../../services/merchant/merchant-slice.services";
import MerchantBankDetails from "../components/merchant-bank-details.component";
import MerchantCommission from "../components/merchant-commission.component";
import MerchantDetails from "../components/merchant-details.component";
import MerchantExperiences from "../components/merchant-experiences.component";
import MerchantStatements from "../components/merchant-statements.component";
import RejectMerchantModal from "../components/reject-merchant-modal.component";
import MerchantBankDetailsLoader from "../loader/merchant-bank-details-loader.component";
import MerchantCommissionLoader from "../loader/merchant-commission-loader.component";
import MerchantDetailsLoader from "../loader/merchant-details-loader.component";
import MerchantExperiencesLoader from "../loader/merchant-experiences-loader.component";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
});

const RowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const RejectedReasonBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.colors.bg.rejectReasonBg,
  padding: theme.padding.paddingX[2],
}));

const validationSchema = Yup.object().shape({
  statusType: Yup.string().required(),
  reason: Yup.string().required().label("Reason"),
});

function MerchantDetailScreen() {
  const location = useLocation();
  const merchantId = new URLSearchParams(location.search).get("merchantId");
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const { getMerchantDetailsObj, updateMerchantObj } = useSelector(merchantSelector);
  const theme = useTheme();
  const [showRejectMerchantModal, setShowRejectMerchantModal] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getMerchantDetails(merchantId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
    dispatch(getMerchantExperienceList({ merchantId, page: 1 })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  const onApproveMerchant = () => {
    dispatch(updateMerchant({ merchantId, type: "status", statusType: "approved" })).then(
      ({ meta, error, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  const onRejectMerchant = (values, { resetForm }) => {
    dispatch(updateMerchant({ merchantId, type: "status", ...values })).then(
      ({ meta, error, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          resetForm();
          setShowRejectMerchantModal(false);
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  const renderStatusButton = () => (
    <>
      {getMerchantDetailsObj.data.merchant.status === "rejected" && (
        <CtaButton
          width="125px"
          onClickButton={onApproveMerchant}
          isLoading={updateMerchantObj.status === "pending"}
        >
          <Text type="WhiteColor">Approve</Text>
        </CtaButton>
      )}
      {getMerchantDetailsObj.data.merchant.status === "approved" && (
        <RedBorderButton
          width="125px"
          isLoading={updateMerchantObj.status === "pending"}
          onClickButton={() => setShowRejectMerchantModal(true)}
        >
          <Text type="RejectText">Reject</Text>
        </RedBorderButton>
      )}
      {getMerchantDetailsObj.data.merchant.status === "pending" && (
        <RowBox>
          <RedBorderButton
            width="125px"
            isLoading={updateMerchantObj.status === "pending"}
            onClickButton={() => setShowRejectMerchantModal(true)}
          >
            <Text type="RejectText">Reject</Text>
          </RedBorderButton>
          <Spacer size="s" position="left" />
          <CtaButton
            width="125px"
            onClickButton={onApproveMerchant}
            isLoading={updateMerchantObj.status === "pending"}
          >
            <Text type="WhiteColor">Approve</Text>
          </CtaButton>
        </RowBox>
      )}
    </>
  );

  const renderTitleAndStatus = () => {
    if (getMerchantDetailsObj.status === "succeeded") {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SpaceBetweenBox>
              <Box>
                <Text variant="h4">{getMerchantDetailsObj.data.merchant.name}</Text>
                {getMerchantDetailsObj.data.merchant.status === "rejected" ? (
                  <Text type="RejectText">
                    {capitalize(getMerchantDetailsObj.data.merchant.status)}
                  </Text>
                ) : (
                  <Text type="GreyColor">
                    {capitalize(getMerchantDetailsObj.data.merchant.status)}
                  </Text>
                )}
              </Box>
              {!isMobile && renderStatusButton()}
            </SpaceBetweenBox>
          </Grid>
          {isMobile && (
            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                {renderStatusButton()}
              </Box>
            </Grid>
          )}
          {getMerchantDetailsObj.data.merchant.reason &&
            getMerchantDetailsObj.data.merchant.status === "rejected" && (
              <Grid item xs={12}>
                <RejectedReasonBox>
                  <Text
                    sx={{
                      fontWeight: theme.fonts.fontWeights.medium,
                      fontSize: theme.fonts.fontSizes.size18,
                    }}
                  >
                    Reason for rejection
                  </Text>
                  <Text sx={{ fontSize: theme.fonts.fontSizes.size14 }}>
                    {getMerchantDetailsObj.data.merchant.reason}
                  </Text>
                </RejectedReasonBox>
              </Grid>
            )}
        </Grid>
      );
    }
    return (
      <>
        <SpaceBetweenBox>
          <Box>
            <SkeletonLoader height="40px" width="200px" />
            <SkeletonLoader height="20px" width="125px" />
          </Box>
          {!isMobile && (
            <RowBox>
              <RedBorderButton width="125px" isLoading={true}>
                <Text type="RejectText">Reject</Text>
              </RedBorderButton>
              <Spacer size="s" position="left" />
              <CtaButton width="125px" isLoading={true}>
                <Text type="WhiteColor">Approve</Text>
              </CtaButton>
            </RowBox>
          )}
        </SpaceBetweenBox>
        {isMobile && (
          <RowBox sx={{ marginTop: "25px" }}>
            <RedBorderButton width="125px" isLoading={true}>
              <Text type="RejectText">Reject</Text>
            </RedBorderButton>
            <Spacer size="s" position="left" />
            <CtaButton width="125px" isLoading={true}>
              <Text type="WhiteColor">Approve</Text>
            </CtaButton>
          </RowBox>
        )}
      </>
    );
  };

  const renderMerchantDetails = () => {
    if (getMerchantDetailsObj.status === "succeeded") {
      return <MerchantDetails />;
    }
    return <MerchantDetailsLoader />;
  };

  const renderMerchantBankDetails = () => {
    if (getMerchantDetailsObj.status === "succeeded") {
      return <MerchantBankDetails />;
    }
    return <MerchantBankDetailsLoader />;
  };

  const renderMerchantCommission = () => {
    if (getMerchantDetailsObj.status === "succeeded") {
      return <MerchantCommission />;
    }
    return <MerchantCommissionLoader />;
  };

  const renderMerchantExperiences = () => {
    if (getMerchantDetailsObj.status === "succeeded") {
      return <MerchantExperiences merchantId={merchantId} />;
    }
    return <MerchantExperiencesLoader />;
  };

  const renderAllStatementDetails = () => {
    if (getMerchantDetailsObj.status === "succeeded") {
      return (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Text variant="h5">Statement Details</Text>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={isMobile ? 12 : 3}>
                <Box>
                  <Text variant="h6">Open: </Text>
                  <Text type="DarkGreyColor" variant="h5">
                    RM {getMerchantDetailsObj.data.statementDetails.open}
                  </Text>
                </Box>
              </Grid>
              <Grid item xs={isMobile ? 12 : 3}>
                <Box>
                  <Text variant="h6">To Be Paid: </Text>
                  <Text type="DarkGreyColor" variant="h5">
                    RM {getMerchantDetailsObj.data.statementDetails.pending}
                  </Text>
                </Box>
              </Grid>
              <Grid item xs={isMobile ? 12 : 3}>
                <Box>
                  <Text variant="h6">Dispute: </Text>
                  <Text type="DarkGreyColor" variant="h5">
                    RM {getMerchantDetailsObj.data.statementDetails.dispute}
                  </Text>
                </Box>
              </Grid>
              <Grid item xs={isMobile ? 12 : 3}>
                <Box>
                  <Text variant="h6">Closed: </Text>
                  <Text type="DarkGreyColor" variant="h5">
                    RM {getMerchantDetailsObj.data.statementDetails.closed}
                  </Text>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Text variant="h5">Statement Details</Text>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={isMobile ? 12 : 3}>
              <Box>
                <Skeleton width="100px" height="30px" />
                <Skeleton width="150px" height="40px" />
              </Box>
            </Grid>
            <Grid item xs={isMobile ? 12 : 3}>
              <Box>
                <Skeleton width="100px" height="30px" />
                <Skeleton width="150px" height="40px" />{" "}
              </Box>
            </Grid>
            <Grid item xs={isMobile ? 12 : 3}>
              <Box>
                <Skeleton width="100px" height="30px" />
                <Skeleton width="150px" height="40px" />{" "}
              </Box>
            </Grid>
            <Grid item xs={isMobile ? 12 : 3}>
              <Box>
                <Skeleton width="100px" height="30px" />
                <Skeleton width="150px" height="40px" />{" "}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <PaddedView multiples={isMobile ? 1 : 3}>
      <Form
        initialValues={{ statusType: "rejected", reason: "" }}
        onSubmit={onRejectMerchant}
        validationSchema={validationSchema}
      >
        <RejectMerchantModal
          showModal={showRejectMerchantModal}
          setShowModal={setShowRejectMerchantModal}
        />
      </Form>
      <Link href={routes.MERCHANTLIST} style={{ textDecoration: "none" }}>
        <Text type="GreyColor">{`< Back`}</Text>
      </Link>
      <Spacer size="xl" position="top" />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {renderTitleAndStatus()}
        </Grid>
        <Grid item xs={12}>
          <DetailCardContainer>{renderMerchantDetails()}</DetailCardContainer>
        </Grid>
        <Grid item xs={12}>
          <DetailCardContainer>{renderMerchantBankDetails()}</DetailCardContainer>
        </Grid>
        <Grid item xs={12}>
          <DetailCardContainer>{renderMerchantCommission()}</DetailCardContainer>
        </Grid>
        <Grid item xs={12}>
          <DetailCardContainer>{renderMerchantExperiences()}</DetailCardContainer>
        </Grid>
        <Grid item xs={12}>
          <DetailCardContainer>{renderAllStatementDetails()}</DetailCardContainer>
        </Grid>
        <Grid item xs={12}>
          <DetailCardContainer>
            <MerchantStatements />
          </DetailCardContainer>
        </Grid>
      </Grid>
    </PaddedView>
  );
}

export default MerchantDetailScreen;
