import React, { useContext } from "react";
import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { login } from "../../../../services/auth/auth-slice.services";
import routes from "../../../../navigation/routes";
import PaddedView from "../../../../components/padded-view.component";
import Form from "../../../../components/forms/form.component";
import YoloLogo from "../../../../assets/images/yolo-logo";
import { SnackbarContext } from "../../../../components/notifications/snackbar.context";
import LoginForm from "../components/login-form.component";

const StyledMainContainer = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  width: "100%",
  backgroundColor: theme.palette.colors.bg.primary,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
}));

const StyledMainGrid = styled(Grid)({
  justifyContent: "center",
  height: "100%",
  alignItems: "center",
  flexDirection: "column",
});

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
  password: Yup.string().required().min(4).label("Password"),
  isRemember: Yup.boolean(),
});

function LoginScreen() {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const createSnackBar = useContext(SnackbarContext);

  const handleSubmitLogin = (values) => {
    if (values.isRemember) {
      localStorage.setItem("isRememberAdminEmail", "true");
      localStorage.setItem("adminEmail", values.email);
    } else {
      localStorage.removeItem("isRememberAdminEmail");
      localStorage.removeItem("adminEmail");
    }

    dispatch(login(values)).then(({ meta, error }) => {
      if (meta.requestStatus === "fulfilled") {
        history.push(routes.HOME);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  return (
    <StyledMainContainer>
      <PaddedView multiples={isMobile ? 0 : 4}>
        <StyledMainGrid container spacing={4}>
          <Grid item xs={12} sx={{ height: isMobile ? 45 : 60 }}>
            <YoloLogo />
          </Grid>
          <Grid item xs={12}>
            <Form
              initialValues={{
                email: localStorage.getItem("adminEmail") || "",
                password: "",
                isRemember: localStorage.getItem("isRememberAdminEmail") === "true",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmitLogin}
            >
              <LoginForm />
            </Form>
          </Grid>
        </StyledMainGrid>
      </PaddedView>
    </StyledMainContainer>
  );
}

export default LoginScreen;
