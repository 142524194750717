import React, { useContext, useEffect, useState } from "react";
import { Box, Pagination, styled, Table, TableBody, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PaddedView from "../../../../components/padded-view.component";
import Spacer from "../../../../components/spacer.component";
import TableWrapper from "../../../../components/table/table-wrapper.component";
import TableHeader from "../../../../components/table/table-header.component";
import { SnackbarContext } from "../../../../components/notifications/snackbar.context";
import {
  bannerSelector,
  deleteBanner,
  getBannerList,
  updateBannerStatus,
} from "../../../../services/marketing/banner/banner-slice.services";
import BannerRow from "../components/banner-row.component";
import Loading from "../../../../components/notifications/loading.component";
import BannerTableLoader from "../loader/banner-table-loader.component";
import routes from "../../../../navigation/routes";
import DeleteConfirmationModal from "../../../../components/notifications/delete-confirmation-modal.component";
import TableTitle from "../../../../components/table/table-title.component";

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

export default function BannerListScreen() {
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const { getBannerListObj, deleteBannerObj } = useSelector(bannerSelector);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteBannerId, setDeleteBannerId] = useState("");

  useEffect(() => {
    dispatch(getBannerList({ q: searchKeyword, page: 1 })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  }, []);

  const refreshBannerList = (newPage) => {
    dispatch(getBannerList({ q: searchKeyword, page: newPage })).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onChangeSearch = (q) => {
    setPage(1);
    setSearchKeyword(q);
    dispatch(getBannerList({ q, page: 1 })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onPageChange = (e, newPage) => {
    setPage(newPage);
    refreshBannerList(newPage);
  };

  const onToggleStatusButton = (bannerId) => {
    setIsLoading(true);
    dispatch(updateBannerStatus(bannerId)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
      if (meta.requestStatus === "fulfilled") {
        refreshBannerList(page);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
    });
  };

  const onToggleDelete = (bannerId) => {
    setDeleteBannerId(bannerId);
    setShowDeleteModal(true);
  };

  const onConfirmDelete = () => {
    setIsLoading(true);
    dispatch(deleteBanner(deleteBannerId)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
      if (meta.requestStatus === "fulfilled") {
        setShowDeleteModal(false);
        setPage(1);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
    });
  };

  const renderContent = () => {
    if (getBannerListObj.status === "succeeded") {
      return (
        <>
          <Table>
            <TableHeader
              headerCells={["Thumbnail", "Banner Name", "Date Modified", "Status", "Duration", ""]}
            />
            <TableBody>
              {getBannerListObj.data.items.map((item) => (
                <BannerRow
                  record={item}
                  key={item.id}
                  onToggleStatusButton={onToggleStatusButton}
                  onToggleDelete={onToggleDelete}
                />
              ))}
            </TableBody>
          </Table>
          <CustomFooter>
            <Pagination
              sx={{
                "&& .Mui-selected": {
                  backgroundColor: theme.palette.colors.brand.primary,
                  color: theme.palette.colors.text.white,
                },
              }}
              page={page}
              onChange={onPageChange}
              count={getBannerListObj.data.pagination.totalPages}
              variant="outlined"
            />
          </CustomFooter>
        </>
      );
    }
    return <BannerTableLoader />;
  };
  return (
    <PaddedView multiples={3}>
      <DeleteConfirmationModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        label="Are you sure to delete this banner?"
        isLoading={deleteBannerObj.status === "pending"}
        onConfirmClicked={onConfirmDelete}
      />
      <Loading isLoading={isLoading} />
      <Spacer position="top" size="m" />
      <TableTitle
        title="Marketing Banner"
        placeholder="Search Banners"
        onChangeSearch={onChangeSearch}
        searchKeyword={searchKeyword}
        onClickCreateButton={() => history.push(routes.MARKETING_BANNER_CREATE)}
      />
      <Spacer position="top" size="xl" />
      <TableWrapper>{renderContent()}</TableWrapper>
    </PaddedView>
  );
}
