import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import Text from "../../../components/text.component";
import {
    creatorSelector,
    updateCreator,
} from "../../../services/creator/creator-slice.services";
import CreatorDetailsFormFieldText from "./creator-details-form-field-text.component";

const FlexEndButtonContainer = styled(Box)({
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
});

const creatorDetailsValidationSchema = Yup.object().shape({
    email: Yup.string().email().required().label("Email Address"),
    phone: Yup.string().required().label("Contact Number"),
    firstName: Yup.string().required().min(2).label("First Name"),
    lastName: Yup.string().required().min(2).label("Last Name"),
    nickName: Yup.string().required().min(2).label("Nick Name"),
    website: Yup.string().nullable().label("Website"),
});

function CreatorDetails() {
    const theme = useTheme();
    const location = useLocation();
    const creatorId = new URLSearchParams(location.search).get("creatorId");
    const [isContactEditing, setIsContactEditing] = useState(false);
    const [isEmailEditing, setIsEmailEditing] = useState(false);
    const [isFirstNameEditing, setIsFirstNameEditing] = useState(false);
    const [isLastNameEditing, setIsLastNameEditing] = useState(false);
    const [isNickNameEditing, setIsNickNameEditing] = useState(false);
    const [isWebsiteEditing, setIsWebsiteEditing] = useState(false);
    const { getCreatorDetailsObj, updateCreatorObj } = useSelector(creatorSelector);
    const dispatch = useDispatch();
    const createSnackBar = useContext(SnackbarContext);
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const resetState = () => {
        setIsContactEditing(false);
        setIsEmailEditing(false);
        setIsFirstNameEditing(false);
        setIsLastNameEditing(false);
        setIsNickNameEditing(false);
        setIsWebsiteEditing(false);
    };

    const onUpdateCreatorDetails = (values) => {
        console.log("update details");
        dispatch(updateCreator({ creatorId, type: "details", ...values })).then(
            ({ meta, error, payload }) => {
                if (meta.requestStatus === "fulfilled") {
                    resetState();
                    createSnackBar({
                        message: payload.message,
                        type: "success",
                        open: true,
                    });
                }
                if (meta.requestStatus === "rejected") {
                    createSnackBar({
                        message: error.message,
                        type: "error",
                        open: true,
                    });
                }
            },
        );
    };

    return (
        <Form
            validationSchema={creatorDetailsValidationSchema}
            onSubmit={onUpdateCreatorDetails}
            initialValues={{
                id: getCreatorDetailsObj.data.creator.id,
                createdAt: getCreatorDetailsObj.data.creator.createdAt,
                email: getCreatorDetailsObj.data.creator.email,
                firstName: getCreatorDetailsObj.data.creator.firstName,
                lastName: getCreatorDetailsObj.data.creator.lastName,
                nickName: getCreatorDetailsObj.data.creator.nickName,
                phone: getCreatorDetailsObj.data.creator.phone,
                website: getCreatorDetailsObj.data.creator.website,
            }}
        >
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={isMobile ? 12 : 3}>
                            <CreatorDetailsFormFieldText name="id" disabled label="Creator ID" />
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 3}>
                            <CreatorDetailsFormFieldText
                                name="email"
                                label="Email Address"
                                isShowIcon
                                editing={isEmailEditing}
                                setEditing={setIsEmailEditing}
                            />
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 3}>
                            <CreatorDetailsFormFieldText
                                name="phone"
                                label="Contact Number"
                                isShowIcon
                                editing={isContactEditing}
                                setEditing={setIsContactEditing}
                            />
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 3}>
                            <CreatorDetailsFormFieldText name="createdAt" disabled label="Date Registered" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={isMobile ? 12 : 3}>
                            <CreatorDetailsFormFieldText
                                name="firstName"
                                label="First Name"
                                isShowIcon
                                editing={isFirstNameEditing}
                                setEditing={setIsFirstNameEditing}
                            />
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 3}>
                            <CreatorDetailsFormFieldText
                                name="lastName"
                                label="Last Name"
                                isShowIcon
                                editing={isLastNameEditing}
                                setEditing={setIsLastNameEditing}
                            />
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 3}>
                            <CreatorDetailsFormFieldText
                                name="nickName"
                                label="Nick Name"
                                isShowIcon
                                editing={isNickNameEditing}
                                setEditing={setIsNickNameEditing}
                            />
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 3}>
                            <CreatorDetailsFormFieldText
                                name="website"
                                label="Website"
                                isShowIcon
                                editing={isWebsiteEditing}
                                setEditing={setIsWebsiteEditing}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <FlexEndButtonContainer sx={{ marginTop: "-30px" }}>
                        <FormSubmitButton width="125px" isLoading={updateCreatorObj.status === "pending"}>
                            <Text type="WhiteColor">Save</Text>
                        </FormSubmitButton>
                    </FlexEndButtonContainer>
                </Grid>
            </Grid>
        </Form>
    );
}

export default CreatorDetails;
