import {
    Box,
    Grid,
    Pagination,
    styled,
    Table,
    TableBody,
    TableCell,
    TableRow,
    useTheme
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import CtaButton from "../../../components/buttons/cta-button.component";
import Form from "../../../components/forms/form.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import TableHeader from "../../../components/table/table-header.component";
import Text from "../../../components/text.component";
import {
    createCreatorSocial,
    creatorSelector,
    getCreatorSocialList,
} from "../../../services/creator/creator-slice.services";
import SearchBar from "../../statement/components/search-bar.component";
import CreatorSocialCreateModal from "./creator-social-create-modal.component";
import SocialListRow from "./social-row.component";

const CustomFooter = styled(Box)({
    display: "flex",
    justifyContent: "center",
    width: "100%",
    minHeight: "70px",
    alignItems: "center",
});

const validationSchema = Yup.object().shape({
    q: Yup.number().nullable().label("SocialList ID"),
});

const socialValidationSchema = Yup.object().shape({
    social_id: Yup.string().required(),
    social_type: Yup.string().required(),
});

export default function CreatorSocialList() {
    const dispatch = useDispatch();
    const location = useLocation();
    const formRef = useRef();
    const creatorId = new URLSearchParams(location.search).get("creatorId");
    const createSnackBar = useContext(SnackbarContext);
    const { getCreatorSocialListObj } = useSelector(creatorSelector);
    const theme = useTheme();
    const [page, setPage] = useState(1);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [showSocialCreateModal, setShowSocialCreateModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        dispatch(getCreatorSocialList({ creatorId, q: searchKeyword, page })).then(
            ({ meta, error }) => {
                if (meta.requestStatus === "rejected") {
                    createSnackBar({
                        message: error.message,
                        type: "error",
                    });
                }
            },
        );
    }, []);

    const onSearchSocialList = (values) => {
        setPage(1);
        dispatch(
            getCreatorSocialList({
                ...values,
                creatorId,
                page: 1,
            }),
        ).then(({ meta, error }) => {
            if (meta.requestStatus === "rejected") {
                createSnackBar({
                    message: error.message,
                    type: "error",
                });
            }
        });
    };

    const onPageChange = (e, newPage) => {
        dispatch(
            getCreatorSocialList({ ...formRef.current.values, creatorId, page: newPage }),
        ).then(({ meta, error }) => {
            if (meta.requestStatus === "rejected") {
                createSnackBar({
                    message: error.message,
                    type: "error",
                    open: true,
                });
            }
        });
        setPage(newPage);
    };

    const resetSearch = () => {
        dispatch(getCreatorSocialList({ creatorId, q: "", page:1 })).then(
            ({ meta, error }) => {
                if (meta.requestStatus === "rejected") {
                    createSnackBar({
                        message: error.message,
                        type: "error",
                    });
                }
            },
        );
        setSearchKeyword("");
        setPage(1);
    };
    
    const onSocialMediaCreate = (value, { resetForm }) => {
        const params = {
            social_type: value.social_type,
            social_id: value.social_id
        };
        setIsLoading(true);
        dispatch(createCreatorSocial({ creatorId, ...params })).then(({ meta, error, payload }) => {
            setIsLoading(false);
            if (meta.requestStatus === "fulfilled") {
                setShowSocialCreateModal(false);
                resetForm();
                resetSearch();
                createSnackBar({
                    message: payload.message,
                    type: "success",
                });
            }
            if (meta.requestStatus === "rejected") {
                createSnackBar({
                    message: error.message,
                    type: "error",
                });
            }
        });
    };

    const renderContent = () => {
        if (getCreatorSocialListObj.status === "succeeded") {
            return (
                <>
                    <Table>
                        <TableHeader headerCells={["ID", "Social Media", "Social ID", "Status", ""]} />
                        <TableBody>
                            {getCreatorSocialListObj.data.items.map((item) => (
                                <SocialListRow record={item} key={item.id} />
                            ))}
                        </TableBody>
                    </Table>
                    <CustomFooter>
                        <Pagination
                            sx={{
                                "&& .Mui-selected": {
                                    backgroundColor: theme.palette.colors.brand.primary,
                                    color: theme.palette.colors.text.white,
                                },
                            }}
                            page={page}
                            onChange={onPageChange}
                            count={getCreatorSocialListObj.data.pagination.totalPages}
                            variant="outlined"
                        />
                    </CustomFooter>
                </>
            );
        }
        return (
            <Table>
                <TableHeader headerCells={["ID", "Social Media", "Social ID", "Status", ""]} />
                <TableBody>
                    <TableRow
                        sx={{
                            "& .MuiTableCell-root": {
                                borderBottom: "no-set",
                            },
                        }}
                    >
                        <TableCell>
                            <Text variant="body2" sx={{ textAlign: "center" }}>
                                <Skeleton />
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text variant="body2" sx={{ textAlign: "center" }}>
                                <Skeleton />
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text variant="body2" sx={{ textAlign: "center" }}>
                                <Skeleton />
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text variant="body2" sx={{ textAlign: "center" }}>
                                <Skeleton />
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Grid container spacing={1} sx={{ justifyContent: "center" }}>
                                <Grid item>
                                    <Skeleton height={20} width={20} circle={true} />
                                </Grid>
                            </Grid>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    };

    return (
        <Grid item xs={12}>
            <Form
                innerRef={formRef}
                onSubmit={onSearchSocialList}
                validationSchema={validationSchema}
                initialValues={{
                    q: "",
                }}
            >
                <Grid container>
                    <Grid item xs={6}>
                        <Text variant="h5">Social Media List</Text>
                    </Grid>
                    <Grid item xs={2}>

                        <Form
                            onSubmit={onSocialMediaCreate}
                            validationSchema={socialValidationSchema}
                            initialValues={{
                                social_type: null,
                                social_id: null,
                            }}
                        >
                            <CreatorSocialCreateModal isShowModal={showSocialCreateModal} setIsShowModal={setShowSocialCreateModal} />
                            <CtaButton width="125px" onClickButton={setShowSocialCreateModal}>
                                <Text type="WhiteColor">Add Social</Text>
                            </CtaButton>
                        </Form>
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={3}>
                        <SearchBar name="q" placeholder="SocialList ID" width="100%" />
                    </Grid>
                </Grid>
            </Form>
            {renderContent()}
        </Grid>
    );
}
