import { Box, Grid, IconButton, styled, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DetailCardContainer from "../../../components/detail-card-container.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import { promoCodeSelector } from "../../../services/promo-code/promo-code-slice.services";
import FormFieldQuantity from "../../../components/forms/form-field-quantity.component";
import FormSelect from "../../../components/forms/form-select.component";
import SpecificListAutoComplete from "./specific-list-autocomplete.component";
import BorderButton from "../../../components/buttons/border-button.component";
import CenteredRowBox from "../../../components/centered-row-box.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import PromoFormFieldQuantity from "./promo-form-field-quantity.component";

const ButtonContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  marginTop: "20px",
});
const FlexStartRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
});

const yesNoOption = [
  { id: 1, label: "No" },
  { id: 2, label: "Yes" },
];

export default function PromoDetailsForm({
  forCreateScreen,
  isEditing,
  setIsEditing,
  selectedType,
  setSelectedType,
  isUserSpecific,
  setIsUserSpecific,
  isExperienceSpecific,
  setIsExperienceSpecific,
}) {
  const theme = useTheme();
  const { resetForm } = useFormikContext();
  const { getPromoCodeDetailObj } = useSelector(promoCodeSelector);

  return (
    <Grid item xs={12}>
      <DetailCardContainer>
        <CenteredRowBox>
          <Text sx={{ fontSize: theme.fonts.fontSizes.size18 }}>Promo Details</Text>
          {!forCreateScreen && (
            <>
              <Spacer size="s" position="left" />
              <IconButton
                sx={{ color: theme.palette.colors.text.primary }}
                disabled={isEditing.promoRules}
                onClick={() => setIsEditing((i) => ({ ...i, promoRules: true }))}
              >
                <ModeEditIcon />
              </IconButton>
            </>
          )}
        </CenteredRowBox>
        <Spacer size="m" position="top" />
        <FlexStartRowBox>
          <Grid item xs={4}>
            <Text>Type</Text>
            <Spacer size="xs" position="top" />
            <FormSelect
              disabled={forCreateScreen ? false : !isEditing.promoRules}
              name="type"
              placeholder="Select type"
              itemList={[
                { id: 1, label: "percentage" },
                { id: 2, label: "fixed amount" },
              ]}
              width="100%"
              setFormVariable={setSelectedType}
            />
          </Grid>
          <Spacer size="xl" position="left" />
          <Grid item xs={4}>
            <Text>Discount {selectedType === "percentage" && "%"}</Text>
            <Spacer size="xs" position="top" />
            <FormFieldQuantity
              disabled={forCreateScreen ? false : !isEditing.promoRules}
              name="discount"
              placeholder={selectedType === "percentage" ? "Percentage" : "Amount"}
              width="100%"
              isPrice={selectedType === "fixed amount"}
            />
          </Grid>
          <Spacer size="xl" position="left" />
          <Grid item xs={4}>
            <Text>Max cap amount</Text>
            <Spacer size="xs" position="top" />
            <PromoFormFieldQuantity
              name="maxCapAmount"
              placeholder="Max cap amount"
              width="100%"
              isPrice
              disabled={
                selectedType === "fixed amount" || (forCreateScreen ? false : !isEditing.promoRules)
              }
              selectedType={selectedType}
            />
          </Grid>
        </FlexStartRowBox>
        <Spacer size="l" position="top" />
        <FlexStartRowBox>
          <Grid item xs={4}>
            <Text>Max No. Claims</Text>
            <Spacer size="xs" position="top" />
            <FormFieldQuantity
              disabled={forCreateScreen ? false : !isEditing.promoRules}
              name="maxNoClaims"
              placeholder="Max no of claims"
              width="100%"
            />
          </Grid>
          <Spacer size="xl" position="left" />
          <Grid item xs={4}>
            <Text>Max No. Claims Per User</Text>
            <Spacer size="xs" position="top" />
            <FormFieldQuantity
              disabled={forCreateScreen ? false : !isEditing.promoRules}
              name="maxNoClaimsPerUser"
              placeholder="Max no of claims per user"
              width="100%"
            />
          </Grid>
          <Spacer size="xl" position="left" />
          <Grid item xs={4}>
            <Text>New User Only</Text>
            <Spacer size="xs" position="top" />
            <FormSelect
              disabled={forCreateScreen ? false : !isEditing.promoRules}
              name="newUserOnly"
              placeholder="For new user only"
              itemList={yesNoOption}
              width="100%"
            />
          </Grid>
        </FlexStartRowBox>
        <Spacer size="l" position="top" />
        <FlexStartRowBox>
          <Grid item xs={4}>
            <Text>Min Spent Amount</Text>
            <Spacer size="xs" position="top" />
            <FormFieldQuantity
              disabled={forCreateScreen ? false : !isEditing.promoRules}
              name="minSpentAmount"
              placeholder="Minimum spent amount"
              width="100%"
              isPrice
            />
          </Grid>
          <Grid item xs={4} />
          <Spacer size="xl" position="left" />
          <Grid item xs={4} />
          <Spacer size="xl" position="left" />
        </FlexStartRowBox>
        <Spacer size="l" position="top" />
        <FlexStartRowBox>
          <Grid item xs={4}>
            <Text>Users Specific</Text>
            <Spacer size="xs" position="top" />
            <FormSelect
              disabled={forCreateScreen ? false : !isEditing.promoRules}
              name="usersSpecific"
              placeholder="For specific users"
              itemList={yesNoOption}
              width="100%"
              setFormVariable={(value) => setIsUserSpecific(value === "Yes")}
            />
          </Grid>
          <Spacer size="xl" position="left" />
          <Grid item xs={12}>
            <Text>Specific User List</Text>
            <Spacer size="xs" position="top" />
            <SpecificListAutoComplete
              disabled={forCreateScreen ? false : !isEditing.promoRules}
              name="usersList"
              placeholder="User's email"
              isSpecific={isUserSpecific}
            />
          </Grid>
        </FlexStartRowBox>
        <Spacer size="l" position="top" />
        <FlexStartRowBox>
          <Grid item xs={4}>
            <Text>Experiences Specific</Text>
            <Spacer size="xs" position="top" />
            <FormSelect
              disabled={forCreateScreen ? false : !isEditing.promoRules}
              name="experiencesSpecific"
              placeholder="For specific experience"
              itemList={yesNoOption}
              width="100%"
              setFormVariable={(value) => setIsExperienceSpecific(value === "Yes")}
            />
          </Grid>
          <Spacer size="xl" position="left" />
          <Grid item xs={12}>
            <Text>Specific Experience List</Text>
            <Spacer size="xs" position="top" />
            <SpecificListAutoComplete
              disabled={forCreateScreen ? false : !isEditing.promoRules}
              name="experiencesList"
              placeholder="Experience's title"
              isSpecific={isExperienceSpecific}
            />
          </Grid>
        </FlexStartRowBox>
        {!forCreateScreen && isEditing.promoRules && (
          <ButtonContainer>
            <BorderButton
              width="100px"
              onClickButton={() => {
                resetForm();
                setIsExperienceSpecific(getPromoCodeDetailObj.data.experiencesSpecific);
                setIsUserSpecific(getPromoCodeDetailObj.data.usersSpecific);
                setIsEditing((i) => ({ ...i, promoRules: false }));
              }}
            >
              <Text type="BrandColor">Cancel</Text>
            </BorderButton>
            <Spacer size="s" position="left" />
            <FormSubmitButton width="100px">
              <Text type="WhiteColor">Save</Text>
            </FormSubmitButton>
          </ButtonContainer>
        )}
      </DetailCardContainer>
    </Grid>
  );
}

PromoDetailsForm.defaultProps = {
  setIsEditing: null,
  isEditing: {
    promoRules: null,
  },
};

PromoDetailsForm.propTypes = {
  setIsEditing: PropTypes.func,
  isEditing: PropTypes.shape({
    promoRules: PropTypes.bool,
  }),
  selectedType: PropTypes.string.isRequired,
  setSelectedType: PropTypes.func.isRequired,
  isUserSpecific: PropTypes.bool.isRequired,
  setIsUserSpecific: PropTypes.func.isRequired,
  isExperienceSpecific: PropTypes.bool.isRequired,
  setIsExperienceSpecific: PropTypes.func.isRequired,
  forCreateScreen: PropTypes.bool.isRequired,
};
