import React from "react";
import { Grid, styled, Box, useTheme, useMediaQuery } from "@mui/material";
import Text from "../../../components/text.component";
import SkeletonLoader from "../../../components/skeleton.component";
import CtaButton from "../../../components/buttons/cta-button.component";

const FlexEndButtonContainer = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "flex-end",
});

function UserDetailsLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={isMobile ? 12 : 3}>
            <Text>User ID</Text>
            <SkeletonLoader height="25px" width="175px" />
          </Grid>
          <Grid item xs={isMobile ? 12 : 3}>
            <Text>Email Address</Text>
            <SkeletonLoader height="25px" width="175px" />
          </Grid>
          <Grid item xs={isMobile ? 12 : 3}>
            <Text>Phone Number</Text>
            <SkeletonLoader height="25px" width="175px" />
          </Grid>
          <Grid item xs={isMobile ? 12 : 3}>
            <Text>Date Registered</Text>
            <SkeletonLoader height="25px" width="175px" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={isMobile ? 12 : 3}>
            <Text>First Name</Text>
            <SkeletonLoader height="25px" width="175px" />
          </Grid>
          <Grid item xs={isMobile ? 12 : 3}>
            <Text>Last Name</Text>
            <SkeletonLoader height="25px" width="175px" />
          </Grid>

          <Grid item xs={isMobile ? 12 : 3}>
            <Text>Gender</Text>
            <SkeletonLoader height="25px" width="175px" />
          </Grid>
          <Grid item xs={isMobile ? 12 : 3}>
            <Text>Date Of Birth</Text>
            <SkeletonLoader height="25px" width="175px" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <FlexEndButtonContainer sx={{ marginTop: "-30px" }}>
          <CtaButton width="125px" isLoading={true}>
            <Text type="WhiteColor">Save</Text>
          </CtaButton>
        </FlexEndButtonContainer>
      </Grid>
    </Grid>
  );
}

export default UserDetailsLoader;
