import React, { useContext, useState } from "react";
import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import MerchantDetailsFormFieldText from "./merchant-details-form-field-text.component";
import Text from "../../../components/text.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import {
  merchantSelector,
  updateMerchant,
} from "../../../services/merchant/merchant-slice.services";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";

const FlexEndButtonContainer = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "flex-end",
});

const merchantDetailsValidationSchema = Yup.object().shape({
  email: Yup.string().email().required().label("Email Address"),
  contactNo: Yup.string().required().label("Contact Number"),
  businessName: Yup.string().required().min(2).label("Business Name"),
  businessRegNumber: Yup.string().required().label("Business Registration Number"),
  website: Yup.string().nullable().label("Website"),
});

function MerchantDetails() {
  const theme = useTheme();
  const location = useLocation();
  const merchantId = new URLSearchParams(location.search).get("merchantId");
  const [isContactEditing, setIsContactEditing] = useState(false);
  const [isEmailEditing, setIsEmailEditing] = useState(false);
  const [isNameEditing, setIsNameEditing] = useState(false);
  const [isBussnessRegNoEditing, setIsBussnessRegNoEditing] = useState(false);
  const [isWebsiteEditing, setIsWebsiteEditing] = useState(false);
  const { getMerchantDetailsObj, updateMerchantObj } = useSelector(merchantSelector);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const resetState = () => {
    setIsContactEditing(false);
    setIsEmailEditing(false);
    setIsNameEditing(false);
    setIsBussnessRegNoEditing(false);
    setIsWebsiteEditing(false);
  };

  const onUpdateMerchantDetails = (values) => {
    dispatch(updateMerchant({ merchantId, type: "details", ...values })).then(
      ({ meta, error, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          resetState();
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  return (
    <Form
      validationSchema={merchantDetailsValidationSchema}
      onSubmit={onUpdateMerchantDetails}
      initialValues={{
        id: getMerchantDetailsObj.data.merchant.id,
        createdAt: getMerchantDetailsObj.data.merchant.createdAt,
        email: getMerchantDetailsObj.data.merchant.email,
        businessRegNumber: getMerchantDetailsObj.data.merchant.businessRegNumber,
        businessName: getMerchantDetailsObj.data.merchant.name,
        contactNo: getMerchantDetailsObj.data.merchant.contactNo,
        website: getMerchantDetailsObj.data.merchant.website,
      }}
    >
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={isMobile ? 12 : 3}>
              <MerchantDetailsFormFieldText name="id" disabled label="Merchant ID" />
            </Grid>
            <Grid item xs={isMobile ? 12 : 3}>
              <MerchantDetailsFormFieldText
                name="email"
                label="Email Address"
                isShowIcon
                editing={isEmailEditing}
                setEditing={setIsEmailEditing}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 3}>
              <MerchantDetailsFormFieldText
                name="contactNo"
                label="Contact Number"
                isShowIcon
                editing={isContactEditing}
                setEditing={setIsContactEditing}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 3}>
              <MerchantDetailsFormFieldText name="createdAt" disabled label="Date Registered" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={isMobile ? 12 : 3}>
              <MerchantDetailsFormFieldText
                name="businessName"
                label="Business Name"
                isShowIcon
                editing={isNameEditing}
                setEditing={setIsNameEditing}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 4.2}>
              <MerchantDetailsFormFieldText
                name="businessRegNumber"
                label="Business Registration Number"
                isShowIcon
                editing={isBussnessRegNoEditing}
                setEditing={setIsBussnessRegNoEditing}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 3}>
              <MerchantDetailsFormFieldText
                name="website"
                label="Website"
                isShowIcon
                editing={isWebsiteEditing}
                setEditing={setIsWebsiteEditing}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FlexEndButtonContainer sx={{ marginTop: "-30px" }}>
            <FormSubmitButton width="125px" isLoading={updateMerchantObj.status === "pending"}>
              <Text type="WhiteColor">Save</Text>
            </FormSubmitButton>
          </FlexEndButtonContainer>
        </Grid>
      </Grid>
    </Form>
  );
}

export default MerchantDetails;
