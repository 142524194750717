import BookIcon from '@mui/icons-material/Book';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import PeopleIcon from '@mui/icons-material/People';
import StorefrontIcon from '@mui/icons-material/Storefront';
import {
    Grid,
    Table,
    TableBody
} from "@mui/material";
import { BarChart } from '@mui/x-charts/BarChart';
import { format } from "date-fns";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import PaddedView from "../../../components/padded-view.component";
import Spacer from "../../../components/spacer.component";
import TableHeader from "../../../components/table/table-header.component";
import TableWrapper from '../../../components/table/table-wrapper.component';
import Text from '../../../components/text.component';
import { bookingSelector, getBookingList } from "../../../services/booking/booking.slice.services";
import { dashboardSelector, getDashboard } from '../../../services/dashboard/dashboard-slice.services';
import {
    reviewSelector,
    updateReview
} from "../../../services/review/review-slice.services";
import TableLoader from '../../booking/loader/table-loader.component';
import InfoCard from "../components/info-card.component";
import LatestList from '../components/latest-list.component';
import LatestReviewList from '../components/latest-review-list.component';


export default function DashboardMainScreen() {
    const dispatch = useDispatch();
    // const theme = useTheme();
    // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const createSnackBar = useContext(SnackbarContext);
    const formRef = useRef();
    const { getDashboardObj } = useSelector(dashboardSelector);
    const { getBookingListObj } = useSelector(bookingSelector);
    const { getReviewListObj } = useSelector(reviewSelector);
    const [showUpdateConfirmModal, setShowUpdateConfirmModal] = useState(false);
    const [page, setPage] = useState(1);
    const [reviewItem, setReviewItem] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const onPageChange = (e, newPage) => {
        setPage(newPage);
        let startAt = "";
        let endAt = "";
        if (formRef.current.values.startDate !== null) {
            startAt = format(new Date(formRef.current.values.startDate), "yyyy-MM-dd 00:00:00");
        }
        if (formRef.current.values.endDate !== null) {
            endAt = format(new Date(formRef.current.values.endDate), "yyyy-MM-dd 23:59:59");
        }
        dispatch(
            getBookingList({
                ...formRef.current.values,
                startAt,
                endAt,
                page: newPage,
            }),
        ).then(({ meta, error }) => {
            if (meta.requestStatus === "rejected") {
                createSnackBar({
                    message: error.message,
                    type: "error",
                });
            }
        });
    };

    useEffect(() => {
        setIsLoading(true);
        dispatch(getDashboard()).then(({ meta, error }) => {
            setIsLoading(false);
            if (meta.requestStatus === "rejected") {
                createSnackBar({
                    message: error.message,
                    type: "error",
                });
            }
        });
    }, []);


    const onUpdateReview = (reviewId, status) => {
        dispatch(
            updateReview({
                reviewId,
                status,
            }),
        ).then(({ meta, error }) => {
            if (meta.requestStatus === "rejected") {
                createSnackBar({
                    message: error.message,
                    type: "error",
                });
            }
            onPageChange(null, page);
            setShowUpdateConfirmModal(false);
        }
        );
    };

    const renderBookingContent = () => {
        if (getDashboardObj.status === "succeeded") {
            return (
                <PaddedView multiples={1}>
                    <Table>
                        <TableHeader
                            headerCells={["ID", "Experience Name", "Start At", "End At", "Status", "Action"]}
                        />
                        <TableBody>
                            {getDashboardObj.data.latestBooking.items.map((item) => (
                                // {getBookingListObj.data.items.map((item) => (
                                <LatestList record={item} key={item.id} />
                            ))}
                        </TableBody>
                    </Table>
                </PaddedView>
            );
        }
        return <TableLoader />;
    };

    const renderDashboardCards = () => {
        if (getDashboardObj.status === "succeeded") {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={3}>
                        <InfoCard record={{ title: "Total Merchants", value: getDashboardObj.data.merchant, icon: <StorefrontIcon />, color: "#009688" }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <InfoCard record={{ title: "Total Users", value: getDashboardObj.data.user, icon: <PeopleIcon />, color: "#F44336" }} />
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={3}>
                        <InfoCard record={{ title: "Total Balance", value: data.totalBalance, icon: <AccountBalanceWalletIcon />, color: "#4CAF50" }} />
                    </Grid> */}
                    <Grid item xs={12} sm={6} md={3}>
                        <InfoCard record={{ title: "Total Bookings", value: getDashboardObj.data.booking, icon: <BookIcon />, color: "#FFC107" }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <InfoCard record={{ title: "Total Experiences", value: getDashboardObj.data.experience, icon: <CardGiftcardIcon />, color: "#3F51B5" }} />
                    </Grid>
                </Grid>
            );
        }
        return <TableLoader />;
    }

    const renderReviewContent = () => {
        if (getDashboardObj.status === "succeeded") {
            return (
                <PaddedView multiples={1}>
                    <Table>
                        <TableHeader
                            headerCells={[
                                "Review ID",
                                "Experience Name",
                                "Username",
                                "Comment",
                                "Rating",
                                "Status",
                                "Date Created",
                                "Action",
                                "",
                            ]}
                        />
                        <TableBody>
                            {getDashboardObj.data.latestReviews.items.map((item) => (
                                <LatestReviewList
                                    record={item}
                                    key={item.id}
                                    setShowUpdateConfirmModal={setShowUpdateConfirmModal}
                                    setReviewItem={setReviewItem}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </PaddedView>
            );
        }
        return <TableLoader />;
    };


    const renderDailyBookingContent = () => {

        if (getDashboardObj.status === "succeeded") {
            return (
                <Grid item xs={12} sm={6}>
                    <Spacer position="top" size="m" />
                    <Text variant="h5" sx={{ textAlign: "Left", size: '20' }}>Daily Bookings</Text>
                    <BarChart
                        xAxis={[
                            {
                                id: 'barCategories',
                                data: getDashboardObj.data.topBookings.map(item => item.date),
                                scaleType: 'band',
                                colorMap: {
                                    type: 'ordinal',
                                    colors: ['#C98474', '#7FBCD2', '#A7D2CB', '#A78295', '#C4C1A4']
                                }
                            },
                        ]}
                        series={[
                            {
                                data: getDashboardObj.data.topBookings.map(item => item.count),
                                // label: 'Dates',
                                backgroundColors: ['#C98474', '#7FBCD2', '#A7D2CB', '#A78295', '#C4C1A4']
                            },
                        ]}
                        width={500}
                        height={300}
                    />
                </Grid>
            );
        } return <TableLoader />;
    };

    const renderTopMerchantContent = () => {

        if (getDashboardObj.status === "succeeded") {
            return (
                <Grid item xs={12} sm={6}>
                    <Spacer position="top" size="m" />
                    <Text variant="h5" sx={{ textAlign: "Left", size: '20' }}>Top Merchants</Text>
                    <BarChart
                        xAxis={[
                            {
                                id: 'barCategories',
                                // data: ['bar A', 'bar B', 'bar C', 'bar D', 'bar E', 'bar F'],
                                data: getDashboardObj.data.topMerchants.map(item => item.merchant_name),
                                scaleType: 'band',
                                colorMap: {
                                    type: 'ordinal',
                                    colors: ['#ccebc5', '#a8ddb5', '#7bccc4', '#4eb3d3', '#2b8cbe', '#08589e']
                                },
                            },
                        ]}
                        series={[
                            {
                                // data: [2, 5, 3, 11, 10, 14],
                                data: getDashboardObj.data.topMerchants.map(item => item.total_bookings),
                            },
                        ]}

                        width={500}
                        height={300}

                    />
                </Grid>
            );
        } return <TableLoader />;
    };


    return (
        <PaddedView multiples={3}>
            <Spacer position="top" size="m" />
            {/* <TableTitle title="Dashboard" /> */}
            <Text variant="h5" sx={{ textAlign: "Left", size: '20' }}>Dashboard</Text>
            <Spacer position="top" size="xl" />
            {renderDashboardCards()}
            <Spacer position="top" size="xl" />
            <Grid container spacing={3}>
                {renderDailyBookingContent()}
                {renderTopMerchantContent()}
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Spacer position="top" size="m" />
                    <Text variant="h5" sx={{ textAlign: "Left", size: '20' }}> Latest Bookings</Text>
                    <Spacer position="top" size="xl" />
                    <TableWrapper>{renderBookingContent()}</TableWrapper>
                </Grid>
            </Grid>
            <Spacer position="top" size="s" />

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Spacer position="top" size="m" />
                    <Text variant="h5" sx={{ textAlign: "Left", size: '20' }}> Latest Reviews</Text>
                    <Spacer position="top" size="xl" />
                    <TableWrapper>{renderReviewContent()}</TableWrapper>
                </Grid>
            </Grid>
            <Spacer position="top" size="l" />
        </PaddedView>
    );
}