import { Box, Pagination, styled, Tab, Table, TableBody, Tabs, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Form from "../../../components/forms/form.component";
import DeleteConfirmationModal from "../../../components/notifications/delete-confirmation-modal.component";
import Loading from "../../../components/notifications/loading.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import PaddedView from "../../../components/padded-view.component";
import Spacer from "../../../components/spacer.component";
import TableHeader from "../../../components/table/table-header.component";
import TableTitle from "../../../components/table/table-title.component";
import TableWrapper from "../../../components/table/table-wrapper.component";
import {
  createMerchant,
  deleteMerchant,
  getMerchantList,
  merchantSelector,
} from "../../../services/merchant/merchant-slice.services";
import MerchantCreateModal from "../components/merchant-create-modal.component";
import MerchantRow from "../components/merchant-row.component";
import MerchantUnableDeleteModal from "../components/merchant-unable-delete-modal.component";
import TableLoader from "../loader/table-loader.component";

const AntTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.colors.brand.primary,
    height: "2px",
  },
}));

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size18,
  fontWeight: theme.fonts.fontWeights.regular,
  "&.Mui-selected": {
    color: theme.palette.colors.brand.primary,
  },
}));

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
  businessName: Yup.string().required().label("Name"),
  website: Yup.string().label("Website"),
  businessRegNumber: Yup.string().required().label("Business Registration Number"),
  contactNo: Yup.string()
    .nullable()
    .required()
    .when({
      is: (value) => value?.length,
      then: (rule) => rule.min(10),
    })
    .label("Contact Number"),
  bankAccountNo: Yup.number().required().label("Account Number"),
  bankName: Yup.string().required().label("Bank Name"),
  bankHolderName: Yup.string().required().label("Account Holder Name"),
  commission: Yup.number().min(0).max(100).required().label("Commission Rate"),
});

function MerchantListScreen() {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentStatus, setCurrentStatus] = useState("all");
  const [page, setPage] = useState(1);
  const { getMerchantListObj, deleteMerchantObj } = useSelector(merchantSelector);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const theme = useTheme();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteMerchantId, setDeleteMerchantId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUnableDeleteModal, setShowUnableDeleteModal] = useState(false);

  useEffect(() => {
    dispatch(getMerchantList({ q: searchKeyword, status: currentStatus, page })).then(
      ({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      },
    );
  }, []);

  const resetSearch = () => {
    dispatch(getMerchantList({ q: "", status: "all", page: 1 })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
    setSearchKeyword("");
    setCurrentStatus("all");
    setPage(1);
  };

  const onChangeSearch = (q) => {
    dispatch(getMerchantList({ q, status: currentStatus, page: 1 })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
    setSearchKeyword(q);
  };

  const onPageChange = (e, newPage) => {
    dispatch(getMerchantList({ q: searchKeyword, status: currentStatus, page: newPage })).then(
      ({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      },
    );
    setPage(newPage);
  };

  const onChangeStatus = (e, status) => {
    dispatch(getMerchantList({ q: searchKeyword, status, page: 1 })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
    setCurrentStatus(status);
  };

  const onCreateMerchant = (value, { resetForm }) => {
    const bank = {
      name: value.bankName,
      holderName: value.bankHolderName,
      accountNo: value.bankAccountNo,
    };
    const website = value.website !== "" ? `https://${value.website}` : "";
    setIsLoading(true);
    dispatch(createMerchant({ ...value, bank, website })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setShowCreateModal(false);
        resetForm();
        resetSearch();
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onTriggerDelete = (deleteable, merchantId) => {
    if (deleteable) {
      setDeleteMerchantId(merchantId);
      setShowDeleteModal(true);
      return;
    }
    setShowUnableDeleteModal(true);
  };

  const onConfirmDelete = () => {
    setIsLoading(true);
    dispatch(deleteMerchant(deleteMerchantId)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setShowDeleteModal(false);
        setDeleteMerchantId(null);
        resetSearch();
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const renderContent = () => {
    if (getMerchantListObj.status === "succeeded") {
      return (
        <>
          <Table>
            <TableHeader
              headerCells={[
                "Merchant ID",
                "Business Name",
                "Commission (%)",
                "Date Registered",
                "Status",
                "",
              ]}
            />
            <TableBody>
              {getMerchantListObj.data.items.map((item) => (
                <MerchantRow record={item} key={item.id} onTriggerDelete={onTriggerDelete}/>
              ))}
            </TableBody>
          </Table>
          <CustomFooter>
            <Pagination
              sx={{
                "&& .Mui-selected": {
                  backgroundColor: theme.palette.colors.brand.primary,
                  color: theme.palette.colors.text.white,
                },
              }}
              page={page}
              onChange={onPageChange}
              count={getMerchantListObj.data.pagination.totalPages}
              variant="outlined"
            />
          </CustomFooter>
        </>
      );
    }

    return <TableLoader />;
  };

  return (
    <PaddedView multiples={3}>
      <MerchantUnableDeleteModal
        showModal={showUnableDeleteModal}
        setShowModal={setShowUnableDeleteModal}
      />
      <DeleteConfirmationModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        label="Are you sure to delete this merchant?"
        isLoading={deleteMerchantObj.status === "pending"}
        onConfirmClicked={onConfirmDelete}
      />
      <Loading isLoading={isLoading} />
      <Form
        onSubmit={onCreateMerchant}
        validationSchema={validationSchema}
        initialValues={{
          email: "",
          businessRegNumber: "",
          businessName: "",
          website: "",
          contactNo: null,
          commission: "",
          bankHolderName: "",
          bankName: "",
          bankAccountNo: "",
        }}
      >
        <MerchantCreateModal isShowModal={showCreateModal} setIsShowModal={setShowCreateModal} />
      </Form>
      <Spacer position="top" size="m" />
      <TableTitle
        title="Merchants"
        onChangeSearch={onChangeSearch}
        searchKeyword={searchKeyword}
        placeholder="Search Merchants"
        onClickCreateButton={() => setShowCreateModal(true)}
      />
      <Spacer position="top" size="xl" />
      <TableWrapper>
        <Spacer position="top" size="m" />
        <AntTabs value={currentStatus} onChange={onChangeStatus}>
          <AntTab value="all" label="All" />
          <AntTab value="approved" label="Approved" />
          <AntTab value="pending" label="Pending" />
          <AntTab value="rejected" label="Rejected" />
        </AntTabs>
        <Spacer position="top" size="xl" />
        {renderContent()}
      </TableWrapper>
    </PaddedView>
  );
}

export default MerchantListScreen;
