import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../api-services";

export const getTagList = createAsyncThunk("employees/tags/list", async () => {
  const response = await getReq(`${process.env.REACT_APP_API_PRIVATE_V1}/employees/tags/list`);
  return response;
});

export const searchTag = createAsyncThunk("employees/tags/search", async (payload) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/tags?q=${payload.q}&page=${payload.page}`,
  );
  return response;
});

export const deleteTag = createAsyncThunk("employees/tags/delete", async (tagId) => {
  const response = await delReq(`${process.env.REACT_APP_API_PRIVATE_V1}/employees/tags/${tagId}`);
  return response;
});

export const createTag = createAsyncThunk("employees/tags/create", async (payload) => {
  const response = await postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/employees/tags`, payload);
  return response;
});

export const searchTagExperience = createAsyncThunk(
  "employees/tags/search_experience",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/tags/search?q=${payload.q}&states=${payload.states}&typeOfPackages=${payload.typeOfPackages}`,
    );
    return response;
  },
);

export const getTagDetail = createAsyncThunk("employees/tags/detail", async (tagId) => {
  const response = await getReq(`${process.env.REACT_APP_API_PRIVATE_V1}/employees/tags/${tagId}`);
  return response;
});

export const updateTag = createAsyncThunk("employees/tags/update", async (payload) => {
  const response = await putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/tags/${payload.tagId}`,
    payload,
  );
  return response;
});

const tagSlice = createSlice({
  name: "tag",
  initialState: {
    getTagListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    searchTagObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteTagObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createTagObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    searchTagExperienceObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getTagDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateTagObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getTagList.pending]: (state) => {
      state.getTagListObj.status = "pending";
    },
    [getTagList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getTagListObj.status = "succeeded";
      state.getTagListObj.data = data;
      state.getTagListObj.successMessage = message;
    },
    [getTagList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getTagListObj.status = "failed";
      state.getTagListObj.errorMessage = message;
    },
    [searchTag.pending]: (state) => {
      state.searchTagObj.status = "pending";
    },
    [searchTag.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.searchTagObj.status = "succeeded";
      state.searchTagObj.data = data;
      state.searchTagObj.successMessage = message;
    },
    [searchTag.rejected]: (state, action) => {
      const { message } = action.error;

      state.searchTagObj.status = "failed";
      state.searchTagObj.errorMessage = message;
    },
    [deleteTag.pending]: (state) => {
      state.deleteTagObj.status = "pending";
    },
    [deleteTag.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteTagObj.status = "succeeded";
      state.deleteTagObj.data = data;
      state.deleteTagObj.successMessage = message;

      state.searchTagObj.data = data;
    },
    [deleteTag.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteTagObj.status = "failed";
      state.deleteTagObj.errorMessage = message;
    },
    [createTag.pending]: (state) => {
      state.createTagObj.status = "pending";
    },
    [createTag.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createTagObj.status = "succeeded";
      state.createTagObj.data = data;
      state.createTagObj.successMessage = message;
    },
    [createTag.rejected]: (state, action) => {
      const { message } = action.error;

      state.createTagObj.status = "failed";
      state.createTagObj.errorMessage = message;
    },
    [searchTagExperience.pending]: (state) => {
      state.searchTagExperienceObj.status = "pending";
    },
    [searchTagExperience.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.searchTagExperienceObj.status = "succeeded";
      state.searchTagExperienceObj.data = data;
      state.searchTagExperienceObj.successMessage = message;
    },
    [searchTagExperience.rejected]: (state, action) => {
      const { message } = action.error;

      state.searchTagExperienceObj.status = "failed";
      state.searchTagExperienceObj.errorMessage = message;
    },
    [getTagDetail.pending]: (state) => {
      state.getTagDetailObj.status = "pending";
    },
    [getTagDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getTagDetailObj.status = "succeeded";
      state.getTagDetailObj.data = data;
      state.getTagDetailObj.successMessage = message;
    },
    [getTagDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.getTagDetailObj.status = "failed";
      state.getTagDetailObj.errorMessage = message;
    },
    [updateTag.pending]: (state) => {
      state.updateTagObj.status = "pending";
    },
    [updateTag.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateTagObj.status = "succeeded";
      state.updateTagObj.data = data;
      state.updateTagObj.successMessage = message;

      state.getTagDetailObj.data = data;
    },
    [updateTag.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateTagObj.status = "failed";
      state.updateTagObj.errorMessage = message;
    },
  },
});

export default tagSlice.reducer;

// state
export const tagSelector = (state) => state.tag;
