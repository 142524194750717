import { Grid, Table, TableBody, TableCell, TableRow } from "@mui/material";
import React from "react";
import Skeleton from "react-loading-skeleton";
import DetailCardContainer from "../../../components/detail-card-container.component";
import TableHeader from "../../../components/table/table-header.component";
import Text from "../../../components/text.component";

function StatementTransactionListLoader() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <DetailCardContainer>
          <Grid container>
            <Grid item xs={10}>
              <Text variant="h5">Transactions List</Text>
            </Grid>
          </Grid>
          <Table>
            <TableHeader
              headerCells={["Transaction Id", "Experience / Commission", "Date Created", "Amount"]}
            />
            <TableBody>
              <TableRow
                sx={{
                  "& .MuiTableCell-root": {
                    borderBottom: "no-set",
                  },
                }}
              >
                <TableCell>
                  <Text variant="body2" sx={{ textAlign: "center" }}>
                    <Skeleton />
                  </Text>
                </TableCell>
                <TableCell>
                  <Text variant="body2" sx={{ textAlign: "center" }}>
                    <Skeleton />
                  </Text>
                </TableCell>
                <TableCell>
                  <Text variant="body2" sx={{ textAlign: "center", minWidth: "100px" }}>
                    <Skeleton />
                  </Text>
                </TableCell>
                <TableCell>
                  <Text variant="body2" sx={{ textAlign: "center", minWidth: "100px" }}>
                    <Skeleton />
                  </Text>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DetailCardContainer>
      </Grid>
    </Grid>
  );
}

export default StatementTransactionListLoader;
