import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import Text from "../../../components/text.component";
import { updateUser, userSelector } from "../../../services/user/user-slice.services";
import UserDetailsFormFieldText from "./user-details-form-field-text.component";

const FlexEndButtonContainer = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "flex-end",
});

const userDetailsValidationSchema = Yup.object().shape({
  email: Yup.string().email().required().label("Email Address"),
  phoneNumber: Yup.string().nullable().label("Phone Number"),
  firstName: Yup.string().required().min(2).label("First Name"),
  lastName: Yup.string().required().min(2).label("Last Name"),
  gender: Yup.string().nullable().label("Gender"),
  dateOfBirth: Yup.date().nullable().label("Date of birth"),
});

function UserDetails() {
  const theme = useTheme();
  const location = useLocation();
  const userId = new URLSearchParams(location.search).get("userId");
  const [isPhoneNumberEditing, setIsPhoneNumberEditing] = useState(false);
  const [isEmailEditing, setIsEmailEditing] = useState(false);
  const [isFirstNameEditing, setIsFirstNameEditing] = useState(false);
  const [isLastNameEditing, setIsLastNameEditing] = useState(false);
  const [isGenderEditing, setIsGenderEditing] = useState(false);
  const [isDobEditing, setIsDobEditing] = useState(false);

  const { getUserDetailsObj, updateUserObj } = useSelector(userSelector);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const resetState = () => {
    setIsPhoneNumberEditing(false);
    setIsEmailEditing(false);
    setIsFirstNameEditing(false);
    setIsLastNameEditing(false);
    setIsGenderEditing(false);
    setIsDobEditing(false);
  };

  const onUpdateUserDetails = (values) => {
    dispatch(updateUser({ userId, ...values })).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        resetState();
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  return (
    <Form
      validationSchema={userDetailsValidationSchema}
      onSubmit={onUpdateUserDetails}
      initialValues={{
        id: getUserDetailsObj.data.id,
        registeredAt: getUserDetailsObj.data.registeredAt,
        email: getUserDetailsObj.data.email,
        firstName: getUserDetailsObj.data.firstName,
        lastName: getUserDetailsObj.data.lastName,
        phoneNumber: getUserDetailsObj.data.phoneNumber,
        emailVerified: getUserDetailsObj.data.emailVerified,
        gender: getUserDetailsObj.data.gender,
        dateOfBirth: getUserDetailsObj.data.dateOfBirth,
      }}
    >
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={isMobile ? 12 : 3}>
              <UserDetailsFormFieldText name="id" disabled label="User ID" />
            </Grid>
            <Grid item xs={isMobile ? 12 : 3}>
              <UserDetailsFormFieldText
                name="email"
                label="Email Address"
                isShowIcon
                editing={isEmailEditing}
                setEditing={setIsEmailEditing}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 3}>
              <UserDetailsFormFieldText
                name="phoneNumber"
                label="Phone Number"
                isShowIcon
                editing={isPhoneNumberEditing}
                setEditing={setIsPhoneNumberEditing}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 3}>
              <UserDetailsFormFieldText name="registeredAt" disabled label="Date Registered" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={isMobile ? 12 : 3}>
              <UserDetailsFormFieldText
                name="firstName"
                label="First Name"
                isShowIcon
                editing={isFirstNameEditing}
                setEditing={setIsFirstNameEditing}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 3}>
              <UserDetailsFormFieldText
                name="lastName"
                label="Last Name"
                isShowIcon
                editing={isLastNameEditing}
                setEditing={setIsLastNameEditing}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 3}>
              <UserDetailsFormFieldText
                name="gender"
                label="Gender"
                isShowIcon
                editing={isGenderEditing}
                setEditing={setIsGenderEditing}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 3}>
              <UserDetailsFormFieldText
                name="dateOfBirth"
                label="Date Of Birth"
                isShowIcon
                editing={isDobEditing}
                setEditing={setIsDobEditing}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FlexEndButtonContainer sx={{ marginTop: "-30px" }}>
            <FormSubmitButton width="125px" isLoading={updateUserObj.status === "pending"}>
              <Text type="WhiteColor">Save</Text>
            </FormSubmitButton>
          </FlexEndButtonContainer>
        </Grid>
      </Grid>
    </Form>
  );
}

export default UserDetails;
