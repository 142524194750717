import {
  AppBar,
  Avatar,
  Box,
  Button,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Text from "../../components/text.component";
import Spacer from "../../components/spacer.component";
import YoloLogo from "../../assets/images/yolo-logo";
import { logout, resetUser } from "../../services/auth/auth-slice.services";
import { SnackbarContext } from "../../components/notifications/snackbar.context";
import routes from "../../navigation/routes";
import DrawerItems from "./drawer-items.component";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 4,
    marginTop: "3px",
    minWidth: 100,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

const CenterBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
});

const MaxWidthBox = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  maxWidth: theme.dimensions.PCMaxWidth,
  minHeight: theme.dimensions.heightWithoutAppBar,
  overflow: "auto",
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  color: theme.palette.colors.brand.primary,
  backgroundColor: "transparent",
  border: `1px solid ${theme.palette.colors.brand.primary}`,
}));

const AppBarFlexEndBox = styled(Box)({
  height: "60px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const IdenticationMenuButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  height: "100%",
  backgroundColor: theme.palette.colors.button.indentityBox,
  display: "flex",
  justifyContent: "center",
  flexDirection: "row",
  alignItems: "center",
  paddingLeft: theme.padding.paddingX[3],
  paddingRight: theme.padding.paddingX[3],
  ":hover": { backgroundColor: theme.palette.colors.button.indentityBox },
}));

const StyledKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)(({ theme }) => ({
  fontSize: "20px",
  color: theme.palette.colors.text.darkGrey,
}));

const FullWidthRowBox = styled(Box)({ width: "100%", display: "flex", flexDirection: "row" });

function AppHeader({ children }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const createSnackBar = useContext(SnackbarContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleLogout = () => {
    dispatch(logout()).then(({ meta, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: "Logout Successful",
          type: "success",
          open: true,
        });
        dispatch(resetUser());
      }
      history.push(routes.LOGIN);
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ overflow: "auto" }}>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: theme.palette.colors.bg.white,
          borderBottom: `1px solid ${theme.palette.colors.ui.border}`,
        }}
        elevation={0}
      >
        <AppBarFlexEndBox>
          <Box sx={{ flexDirection: "row", display: "flex" }}>
            <Spacer position="right" size={isMobile ? "s" : "xl"} />
            <YoloLogo />
            <Spacer position="right" size={isMobile ? "s" : "xl"} />
            <IconButton
              onClick={() => setOpenDrawer(true)}
              sx={{
                borderRadius: "3px",
                padding: "10px",
                marginTop: "5px",
              }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Box sx={{ flexDirection: "row", display: "flex" }}>
            <IdenticationMenuButton onClick={handleClick} disableRipple>
              <StyledAvatar />
              <Spacer position="right" size="s" />
              <Text
                type="DarkGreyColor"
                sx={{
                  fontSize: theme.fonts.fontSizes.size14,
                  fontWeight: theme.fonts.fontWeights.medium,
                }}
              >
                Admin
              </Text>
              <StyledKeyboardArrowDownIcon />
            </IdenticationMenuButton>
            <Spacer position="right" size={isMobile ? "s" : "xl"} />
          </Box>
        </AppBarFlexEndBox>
        <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem
            onClick={(e) => {
              handleClose(e);
              handleLogout();
            }}
            disableRipple
          >
            <Text sx={{ fontSize: theme.fonts.fontSizes.size14 }}>Logout</Text>
          </MenuItem>
        </StyledMenu>
      </AppBar>
      <CenterBox>
        <FullWidthRowBox>
          <Box>
            {isMobile ? (
              <Drawer
                variant="temporary"
                anchor="left"
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                elevation={1}
                sx={{
                  width: "250px",
                  "& .MuiDrawer-paper": {
                    width: "250px",
                    backgroundColor: theme.palette.darkColors.bg.white,
                  },
                }}
              >
                <Spacer size="xl" />
                <CenterBox>
                  <YoloLogo />
                </CenterBox>
                <Spacer size="l" />
                <DrawerItems setOpenDrawer={setOpenDrawer} />
              </Drawer>
            ) : (
              <Drawer
                variant="permanent"
                anchor="left"
                open={true}
                elevation={1}
                sx={{
                  width: "250px",
                  "& .MuiDrawer-paper": {
                    width: "250px",
                    backgroundColor: theme.palette.darkColors.bg.white,
                  },
                }}
              >
                <Spacer size="xl" />
                <CenterBox>
                  <YoloLogo />
                </CenterBox>
                <Spacer size="l" />
                <DrawerItems setOpenDrawer={setOpenDrawer} />
              </Drawer>
            )}
          </Box>
          <CenterBox
            sx={{
              width: "100%",
              backgroundColor: theme.palette.colors.bg.secondary,
              marginTop: "40px",
            }}
          >
            <MaxWidthBox>{children}</MaxWidthBox>
          </CenterBox>
        </FullWidthRowBox>
      </CenterBox>
    </Box>
  );
}

AppHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppHeader;
