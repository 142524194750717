import React from "react";
import { useTheme } from "@mui/material";
import PropTypes from "prop-types";

function DeleteIcon({ disabled }) {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.175"
      height="23.175"
      viewBox="0 0 21.175 23.175"
    >
      <g id="Group_10" data-name="Group 10" transform="translate(1 1)">
        <g id="Icon_feather-trash-2" data-name="Icon feather-trash-2">
          <path
            id="Path_3"
            data-name="Path 3"
            d="M4.5,9H23.675"
            transform="translate(-4.5 -4.765)"
            fill="none"
            stroke={
              disabled ? theme.palette.colors.ui.disabled : theme.palette.colors.text.tertiary
            }
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_4"
            data-name="Path 4"
            d="M21.525,7.235V22.057a2.063,2.063,0,0,1-2,2.118H9.5a2.063,2.063,0,0,1-2-2.118V7.235m3.005,0V5.117a2.063,2.063,0,0,1,2-2.117h4.007a2.063,2.063,0,0,1,2,2.117V7.235"
            transform="translate(-4.925 -3)"
            fill="none"
            stroke={
              disabled ? theme.palette.colors.ui.disabled : theme.palette.colors.text.tertiary
            }
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_5"
            data-name="Path 5"
            d="M15,16.5v7.725"
            transform="translate(-7.543 -7.854)"
            fill="none"
            stroke={
              disabled ? theme.palette.colors.ui.disabled : theme.palette.colors.text.tertiary
            }
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_6"
            data-name="Path 6"
            d="M21,16.5v7.725"
            transform="translate(-9.282 -7.854)"
            fill="none"
            stroke={
              disabled ? theme.palette.colors.ui.disabled : theme.palette.colors.text.tertiary
            }
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
}

DeleteIcon.defaultProps = {
  disabled: false,
};

DeleteIcon.propTypes = {
  disabled: PropTypes.bool,
};

export default DeleteIcon;
