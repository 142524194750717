import React from "react";
import PropTypes from "prop-types";
import { TableCell, IconButton, TableRow } from "@mui/material";
import { Link } from "react-router-dom";
import Text from "../../../components/text.component";
import EditIcon from "../../../assets/images/edit-icon";
import routes from "../../../navigation/routes";
import DeleteIcon from "../../../assets/images/delete-icon";

function MerchantRow({ record, onTriggerDelete }) {
  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  return (
    <TableRow
      key={record.id}
      sx={{
        "& .MuiTableCell-root": {
          borderBottom: "no-set",
        },
      }}
    >
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center" }}>
          {record.id}
        </Text>
      </TableCell>
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center" }}>
          {record.name}
        </Text>
      </TableCell>
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center" }}>
          {record.commission}
        </Text>
      </TableCell>
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center", minWidth: "100px" }}>
          {record.createdAt}
        </Text>
      </TableCell>
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center" }}>
          {record.status !== null ? capitalize(record.status.toString()) : "-"}
        </Text>
      </TableCell>
      <TableCell>
        <Link to={{ pathname: `${routes.MERCHANTDETAIL}`, search: `merchantId=${record.id}` }}>
          <IconButton>
            <EditIcon />
          </IconButton>
        </Link>
        <IconButton onClick={() => onTriggerDelete(record.deletable, record.id)} disabled={!record.deletable}>
          <DeleteIcon disabled={!record.deletable} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

MerchantRow.defaultProps = {
  record: {
    commission: null,
    name: "",
    createdAt: "",
    id: null,
    status: "",
    deletable: false,
  },
};

MerchantRow.propTypes = {
  onTriggerDelete: PropTypes.func.isRequired,
  record: PropTypes.shape({
    commission: PropTypes.number,
    name: PropTypes.string,
    createdAt: PropTypes.string,
    id: PropTypes.number,
    status: PropTypes.string,
    deletable: PropTypes.bool,
  }),
};

export default MerchantRow;
