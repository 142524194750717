import { Box, Grid } from "@mui/material";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import {
    creatorSelector,
    updateCreator,
} from "../../../services/creator/creator-slice.services";
import CreatorCommissionField from "./creator-commision-field.component";

const CreatorCommissionValidationSchema = Yup.object().shape({
    commission: Yup.number().max(100).required().label("Commission Rate"),
});

function CreatorCommission() {
    const location = useLocation();
    const creatorId = new URLSearchParams(location.search).get("creatorId");
    const { getCreatorDetailsObj, updateCreatorObj } = useSelector(creatorSelector);
    const dispatch = useDispatch();
    const createSnackBar = useContext(SnackbarContext);

    const onUpdateCreatorCommission = (values) => {
        dispatch(updateCreator({ creatorId, type: "commission", ...values })).then(
            ({ meta, error, payload }) => {
                if (meta.requestStatus === "fulfilled") {
                    createSnackBar({
                        message: payload.message,
                        type: "success",
                        open: true,
                    });
                }
                if (meta.requestStatus === "rejected") {
                    createSnackBar({
                        message: error.message,
                        type: "error",
                        open: true,
                    });
                }
            },
        );
    };

    return (
        <Form
            validationSchema={CreatorCommissionValidationSchema}
            onSubmit={onUpdateCreatorCommission}
            initialValues={{
                id: getCreatorDetailsObj.data.creator.id,
                commission: getCreatorDetailsObj.data.creator.commission,
            }}
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Text variant="h5">Commission Rate (%)</Text>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                        <CreatorCommissionField name="commission" />
                        <Spacer size="m" position="left" />
                        <FormSubmitButton width="125px" isLoading={updateCreatorObj.status === "pending"}>
                            <Text type="WhiteColor">Save</Text>
                        </FormSubmitButton>
                    </Box>
                </Grid>
            </Grid>
        </Form>
    );
}

export default CreatorCommission;
