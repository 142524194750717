import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Box, IconButton, styled, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";
import HTMLRenderer from "react-html-renderer";
import BorderButton from "../../../components/buttons/border-button.component";
import CenteredRowBox from "../../../components/centered-row-box.component";
import FormPhoneNumber from "../../../components/forms/form-phone-number.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";

const ButtonContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  marginTop: "20px",
});

export default function ExperiencePicContactNo({ setIsEditing, isEditing }) {
  const theme = useTheme();
  const { resetForm, values } = useFormikContext();

  return (
    <Box>
      <CenteredRowBox>
        <Text sx={{ fontSize: theme.fonts.fontSizes.size18 }}>Pic Contact No</Text>
        <Spacer size="s" position="left" />
        <IconButton
          sx={{ color: theme.palette.colors.text.primary }}
          disabled={isEditing.picContactNo}
          onClick={() => setIsEditing((i) => ({ ...i, picContactNo: true }))}
        >
          <ModeEditIcon />
        </IconButton>
      </CenteredRowBox>
      <Spacer size="s" position="top" />
      {!isEditing.picContactNo ? (
        <Box
          sx={{
            padding: "16.5px 14px",
            borderRadius: "4px",
            border: `1px solid ${theme.palette.colors.ui.border}`,
          }}
        >
          <HTMLRenderer html={values.picContactNo || ""} />
        </Box>
      ) : (
        <FormPhoneNumber name="picContactNo" width="100%" placeholder="Enter pic Contact No" />
      )}
      {isEditing.picContactNo && (
        <ButtonContainer>
          <BorderButton
            width="100px"
            onClickButton={() => {
              resetForm();
              setIsEditing((i) => ({ ...i, picContactNo: false }));
            }}
          >
            <Text type="BrandColor">Cancel</Text>
          </BorderButton>
          <Spacer size="s" position="left" />
          <FormSubmitButton width="100px">
            <Text type="WhiteColor">Save</Text>
          </FormSubmitButton>
        </ButtonContainer>
      )}
    </Box>
  );
}

ExperiencePicContactNo.propTypes = {
  setIsEditing: PropTypes.func.isRequired,
  isEditing: PropTypes.shape({
    picContactNo: PropTypes.bool,
  }).isRequired,
};
