import React from "react";
import { Box, Grid, IconButton, Modal, styled, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import Text from "../../../../components/text.component";
import FormFieldTextWithCounter from "../../../../components/forms/form-field-text-with-counter.component";
import Spacer from "../../../../components/spacer.component";
import FormImageCropper from "../../../../components/forms/form-image-cropper.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import FestivalCategoryLinkTypeForm from "./festival-category-link-type-form.component";
import { festivalSectionSelector } from "../../../../services/marketing/festival-section/festival-section-slice.services";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.brand.white,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  outline: "none",
  maxHeight: "95%",
  overflow: "scroll",
  maxWidth: "90%",
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.colors.brand.secondary,
}));

const SpaceBetweenBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-end",
});

export default function FestivalCategoryModal({ showModal, setShowModal }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { createFestivalCategoryObj, updateFestivalCategoryObj } =
    useSelector(festivalSectionSelector);
  const { resetForm, values } = useFormikContext();
  const cropSetting = {
    unit: "px",
    aspect: 12.75 / 13,
    width: 51,
    height: 52,
    x: 0,
    y: 0,
  };
  const aspectRatio = 12.75 / 13;

  const handleCancel = () => {
    resetForm();
    setShowModal(false);
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox
        sx={{
          padding: isMobile ? theme.padding.paddingX[1] : theme.padding.paddingX[0],
          width: isMobile ? "95%" : "600px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SpaceBetweenBox>
              <Box sx={{ width: "24px", height: "24px" }} />
              <Text
                variant="h5"
                sx={{
                  textAlign: "center",
                  fontWeight: theme.fonts.fontWeights.bold,
                }}
              >
                {values.postType === "edit" ? "Edit Festival Category" : "Create Festival Category"}
              </Text>
              <CloseIconButton onClick={handleCancel}>
                <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
              </CloseIconButton>
            </SpaceBetweenBox>
          </Grid>
          <Grid item xs={12}>
            <Text>Title</Text>
            <Spacer position="top" size="xs" />
            <FormFieldTextWithCounter
              name="label"
              maxLength={50}
              placeholder="Enter title here..."
            />
          </Grid>
          <Grid item xs={12}>
            <FormImageCropper
              name="imagePath"
              label="Image"
              width="104px"
              height="102px"
              cropSetting={cropSetting}
              aspectRatio={aspectRatio}
            />
          </Grid>
          <Grid item xs={12}>
            <FestivalCategoryLinkTypeForm />
          </Grid>

          <Grid item xs={12} />
          <Grid item xs={12}>
            <FormSubmitButton
              isLoading={
                createFestivalCategoryObj.status === "pending" ||
                updateFestivalCategoryObj.status === "pending"
              }
            >
              <Text type="WhiteColor"> {values.postType === "edit" ? "Save" : "Create"}</Text>
            </FormSubmitButton>
          </Grid>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

FestivalCategoryModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};
