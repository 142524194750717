import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../api-services";

export const getGiftVoucherList = createAsyncThunk("gift_voucher/credit/list", async () => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/gifts/options?type=credit`,
  );
  return response;
});

export const updateGiftVoucher = createAsyncThunk("gift_voucher/credit/update", async (payload) => {
  const response = await putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/gifts/options/${payload.id}`,
    payload,
  );
  return response;
});

export const createGiftVoucher = createAsyncThunk("gift_voucher/credit/create", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/gifts/options/`,
    payload,
  );
  return response;
});

export const removeGiftVoucher = createAsyncThunk(
  "gift_voucher/credit/remove",
  async (voucherId) => {
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/gifts/options/${voucherId}`,
    );
    return response;
  },
);

const giftVoucherSlice = createSlice({
  name: "giftVoucher",
  initialState: {
    getGiftVoucherListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateGiftVoucherObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createGiftVoucherObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    removeGiftVoucherObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getGiftVoucherList.pending]: (state) => {
      state.getGiftVoucherListObj.status = "pending";
    },
    [getGiftVoucherList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getGiftVoucherListObj.status = "succeeded";
      state.getGiftVoucherListObj.data = data;
      state.getGiftVoucherListObj.successMessage = message;
    },
    [getGiftVoucherList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getGiftVoucherListObj.status = "failed";
      state.getGiftVoucherListObj.errorMessage = message;
    },
    [updateGiftVoucher.pending]: (state) => {
      state.updateGiftVoucherObj.status = "pending";
    },
    [updateGiftVoucher.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateGiftVoucherObj.status = "succeeded";
      state.updateGiftVoucherObj.data = data;
      state.updateGiftVoucherObj.successMessage = message;
    },
    [updateGiftVoucher.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateGiftVoucherObj.status = "failed";
      state.updateGiftVoucherObj.errorMessage = message;
    },
    [createGiftVoucher.pending]: (state) => {
      state.createGiftVoucherObj.status = "pending";
    },
    [createGiftVoucher.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createGiftVoucherObj.status = "succeeded";
      state.createGiftVoucherObj.data = data;
      state.createGiftVoucherObj.successMessage = message;

      state.getGiftVoucherListObj.data = data;
    },
    [createGiftVoucher.rejected]: (state, action) => {
      const { message } = action.error;

      state.createGiftVoucherObj.status = "failed";
      state.createGiftVoucherObj.errorMessage = message;
    },
    [removeGiftVoucher.pending]: (state) => {
      state.removeGiftVoucherObj.status = "pending";
    },
    [removeGiftVoucher.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.removeGiftVoucherObj.status = "succeeded";
      state.removeGiftVoucherObj.data = data;
      state.removeGiftVoucherObj.successMessage = message;

      state.getGiftVoucherListObj.data = data;
    },
    [removeGiftVoucher.rejected]: (state, action) => {
      const { message } = action.error;

      state.removeGiftVoucherObj.status = "failed";
      state.removeGiftVoucherObj.errorMessage = message;
    },
  },
});

export default giftVoucherSlice.reducer;

// state
export const giftVoucherSelectore = (state) => state.giftVoucher;
