import React from "react";
import { Grid, styled, Box } from "@mui/material";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Text from "../../../components/text.component";
import Spacer from "../../../components/spacer.component";
import ExperienceCardLoader from "./experience-card-loader.component";
import CtaButton from "../../../components/buttons/cta-button.component";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-end",
});

function TagDetailLoader() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <SpaceBetweenBox>
          <Text variant="h4">Edit Tag</Text>
          <CtaButton width="125px" isLoading={true}>
            <Text type="WhiteColor">Confirm</Text>
          </CtaButton>
        </SpaceBetweenBox>
      </Grid>
      <Grid item xs={12}>
        <Text>Tag Name</Text>
        <Spacer size="xs" position="top" />
        <Skeleton width="350px" height="56px" />
      </Grid>
      <Grid item xs={12}>
        <Text>Targeted Experiences</Text>
        <Spacer size="xs" position="top" />
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <ExperienceCardLoader />
          </Grid>
          <Grid item xs={4}>
            <ExperienceCardLoader />
          </Grid>
          <Grid item xs={4}>
            <ExperienceCardLoader />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} />
    </Grid>
  );
}

export default TagDetailLoader;
