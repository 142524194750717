import React from "react";
import { Box, styled, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";

const CardContainer = styled(Box)(({ theme }) => ({
  width: "285px",
  backgroundColor: theme.palette.colors.bg.secondary,
  padding: theme.padding.paddingX[2],
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  position: "relative",
}));

export default function SlotCardContainer({ children }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return <CardContainer sx={{ width: isMobile ? "100%" : "285px" }}>{children}</CardContainer>;
}

SlotCardContainer.defaultProps = {
  children: null,
};

SlotCardContainer.propTypes = {
  children: PropTypes.node,
};
