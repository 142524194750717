import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useTheme } from "@mui/material/styles";
import { FormHelperText, TextField } from "@mui/material";
import PhoneInput from "react-phone-input-2";

function BookingRecipientDetailField({ name, placeholder, width, disabled, onClickHandle }) {
  const theme = useTheme();
  const { setFieldTouched, setFieldValue, errors, touched, values, handleSubmit } =
    useFormikContext();

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  const showError =
    touched.recipient &&
    errors.recipient &&
    touched.recipient[name] &&
    typeof errors.recipient[name] === "string";

  if (name === "contactNo" && !disabled) {
    return (
      <>
        <PhoneInput
          placeholder={placeholder}
          specialLabel=""
          value={values.recipient[name]}
          isValid={() => !showError}
          country="my"
          onBlur={() => setFieldTouched(name)}
          onChange={(e) => setFieldValue(`recipient.${name}`, e)}
          inputStyle={{
            width: "100%",
            borderRadius: theme.shape.borderRadius[0],
            maxWidth: "450px",
            backgroundColor: theme.palette.colors.text.white,
          }}
        />
        {showError && (
          <FormHelperText
            sx={{
              color: theme.palette.colors.text.error,
              margin: "3px 14px 0px 14px",
            }}
          >
            {errors.recipient[name]}
          </FormHelperText>
        )}
      </>
    );
  }

  return (
    <TextField
      sx={{
        borderTopLeftRadius: theme.shape.borderRadius[0],
        borderTopRightRadius: theme.shape.borderRadius[0],
        borderBottomLeftRadius: theme.shape.borderRadius[0],
        borderBottomRightRadius: theme.shape.borderRadius[0],
        width,
        maxWidth: "450px",
        "& .MuiOutlinedInput-root": {
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          backgroundColor: theme.palette.colors.text.white,
        },
      }}
      onClick={onClickHandle}
      disabled={disabled}
      onKeyDown={keyPress}
      error={showError}
      variant="outlined"
      helperText={showError ? errors.recipient[name] : null}
      onBlur={() => setFieldTouched(name)}
      name={name}
      placeholder={placeholder}
      onChange={(e) => {
        setFieldValue(`recipient.${name}`, e.target.value);
      }}
      value={values.recipient[name] === null ? "" : values.recipient[name]}
    />
  );
}

BookingRecipientDetailField.defaultProps = {
  width: "100%",
  disabled: false,
  onClickHandle: null,
};

BookingRecipientDetailField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  width: PropTypes.string,
  disabled: PropTypes.bool,
  onClickHandle: PropTypes.func,
};

export default BookingRecipientDetailField;
