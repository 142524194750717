import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, Link, styled, useMediaQuery, useTheme } from "@mui/material";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import PaddedView from "../../../components/padded-view.component";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import Spacer from "../../../components/spacer.component";
import Form from "../../../components/forms/form.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import FormFieldTextWithCounter from "../../../components/forms/form-field-text-with-counter.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import Loading from "../../../components/notifications/loading.component";
import { getTagDetail, tagSelector, updateTag } from "../../../services/tag/tag-slice.services";
import ExperienceSelectionModal from "../components/experience-selection-modal.component";
import SelectedExperienceList from "../components/selected-experience-list.component";
import TagDetailLoader from "../loader/tag-detail-loader.component";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-end",
});

const validationSchema = Yup.object().shape({
  label: Yup.string().required().max(80).label("Label Name"),
  experiencesObj: Yup.array()
    .of(Yup.object().shape({}))
    .required()
    .min(1)
    .label("Targeted Experiences"),
});

export default function TagEditScreen() {
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);
  const { getTagDetailObj, updateTagObj } = useSelector(tagSelector);
  const [showExperienceModal, setShowExperienceModal] = useState(false);
  const location = useLocation();
  const tagId = new URLSearchParams(location.search).get("tagId");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    dispatch(getTagDetail(tagId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({ message: error.message, type: "error" });
      }
    });
  }, []);

  const onUpdateBanner = (values) => {
    setIsLoading(true);
    const experiencesId = [];
    values.experiencesObj.map((item) => experiencesId.push(item.id));
    dispatch(updateTag({ label: values.label, experiencesId, tagId })).then(
      ({ meta, error, payload }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          createSnackBar({ message: payload.message, type: "success" });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({ message: error.message, type: "error" });
        }
      },
    );
  };

  const renderContent = () => {
    if (getTagDetailObj.status === "succeeded") {
      return (
        <Form
          validationSchema={validationSchema}
          onSubmit={onUpdateBanner}
          initialValues={{
            label: getTagDetailObj.data.label,
            experiencesObj: getTagDetailObj.data.experiences,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SpaceBetweenBox>
                <Text variant="h4">Edit Tag</Text>
                <FormSubmitButton width="125px" isLoading={updateTagObj.status === "pending"}>
                  <Text type="WhiteColor">Confirm</Text>
                </FormSubmitButton>
              </SpaceBetweenBox>
            </Grid>
            <Grid item xs={12}>
              <Text>Tag Name</Text>
              <Spacer size="xs" position="top" />
              <FormFieldTextWithCounter
                maxLength={50}
                name="label"
                placeholder="Enter Tag Name here"
                width="350px"
              />
            </Grid>
            <Grid item xs={12}>
              <Text>Targeted Experiences</Text>
              <Spacer size="xs" position="top" />
              <SelectedExperienceList setShowExperienceModal={setShowExperienceModal} />
            </Grid>

            <Grid item xs={12} />
          </Grid>
          {showExperienceModal && (
            <ExperienceSelectionModal
              showModal={showExperienceModal}
              setShowModal={setShowExperienceModal}
            />
          )}
        </Form>
      );
    }
    return <TagDetailLoader />;
  };

  return (
    <PaddedView multiples={isMobile ? 1 : 3}>
      <Loading isLoading={isLoading} />
      <Link href={routes.TAG_LIST} style={{ textDecoration: "none" }}>
        <Text type="GreyColor">{`< Back`}</Text>
      </Link>
      <Spacer size="xl" position="top" />
      {renderContent()}
    </PaddedView>
  );
}
