import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../api-services";

export const getExperienceList = createAsyncThunk("employees/experience", async (payload) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/experiences?status=${payload.status}&q=${payload.q}&page=${payload.page}`,
    payload,
  );
  return response;
});

export const getExperienceDetail = createAsyncThunk(
  "employees/experience-detail",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/experiences/${payload}`,
    );
    return response;
  },
);

export const updateExperience = createAsyncThunk("employees/experience-update", async (payload) => {
  const response = await putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/experiences/${payload.experienceId}?params=${payload.params}`,
    payload,
  );
  return response;
});

export const updateExperienceSubcategories = createAsyncThunk(
  "employees/experiences/experienceId/categories/subcategories",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/experiences/${payload.experienceId}/categories/subcategories`,
      { subcategoriesId: payload.subcategoriesId },
    );
    return response;
  },
);

export const getExperienceTimeslot = createAsyncThunk(
  "employees/experience-timeslot",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/experiences/${payload}/slots`,
    );
    return response;
  },
);

export const getExperienceLocationList = createAsyncThunk(
  "employees/experience-location",
  async () => {
    const response = await getReq(`${process.env.REACT_APP_API_PUBLIC_V1}/experiences/locations`);
    return response;
  },
);

export const createExperienceImage = createAsyncThunk(
  "employees/experience/image/create",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/experiences/${payload.experienceId}/images`,
      payload,
    );
    return response;
  },
);

export const removeExperienceImage = createAsyncThunk(
  "employees/experience/image/remove",
  async (payload) => {
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/experiences/${payload.experienceId}/images/${payload.imageId}`,
    );
    return response;
  },
);

export const reOrderExperienceImage = createAsyncThunk(
  "employees/experience/image/re-order",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/experiences/${payload.experienceId}/images/${payload.imageId}/reorder?type=${payload.type}`,
    );
    return response;
  },
);

export const experienceCreateSlot = createAsyncThunk(
  "employees/experience/slot/create",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/experiences/${payload.experienceId}/slots`,
      payload,
    );
    return response;
  },
);

export const experienceUpdateSlot = createAsyncThunk(
  "employees/experience/slot/update",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/experiences/${payload.experienceId}/slots`,
      payload,
    );
    return response;
  },
);

export const experienceRemoveSlot = createAsyncThunk(
  "employees/experience/slot/remove",
  async (payload) => {
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/experiences/${payload.experienceId}/slots/${payload.slotId}`,
      payload,
    );
    return response;
  },
);

export const createExperience = createAsyncThunk("employees/experience/create", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/experiences`,
    payload,
  );
  return response;
});

export const deleteExperience = createAsyncThunk(
  "employees/experience/delete",
  async (experienceId) => {
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/experiences/${experienceId}`,
    );
    return response;
  },
);

const experienceSlice = createSlice({
  name: "experience",
  initialState: {
    getExperienceListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getExperienceDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateExperienceObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateExperienceSubcategoriesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getExperienceTimeslotObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getExperienceLocationListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createExperienceImageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    removeExperienceImageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    reOrderExperienceImageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    experienceCreateSlotObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    experienceUpdateSlotObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    experienceRemoveSlotObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createExperienceObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteExperienceObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getExperienceList.pending]: (state) => {
      state.getExperienceListObj.status = "pending";
    },
    [getExperienceList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getExperienceListObj.status = "succeeded";
      state.getExperienceListObj.data = data;
      state.getExperienceListObj.successMessage = message;
    },
    [getExperienceList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getExperienceListObj.status = "failed";
      state.getExperienceListObj.errorMessage = message;
    },
    [getExperienceDetail.pending]: (state) => {
      state.getExperienceDetailObj.status = "pending";
    },
    [getExperienceDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getExperienceDetailObj.status = "succeeded";
      state.getExperienceDetailObj.data = data;
      state.getExperienceDetailObj.successMessage = message;
    },
    [getExperienceDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.getExperienceDetailObj.status = "failed";
      state.getExperienceDetailObj.errorMessage = message;
    },
    [updateExperience.pending]: (state) => {
      state.updateExperienceObj.status = "pending";
    },
    [updateExperience.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateExperienceObj.status = "succeeded";
      state.updateExperienceObj.data = data;
      state.updateExperienceObj.successMessage = message;

      state.getExperienceDetailObj.data = data;
    },
    [updateExperience.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateExperienceObj.status = "failed";
      state.updateExperienceObj.errorMessage = message;
    },
    [updateExperienceSubcategories.pending]: (state) => {
      state.updateExperienceObj.status = "pending";
    },
    [updateExperienceSubcategories.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateExperienceSubcategoriesObj.status = "succeeded";
      state.updateExperienceSubcategoriesObj.data = data;
      state.updateExperienceSubcategoriesObj.successMessage = message;

      state.getExperienceDetailObj.data = data;
    },
    [updateExperienceSubcategories.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateExperienceSubcategoriesObj.status = "failed";
      state.updateExperienceSubcategoriesObj.errorMessage = message;
    },
    [getExperienceTimeslot.pending]: (state) => {
      state.getExperienceTimeslotObj.status = "pending";
    },
    [getExperienceTimeslot.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getExperienceTimeslotObj.status = "succeeded";
      state.getExperienceTimeslotObj.data = data;
      state.getExperienceTimeslotObj.successMessage = message;
    },
    [getExperienceTimeslot.rejected]: (state, action) => {
      const { message } = action.error;

      state.getExperienceTimeslotObj.status = "failed";
      state.getExperienceTimeslotObj.errorMessage = message;
    },
    [getExperienceLocationList.pending]: (state) => {
      state.getExperienceLocationListObj.status = "pending";
    },
    [getExperienceLocationList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getExperienceLocationListObj.status = "succeeded";
      state.getExperienceLocationListObj.data = data;
      state.getExperienceLocationListObj.successMessage = message;
    },
    [getExperienceLocationList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getExperienceLocationListObj.status = "failed";
      state.getExperienceLocationListObj.errorMessage = message;
    },
    [createExperienceImage.pending]: (state) => {
      state.createExperienceImageObj.status = "pending";
    },
    [createExperienceImage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createExperienceImageObj.status = "succeeded";
      state.createExperienceImageObj.data = data;
      state.createExperienceImageObj.successMessage = message;

      state.getExperienceDetailObj.data = data;
    },
    [createExperienceImage.rejected]: (state, action) => {
      const { message } = action.error;

      state.createExperienceImageObj.status = "failed";
      state.createExperienceImageObj.errorMessage = message;
    },
    [removeExperienceImage.pending]: (state) => {
      state.removeExperienceImageObj.status = "pending";
    },
    [removeExperienceImage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.removeExperienceImageObj.status = "succeeded";
      state.removeExperienceImageObj.data = data;
      state.removeExperienceImageObj.successMessage = message;

      state.getExperienceDetailObj.data = data;
    },
    [removeExperienceImage.rejected]: (state, action) => {
      const { message } = action.error;

      state.removeExperienceImageObj.status = "failed";
      state.removeExperienceImageObj.errorMessage = message;
    },
    [reOrderExperienceImage.pending]: (state) => {
      state.reOrderExperienceImageObj.status = "pending";
    },
    [reOrderExperienceImage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.reOrderExperienceImageObj.status = "succeeded";
      state.reOrderExperienceImageObj.data = data;
      state.reOrderExperienceImageObj.successMessage = message;

      state.getExperienceDetailObj.data = data;
    },
    [reOrderExperienceImage.rejected]: (state, action) => {
      const { message } = action.error;

      state.reOrderExperienceImageObj.status = "failed";
      state.reOrderExperienceImageObj.errorMessage = message;
    },
    [experienceCreateSlot.pending]: (state) => {
      state.experienceCreateSlotObj.status = "pending";
    },
    [experienceCreateSlot.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.experienceCreateSlotObj.status = "succeeded";
      state.experienceCreateSlotObj.data = data;
      state.experienceCreateSlotObj.successMessage = message;

      state.getExperienceTimeslotObj.data = data;
    },
    [experienceCreateSlot.rejected]: (state, action) => {
      const { message } = action.error;

      state.experienceCreateSlotObj.status = "failed";
      state.experienceCreateSlotObj.errorMessage = message;
    },
    [experienceUpdateSlot.pending]: (state) => {
      state.experienceUpdateSlotObj.status = "pending";
    },
    [experienceUpdateSlot.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.experienceUpdateSlotObj.status = "succeeded";
      state.experienceUpdateSlotObj.data = data;
      state.experienceUpdateSlotObj.successMessage = message;

      state.getExperienceTimeslotObj.data = data;
    },
    [experienceUpdateSlot.rejected]: (state, action) => {
      const { message } = action.error;

      state.experienceUpdateSlotObj.status = "failed";
      state.experienceUpdateSlotObj.errorMessage = message;
    },
    [experienceRemoveSlot.pending]: (state) => {
      state.experienceRemoveSlotObj.status = "pending";
    },
    [experienceRemoveSlot.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.experienceRemoveSlotObj.status = "succeeded";
      state.experienceRemoveSlotObj.data = data;
      state.experienceRemoveSlotObj.successMessage = message;

      state.getExperienceTimeslotObj.data = data;
    },
    [experienceRemoveSlot.rejected]: (state, action) => {
      const { message } = action.error;

      state.experienceRemoveSlotObj.status = "failed";
      state.experienceRemoveSlotObj.errorMessage = message;
    },
    [createExperience.pending]: (state) => {
      state.createExperienceObj.status = "pending";
    },
    [createExperience.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createExperienceObj.status = "succeeded";
      state.createExperienceObj.data = data;
      state.createExperienceObj.successMessage = message;
    },
    [createExperience.rejected]: (state, action) => {
      const { message } = action.error;

      state.createExperienceObj.status = "failed";
      state.createExperienceObj.errorMessage = message;
    },
    [deleteExperience.pending]: (state) => {
      state.deleteExperienceObj.status = "pending";
    },
    [deleteExperience.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteExperienceObj.status = "succeeded";
      state.deleteExperienceObj.data = data;
      state.deleteExperienceObj.successMessage = message;
    },
    [deleteExperience.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteExperienceObj.status = "failed";
      state.deleteExperienceObj.errorMessage = message;
    },
  },
});

export default experienceSlice.reducer;

// state
export const experienceSelector = (state) => state.experience;
