import { Box, Grid, Pagination, styled, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-js-loader";
import PropTypes from "prop-types";
import Text from "../../../components/text.component";
import {
  getMerchantExperienceList,
  merchantSelector,
} from "../../../services/merchant/merchant-slice.services";
import MerchantExperiencesCard from "./merchant-experiences-card.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";

const PaginationBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  paddingTop: "15px",
});

const LoaderBox = styled(Box)({
  display: "flex",
  height: "300px",
  justifyContent: "center",
  alignItems: "center",
});

function MerchantExperiences({ merchantId }) {
  const { getMerchantExperienceListObj } = useSelector(merchantSelector);
  const theme = useTheme();
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const onPageChange = (event, newPage) => {
    setPage(newPage);
    dispatch(getMerchantExperienceList({ merchantId, page: newPage })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const renderExperienceList = () => {
    if (getMerchantExperienceListObj.status === "succeeded") {
      if (getMerchantExperienceListObj.data.items.length <= 0) {
        return (
          <Grid item xs={12}>
            <LoaderBox>
              <Text>No Items</Text>
            </LoaderBox>
          </Grid>
        );
      }
      return getMerchantExperienceListObj.data.items.map((item) => (
        <Grid item xs={isMobile ? 12 : 4} key={item.id}>
          <MerchantExperiencesCard cardDetails={item} />
        </Grid>
      ));
    }
    return (
      <Grid item xs={12}>
        <LoaderBox>
          <Loader type="bubble-top" bgColor={theme.palette.colors.brand.secondary} size={40} />
        </LoaderBox>
      </Grid>
    );
  };

  return (
    <Box sx={{ paddingTop: 3, paddingBottom: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Text variant="h4">Experiences</Text>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {renderExperienceList()}
          </Grid>
        </Grid>
        {getMerchantExperienceListObj.status === "succeeded" &&
          getMerchantExperienceListObj.data.pagination.totalPages >= 2 && (
            <Grid item xs={12}>
              <PaginationBox>
                <Pagination
                  sx={{
                    "&& .Mui-selected": {
                      backgroundColor: theme.palette.colors.brand.primary,
                      color: theme.palette.colors.text.white,
                    },
                  }}
                  page={page}
                  onChange={onPageChange}
                  count={getMerchantExperienceListObj.data.pagination.totalPages}
                  variant="outlined"
                />
              </PaginationBox>
            </Grid>
          )}
      </Grid>
    </Box>
  );
}

MerchantExperiences.propTypes = {
  merchantId: PropTypes.string.isRequired,
};

export default MerchantExperiences;
