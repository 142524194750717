import { Box, Grid, Link, styled, useTheme } from "@mui/material";
import { format } from "date-fns";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import DetailCardContainer from "../../../components/detail-card-container.component";
import FormFieldTextWithCounter from "../../../components/forms/form-field-text-with-counter.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import PaddedView from "../../../components/padded-view.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";

import FormFieldTextMultiline from "../../../components/forms/form-field-text-with-multiline.component";
import Loading from "../../../components/notifications/loading.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import { createPromoCode } from "../../../services/promo-code/promo-code-slice.services";
import PromoDetailsForm from "../components/promo-details-form.component";
import PromoPeriodForm from "../components/promo-period-form.component";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-end",
});

const validationSchema = Yup.object().shape({
  code: Yup.string()
    .nullable()
    .label("Code")
    .length(6, "Code must have exactly 6 alphanumeric character"),
  title: Yup.string().required().max(50).label("Title"),
  description: Yup.string().required().label("Description"),
  startDate: Yup.date().required().label("Start Date"),
  startTime: Yup.date().required().label("Start Time"),
  endDate: Yup.date()
    .label("End Date")
    .min(Yup.ref("startDate"), "End date can't earlier than start date")
    .required(),
  endTime: Yup.date()
    .label("End Time")
    .required()
    .when(["startDate", "endDate"], (startDate, endDate) => {
      try {
        if (format(endDate, "yyyy-MM-dd") === format(startDate, "yyyy-MM-dd")) {
          return Yup.date()
            .label("End Time")
            .min(Yup.ref("startTime"), "End time can't earlier than start time on the same day")
            .required();
        }

        return Yup.date().label("End Time").required();
      } catch (err) {
        return Yup.date().label("End Time").required();
      }
    }),
  type: Yup.string().label("Type").required(),
  discount: Yup.number()
    .required()
    .when("type", {
      is: "percentage",
      then: Yup.number().min(0).max(100).required(),
    })
    .when("type", {
      is: "fixed amount",
      then: Yup.number().min(1).required(),
    })
    .label("Discount"),
  maxCapAmount: Yup.number().when("type", {
    is: "percentage",
    then: Yup.number().min(0).required(),
  }),
  maxNoClaims: Yup.number().min(0).required(),
  maxNoClaimsPerUser: Yup.number().min(0).required(),
  newUserOnly: Yup.string().label("New User Only").required(),
  minSpentAmount: Yup.number().min(0).required(),
  usersSpecific: Yup.string().label("Users Specific").required(),
  experiencesSpecific: Yup.string().label("Experiences Specific").required(),
  usersList: Yup.array()
    .of(Yup.object().shape({ id: Yup.number() }))
    .when("usersSpecific", {
      is: "Yes",
      then: Yup.array()
        .of(Yup.object().shape({ id: Yup.number() }))
        .required()
        .min(1),
    })
    .label("Specific User List"),
  experiencesList: Yup.array()
    .of(Yup.object().shape({ id: Yup.number() }))
    .when("experiencesSpecific", {
      is: "Yes",
      then: Yup.array()
        .of(Yup.object().shape({ id: Yup.number() }))
        .required()
        .min(1),
    })
    .label("Specific Experience List"),
});

export default function PromoCodeCreateScreen() {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [isUserSpecific, setIsUserSpecific] = useState(false);
  const [isExperienceSpecific, setIsExperienceSpecific] = useState(false);

  const onCreatePromoCode = (values) => {
    setIsLoading(true);
    if (!isUserSpecific) {
      values.usersList = [];
    } else {
      values.usersList = values.usersList.map((item) => item.id);
    }
    if (!isExperienceSpecific) {
      values.experiencesList = [];
    } else {
      values.experiencesList = values.experiencesList.map((item) => item.id);
    }
    if (selectedType === "fixed amount") values.maxCapAmount = values.discount;
    const startAt = `${format(new Date(values.startDate), "yyyy-MM-dd")} ${format(
      new Date(values.startTime),
      "HH:mm:ss",
    )}`;
    const endAt = `${format(new Date(values.endDate), "yyyy-MM-dd")} ${format(
      new Date(values.endTime),
      "HH:mm:ss",
    )}`;

    dispatch(
      createPromoCode({
        ...values,
        newUserOnly: values.newUserOnly === "Yes",
        usersSpecific: isUserSpecific,
        experiencesSpecific: isExperienceSpecific,
        startAt,
        endAt,
      }),
    ).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        history.push(routes.PROMO_CODE_LIST);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  return (
    <PaddedView multiples={3}>
      <Loading isLoading={isLoading} />
      <Link href={routes.PROMO_CODE_LIST} style={{ textDecoration: "none" }}>
        <Text type="GreyColor">{`< Back`}</Text>
      </Link>
      <Spacer size="xl" position="top" />
      <Form
        validationSchema={validationSchema}
        onSubmit={onCreatePromoCode}
        initialValues={{
          code: "",
          title: "",
          description: "",
          startDate: null,
          endDate: null,
          startTime: null,
          endTime: null,
          type: selectedType,
          discount: "",
          maxCapAmount: "",
          maxNoClaims: "",
          maxNoClaimsPerUser: "",
          newUserOnly: "No",
          minSpentAmount: "",
          usersSpecific: "No",
          experiencesSpecific: "No",
          usersList: [],
          experiencesList: [],
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SpaceBetweenBox>
              <Text variant="h4">Create Promo Code</Text>
            </SpaceBetweenBox>
          </Grid>
          <Grid item xs={12}>
            <DetailCardContainer>
              <Grid item xs={12}>
                <Text sx={{ fontSize: theme.fonts.fontSizes.size18 }}>Code (Optional)</Text>
                <Spacer size="xs" position="top" />
                <FormFieldTextWithCounter
                  maxLength={6}
                  name="code"
                  placeholder="Autogenerated if left empty"
                  width="100%"
                  toUpper={true}
                />
              </Grid>
            </DetailCardContainer>
          </Grid>
          <Grid item xs={12}>
            <DetailCardContainer>
              <Grid item xs={12}>
                <Text sx={{ fontSize: theme.fonts.fontSizes.size18 }}>Title</Text>
                <Spacer size="xs" position="top" />
                <FormFieldTextWithCounter
                  maxLength={50}
                  name="title"
                  placeholder="Enter Promo Code Title"
                  width="100%"
                />
              </Grid>
            </DetailCardContainer>
          </Grid>
          <Grid item xs={12}>
            <DetailCardContainer>
              <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                <Text sx={{ fontSize: theme.fonts.fontSizes.size18 }}>Description</Text>
                <Spacer size="xs" position="top" />
                <FormFieldTextMultiline
                  name="description"
                  placeholder="Enter promo code description here..."
                  multiline
                  rows={5}
                />
              </Box>
            </DetailCardContainer>
          </Grid>
          <PromoPeriodForm forCreateScreen={true} />
          <PromoDetailsForm
            forCreateScreen={true}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
            isUserSpecific={isUserSpecific}
            setIsUserSpecific={setIsUserSpecific}
            isExperienceSpecific={isExperienceSpecific}
            setIsExperienceSpecific={setIsExperienceSpecific}
          />
          <Grid item xs={12} />
          <Grid container justifyContent="flex-end">
            <SpaceBetweenBox>
              <FormSubmitButton width="125px" isLoading={false}>
                <Text type="WhiteColor">Confirm</Text>
              </FormSubmitButton>
            </SpaceBetweenBox>
          </Grid>
          <Grid item xs={12} />
        </Grid>
      </Form>
    </PaddedView>
  );
}
