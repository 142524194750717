import React from "react";
import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";

import PaddedView from "../../../../components/padded-view.component";
import Spacer from "../../../../components/spacer.component";
import FormFieldText from "../../../../components/forms/form-field-text.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import Text from "../../../../components/text.component";
import { authSelector } from "../../../../services/auth/auth-slice.services";

const CardContainer = styled(Box)(({ theme, isMobile }) => ({
  backgroundColor: theme.palette.colors.bg.white,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  flex: 1,
  maxWidth: 500,
  minWidth: isMobile ? 340 : 450,
  alignItems: "center",
  justifyContent: "center",
  overflow: "hidden",
}));

const TitleContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "80px",
  backgroundColor: theme.palette.colors.bg.primary,
  justifyContent: "center",
  alignItems: "center",
}));

function ForgotPasswordEmailForm() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { forgotPasswordObj } = useSelector(authSelector);

  return (
    <CardContainer isMobile={isMobile} sx={{ boxShadow: 2 }}>
      <TitleContainer>
        <Text variant="h5">Forgot Your Password?</Text>
      </TitleContainer>

      <PaddedView multiples={isMobile ? 3 : 4}>
        <Grid container sx={{ justifyContent: "center" }}>
          <Text>Enter your email address to receive verification code.</Text>
        </Grid>

        <Spacer size="xl" />
        <FormFieldText type="email" name="email" placeholder="Enter your email" />
        <Spacer size="xxxl" />

        <FormSubmitButton isLoading={forgotPasswordObj.status === "pending"}>
          <Typography>Confirm</Typography>
        </FormSubmitButton>
      </PaddedView>
    </CardContainer>
  );
}

export default ForgotPasswordEmailForm;
