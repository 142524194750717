import { Button, FormHelperText, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import Text from "../../../components/text.component";
import { experienceSelector } from "../../../services/experience/experience-slice.services";
import ImageCropper from "../../../components/image-cropper.component";
import Spacer from "../../../components/spacer.component";
import CenteredRowBox from "../../../components/centered-row-box.component";
import ExperienceImage from "./experience-image.component";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png", "image/webp"];

const UploadButtonContainer = styled(Button)(({ theme }) => ({
  minHeight: "200px",
  backgroundColor: "transparent",
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  border: `2px dashed ${theme.palette.colors.ui.border}`,
  color: theme.palette.colors.ui.border,
  height: "100%",
  "&:hover": {
    background: "transparent",
  },
  marginTop: "-3px",
}));

function ExperienceImageList({ name, imageEventHandle }) {
  const { getExperienceDetailObj } = useSelector(experienceSelector);
  const theme = useTheme();
  const { setFieldError, errors, setFieldValue, handleSubmit } = useFormikContext();
  const [image, setImage] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [isError, showIsError] = useState(false);
  const showError = isError && typeof errors[name] === "string";
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleImageUploaded = (imagePath) => {
    setFieldValue(name, imagePath.image);
    handleSubmit();
    setShowCropper(false);
  };

  const inputButton = () => (
    <input
      type="file"
      name={name}
      hidden
      accept="image/*"
      onClick={(event) => {
        event.target.value = null;
      }}
      onChange={(e) => {
        const { files } = e.target;
        if (files) {
          const selectedFIles = [];
          const targetFilesObject = [...files];
          targetFilesObject.map((file) => selectedFIles.push(file));
          const withinFileSizeLimit = selectedFIles.some((item) => item.size <= 5000000); // 1mb = 1^6 bytes
          const allowedFileType = selectedFIles.some((item) =>
            SUPPORTED_FORMATS.includes(item.type),
          );
          if (withinFileSizeLimit && allowedFileType) {
            showIsError(false);
            setImage(URL.createObjectURL(selectedFIles[0]));
            setShowCropper(true);
          } else if (!withinFileSizeLimit) {
            showIsError(true);
            setFieldError(name, "Image file cannot be more than 5mb");
          } else if (!allowedFileType) {
            showIsError(true);
            setFieldError(name, `Only ${SUPPORTED_FORMATS} are allowed`);
          }
        }
      }}
    />
  );

  const renderUploadButton = () => (
    <UploadButtonContainer onClick={() => setFieldError(name, "")} component="label" fullWidth>
      <AddCircleOutlineRoundedIcon />
      {inputButton()}
    </UploadButtonContainer>
  );

  return (
    <>
      <CenteredRowBox>
        <Text sx={{ fontSize: theme.fonts.fontSizes.size18 }}>Uploaded Photos</Text>
        <Spacer size="s" position="left" />
        {showError && <FormHelperText error>{errors[name]}</FormHelperText>}
      </CenteredRowBox>

      <Spacer size="s" position="top" />
      <Grid container spacing={3}>
        {getExperienceDetailObj.data.images?.length > 0 &&
          getExperienceDetailObj.data.images.map((item, index) => (
            <Grid item xs={isMobile ? 12 : 4} key={item.id}>
              <ExperienceImage image={item} index={index} imageEventHandle={imageEventHandle} />
            </Grid>
          ))}
        {getExperienceDetailObj.data.images?.length < 10 && (
          <Grid item xs={isMobile ? 12 : 4}>
            {renderUploadButton()}
          </Grid>
        )}
        <ImageCropper
          handleUploadImage={handleImageUploaded}
          setIsShowModal={setShowCropper}
          isShowModal={showCropper}
          imageFile={image}
        />
      </Grid>
    </>
  );
}

ExperienceImageList.propTypes = {
  name: PropTypes.string.isRequired,
  imageEventHandle: PropTypes.func.isRequired,
};

export default ExperienceImageList;
