import React from "react";
import {
  Box,
  Grid,
  useMediaQuery,
  useTheme,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Text from "../../../components/text.component";
import Spacer from "../../../components/spacer.component";
import DetailCardContainer from "../../../components/detail-card-container.component";
import TableHeader from "../../../components/table/table-header.component";

export default function CategoryDetailLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Text variant="h4">Edit Category</Text>
      </Grid>
      <Grid item xs={12}>
        <DetailCardContainer>
          <Box>
            <Text>Category Name</Text>
            <Spacer size="xs" position="top" />
            <Skeleton width="100%" height="56px" />
          </Box>
          <Spacer size="m" position="top" />
          <Box>
            <Text>Category Description</Text>
            <Spacer size="xs" position="top" />
            <Skeleton width="100" height="200px" />
          </Box>
        </DetailCardContainer>
      </Grid>
      <Grid item xs={12}>
        <DetailCardContainer>
          <Text>Category Images</Text>
          <Spacer size="xs" position="top" />
          <Grid container spacing={3}>
            <Grid item xs={isMobile ? 12 : 4}>
              <Skeleton width="100%" height="170px" />
            </Grid>
            <Grid item xs={isMobile ? 12 : 4}>
              <Skeleton width="100%" height="170px" />
            </Grid>
            <Grid item xs={isMobile ? 12 : 4}>
              <Skeleton width="100%" height="170px" />
            </Grid>
          </Grid>
        </DetailCardContainer>
      </Grid>
      <Grid item xs={12}>
        <DetailCardContainer>
          <Grid item xs={12}>
            <Text variant="h5">Experience List</Text>
          </Grid>
          <Table>
            <TableHeader headerCells={["ID", "Experience Name", ""]} />
            <TableBody>
              <TableRow>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Skeleton height={20} width={20} circle={true} />
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DetailCardContainer>
      </Grid>
      <Grid item xs={12}>
        <DetailCardContainer>
          <Grid item xs={12}>
            <Text variant="h5">Subcategory List</Text>
          </Grid>
          <Table>
            <TableHeader
              headerCells={["ID", "Subcategory Name", "Date Created", "Active Experience", ""]}
            />
            <TableBody>
              <TableRow>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Skeleton height={20} width={20} circle={true} />
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DetailCardContainer>
      </Grid>
      <Grid item xs={12} />
    </Grid>
  );
}
