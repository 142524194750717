import React from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";

export default function DetailCardContainer({ children }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        borderTopLeftRadius: theme.shape.borderRadius[0],
        borderTopRightRadius: theme.shape.borderRadius[0],
        borderBottomLeftRadius: theme.shape.borderRadius[0],
        borderBottomRightRadius: theme.shape.borderRadius[0],
        width: "100%",
        backgroundColor: theme.palette.colors.bg.white,
        boxShadow: `1px 2px 5px ${theme.palette.colors.loading.foregroundColor}`,
        paddingTop: "25px",
        paddingBottom: "25px",
        paddingLeft: isMobile ? "15px" : "25px",
        paddingRight: isMobile ? "15px" : "25px",
      }}
    >
      {children}
    </Box>
  );
}

DetailCardContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
