import React from "react";
import PropTypes from "prop-types";
import { TableCell, IconButton, TableRow, Box } from "@mui/material";
import { Link } from "react-router-dom";
import Text from "../../../components/text.component";
import EditIcon from "../../../assets/images/edit-icon";
import routes from "../../../navigation/routes";
import DeleteIcon from "../../../assets/images/delete-icon";

function TagRow({ record, onToggleDelete }) {
  return (
    <TableRow
      key={record.id}
      sx={{
        "& .MuiTableCell-root": {
          borderBottom: "no-set",
        },
      }}
    >
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center" }}>
          {record.id}
        </Text>
      </TableCell>
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center", textTransform: "capitalize" }}>
          {record.label}
        </Text>
      </TableCell>
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center", minWidth: "100px" }}>
          {record.createdAt}
        </Text>
      </TableCell>
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center" }}>
          {record.experiences}
        </Text>
      </TableCell>

      <TableCell sx={{ width: "150px" }}>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <Link to={{ pathname: `${routes.TAG_EDIT}`, search: `tagId=${record.id}` }}>
            <IconButton>
              <EditIcon />
            </IconButton>
          </Link>

          <IconButton onClick={() => onToggleDelete(record.id)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
}

TagRow.defaultProps = {
  record: {
    id: null,
    label: "",
    createdAt: "",
    experiences: null,
  },
};

TagRow.propTypes = {
  onToggleDelete: PropTypes.func.isRequired,
  record: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
    createdAt: PropTypes.string,
    experiences: PropTypes.number,
  }),
};

export default TagRow;
