import { setAxiosToken } from "../../services/api-services";

export const isLogin = () => {
  const storedAuthToken = localStorage.getItem("yoloAdminAuthToken");
  if (storedAuthToken) {
    setAxiosToken(storedAuthToken);
    return true;
  }
  return false;
};
