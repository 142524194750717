import React from "react";
import { Grid, styled, Box, useTheme, useMediaQuery } from "@mui/material";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Text from "../../../../components/text.component";
import CtaButton from "../../../../components/buttons/cta-button.component";
import Spacer from "../../../../components/spacer.component";
import DetailCardContainer from "../../../../components/detail-card-container.component";

const FlexEndButtonContainer = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  height: "100%",
  alignItems: "flex-end",
});
function FestivalSectionDetailLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Text variant="h4">Edit Festival Section</Text>
      </Grid>
      <Grid item xs={12}>
        <DetailCardContainer>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", flexDirection: isMobile ? "column" : "row" }}>
                <Box>
                  <Skeleton width="100px" height="100px" />
                </Box>
                <Spacer size="l" position="left" />
                <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                  <Text>Title</Text>
                  <Spacer size="xs" position="top" />
                  <Skeleton height="56px" width="100%" />
                </Box>
              </Box>
            </Grid>

            <Grid item xs={isMobile ? 12 : 4.5}>
              <Text>Start from</Text>
              <Spacer size="xs" position="top" />
              <Skeleton height="56px" width="100%" />
            </Grid>
            <Grid item xs={isMobile ? 12 : 4.5}>
              <Text>End at</Text>
              <Spacer size="xs" position="top" />
              <Skeleton height="56px" width="100%" />
            </Grid>
            <Grid item xs={isMobile ? 12 : 3}>
              <FlexEndButtonContainer>
                <CtaButton width="125px" isLoading={true}>
                  <Text type="WhiteColor">Save</Text>
                </CtaButton>
              </FlexEndButtonContainer>
            </Grid>
          </Grid>
        </DetailCardContainer>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Text>Featured Groups</Text>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={isMobile ? 12 : 3}>
                <Skeleton width="100%" height="200px" />
              </Grid>
              <Grid item xs={isMobile ? 12 : 3}>
                <Skeleton width="100%" height="200px" />
              </Grid>
              <Grid item xs={isMobile ? 12 : 3}>
                <Skeleton width="100%" height="200px" />
              </Grid>
              <Grid item xs={isMobile ? 12 : 3}>
                <Skeleton width="100%" height="200px" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default FestivalSectionDetailLoader;
