import React from "react";
import { Box, FormLabel, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { authSelector } from "../../../../services/auth/auth-slice.services";
import Text from "../../../../components/text.component";
import PaddedView from "../../../../components/padded-view.component";
import FormFieldText from "../../../../components/forms/form-field-text.component";
import Spacer from "../../../../components/spacer.component";
import FormMiniCheckBox from "../../../../components/forms/form-mini-check-box.component";
import routes from "../../../../navigation/routes";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";

const CardContainer = styled(Box)(({ theme, isMobile }) => ({
  backgroundColor: theme.palette.colors.bg.white,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  flex: 1,
  maxWidth: 500,
  minWidth: isMobile ? 340 : 450,
  alignItems: "center",
  justifyContent: "center",
  overflow: "hidden",
}));

const TitleContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "80px",
  backgroundColor: theme.palette.colors.bg.primary,
  justifyContent: "center",
  alignItems: "center",
}));

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  ":visited": { textDecoration: "underline" },
  ":hover": {
    color: theme.palette.colors.brand.primary,
    textDecoration: "underline",
  },
}));

const SpaceBetweenGrid = styled(Grid)({
  paddingTop: "5px",
  justifyContent: "space-between",
  alignItems: "center",
});

function LoginForm() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { loginObj } = useSelector(authSelector);

  return (
    <CardContainer isMobile={isMobile} sx={{ boxShadow: 2 }}>
      <TitleContainer>
        <Text variant="h5">Login</Text>
      </TitleContainer>

      <PaddedView multiples={isMobile ? 3 : 4}>
        <FormLabel>Email</FormLabel>
        <Spacer size="s" />
        <FormFieldText name="email" placeholder="Enter Your Email Address" />

        <Spacer size="m" />
        <FormLabel>Password</FormLabel>
        <Spacer size="s" />
        <FormFieldText name="password" placeholder="Enter Your Password" />

        <SpaceBetweenGrid container>
          <FormMiniCheckBox name="isRemember" label="Remember me" />
          <StyledLink to={{ pathname: routes.FORGOTPASSWORD }}>
            <Text type="BrandColor" variant="body2">
              Forgot Password ?
            </Text>
          </StyledLink>
        </SpaceBetweenGrid>

        <Spacer size="xxl" />

        <FormSubmitButton isLoading={loginObj.status === "pending"}>
          <Typography>Continue</Typography>
        </FormSubmitButton>
      </PaddedView>
    </CardContainer>
  );
}

export default LoginForm;
