import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import Text from "../../../components/text.component";
import {
    corporateSelector,
    updateCorporate,
} from "../../../services/corporate/corporate-slice.services";
import CorporateDetailsFormFieldText from "./corporate-details-form-field-text.component";

const FlexEndButtonContainer = styled(Box)({
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
});

const corporateDetailsValidationSchema = Yup.object().shape({
    email: Yup.string().email().required().label("Email Address"),
    phone: Yup.string().required().label("Contact Number"),
    firstName: Yup.string().required().min(2).label("First Name"),
    lastName: Yup.string().required().min(2).label("Last Name"),
    companyName: Yup.string().required().min(2).label("Company Name"),
    website: Yup.string().nullable().label("Website"),
});

function CorporateDetails() {
    const theme = useTheme();
    const location = useLocation();
    const corporateId = new URLSearchParams(location.search).get("corporateId");
    const [isContactEditing, setIsContactEditing] = useState(false);
    const [isEmailEditing, setIsEmailEditing] = useState(false);
    const [isFirstNameEditing, setIsFirstNameEditing] = useState(false);
    const [isLastNameEditing, setIsLastNameEditing] = useState(false);
    const [isCompanyNameEditing, setIsCompanyNameEditing] = useState(false);
    const [isWebsiteEditing, setIsWebsiteEditing] = useState(false);
    const { getCorporateDetailsObj, updateCorporateObj } = useSelector(corporateSelector);
    const dispatch = useDispatch();
    const createSnackBar = useContext(SnackbarContext);
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const resetState = () => {
        setIsContactEditing(false);
        setIsEmailEditing(false);
        setIsFirstNameEditing(false);
        setIsLastNameEditing(false);
        setIsCompanyNameEditing(false);
        setIsWebsiteEditing(false);
    };

    const onUpdateCorporateDetails = (values) => {
        console.log("update details");
        dispatch(updateCorporate({ corporateId, type: "details", ...values })).then(
            ({ meta, error, payload }) => {
                if (meta.requestStatus === "fulfilled") {
                    resetState();
                    createSnackBar({
                        message: payload.message,
                        type: "success",
                        open: true,
                    });
                }
                if (meta.requestStatus === "rejected") {
                    createSnackBar({
                        message: error.message,
                        type: "error",
                        open: true,
                    });
                }
            },
        );
    };

    return (
        <Form
            validationSchema={corporateDetailsValidationSchema}
            onSubmit={onUpdateCorporateDetails}
            initialValues={{
                id: getCorporateDetailsObj.data.corporate.id,
                createdAt: getCorporateDetailsObj.data.corporate.createdAt,
                email: getCorporateDetailsObj.data.corporate.email,
                firstName: getCorporateDetailsObj.data.corporate.firstName,
                lastName: getCorporateDetailsObj.data.corporate.lastName,
                companyName: getCorporateDetailsObj.data.corporate.companyName,
                phone: getCorporateDetailsObj.data.corporate.phone,
                website: getCorporateDetailsObj.data.corporate.website,
            }}
        >
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={isMobile ? 12 : 3}>
                            <CorporateDetailsFormFieldText name="id" disabled label="Corporate ID" />
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 3}>
                            <CorporateDetailsFormFieldText
                                name="email"
                                label="Email Address"
                                isShowIcon
                                editing={isEmailEditing}
                                setEditing={setIsEmailEditing}
                            />
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 3}>
                            <CorporateDetailsFormFieldText
                                name="phone"
                                label="Contact Number"
                                isShowIcon
                                editing={isContactEditing}
                                setEditing={setIsContactEditing}
                            />
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 3}>
                            <CorporateDetailsFormFieldText name="createdAt" disabled label="Date Registered" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={isMobile ? 12 : 3}>
                            <CorporateDetailsFormFieldText
                                name="firstName"
                                label="First Name"
                                isShowIcon
                                editing={isFirstNameEditing}
                                setEditing={setIsFirstNameEditing}
                            />
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 3}>
                            <CorporateDetailsFormFieldText
                                name="lastName"
                                label="Last Name"
                                isShowIcon
                                editing={isLastNameEditing}
                                setEditing={setIsLastNameEditing}
                            />
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 3}>
                            <CorporateDetailsFormFieldText
                                name="companyName"
                                label="Company Name"
                                isShowIcon
                                editing={isCompanyNameEditing}
                                setEditing={setIsCompanyNameEditing}
                            />
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 3}>
                            <CorporateDetailsFormFieldText
                                name="website"
                                label="Website"
                                isShowIcon
                                editing={isWebsiteEditing}
                                setEditing={setIsWebsiteEditing}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <FlexEndButtonContainer sx={{ marginTop: "-30px" }}>
                        <FormSubmitButton width="125px" isLoading={updateCorporateObj.status === "pending"}>
                            <Text type="WhiteColor">Save</Text>
                        </FormSubmitButton>
                    </FlexEndButtonContainer>
                </Grid>
            </Grid>
        </Form>
    );
}

export default CorporateDetails;
