import {
    Box,
    Grid,
    Pagination,
    styled,
    Table,
    TableBody,
    TableCell,
    TableRow,
    useTheme
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import Form from "../../../components/forms/form.component";
import Loading from "../../../components/notifications/loading.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import TableHeader from "../../../components/table/table-header.component";
import Text from "../../../components/text.component";
import {
    corporateSelector,
    getCorporatePointTransferLogs,
} from "../../../services/corporate/corporate-slice.services";
import SearchBar from "../../statement/components/search-bar.component";
import CorporatePointsTransferRow from "./corporate-points-transfer-logs-row.componentt";

const CustomFooter = styled(Box)({
    display: "flex",
    justifyContent: "center",
    width: "100%",
    minHeight: "70px",
    alignItems: "center",
});

const validationSchema = Yup.object().shape({
    q: Yup.number().nullable().label("UniqueLinks ID"),
});

export default function CorporatePointTransferLogs() {
    const dispatch = useDispatch();
    const location = useLocation();
    const formRef = useRef();
    const corporateId = new URLSearchParams(location.search).get("corporateId");
    const createSnackBar = useContext(SnackbarContext);
    const { getCorporatePointTransferLogsObj } = useSelector(corporateSelector);
    const theme = useTheme();
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [page, setPage] = useState(1);

    useEffect(() => {
        dispatch(getCorporatePointTransferLogs({ corporateId, q: searchKeyword, page })).then(
            ({ meta, error }) => {
                if (meta.requestStatus === "rejected") {
                    createSnackBar({
                        message: error.message,
                        type: "error",
                    });
                }
            },
        );
    }, []);

    const onSearchPointTransferLogs = (values) => {
        setPage(1);
        dispatch(
            getCorporatePointTransferLogs({
                ...values,
                corporateId,
                page: 1,
            }),
        ).then(({ meta, error }) => {
            if (meta.requestStatus === "rejected") {
                createSnackBar({
                    message: error.message,
                    type: "error",
                });
            }
        });
    };

    const resetSearch = () => {
        dispatch(getCorporatePointTransferLogs({ corporateId, q: "", page:1 })).then(
            ({ meta, error }) => {
                if (meta.requestStatus === "rejected") {
                    createSnackBar({
                        message: error.message,
                        type: "error",
                    });
                }
            },
        );
        setSearchKeyword("");
        setPage(1);
    };

    const onPageChange = (e, newPage) => {
        dispatch(
            getCorporatePointTransferLogs({ ...formRef.current.values, corporateId, page: newPage }),
        ).then(({ meta, error }) => {
            if (meta.requestStatus === "rejected") {
                createSnackBar({
                    message: error.message,
                    type: "error",
                    open: true,
                });
            }
        });
        setPage(newPage);
    };


    const renderContent = () => {
        if (getCorporatePointTransferLogsObj.status === "succeeded") {
            return (
                <>
                    <Table>
                        <TableHeader headerCells={["ID", "Amount", "Reason", "Transfered By", "Role", ""]} />
                        <TableBody>
                            {getCorporatePointTransferLogsObj.data.items.map((item) => (
                                <CorporatePointsTransferRow record={item} key={item.id} />
                            ))}
                        </TableBody>
                    </Table>
                    <CustomFooter>
                        <Pagination
                            sx={{
                                "&& .Mui-selected": {
                                    backgroundColor: theme.palette.colors.brand.primary,
                                    color: theme.palette.colors.text.white,
                                },
                            }}
                            page={page}
                            onChange={onPageChange}
                            count={getCorporatePointTransferLogsObj.data.pagination.totalPages}
                            variant="outlined"
                        />
                    </CustomFooter>
                </>
            );
        }
        return (
            <Table>
                <TableHeader headerCells={["ID", "Amount", "Reason", "Transfered By", "Role", ""]} />
                <TableBody>
                    <TableRow
                        sx={{
                            "& .MuiTableCell-root": {
                                borderBottom: "no-set",
                            },
                        }}
                    >
                        <TableCell>
                            <Text variant="body2" sx={{ textAlign: "center" }}>
                                <Skeleton />
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text variant="body2" sx={{ textAlign: "center" }}>
                                <Skeleton />
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text variant="body2" sx={{ textAlign: "center" }}>
                                <Skeleton />
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text variant="body2" sx={{ textAlign: "center" }}>
                                <Skeleton />
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Grid container spacing={1} sx={{ justifyContent: "center" }}>
                                <Grid item>
                                    <Skeleton height={20} width={20} circle={true} />
                                </Grid>
                            </Grid>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    };

    return (
        <Grid item xs={12}>
            <Grid container>
                <Grid item xs={6}>
                    <Text variant="h5">Points Transfer Logs</Text>
                </Grid>
                <Loading isLoading={isLoading} />
                <Grid item xs={1} />
                <Grid item xs={3}>
                    <Form
                        innerRef={formRef}
                        onSubmit={onSearchPointTransferLogs}
                        validationSchema={validationSchema}
                        initialValues={{
                            q: "",
                        }}
                    >
                        <SearchBar name="q" placeholder="Point Transfer Log ID" width="100%" />
                    </Form>
                </Grid>
            </Grid>
            {renderContent()}
        </Grid>
    );
}
