import ModeEditIcon from "@mui/icons-material/ModeEdit";
import {
    Box,
    FormControl,
    FormHelperText,
    IconButton,
    MenuItem,
    Select,
    styled,
    TextField,
    useTheme,
} from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";

const CenterRowBox = styled(Box)({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
});

const StyledEditButton = styled(IconButton)({
    padding: "2px",
});

const ColumnBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    paddingLeft: "10px",
    paddingRight: "10px",
    "&& .form-control.invalid-number": {
        boxShadow: "none",
    },
    " .form-control:focus": {
        boxShadow: "none",
    },
});

const BANKLIST = [
    { label: "Affin Bank", value: "Affin Bank" },
    { label: "Agro Bank", value: "Agro Bank" },
    { label: "Al-Rajhi Malaysia", value: "Al-Rajhi Malaysia" },
    { label: "Alliance Bank Malaysia Berhad", value: "Alliance Bank Malaysia Berhad" },
    { label: "AmBank", value: "AmBank" },
    { label: "Bank Islam Malaysia", value: "Bank Islam Malaysia" },
    { label: "Bank Muamalat Malaysia Berhad", value: "Bank Muamalat Malaysia Berhad" },
    { label: "Bank Rakyat", value: "Bank Rakyat" },
    { label: "Bank Simpanan Nasional (BSN)", value: "Bank Simpanan Nasional (BSN)" },
    { label: "CIMB Bank", value: "CIMB Bank" },
    { label: "Citibank", value: "Citibank" },
    { label: "Co-op Bank Pertama", value: "Co-op Bank Pertama" },
    { label: "Hong Leong Bank", value: "Hong Leong Bank" },
    { label: "HSBC Bank Malaysia", value: "HSBC Bank Malaysia" },
    { label: "Maybank", value: "Maybank" },
    { label: "MBSB Bank", value: "MBSB Bank" },
    { label: "OCBC Bank", value: "OCBC Bank" },
    { label: "Public Bank", value: "Public Bank" },
    { label: "RHB Bank", value: "RHB Bank" },
    { label: "Standard Chartered Bank", value: "Standard Chartered Bank" },
    { label: "UOB Malaysia", value: "UOB Malaysia" },
];

function CreatorDetailsFormFieldText({ name, disabled, label, isShowIcon, editing, setEditing }) {
    const { setFieldValue, values, touched, errors, handleSubmit } = useFormikContext();
    const showError = touched[name] && typeof errors[name] === "string";
    const theme = useTheme();
    const [isPhoneFocus, setIsPhoneFocus] = useState(false);

    const keyPress = (e) => {
        if (e.keyCode === 13 && editing) {
            handleSubmit();
        }
    };

    const PhoneBottomBorderStyle = () => {
        if (showError) {
            return `2px solid ${theme.palette.colors.text.error}`;
        }
        if (isPhoneFocus) {
            return `2px solid ${theme.palette.colors.text.defaultFocusedBorder}`;
        }
        return `1px solid ${theme.palette.colors.text.defaultFieldBorder}`;
    };

    const handleChange = (event) => {
        setFieldValue(name, event.target.value);
    };

    if (name === "bankName" && editing) {
        return (
            <ColumnBox>
                <CenterRowBox>
                    <Text>{label}</Text>
                    <Spacer size="s" position="left" />
                    {isShowIcon && (
                        <StyledEditButton onClick={() => setEditing(true)}>
                            <ModeEditIcon
                                sx={{
                                    fontSize: theme.fonts.fontSizes.size18,
                                    color: editing
                                        ? theme.palette.colors.text.placeholder
                                        : theme.palette.colors.brand.secondary,
                                }}
                            />
                        </StyledEditButton>
                    )}
                </CenterRowBox>
                <FormControl variant="standard">
                    <Select
                        value={values[name] === null ? "" : values[name]}
                        onChange={handleChange}
                        sx={{
                            color: theme.palette.colors.text.darkGrey,
                            fontSize: theme.fonts.fontSizes.size14,
                        }}
                    >
                        {BANKLIST.map((item) => (
                            <MenuItem value={item.value} key={item.value}>
                                {item.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </ColumnBox>
        );
    }

    if (name === "contactNo" && editing) {
        return (
            <ColumnBox>
                <CenterRowBox>
                    <Text>{label}</Text>
                    <Spacer size="s" position="left" />
                    {isShowIcon && (
                        <StyledEditButton onClick={() => setEditing(true)}>
                            <ModeEditIcon
                                sx={{
                                    fontSize: theme.fonts.fontSizes.size18,
                                    color: editing
                                        ? theme.palette.colors.text.placeholder
                                        : theme.palette.colors.brand.secondary,
                                }}
                            />
                        </StyledEditButton>
                    )}
                </CenterRowBox>
                <PhoneInput
                    onKeyDown={keyPress}
                    containerStyle={{
                        borderBottom: PhoneBottomBorderStyle(),
                    }}
                    onFocus={() => setIsPhoneFocus(true)}
                    onBlur={() => setIsPhoneFocus(false)}
                    buttonStyle={{ marginLeft: "-8px" }}
                    specialLabel=""
                    value={values[name] === null ? "" : values[name]}
                    isValid={() => !showError}
                    country="my"
                    onChange={(e) => setFieldValue(name, e)}
                    inputStyle={{
                        width: "100%",
                        borderRadius: theme.shape.borderRadius[0],
                        border: "0px",
                        padding: "6px 0px 5px 50px",
                        marginLeft: "-5px",
                        fontSize: theme.fonts.fontSizes.size14,
                        color: theme.palette.colors.text.darkGrey,
                    }}
                />
                {showError && (
                    <FormHelperText
                        sx={{
                            color: theme.palette.colors.text.error,
                            margin: "3px 0px 0px 1px",
                        }}
                    >
                        {errors[name]}
                    </FormHelperText>
                )}
            </ColumnBox>
        );
    }

    return (
        <ColumnBox>
            <CenterRowBox>
                <Text>{label}</Text>
                <Spacer size="s" position="left" />
                {isShowIcon && (
                    <StyledEditButton onClick={() => setEditing(true)}>
                        <ModeEditIcon
                            sx={{
                                fontSize: theme.fonts.fontSizes.size18,
                                color: editing
                                    ? theme.palette.colors.text.placeholder
                                    : theme.palette.colors.brand.secondary,
                            }}
                        />
                    </StyledEditButton>
                )}
            </CenterRowBox>
            <TextField
                onKeyDown={keyPress}
                disabled={disabled || !editing}
                onChange={(e) => {
                    setFieldValue(name, e.target.value);
                }}
                variant="standard"
                value={values[name] === null ? "" : values[name]}
                error={showError}
                helperText={showError ? errors[name] : null}
                sx={{
                    width: "100%",
                    "&& .Mui-disabled": {
                        WebkitTextFillColor: theme.palette.colors.text.darkGrey,
                    },
                }}
                InputProps={{
                    disableUnderline: !editing,
                    style: {
                        fontSize: "14px",
                        color: theme.palette.colors.text.darkGrey,
                    },
                }}
            />
        </ColumnBox>
    );
}

CreatorDetailsFormFieldText.defaultProps = {
    disabled: false,
    isShowIcon: false,
    editing: false,
    setEditing: null,
};

CreatorDetailsFormFieldText.propTypes = {
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isShowIcon: PropTypes.bool,
    editing: PropTypes.bool,
    setEditing: PropTypes.func,
};

export default CreatorDetailsFormFieldText;
