import { IconButton, styled, TableCell, TableRow } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Text from "../../../components/text.component";

function CorporatePointsTransferRow({ record }) {

    const CloseIconButton = styled(IconButton)(({ theme }) => ({
        height: "25px",
        width: "25px",
        color: theme.palette.colors.brand.secondary,
    }));

    const popupStyles = {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '20px',
        backgroundColor: 'white',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '5px',
        zIndex: 1000
    };

    const [showPopup, setShowPopup] = useState(false);

    const closePopup = () => {
        setShowPopup(false);
    };

    return (
        <TableRow
            key={record.id}
            sx={{
                "& .MuiTableCell-root": {
                    borderBottom: "no-set",
                },
            }}
        >
            <TableCell>
                <Text variant="body2" sx={{ textAlign: "center" }}>
                    {record.id}
                </Text>
            </TableCell>
            <TableCell>
                <Text variant="body2" sx={{ textAlign: "center" }}>
                    {record.amount}
                </Text>
            </TableCell>
            <TableCell>
                <Text variant="body2" sx={{ textAlign: "center" }}>
                    {record.reason}
                </Text>
            </TableCell>
            <TableCell>
                <Text variant="body2" sx={{ textAlign: "center" }}>
                    {record.addedBy}
                </Text>
            </TableCell>
            <TableCell>
                <Text variant="body2" sx={{ textAlign: "center" }}>
                    {record.userRole}
                </Text>
            </TableCell>
            <TableCell>
                {/* <Link to={{ pathname: `${routes.UNIQUE_LINK_DETAIL}`, search: `amountId=${record.id}` }}>
                    <IconButton>
                        <EditIcon />
                    </IconButton>
                </Link> */}
                {/* <Link to={{ pathname: `${routes.UNIQUE_LINK_DETAIL}`, search: `amountId=${record.amount}` }}> */}
            </TableCell>
        </TableRow>
    );
}

CorporatePointsTransferRow.defaultProps = {
    record: {
        id: null,
        amount: null,
        reason: "",
        addedBy: "",
        userRole: "",
    },
};

CorporatePointsTransferRow.propTypes = {
    record: PropTypes.shape({
        id: PropTypes.number,
        amount: PropTypes.number,
        reason: PropTypes.string,
        addedBy: PropTypes.string,
        userRole: PropTypes.string,
    }),
};

export default CorporatePointsTransferRow;
