import { Box, Link, styled, useMediaQuery, useTheme, Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import BorderButton from "../../../components/buttons/border-button.component";
import CtaButton from "../../../components/buttons/cta-button.component";
import RedBorderButton from "../../../components/buttons/red-border-button.component";
import Form from "../../../components/forms/form.component";
import Loading from "../../../components/notifications/loading.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import PaddedView from "../../../components/padded-view.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import {
  getStatementDetail,
  updateStatement,
  statementSelector,
} from "../../../services/statement/statement-slice.services";
import ClosePendingConfirmationModal from "../components/close-pending-confirmation-modal.component";
import DisputeLog from "../components/dispute-log.component";
import RejectReasonModal from "../components/reject-reason-modal.component";
import RejectStatementModal from "../components/reject-statement-modal.component";
import StatementDetails from "../components/statement-details.component";
import TransactionTable from "../components/transaction-table.component";
import StatementDetailLoader from "../loader/statement-detail-loader.component";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
});

const RowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const validationSchema = Yup.object().shape({
  status: Yup.string().required(),
  reason: Yup.string().required().label("Reason"),
});

function StatementDetailScreen() {
  const location = useLocation();
  const statementId = new URLSearchParams(location.search).get("statementId");
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const [showRejectStatementModal, setShowRejectStatementModal] = useState(false);
  const [showRejectReasonModal, setShowRejectReasonModal] = useState(false);
  const [showClosePendingStatementModal, setShowClosePendingStatementModal] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const { getStatementDetailObj, updateStatementObj } = useSelector(statementSelector);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [closedOrPendingModal, setClosedOrPendingModal] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getStatementDetail(statementId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  const onRejectStatement = (values, { resetForm }) => {
    setIsLoading(true);
    dispatch(updateStatement({ statementId, ...values })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        resetForm();
        setShowRejectStatementModal(false);
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const onCloseOrPendingStatement = (status) => {
    setIsLoading(true);
    dispatch(updateStatement({ statementId, status })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setShowClosePendingStatementModal(false);
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const renderStatus = (status) => {
    if (status === "dispute") {
      return (
        <Text variant="h6" type="RejectText">
          {status.toUpperCase()}
        </Text>
      );
    }
    if (status === "open") {
      return (
        <Text variant="h6" type="GreenColor">
          {status.toUpperCase()}
        </Text>
      );
    }
    if (status === "pending") {
      return (
        <Text variant="h6" type="BlueColor">
          {status.toUpperCase()}
        </Text>
      );
    }
    return (
      <Text variant="h6" type="BrandColor">
        {status.toUpperCase()}
      </Text>
    );
  };

  const renderDisputeAndCloseButton = () => (
    <RowBox>
      {getStatementDetailObj.data.status === "pending" && (
        <RedBorderButton width="125px" onClickButton={() => setShowRejectStatementModal(true)}>
          <Text type="RejectText">Reject</Text>
        </RedBorderButton>
      )}

      {getStatementDetailObj.data.status === "dispute" && (
        <BorderButton
          width="125px"
          onClickButton={() => {
            setShowClosePendingStatementModal(true);
            setClosedOrPendingModal("pending");
          }}
        >
          <Text>Pending</Text>
        </BorderButton>
      )}

      <Spacer size="s" position="left" />
      {getStatementDetailObj.data.status === "pending" && (
        <CtaButton
          width="125px"
          onClickButton={() => {
            setShowClosePendingStatementModal(true);
            setClosedOrPendingModal("closed");
          }}
        >
          <Text type="WhiteColor">Close</Text>
        </CtaButton>
      )}
    </RowBox>
  );

  const renderContent = () => {
    if (getStatementDetailObj.status === "succeeded") {
      const statement = getStatementDetailObj.data;
      return (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SpaceBetweenBox>
              <Box>
                <Text variant="h4">Statement ID: {statement.id}</Text>
                {renderStatus(statement.status)}
              </Box>
              {!isMobile && renderDisputeAndCloseButton()}
            </SpaceBetweenBox>
          </Grid>
          {isMobile && (
            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                {renderDisputeAndCloseButton()}
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <StatementDetails />
          </Grid>
          {statement.disputeLog.length > 0 && (
            <Grid item xs={12}>
              <RejectReasonModal
                showModal={showRejectReasonModal}
                setShowModal={setShowRejectReasonModal}
                reason={rejectReason}
              />
              <DisputeLog
                setShowModal={setShowRejectReasonModal}
                setRejectReason={setRejectReason}
                setShowRejectStatementModal={setShowRejectStatementModal}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <TransactionTable />
          </Grid>
        </Grid>
      );
    }
    return <StatementDetailLoader />;
  };

  return (
    <PaddedView multiples={isMobile ? 1 : 3}>
      <Loading isLoading={isLoading} />
      <Form
        initialValues={{ status: "dispute", reason: "" }}
        onSubmit={onRejectStatement}
        validationSchema={validationSchema}
      >
        <RejectStatementModal
          showModal={showRejectStatementModal}
          setShowModal={setShowRejectStatementModal}
        />
      </Form>
      <ClosePendingConfirmationModal
        showModal={showClosePendingStatementModal}
        setShowModal={setShowClosePendingStatementModal}
        isLoading={updateStatementObj.status === "pending"}
        onConfirmClicked={() => onCloseOrPendingStatement(closedOrPendingModal)}
        type={closedOrPendingModal}
      />
      <Link href={routes.STATEMENT_LIST} style={{ textDecoration: "none" }}>
        <Text type="GreyColor">{`< Back`}</Text>
      </Link>
      <Spacer size="xl" position="top" />
      {renderContent()}
    </PaddedView>
  );
}

export default StatementDetailScreen;
