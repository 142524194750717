import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Grid, IconButton, Link, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import * as Yup from "yup";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import PaddedView from "../../../components/padded-view.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import Form from "../../../components/forms/form.component";
import Loading from "../../../components/notifications/loading.component";
import {
  bookingSelector,
  cancelBooking,
  confirmBooking,
  getBookingDetail,
  updateBooking,
} from "../../../services/booking/booking.slice.services";
import CenteredRowBox from "../../../components/centered-row-box.component";
import BookingRecipientDetailField from "../components/booking-recipient-detail-field.component";
import BookingDetailDisabledField from "../components/booking-detail-disabled-field.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import BorderButton from "../../../components/buttons/border-button.component";
import BookingDetailLoader from "../loader/booking-detail-loader.component";
import RedBorderButton from "../../../components/buttons/red-border-button.component";
import CtaButton from "../../../components/buttons/cta-button.component";
import CancelBookingReasonModal from "../components/cancel-booking-reason-modal.component";
import EditReminderModal from "../components/edit-reminder-modal.component";
import BookingDatePicker from "../components/booking-date-picker.component";
import BookingSlotList from "../components/booking-slot-list.component";

const cancelBookingValidationSchema = Yup.object().shape({
  cancelReason: Yup.string().required().label("Reason"),
});

const validationSchema = Yup.object().shape({
  recipient: Yup.object().shape({
    lastName: Yup.string().label("Last Name").required(),
    firstName: Yup.string().label("First Name").required(),
    contactNo: Yup.string()
      .when({
        is: (value) => value?.length,
        then: (rule) => rule.min(10),
      })
      .required()
      .label("Phone Number"),
  }),
  type: Yup.string(),
  date: Yup.date().label("Date").nullable().when("type", {
    is: "multiple",
    then: Yup.date().required(),
  }),
  slotId: Yup.number().label("Slot").nullable().when("type", {
    is: "multiple",
    then: Yup.number().required(),
  }),
});

function BookingDetailScreen() {
  const location = useLocation();
  const bookingId = new URLSearchParams(location.search).get("bookingId");
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { getBookingDetailObj } = useSelector(bookingSelector);
  const [isEditing, setIsEditing] = useState(false);
  const formRef = useRef();
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showEditReminder, setShowEditReminder] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getBookingDetail(bookingId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  }, []);

  const onConfirmBooking = () => {
    setIsLoading(true);
    dispatch(confirmBooking(bookingId)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onCancelBooking = (value) => {
    setIsLoading(true);
    dispatch(cancelBooking(value)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setShowRejectModal(false);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onConfirmEdit = () => {
    setShowEditReminder(false);
    setIsLoading(true);
    let startAt = null;
    let endAt = null;
    if (formRef.current.values.startDate !== null) {
      startAt = `${format(new Date(formRef.current.values.startDate), "yyyy-MM-dd")} ${format(
        new Date(formRef.current.values.startTime),
        "HH:mm:ss",
      )}`;
      endAt = `${format(new Date(formRef.current.values.endDate), "yyyy-MM-dd")} ${format(
        new Date(formRef.current.values.endTime),
        "HH:mm:ss",
      )}`;
    }

    dispatch(updateBooking({ ...formRef.current.values, startAt, endAt })).then(
      ({ meta, error, payload }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          setIsEditing(false);
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      },
    );
  };

  const updateBookingDetail = (value) => {
    // if (
    //   getBookingDetailObj.data.type === "multiple" &&
    //   startAt !== null &&
    //   endAt !== null &&
    //   getBookingDetailObj.data.startAt !== null &&
    //   getBookingDetailObj.data.endAt !== null &&
    //   (new Date(startAt).getTime() !== new Date(getBookingDetailObj.data.startAt).getTime() ||
    //     new Date(endAt).getTime() !== new Date(getBookingDetailObj.data.endAt).getTime())
    // ) {
    //   setShowEditReminder(true);
    //   return;
    // }
    setIsLoading(true);
    dispatch(updateBooking({ ...value })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setIsEditing(false);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const renderStatusButton = () => {
    if (getBookingDetailObj.data.status === "pending") {
      return (
        <CenteredRowBox>
          {getBookingDetailObj.data.cancellable && (
            <RedBorderButton width="100px" onClickButton={() => setShowRejectModal(true)}>
              <Text type="RejectText">Cancel</Text>
            </RedBorderButton>
          )}
          <Spacer size="s" position="left" />
          <CtaButton onClickButton={onConfirmBooking} width="100px">
            Confirm
          </CtaButton>
        </CenteredRowBox>
      );
    }
    if (getBookingDetailObj.data.status === "confirmed" && getBookingDetailObj.data.cancellable) {
      return (
        <CenteredRowBox>
          <RedBorderButton width="100px" onClickButton={() => setShowRejectModal(true)}>
            <Text type="RejectText">Cancel</Text>
          </RedBorderButton>
        </CenteredRowBox>
      );
    }
    return null;
  };

  const renderDetail = () => (
    <Form
      innerRef={formRef}
      validationSchema={validationSchema}
      onSubmit={updateBookingDetail}
      initialValues={{
        type: getBookingDetailObj.data.type,
        recipient: {
          firstName: getBookingDetailObj.data.recipient.firstName,
          lastName: getBookingDetailObj.data.recipient.lastName,
          contactNo: getBookingDetailObj.data.recipient.contactNo,
        },
        bookingId,
        date: getBookingDetailObj.data.startAt
          ? format(new Date(getBookingDetailObj.data.startAt), "yyyy-MM-dd 00:00:00")
          : "",
        slotList:
          (getBookingDetailObj.data.availableSlots &&
            getBookingDetailObj.data.availableSlots[
              format(new Date(getBookingDetailObj.data.startAt), "yyyy-MM-dd")
            ]) ||
          [],
        slotId: getBookingDetailObj.data.slotId || "",
      }}
    >
      <Grid container spacing={3}>
        <EditReminderModal
          showModal={showEditReminder}
          setShowModal={setShowEditReminder}
          onConfirmClicked={onConfirmEdit}
        />
        <Grid item xs={12}>
          <Box sx={{ justifyContent: "space-between", display: "flex" }}>
            <CenteredRowBox>
              <Text variant="h4">Booking Details</Text>
              <IconButton
                sx={{ color: theme.palette.colors.text.primary }}
                disabled={isEditing}
                onClick={() => setIsEditing(true)}
              >
                <ModeEditIcon />
              </IconButton>
            </CenteredRowBox>
            {!isMobile && renderStatusButton()}
          </Box>
          {isMobile && (
            <>
              <Spacer size="s" position="top" />
              {renderStatusButton()}
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <Text>First Name</Text>
          <BookingRecipientDetailField
            name="firstName"
            placeholder="First Name"
            disabled={!isEditing}
          />
        </Grid>
        <Grid item xs={12}>
          <Text>Last Name</Text>
          <BookingRecipientDetailField
            name="lastName"
            placeholder="Last Name"
            disabled={!isEditing}
          />
        </Grid>
        <Grid item xs={12}>
          <Text>Contact Number</Text>
          <BookingRecipientDetailField
            name="contactNo"
            placeholder="Contact Number"
            disabled={!isEditing}
          />
        </Grid>
        {getBookingDetailObj.data.type === "flexi" ? (
          <Spacer size="xs" position="top" />
        ) : (
          <>
            <Grid item xs={12}>
              <Text>Date</Text>
              <BookingDatePicker disabled={!isEditing} />
            </Grid>
            <Grid item xs={12}>
              <Text>Slot</Text>
              <BookingSlotList disabled={!isEditing} />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Text>Experience</Text>
          <BookingDetailDisabledField value={getBookingDetailObj.data.experience.title} />
        </Grid>
        <Grid item xs={12}>
          <Text>Quantity</Text>
          <BookingDetailDisabledField
            value={getBookingDetailObj.data.noOfPax?.toString()}
            width="75px"
          />
        </Grid>
        <Grid item xs={12}>
          <Text>Price</Text>
          <BookingDetailDisabledField value={`RM ${getBookingDetailObj.data.order.price}`} />
        </Grid>
        {isEditing && (
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", flexDirection: "row" }}>
              <BorderButton
                width="120px"
                onClickButton={() => {
                  formRef.current.resetForm();
                  setIsEditing(false);
                }}
              >
                <Text type="BrandColor">Cancel</Text>
              </BorderButton>
              <Spacer size="s" position="left" />
              <FormSubmitButton width="120px">
                <Text type="WhiteColor">Save</Text>
              </FormSubmitButton>
            </Box>
          </Grid>
        )}
      </Grid>
    </Form>
  );

  return (
    <PaddedView multiples={isMobile ? 1 : 3}>
      <Loading isLoading={isLoading} />
      <Form
        initialValues={{ cancelReason: "", bookingId }}
        onSubmit={onCancelBooking}
        validationSchema={cancelBookingValidationSchema}
      >
        <CancelBookingReasonModal showModal={showRejectModal} setShowModal={setShowRejectModal} />
      </Form>
      <Link href={routes.BOOKING_LIST} style={{ textDecoration: "none" }}>
        <Text type="GreyColor">{`< Back`}</Text>
      </Link>
      <Spacer size="xl" position="top" />

      {getBookingDetailObj.status === "succeeded" ? renderDetail() : <BookingDetailLoader />}
    </PaddedView>
  );
}

export default BookingDetailScreen;
