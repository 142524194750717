import { IconButton, TableCell, TableRow } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import DeleteIcon from "../../../assets/images/delete-icon";
import EditIcon from "../../../assets/images/edit-icon";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";

function CreatorRow({ record, onTriggerDelete }) {
    const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

    return (
        <TableRow
            key={record.id}
            sx={{
                "& .MuiTableCell-root": {
                    borderBottom: "no-set",
                },
            }}
        >
            <TableCell>
                <Text variant="body2" sx={{ textAlign: "center" }}>
                    {record.id}
                </Text>
            </TableCell>
            <TableCell>
                <Text variant="body2" sx={{ textAlign: "center" }}>
                    {record.first_name}
                </Text>
            </TableCell>
            <TableCell>
                <Text variant="body2" sx={{ textAlign: "center" }}>
                    {record.last_name}
                </Text>
            </TableCell>
            <TableCell>
                <Text variant="body2" sx={{ textAlign: "center" }}>
                    {record.nick_name}
                </Text>
            </TableCell>
            <TableCell>
                <Text variant="body2" sx={{ textAlign: "center" }}>
                    {record.comission}
                </Text>
            </TableCell>
            <TableCell>
                <Text variant="body2" sx={{ textAlign: "center", minWidth: "100px" }}>
                    {record.createdAt}
                </Text>
            </TableCell>
            <TableCell>
                <Text variant="body2" sx={{ textAlign: "center" }}>
                    {record.status !== null ? capitalize(record.status.toString()) : "-"}
                </Text>
            </TableCell>
            <TableCell>
                <Link to={{ pathname: `${routes.CREATORDETAIL}`, search: `creatorId=${record.id}` }}>
                    <IconButton>
                        <EditIcon />
                    </IconButton>
                </Link>
                {/* <Link to={{ pathname: `${routes.CREATORDETAIL}`, search: `creatorId=${record.id}` }}>
                    <IconButton>
                        <BookingIcon />
                    </IconButton>
                </Link> */}
                <IconButton onClick={() => onTriggerDelete(record.deletable, record.id)} disabled={!record.deletable}>
                    <DeleteIcon disabled={!record.deletable} />
                </IconButton>
            </TableCell>
        </TableRow>
    );
}

CreatorRow.defaultProps = {
    record: {
        comission: null,
        first_name: "",
        last_name: "",
        nick_name: "",
        phone: "",
        createdAt: "",
        id: null,
        status: "",
        deletable: false,
    },
};

CreatorRow.propTypes = {
    onTriggerDelete: PropTypes.func.isRequired,
    record: PropTypes.shape({
        comission: PropTypes.number,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        nick_name: PropTypes.string,
        phone: PropTypes.string,
        createdAt: PropTypes.string,
        id: PropTypes.number,
        status: PropTypes.string,
        deletable: PropTypes.bool,
    }),
};

export default CreatorRow;
