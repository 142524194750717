import React, { useState } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { Box, IconButton, Menu, MenuItem, styled, useTheme } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Text from "../../../components/text.component";

const StyledButtonIcon = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  padding: 0,
  top: "10px",
  right: "10px",
  backgroundColor: theme.palette.colors.brand.white,
  width: "30px",
  height: "30px",
  ":hover": { backgroundColor: theme.palette.colors.brand.white },
}));

const StyledMoreHorizIcon = styled(MoreHorizIcon)(({ theme }) => ({
  fontSize: "25px",
  color: theme.palette.colors.brand.secondary,
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 4,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

const NumberingBox = styled(Box)({
  position: "absolute",
  height: "25px",
  width: "25px",
  backgroundColor: "rgba(0,0,0,0.5)",
  top: "10px",
  left: "10px",
  borderRadius: "25px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export default function CategoryImage({
  item,
  onDeleteCategoryImage,
  onImageOrderUpdate,
  isLast,
  index,
}) {
  const [showMenu, setShowMenu] = useState(null);
  const theme = useTheme();

  const handleOpenMenu = (event) => {
    setShowMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setShowMenu(null);
  };

  const renderMenu = () => (
    <StyledMenu
      sx={{ mt: "30px" }}
      anchorEl={showMenu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(showMenu)}
      onClose={handleCloseMenu}
    >
      {!isLast && (
        <MenuItem
          key={uuidv4()}
          onClick={() => {
            onImageOrderUpdate({ imageId: item.id, orderType: "backward" });
            handleCloseMenu();
          }}
        >
          <Text textAlign="center">Move Backward</Text>
        </MenuItem>
      )}

      {index !== 1 && (
        <MenuItem
          key={uuidv4()}
          onClick={() => {
            onImageOrderUpdate({ imageId: item.id, orderType: "forward" });
            handleCloseMenu();
          }}
        >
          <Text textAlign="center">Move Foward</Text>
        </MenuItem>
      )}

      <MenuItem
        key={4}
        onClick={() => {
          onDeleteCategoryImage(item.id);
          handleCloseMenu();
        }}
      >
        <Text textAlign="center">Delete</Text>
      </MenuItem>
    </StyledMenu>
  );

  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      <img
        src={item.imagePath}
        alt="category-img"
        style={{
          width: "100%",
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
        }}
      />
      <NumberingBox>
        <Text type="WhiteColor">{index}</Text>
      </NumberingBox>
      <StyledButtonIcon
        onClick={(event) => {
          handleOpenMenu(event);
        }}
      >
        <StyledMoreHorizIcon />
      </StyledButtonIcon>
      {renderMenu()}
    </Box>
  );
}

CategoryImage.defaultProps = {
  item: { id: null, orderBy: null, imagePath: "s" },
};

CategoryImage.propTypes = {
  index: PropTypes.number.isRequired,
  isLast: PropTypes.bool.isRequired,
  onImageOrderUpdate: PropTypes.func.isRequired,
  onDeleteCategoryImage: PropTypes.func.isRequired,
  item: PropTypes.shape({
    id: PropTypes.number,
    orderBy: PropTypes.number,
    imagePath: PropTypes.string,
  }),
};
