import { Button, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { exploreSectionSelector } from "../../../../services/marketing/explore-section/explore-section-slice.services";
import ExperienceCard from "./experience-card.component";
import ExperienceSelectionModal from "./experience-selection-modal.component";

const UploadButton = styled(Button)(({ theme }) => ({
  backgroundColor: "transparent",
  borderTopLeftRadius: theme.shape.borderRadius[1],
  borderTopRightRadius: theme.shape.borderRadius[1],
  borderBottomLeftRadius: theme.shape.borderRadius[1],
  borderBottomRightRadius: theme.shape.borderRadius[1],
  border: `1px dashed ${theme.palette.colors.ui.disabled}`,
  minHeight: "250px",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: theme.palette.colors.ui.disabled,
  padding: "0px",
  width: "100%",
  "&:hover": {
    background: "transparent",
  },
}));

export default function ExploreSectionExperienceList({
  onRemoveExploreExperience,
  onAddExploreExperience,
}) {
  const { getExploreExperienceListObj } = useSelector(exploreSectionSelector);
  const [showExperienceModal, setShowExperienceModal] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container spacing={3}>
      {showExperienceModal && (
        <ExperienceSelectionModal
          showModal={showExperienceModal}
          setShowModal={setShowExperienceModal}
          onRemoveExploreExperience={onRemoveExploreExperience}
          onAddExploreExperience={onAddExploreExperience}
        />
      )}
      {getExploreExperienceListObj.data.map((item) => (
        <Grid item xs={isMobile ? 12 : 4} key={item.id}>
          <ExperienceCard
            experience={item}
            onRemoveExploreExperience={onRemoveExploreExperience}
            onAddExploreExperience={onAddExploreExperience}
          />
        </Grid>
      ))}
      <Grid item xs={isMobile ? 12 : 4}>
        <UploadButton onClick={() => setShowExperienceModal(true)}>
          <AddCircleOutlineRoundedIcon />
        </UploadButton>
      </Grid>
    </Grid>
  );
}

ExploreSectionExperienceList.propTypes = {
  onAddExploreExperience: PropTypes.func.isRequired,
  onRemoveExploreExperience: PropTypes.func.isRequired,
};
