import { Grid, Table, TableBody, TableCell, TableRow } from "@mui/material";
import React from "react";
import Skeleton from "react-loading-skeleton";

import DetailCardContainer from "../../../components/detail-card-container.component";
import Text from "../../../components/text.component";

import TableHeader from "../../../components/table/table-header.component";

export default function PromoRedemptionListLoader() {
  return (
    <Grid item xs={12}>
      <DetailCardContainer>
        <Grid item xs={12}>
          <Text variant="h5">Redemption List</Text>
        </Grid>
        <Table>
          <TableHeader
            headerCells={[
              "Redemption ID",
              "User Id",
              "Username",
              "Status",
              "Date Redeemed",
              "Booking Id",
              "",
            ]}
          />
          <TableBody>
            <TableRow
              sx={{
                "& .MuiTableCell-root": {
                  borderBottom: "no-set",
                },
              }}
            >
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Grid container spacing={1}>
                  <Grid item>
                    <Skeleton height={20} width={20} circle={true} />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DetailCardContainer>
    </Grid>
  );
}
