import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
    Box,
    Collapse,
    Grid,
    IconButton,
    TableCell,
    TableRow,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import ImageFullViewModal from "../../review/components/image-full-view-modal.component";

function LatestReviewList({ record, setShowUpdateConfirmModal, setReviewItem }) {
    const [collapse, setCollapse] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [showImageModal, setShowImageModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(0);

    return (
        <>
            <ImageFullViewModal
                showModal={showImageModal}
                setShowModal={setShowImageModal}
                imageList={record.images}
                selectedItem={selectedItem}
            />
            <TableRow
                key={record.id}
                sx={{
                    "& .MuiTableCell-root": {
                        borderBottom: "0px",
                    },
                }}
            >
                <TableCell width="25px">
                    <Text variant="body2" sx={{ textAlign: "center" }}>
                        {record.id}
                    </Text>
                </TableCell>
                <TableCell>
                    <Link
                        style={{ textDecorationColor: theme.palette.colors.text.primary }}
                        to={{
                            pathname: routes.EXPERIENCEDETAIL,
                            search: `experienceId=${record.experience.id}`,
                        }}
                    >
                        <Text variant="body2" sx={{ textAlign: "center",  minWidth: "30px" }}>
                            {record.experience.title}
                        </Text>
                    </Link>
                </TableCell>
                <TableCell>
                    <Text variant="body2" sx={{ textAlign: "center",  minWidth: "30px" }}>
                        {record.username}
                    </Text>
                </TableCell>
                <TableCell>
                    <Text variant="body2" sx={{ textAlign: "justify",  minWidth: "30px" }}>
                        {record.comment}
                    </Text>
                </TableCell>
                <TableCell>
                    <Text variant="body2" sx={{ textAlign: "center" }}>
                        {record.rating}
                    </Text>
                </TableCell>
                <TableCell>
                    <Text variant="body2" sx={{ textAlign: "center",  minWidth: "20px", maxWidth: "20px" }}>
                        {record.status}
                    </Text>
                </TableCell>
                <TableCell>
                    <Text variant="body2" sx={{ textAlign: "center" }}>
                        {record.createdAt}
                    </Text>
                </TableCell>
                <TableCell>
                    <IconButton
                        onClick={() => {
                            const reviewItem = {};
                            reviewItem.id = record.id;
                            reviewItem.toStatus = "approve";
                            setReviewItem(reviewItem);
                            setShowUpdateConfirmModal(true);
                        }}
                        disabled={record.status !== "pending"}
                    >
                        <DoneIcon disabled={record.status !== "pending"} />
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            const reviewItem = {};
                            reviewItem.id = record.id;
                            reviewItem.toStatus = "reject";
                            setReviewItem(reviewItem);
                            setShowUpdateConfirmModal(true);
                        }}
                        disabled={record.status !== "pending"}
                    >
                        <CloseIcon disabled={record.status !== "pending"} />
                    </IconButton>
                </TableCell>
                <TableCell sx={{ width: "100px" }}>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <IconButton aria-label="expand row" size="small" onClick={() => setCollapse(!collapse)}>
                            {collapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </Box>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                    <Collapse in={collapse} unmountOnExit timeout="auto">
                        <Spacer size="m" />
                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <Text>Review Images</Text>
                        </Box>
                        <Spacer />
                        <Grid item xs={12}>
                            <Grid container paddingTop={2}>
                                {record.images.map((photo, index) => (
                                    <Grid
                                        item
                                        xs={isMobile ? 4 : 2}
                                        paddingRight={1}
                                        key={uuidv4()}
                                        position="relative"
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setShowImageModal(true);
                                            setSelectedItem(index);
                                        }}
                                    >
                                        <img
                                            src={photo}
                                            alt="reviewImage"
                                            width="100%"
                                            height="100%"
                                            style={{ objectFit: "cover" }}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        <Spacer size="m" />
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

LatestReviewList.defaultProps = {
    record: {
        id: null,
        username: "",
        comment: "",
        rating: 0,
        createdAt: "",
        status: "",
        images: [],
    },
};

LatestReviewList.propTypes = {
    record: PropTypes.shape({
        id: PropTypes.number,
        experience: PropTypes.shape({
            id: PropTypes.number,
            title: PropTypes.string,
        }),
        username: PropTypes.string,
        comment: PropTypes.string,
        rating: PropTypes.number,
        createdAt: PropTypes.string,
        status: PropTypes.string,
        images: PropTypes.arrayOf(PropTypes.string),
    }),
    setShowUpdateConfirmModal: PropTypes.func.isRequired,
    setReviewItem: PropTypes.func.isRequired,
};

export default LatestReviewList;
