import React, { useContext, useEffect, useState } from "react";
import { Box, Pagination, styled, Tab, Table, TableBody, Tabs, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import PaddedView from "../../../components/padded-view.component";
import Spacer from "../../../components/spacer.component";
import TableWrapper from "../../../components/table/table-wrapper.component";
import TableHeader from "../../../components/table/table-header.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import {
  createExperience,
  deleteExperience,
  experienceSelector,
  getExperienceList,
} from "../../../services/experience/experience-slice.services";
import TableLoader from "../loader/table-loader.component";
import ExperienceRow from "../components/experience-row.component";
import TableTitle from "../../../components/table/table-title.component";
import ExperienceCreateModal from "../components/experience-create-modal.component";
import Form from "../../../components/forms/form.component";
import { searchMerchant } from "../../../services/merchant/merchant-slice.services";
import Loading from "../../../components/notifications/loading.component";
import DeleteConfirmationModal from "../../../components/notifications/delete-confirmation-modal.component";
import ExperienceUnableDeleteModal from "../components/experience-unable-delete-modal.component";

const validationSchema = Yup.object().shape({
  title: Yup.string().required().label("Title"),
  merchant: Yup.object().shape({}).required().label("Merchant").nullable(),
});

const AntTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.colors.brand.primary,
    height: "2px",
  },
}));

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size18,
  fontWeight: theme.fonts.fontWeights.regular,
  "&.Mui-selected": {
    color: theme.palette.colors.brand.primary,
  },
}));

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

function ExperienceListScreen() {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentStatus, setCurrentStatus] = useState("all");
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const theme = useTheme();
  const { getExperienceListObj, deleteExperienceObj } = useSelector(experienceSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteExperienceId, setDeleteExperienceId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUnableDeleteModal, setShowUnableDeleteModal] = useState(false);

  useEffect(() => {
    dispatch(getExperienceList({ q: searchKeyword, status: currentStatus, page })).then(
      ({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      },
    );
    dispatch(searchMerchant("merchant")).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  }, []);

  const resetSearch = () => {
    dispatch(getExperienceList({ q: "", status: "all", page: 1 })).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
    setSearchKeyword("");
    setCurrentStatus("all");
    setPage(1);
  };

  const onChangeSearch = (q) => {
    dispatch(getExperienceList({ q, status: currentStatus, page: 1 })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
    setSearchKeyword(q);
  };

  const onPageChange = (e, newPage) => {
    dispatch(getExperienceList({ q: searchKeyword, status: currentStatus, page: newPage })).then(
      ({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      },
    );
    setPage(newPage);
  };

  const onChangeStatus = (e, status) => {
    dispatch(getExperienceList({ q: searchKeyword, status, page: 1 })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
    setCurrentStatus(status);
  };

  const onTriggerDelete = (deleteable, experienceId) => {
    if (deleteable) {
      setDeleteExperienceId(experienceId);
      setShowDeleteModal(true);
      return;
    }
    setShowUnableDeleteModal(true);
  };

  const onConfirmDelete = () => {
    setIsLoading(true);
    dispatch(deleteExperience(deleteExperienceId)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        dispatch(getExperienceList({ q: searchKeyword, status: currentStatus, page }));
        setShowDeleteModal(false);
        setIsLoading(false);
        setDeleteExperienceId(null);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onCreateExperience = (value, { resetForm }) => {
    const merchantId = value.merchant.id;
    setIsLoading(true);
    dispatch(createExperience({ ...value, merchantId })).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        resetSearch();
        setShowCreateModal(false);
        resetForm();
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onChangeSearchMerchant = (merchant) => {
    dispatch(searchMerchant(merchant)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const renderContent = () => {
    if (getExperienceListObj.status === "succeeded") {
      return (
        <>
          <Table>
            <TableHeader
              headerCells={[
                "Listing ID",
                "Merchant",
                "Category",
                "Experience Name",
                "Date Listed",
                "Status",
                "",
              ]}
            />
            <TableBody>
              {getExperienceListObj.data.items.map((item) => (
                <ExperienceRow record={item} key={item.id} onTriggerDelete={onTriggerDelete} />
              ))}
            </TableBody>
          </Table>
          <CustomFooter>
            <Pagination
              sx={{
                "&& .Mui-selected": {
                  backgroundColor: theme.palette.colors.brand.primary,
                  color: theme.palette.colors.text.white,
                },
              }}
              page={page}
              onChange={onPageChange}
              count={getExperienceListObj.data.pagination.totalPages}
              variant="outlined"
            />
          </CustomFooter>
        </>
      );
    }

    return <TableLoader />;
  };

  return (
    <PaddedView multiples={3}>
      <ExperienceUnableDeleteModal
        showModal={showUnableDeleteModal}
        setShowModal={setShowUnableDeleteModal}
      />
      <DeleteConfirmationModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        label="Are you sure to delete this experience?"
        isLoading={deleteExperienceObj.status === "pending"}
        onConfirmClicked={onConfirmDelete}
      />
      <Loading isLoading={isLoading} />
      <Form
        initialValues={{
          title: "",
          merchant: null,
        }}
        validationSchema={validationSchema}
        onSubmit={onCreateExperience}
      >
        <ExperienceCreateModal
          isShowModal={showCreateModal}
          setIsShowModal={setShowCreateModal}
          onSearchKeywordChange={onChangeSearchMerchant}
        />
      </Form>
      <Spacer position="top" size="m" />
      <TableTitle
        title="Experiences"
        searchKeyword={searchKeyword}
        onChangeSearch={onChangeSearch}
        placeholder="Search Experiences"
        onClickCreateButton={() => setShowCreateModal(true)}
      />
      <Spacer position="top" size="xl" />
      <TableWrapper>
        <Spacer position="top" size="m" />
        <AntTabs value={currentStatus} onChange={onChangeStatus}>
          <AntTab value="all" label="All" />
          <AntTab value="publish" label="Approved" />
          <AntTab value="unlisted" label="Unlisted" />
          <AntTab value="pending" label="Pending" />
          <AntTab value="rejected" label="Rejected" />
        </AntTabs>
        <Spacer position="top" size="xl" />
        {renderContent()}
      </TableWrapper>
    </PaddedView>
  );
}

export default ExperienceListScreen;
