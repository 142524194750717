import React from "react";
import { Box, Grid, IconButton, styled, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useFormikContext } from "formik";
import Text from "../../../components/text.component";
import { experienceSelector } from "../../../services/experience/experience-slice.services";
import SlotCardContainer from "./slot-card-container.component";
import Spacer from "../../../components/spacer.component";
import { capitalize } from "../../../infrastructure/utils/capitalize";
import CenteredRowBox from "../../../components/centered-row-box.component";

const EmptyItemsBox = styled(Box)({
  height: "100px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const EditIconButton = styled(IconButton)(({ theme }) => ({
  padding: "0px",
  color: theme.palette.colors.text.primary,
}));

export default function FlexiSlotList({ setShowTimeSlotModal, setShowDeleteModal }) {
  const theme = useTheme();
  const { getExperienceTimeslotObj } = useSelector(experienceSelector);
  const { setFieldValue } = useFormikContext();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const onEditHandle = (slot) => {
    setFieldValue("formType", "edit");
    setFieldValue("bookingType", "flexi");
    setFieldValue("flexiTitle", slot.massSlot.title);
    setFieldValue("capacity", slot.massSlot.capacity.total);
    setFieldValue("massSlotId", slot.massSlot.id);
    setFieldValue("startDate", "");
    setFieldValue("startTime", "");
    setFieldValue("endDate", "");
    setFieldValue("endTime", "");
    setFieldValue("slotId", "");
    setShowTimeSlotModal(true);
  };

  const onDeletePress = (slot) => {
    setFieldValue("formType", "delete");
    setFieldValue("bookingType", "flexi");
    setFieldValue("flexiTitle", slot.massSlot.title);
    setFieldValue("capacity", slot.massSlot.capacity.total);
    setFieldValue("massSlotId", slot.massSlot.id);
    setFieldValue("startDate", "");
    setFieldValue("startTime", "");
    setFieldValue("endDate", "");
    setFieldValue("endTime", "");
    setFieldValue("slotId", slot.id);
    setShowDeleteModal(true);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Text
          sx={{
            fontSize: theme.fonts.fontSizes.size18,
            fontWeight: theme.fonts.fontWeights.medium,
          }}
        >
          Flexi Timeslot
        </Text>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={3}>
          {getExperienceTimeslotObj.data.flexiTimeSlots.length > 0 ? (
            getExperienceTimeslotObj.data.flexiTimeSlots.map((timeSlot) => (
              <Grid item xs={isMobile ? 12 : null} key={timeSlot.massSlot.id}>
                <SlotCardContainer>
                  <CenteredRowBox position="absolute" top="10px" right="10px">
                    <EditIconButton onClick={() => onEditHandle(timeSlot)}>
                      <ModeEditIcon />
                    </EditIconButton>
                    <Spacer position="left" size="xs" />
                    <EditIconButton
                      disabled={!timeSlot.deletable}
                      onClick={() => onDeletePress(timeSlot)}
                    >
                      <DeleteOutlineIcon />
                    </EditIconButton>
                  </CenteredRowBox>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Text sx={{ fontSize: theme.fonts.fontSizes.size14 }}>Title:</Text>
                    <Spacer size="xs" position="left" />
                    <Text sx={{ fontSize: theme.fonts.fontSizes.size14, textAlign: "justify" }}>
                      {timeSlot.massSlot.title}
                    </Text>
                  </Box>
                  <Text sx={{ fontSize: theme.fonts.fontSizes.size14 }}>
                    Capacity: {timeSlot.massSlot.capacity.total}
                  </Text>
                  <Text sx={{ fontSize: theme.fonts.fontSizes.size14 }}>
                    Status: {capitalize(timeSlot.massSlot.status.toString())}
                  </Text>
                  <Text sx={{ fontSize: theme.fonts.fontSizes.size14 }}>
                    Occupied: {timeSlot.massSlot.capacity.occupied}
                  </Text>
                </SlotCardContainer>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <EmptyItemsBox>
                <Text>No Items</Text>
              </EmptyItemsBox>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

FlexiSlotList.propTypes = {
  setShowTimeSlotModal: PropTypes.func.isRequired,
  setShowDeleteModal: PropTypes.func.isRequired,
};
