import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useTheme } from "@mui/material/styles";
import { TextField, Box, FormHelperText } from "@mui/material";

function FormFieldTextWithCounter({
  name,
  placeholder,
  width,
  height,
  maxLength,
  multiline,
  isTransparent,
  disabled,
  toUpper,
}) {
  const theme = useTheme();
  const { setFieldTouched, setFieldValue, errors, touched, values, handleSubmit } =
    useFormikContext();

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  const showError = touched[name] && typeof errors[name] === "string";

  return (
    <Box
      sx={{
        width,
        "& .MuiTextField-root": {
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          width,
          "& .MuiOutlinedInput-root": {
            minHeight: height,
            borderTopLeftRadius: theme.shape.borderRadius[0],
            borderTopRightRadius: theme.shape.borderRadius[0],
            borderBottomLeftRadius: theme.shape.borderRadius[0],
            borderBottomRightRadius: theme.shape.borderRadius[0],
            backgroundColor: isTransparent ? "transparent" : theme.palette.colors.text.white,
            justifyContent: "flex-start",
            alignItems: "flex-start",
          },
        },
      }}
    >
      <TextField
        disabled={disabled}
        multiline={multiline}
        onKeyDown={keyPress}
        error={showError}
        onBlur={() => setFieldTouched(name)}
        name={name}
        placeholder={placeholder}
        inputProps={{ maxLength }}
        onChange={(e) => {
          setFieldValue(name, e.target.value);

          if (toUpper) {
            setFieldValue(name, e.target.value.toUpperCase());
          }
        }}
        value={values[name] === null ? "" : values[name]}
      />
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        {showError ? <FormHelperText error>{errors[name]}</FormHelperText> : <Box />}
        <FormHelperText error={showError} sx={{ textAlign: "end" }}>
          {`${(values[name] && values[name].length) || 0}/${maxLength} characters`}
        </FormHelperText>
      </Box>
    </Box>
  );
}

FormFieldTextWithCounter.defaultProps = {
  width: "100%",
  height: "45px",
  multiline: false,
  isTransparent: false,
  disabled: false,
  toUpper: false,
};

FormFieldTextWithCounter.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  maxLength: PropTypes.number.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  multiline: PropTypes.bool,
  isTransparent: PropTypes.bool,
  disabled: PropTypes.bool,
  toUpper: PropTypes.bool,
};

export default FormFieldTextWithCounter;
