import React from "react";
import { Box, IconButton, styled, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useFormikContext } from "formik";
import Text from "../../../components/text.component";
import Spacer from "../../../components/spacer.component";
import FormFieldQuantity from "../../../components/forms/form-field-quantity.component";
import CenteredRowBox from "../../../components/centered-row-box.component";
import BorderButton from "../../../components/buttons/border-button.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";

const ButtonContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  marginTop: "20px",
});

export default function ExperienceNoOfPax({ isEditing, setIsEditing }) {
  const theme = useTheme();
  const { resetForm } = useFormikContext();
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
        <Box>
          <Text sx={{ fontSize: theme.fonts.fontSizes.size18, height: "40px", paddingY: "8px" }}>
            Min no. pax
          </Text>
          <Spacer size="s" position="top" />
          <FormFieldQuantity name="minNoPax" placeholder="Min" disabled={!isEditing.noPax} />
        </Box>
        <Spacer size="m" position="left" />
        <Box>
          <CenteredRowBox>
            <Text sx={{ fontSize: theme.fonts.fontSizes.size18, height: "40px", paddingY: "8px" }}>
              Max no. pax
            </Text>
            <Spacer size="s" position="left" />
            <IconButton
              sx={{ color: theme.palette.colors.text.primary }}
              disabled={isEditing.noPax}
              onClick={() => setIsEditing((i) => ({ ...i, noPax: true }))}
            >
              <ModeEditIcon />
            </IconButton>
          </CenteredRowBox>

          <Spacer size="s" position="top" />
          <FormFieldQuantity name="maxNoPax" placeholder="Max" disabled={!isEditing.noPax} />
        </Box>
      </Box>
      {isEditing.noPax && (
        <ButtonContainer>
          <BorderButton
            width="100px"
            onClickButton={() => {
              resetForm();
              setIsEditing((i) => ({ ...i, noPax: false }));
            }}
          >
            <Text type="BrandColor">Cancel</Text>
          </BorderButton>
          <Spacer size="s" position="left" />
          <FormSubmitButton width="100px">
            <Text type="WhiteColor">Save</Text>
          </FormSubmitButton>
        </ButtonContainer>
      )}
    </>
  );
}

ExperienceNoOfPax.propTypes = {
  setIsEditing: PropTypes.func.isRequired,
  isEditing: PropTypes.shape({
    noPax: PropTypes.bool,
  }).isRequired,
};
