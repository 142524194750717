import { Box, Button, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import FormFieldText from "../../../../components/forms/form-field-text.component";
import FormMultipleSelect from "../../../../components/forms/form-multiple-select.component";
import FormSelect from "../../../../components/forms/form-select.component";
import Spacer from "../../../../components/spacer.component";
import Text from "../../../../components/text.component";
import { categorySelector } from "../../../../services/category/category-slice.services";
import { tagSelector } from "../../../../services/tag/tag-slice.services";

const CenteredRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const ButtonContainer = styled(Box)(({ theme, isSelected }) => ({
  "& .MuiButton-root": {
    width: "125px",
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    textTransform: "none",
    boxShadow: 0,
    border: `1px solid ${theme.palette.colors.brand.primary}`,
    backgroundColor: isSelected ? theme.palette.colors.brand.primary : "transparent",
    "&:hover": {
      backgroundColor: isSelected ? theme.palette.colors.brand.primary : "transparent",
    },
  },
}));

export default function FestivalCategoryLinkTypeForm() {
  const { setFieldValue, values } = useFormikContext();
  const { getCategoryListObj } = useSelector(categorySelector);
  const { getTagListObj } = useSelector(tagSelector);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Text>Link to (Pick 1 only)</Text>
      <Spacer size="s" position="top" />
      <CenteredRowBox>
        <Grid container spacing={2}>
          <Grid item>
            <ButtonContainer
              sx={{
                "& .MuiButton-root": {
                  backgroundColor:
                    values.queryType === "category"
                      ? theme.palette.colors.brand.primary
                      : "transparent",
                  "&:hover": {
                    backgroundColor:
                      values.queryType === "category"
                        ? theme.palette.colors.brand.primary
                        : "transparent",
                  },
                },
              }}
            >
              <Button variant="contained" onClick={() => setFieldValue("queryType", "category")}>
                <Text type={values.queryType === "category" ? "WhiteColor" : "BrandColor"}>
                  Category
                </Text>
              </Button>
            </ButtonContainer>
          </Grid>

          <Grid item>
            <ButtonContainer
              sx={{
                "& .MuiButton-root": {
                  backgroundColor:
                    values.queryType === "tag" ? theme.palette.colors.brand.primary : "transparent",
                  "&:hover": {
                    backgroundColor:
                      values.queryType === "tag"
                        ? theme.palette.colors.brand.primary
                        : "transparent",
                  },
                },
              }}
            >
              <Button variant="contained" onClick={() => setFieldValue("queryType", "tag")}>
                <Text type={values.queryType === "tag" ? "WhiteColor" : "BrandColor"}>Tag</Text>
              </Button>
            </ButtonContainer>
          </Grid>

          <Grid item>
            <ButtonContainer
              sx={{
                "& .MuiButton-root": {
                  backgroundColor:
                    values.queryType === "url" ? theme.palette.colors.brand.primary : "transparent",
                  "&:hover": {
                    backgroundColor:
                      values.queryType === "url"
                        ? theme.palette.colors.brand.primary
                        : "transparent",
                  },
                },
              }}
            >
              <Button variant="contained" onClick={() => setFieldValue("queryType", "url")}>
                <Text type={values.queryType === "url" ? "WhiteColor" : "BrandColor"}>URL</Text>
              </Button>
            </ButtonContainer>
          </Grid>
        </Grid>
      </CenteredRowBox>

      <Spacer size="m" position="top" />
      {getCategoryListObj.status === "succeeded" && values.queryType === "category" && (
        <FormSelect
          name="categoryObj"
          placeholder="Select Category"
          itemList={getCategoryListObj.data}
          width={isMobile ? "100%" : "300px"}
        />
      )}
      {getCategoryListObj.status === "succeeded" && values.queryType === "tag" && (
        <FormMultipleSelect
          name="tagObj"
          placeholder="Select Tag"
          itemList={getTagListObj.data}
          width={isMobile ? "100%" : "300px"}
        />
      )}
      {values.queryType === "url" && (
        <FormFieldText
          name="urlObj"
          placeholder="Enter URL here"
          width={isMobile ? "100%" : "300px"}
        />
      )}
    </>
  );
}
