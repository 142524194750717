import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, Link, styled, useMediaQuery, useTheme } from "@mui/material";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import PaddedView from "../../../../components/padded-view.component";
import Text from "../../../../components/text.component";
import routes from "../../../../navigation/routes";
import Spacer from "../../../../components/spacer.component";
import Form from "../../../../components/forms/form.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import FormFieldTextWithCounter from "../../../../components/forms/form-field-text-with-counter.component";
import FormImageCropper from "../../../../components/forms/form-image-cropper.component";
import FormDatePicker from "../../../../components/forms/form-date-picker.component";
import BannerLinkTypeForm from "../components/banner-link-type-form.component";
import {
  categorySelector,
  getCategoryList,
} from "../../../../services/category/category-slice.services";
import { SnackbarContext } from "../../../../components/notifications/snackbar.context";
import { getTagList, tagSelector } from "../../../../services/tag/tag-slice.services";
import {
  bannerSelector,
  getBannerDetail,
  updateBanner,
} from "../../../../services/marketing/banner/banner-slice.services";
import Loading from "../../../../components/notifications/loading.component";
import BannerDetailLoader from "../loader/banner-detail-loader.component";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-end",
});

const FlexStartRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
});

const validationSchema = Yup.object().shape({
  description: Yup.string().required().max(80).label("Banner Title"),
  imagePath: Yup.string().required().label("Banner Image"),
  startAt: Yup.date().required().label("Start from"),
  endAt: Yup.date()
    .min(Yup.ref("startAt"), "End date must be after start date")
    .required()
    .label("End at"),
  queryType: Yup.string().required().label("Type"),
  categoryObj: Yup.string().nullable().label("Category").when("queryType", {
    is: "category",
    then: Yup.string().required(),
  }),
  tagObj: Yup.array()
    .of(Yup.string())
    .when("queryType", {
      is: "tag",
      then: Yup.array().of(Yup.string()).required().min(1),
    })
    .label("Tag"),
  urlObj: Yup.string().nullable().label("URL").when("queryType", {
    is: "url",
    then: Yup.string().required(),
  }),
});

export default function BannerEditScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const location = useLocation();
  const bannerId = new URLSearchParams(location.search).get("bannerId");
  const cropSetting = {
    unit: "px",
    aspect: 16 / 3,
    width: 266.67,
    height: 50,
    x: 0,
    y: 0,
  };
  const aspectRatio = 16 / 3;
  const createSnackBar = useContext(SnackbarContext);
  const { getBannerDetailObj, updateBannerObj } = useSelector(bannerSelector);
  const { getCategoryListObj } = useSelector(categorySelector);
  const { getTagListObj } = useSelector(tagSelector);
  const [isLoading, setIsLoading] = useState(false);

  const onUpdateBanner = (values) => {
    setIsLoading(true);
    let queryValue = [];
    if (values.queryType === "category") {
      queryValue.push(values.categoryObj);
    }
    if (values.queryType === "url") {
      queryValue.push(`https://${values.urlObj}`);
    }
    if (values.queryType === "tag") {
      queryValue = queryValue.concat(values.tagObj);
    }

    if (values.imagePathChanged === "1") {
      values.imageChanged = true;
    }
    dispatch(updateBanner({ bannerId, queryValue, ...values })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  useEffect(() => {
    dispatch(getBannerDetail(bannerId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
    dispatch(getCategoryList()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
    dispatch(getTagList()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  const renderContent = () => {
    if (
      getCategoryListObj.status === "succeeded" &&
      getTagListObj.status === "succeeded" &&
      getBannerDetailObj.status === "succeeded"
    ) {
      return (
        <Form
          validationSchema={validationSchema}
          onSubmit={onUpdateBanner}
          initialValues={{
            description: getBannerDetailObj.data.description,
            imagePath: getBannerDetailObj.data.imagePath,
            imagePathChanged: "0",
            startAt: getBannerDetailObj.data.startAt.split(" ")[0],
            endAt: getBannerDetailObj.data.endAt.split(" ")[0],
            tagObj:
              getBannerDetailObj.data.queryType === "tag" ? getBannerDetailObj.data.queryValue : [],
            categoryObj:
              getBannerDetailObj.data.queryType === "category"
                ? getBannerDetailObj.data.queryValue[0]
                : "",
            queryType: getBannerDetailObj.data.queryType,
            urlObj:
              getBannerDetailObj.data.queryType === "url"
                ? getBannerDetailObj.data.queryValue[0].replace("https://", "")
                : "",
            imageChanged: false,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SpaceBetweenBox>
                <Text variant="h4">Edit Banner</Text>
                <FormSubmitButton width="125px" isLoading={updateBannerObj.status === "pending"}>
                  <Text type="WhiteColor">Confirm</Text>
                </FormSubmitButton>
              </SpaceBetweenBox>
            </Grid>
            <Grid item xs={12}>
              <Text>Banner Title</Text>
              <Spacer size="xs" position="top" />
              <FormFieldTextWithCounter
                maxLength={50}
                name="description"
                placeholder="Enter Banner Name here"
                width={isMobile ? "100%" : "350px"}
              />
            </Grid>
            <Grid item xs={12}>
              <FormImageCropper
                name="imagePath"
                label="Banner Image"
                width={isMobile ? "100%" : "533.33px"}
                cropSetting={cropSetting}
                aspectRatio={aspectRatio}
              />
            </Grid>
            <Grid item xs={12}>
              <FlexStartRowBox>
                <Box>
                  <Text>Start from</Text>
                  <Spacer size="xs" position="top" />
                  <FormDatePicker name="startAt" disablePast={false} />
                </Box>
                <Spacer size="xl" position="left" />
                <Box>
                  <Text>End at</Text>
                  <Spacer size="xs" position="top" />
                  <FormDatePicker name="endAt" />
                </Box>
              </FlexStartRowBox>
            </Grid>
            <Grid item xs={12}>
              <BannerLinkTypeForm />
            </Grid>
            <Grid item xs={12} />
          </Grid>
        </Form>
      );
    }
    return <BannerDetailLoader />;
  };

  return (
    <PaddedView multiples={isMobile ? 1 : 3}>
      <Loading isLoading={isLoading} />
      <Link href={routes.MARKETING_BANNER_LIST} style={{ textDecoration: "none" }}>
        <Text type="GreyColor">{`< Back`}</Text>
      </Link>
      <Spacer size="xl" position="top" />
      {renderContent()}
    </PaddedView>
  );
}
