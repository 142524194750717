import React, { useContext } from "react";
import { Box, Grid } from "@mui/material";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Text from "../../../components/text.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import {
  merchantSelector,
  updateMerchant,
} from "../../../services/merchant/merchant-slice.services";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import MerchantCommissionField from "./merchant-commision-field.component";
import Spacer from "../../../components/spacer.component";

const MerchantCommissionValidationSchema = Yup.object().shape({
  commission: Yup.number().max(100).required().label("Commission Rate"),
});

function MerchantCommission() {
  const location = useLocation();
  const merchantId = new URLSearchParams(location.search).get("merchantId");
  const { getMerchantDetailsObj, updateMerchantObj } = useSelector(merchantSelector);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);

  const onUpdateMerchantCommission = (values) => {
    dispatch(updateMerchant({ merchantId, type: "commission", ...values })).then(
      ({ meta, error, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  return (
    <Form
      validationSchema={MerchantCommissionValidationSchema}
      onSubmit={onUpdateMerchantCommission}
      initialValues={{
        id: getMerchantDetailsObj.data.merchant.id,
        commission: getMerchantDetailsObj.data.merchant.commission,
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Text variant="h5">Commission Rate (%)</Text>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <MerchantCommissionField name="commission" />
            <Spacer size="m" position="left" />
            <FormSubmitButton width="125px" isLoading={updateMerchantObj.status === "pending"}>
              <Text type="WhiteColor">Save</Text>
            </FormSubmitButton>
          </Box>
        </Grid>
      </Grid>
    </Form>
  );
}

export default MerchantCommission;
