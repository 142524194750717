import {
  Box,
  Grid,
  Pagination,
  styled,
  Tab,
  Table,
  TableBody,
  Tabs,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import BorderButton from "../../../components/buttons/border-button.component";
import CtaButton from "../../../components/buttons/cta-button.component";
import DetailCardContainer from "../../../components/detail-card-container.component";
import Form from "../../../components/forms/form.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import PaddedView from "../../../components/padded-view.component";
import Spacer from "../../../components/spacer.component";
import TableHeader from "../../../components/table/table-header.component";
import TableTitle from "../../../components/table/table-title.component";
import TableWrapper from "../../../components/table/table-wrapper.component";
import Text from "../../../components/text.component";
import {
  getStatementList,
  statementSelector,
} from "../../../services/statement/statement-slice.services";
import SearchBar from "../components/search-bar.component";
import StatementRow from "../components/statement-row.component";
import TableLoader from "../loader/table-loader.component";

const validationSchema = Yup.object().shape({
  statementId: Yup.number().nullable().label("Statement ID"),
  merchant: Yup.string().label("Merchant").nullable(),
});

const AntTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.colors.brand.primary,
    height: "2px",
  },
}));

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size18,
  fontWeight: theme.fonts.fontWeights.regular,
  "&.Mui-selected": {
    color: theme.palette.colors.brand.primary,
  },
}));

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

export default function StatementListScreen() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const createSnackBar = useContext(SnackbarContext);
  const formRef = useRef();
  const { getStatementListObj } = useSelector(statementSelector);
  const [page, setPage] = useState(1);
  const [currentStatus, setCurrentStatus] = useState("all");

  const onSearchStatement = (values) => {
    setPage(1);
    dispatch(
      getStatementList({
        ...values,
        status: currentStatus,
        page: 1,
      }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onPageChange = (e, newPage) => {
    setPage(newPage);
    dispatch(
      getStatementList({
        ...formRef.current.values,
        status: currentStatus,
        page: newPage,
      }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  useEffect(() => {
    onPageChange(1);
  }, []);

  const onChangeStatus = (e, status) => {
    dispatch(getStatementList({ ...formRef.current.values, status, page: 1 })).then(
      ({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      },
    );
    setCurrentStatus(status);
  };

  const renderContent = () => {
    if (getStatementListObj.status === "succeeded") {
      return (
        <>
          <Table>
            <TableHeader
              headerCells={["Statement ID", "Merchant Name", "Status", "Amount", "Action", ""]}
            />
            <TableBody>
              {getStatementListObj.data.items.map((item) => (
                <StatementRow record={item} key={item.id} />
              ))}
            </TableBody>
          </Table>
          <CustomFooter>
            <Pagination
              sx={{
                "&& .Mui-selected": {
                  backgroundColor: theme.palette.colors.brand.primary,
                  color: theme.palette.colors.text.white,
                },
              }}
              page={page}
              onChange={onPageChange}
              count={getStatementListObj.data.pagination.totalPages}
              variant="outlined"
            />
          </CustomFooter>
        </>
      );
    }

    return <TableLoader />;
  };

  return (
    <PaddedView multiples={3}>
      <Spacer position="top" size="m" />
      <TableTitle title="Statements" />
      <Spacer position="top" size="xl" />
      <Form
        innerRef={formRef}
        onSubmit={onSearchStatement}
        validationSchema={validationSchema}
        initialValues={{
          merchant: "",
          statementId: "",
        }}
      >
        <DetailCardContainer>
          <Grid container spacing={2}>
            <Grid item sx={{ display: "flex", flex: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={2.5}>
                  <SearchBar width="100%" name="statementId" placeholder="Statement ID" />
                </Grid>
                <Grid item xs={5.5}>
                  <SearchBar
                    width="100%"
                    name="merchant"
                    placeholder="Merchant ID / name / email"
                  />
                </Grid>
                <Grid
                  item
                  xs={4}
                  width={useMediaQuery(theme.breakpoints.down("990")) ? "100%" : "150px"}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <BorderButton
                        height="56px"
                        onClickButton={() => {
                          formRef.current.resetForm();
                          setTimeout(() => {
                            formRef.current.handleSubmit();
                          }, 50);
                        }}
                      >
                        <Text type="BrandColor">Clear</Text>
                      </BorderButton>
                    </Grid>
                    <Grid item xs={6}>
                      <CtaButton height="56px" onClickButton={() => formRef.current.handleSubmit()}>
                        <Text type="WhiteColor">Search</Text>
                      </CtaButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DetailCardContainer>
      </Form>
      <Spacer position="top" size="l" />
      <TableWrapper>
        <Spacer position="top" size="m" />
        <AntTabs value={currentStatus} onChange={onChangeStatus}>
          <AntTab value="all" label="All" />
          <AntTab value="open" label="Open" />
          <AntTab value="pending" label="Pending" />
          <AntTab value="closed" label="Closed" />
          <AntTab value="dispute" label="Dispute" />
        </AntTabs>
        <Spacer position="top" size="xl" />
        {renderContent()}
      </TableWrapper>{" "}
    </PaddedView>
  );
}
