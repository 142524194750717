import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { SnackbarContext } from "../../../../components/notifications/snackbar.context";
import { searchTagExperience, tagSelector } from "../../../../services/tag/tag-slice.services";
import SearchBar from "../../../../components/table/search-bar.component";
import { getExperienceLocationList } from "../../../../services/experience/experience-slice.services";
import Text from "../../../../components/text.component";
import Spacer from "../../../../components/spacer.component";
import ExperienceCardLoader from "../../../tag/loader/experience-card-loader.component";
import StatesFilter from "../../../tag/components/states-filter.component";
import PackageFilter from "../../../tag/components/package-filter.component";
import ExperienceCard from "./experience-card.component";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.primary,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  padding: "40px",
  width: "90%",
  outline: "none",
  display: "flex",
  maxHeight: "90%",
  flexDirection: "column",
  maxWidth: "1000px",
}));

const ExperienceListContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  flex: 1,
  overflow: "scroll",
  marginTop: "20px",
});

const TopBarContainer = styled(Box)({
  display: "flex",
  height: "20%",
  alignItems: "center",
  width: "100%",
});

const TextButton = styled(Button)(({ theme }) => ({
  padding: "0px",
  textTransform: "none",
  textDecoration: "underline",
  textDecorationColor: theme.palette.colors.brand.primary,
  ":hover": {
    textDecoration: "underline",
    textDecorationColor: theme.palette.colors.brand.primary,
    backgroundColor: "transparent",
  },
}));

const FlexEndButtonBox = styled(Box)({
  height: "100%",
  justifyContent: "flex-end",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const EmptyBox = styled(Box)({
  width: "100%",
  height: "350px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
  stroke: "black",
  strokeWidth: 2,
  color: theme.palette.colors.brand.secondary,
}));

export default function ExperienceSelectionModal({
  showModal,
  setShowModal,
  onRemoveExploreExperience,
  onAddExploreExperience,
}) {
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [q, setQ] = useState("");
  const [states, setStates] = useState([]);
  const [typeOfPackages, setTypeOfPackages] = useState([]);
  const { searchTagExperienceObj } = useSelector(tagSelector);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const resetFilter = () => {
    setQ("");
    setStates([]);
    setTypeOfPackages([]);
    dispatch(searchTagExperience({ q, states: "", typeOfPackages: "" })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const getTypeOfPackages = () => {
    const type = [];
    typeOfPackages.map((item) => type.push(item.value));
    return type.toString();
  };

  const onFilterApply = () => {
    dispatch(
      searchTagExperience({
        q,
        states: states.toString(),
        typeOfPackages: getTypeOfPackages(),
      }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onChangeSearch = (keyWord) => {
    setQ(keyWord);
    dispatch(
      searchTagExperience({
        q: keyWord,
        states: states.toString(),
        typeOfPackages: getTypeOfPackages(),
      }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  useEffect(() => {
    dispatch(searchTagExperience({ q, states: "", typeOfPackages: "" })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
    dispatch(getExperienceLocationList()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  }, []);

  const handleCancel = () => {
    setShowModal(false);
  };

  const renderExperienceList = () => {
    if (searchTagExperienceObj.status === "succeeded") {
      if (searchTagExperienceObj.data.length <= 0) {
        return (
          <Grid item xs={12}>
            <EmptyBox>
              <Text>No data found</Text>
            </EmptyBox>
          </Grid>
        );
      }
      return searchTagExperienceObj.data.map((experience) => (
        <Grid item xs={isMobile ? 12 : 4} key={experience.id}>
          <ExperienceCard
            experience={experience}
            onRemoveExploreExperience={onRemoveExploreExperience}
            onAddExploreExperience={onAddExploreExperience}
          />
        </Grid>
      ));
    }

    return (
      <>
        <Grid item xs={isMobile ? 12 : 4}>
          <ExperienceCardLoader />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <ExperienceCardLoader />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <ExperienceCardLoader />
        </Grid>
      </>
    );
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox>
        <IconButton onClick={handleCancel} sx={{ position: "absolute", top: "5px", right: "15px" }}>
          <StyledCloseIcon />
        </IconButton>
        <TopBarContainer>
          <Grid container spacing={3} rowSpacing={1}>
            <Grid item xs={isMobile ? 12 : null}>
              <SearchBar
                searchKeyword={q}
                setSearchKeyword={onChangeSearch}
                placeholder="Search Experience"
                width="100%"
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : null}>
              <StatesFilter states={states} setStates={setStates} />
            </Grid>
            <Grid item xs={isMobile ? 12 : null}>
              <PackageFilter
                typeOfPackages={typeOfPackages}
                setTypeOfPackages={setTypeOfPackages}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : null}>
              <FlexEndButtonBox sx={{ justifyContent: isMobile ? "space-between" : "flex-end" }}>
                <TextButton onClick={onFilterApply}>
                  <Text type="BrandColor">Apply Filter</Text>
                </TextButton>
                <Spacer position="left" size="m" />
                <TextButton onClick={resetFilter}>
                  <Text type="BrandColor">Clear Filter</Text>
                </TextButton>
              </FlexEndButtonBox>
            </Grid>
          </Grid>
        </TopBarContainer>
        <ExperienceListContainer>
          <Grid container spacing={3}>
            {renderExperienceList()}
          </Grid>
        </ExperienceListContainer>
      </ModalBox>
    </Modal>
  );
}

ExperienceSelectionModal.propTypes = {
  onAddExploreExperience: PropTypes.func.isRequired,
  onRemoveExploreExperience: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};
