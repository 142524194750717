import React, { useState } from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useTheme } from "@mui/material/styles";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import Spacer from "../spacer.component";
import Text from "../text.component";

function FormFieldText({
  isShowIcon,
  name,
  placeholder,
  type,
  width,
  disabled,
  isDisableEnter,
  onClickHandle,
  isTransparent,
}) {
  const theme = useTheme();
  const { setFieldTouched, setFieldValue, errors, touched, values, handleSubmit } =
    useFormikContext();
  const [isShowPassword, setIsShowPassword] = useState(false);

  const keyPress = (e) => {
    if (e.keyCode === 13 && !isDisableEnter) {
      handleSubmit();
    }
  };

  const showError = touched[name] && typeof errors[name] === "string";

  const getType = (inputType) => {
    const tempType = inputType;
    if (tempType === "password" && isShowPassword) {
      return "string";
    }
    return tempType;
  };

  return (
    <TextField
      sx={{
        borderTopLeftRadius: theme.shape.borderRadius[0],
        borderTopRightRadius: theme.shape.borderRadius[0],
        borderBottomLeftRadius: theme.shape.borderRadius[0],
        borderBottomRightRadius: theme.shape.borderRadius[0],
        width,
        "& .MuiOutlinedInput-root": {
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          backgroundColor: isTransparent ? "transparent" : theme.palette.colors.text.white,
        },
      }}
      onClick={onClickHandle}
      disabled={disabled}
      onKeyDown={keyPress}
      error={showError}
      variant="outlined"
      helperText={showError ? errors[name] : null}
      onBlur={() => setFieldTouched(name)}
      name={name}
      type={getType(type || name)}
      placeholder={placeholder}
      onChange={(e) => {
        if (e.target.value.match(/\./g) && name === "commission") {
          return;
        }
        setFieldValue(name, e.target.value);
      }}
      InputProps={{
        startAdornment: (
          <>
            {(name.toUpperCase().includes("URL") || name.toUpperCase().includes("WEBSITE")) && (
              <InputAdornment position="start">
                <Text type="GreyColor">https://</Text>
                <Spacer position="right" />
              </InputAdornment>
            )}
            {name.toUpperCase().includes("AMOUNT") && (
              <InputAdornment position="start">
                <Text type="GreyColor">RM</Text>
                <Spacer position="right" />
              </InputAdornment>
            )}
            {isShowIcon && name.toUpperCase().includes("EMAIL") && (
              <InputAdornment position="start">
                <PersonIcon />
                <Spacer position="right" />
              </InputAdornment>
            )}
            {name.toUpperCase().includes("PASSWORD") && (
              <InputAdornment position="start">
                <LockIcon />
                <Spacer position="right" />
              </InputAdornment>
            )}
          </>
        ),
        endAdornment: name.toUpperCase().includes("PASSWORD") && (
          <InputAdornment position="start">
            <IconButton
              onClick={() => {
                setIsShowPassword(!isShowPassword);
              }}
            >
              {isShowPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      value={values[name] === null ? "" : values[name]}
    />
  );
}

FormFieldText.defaultProps = {
  type: null,
  width: "100%",
  disabled: false,
  isDisableEnter: false,
  onClickHandle: null,
  isTransparent: false,
  isShowIcon: true,
};

FormFieldText.propTypes = {
  isShowIcon: PropTypes.bool,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string,
  width: PropTypes.string,
  disabled: PropTypes.bool,
  isDisableEnter: PropTypes.bool,
  onClickHandle: PropTypes.func,
  isTransparent: PropTypes.bool,
};

export default FormFieldText;
