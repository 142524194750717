import React from "react";
import { Box, FormHelperText, styled, TextField, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";

const PriceFieldBox = styled(Box)(({ theme }) => ({
  width: "175px",
  "& .MuiTextField-root": {
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    width: "175px",
    "& .MuiOutlinedInput-root": {
      borderTopLeftRadius: theme.shape.borderRadius[0],
      borderTopRightRadius: theme.shape.borderRadius[0],
      borderBottomLeftRadius: theme.shape.borderRadius[0],
      borderBottomRightRadius: theme.shape.borderRadius[0],
      backgroundColor: theme.palette.colors.bg.secondary,
    },
  },
}));

function MerchantCommissionField({ name }) {
  const { values, errors, setFieldValue, setFieldTouched, touched, handleSubmit } =
    useFormikContext();
  const showError = touched[name] && typeof errors[name] === "string";
  const theme = useTheme();

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  const handleChange = (evt) => {
    const { value } = evt.target;
    if (value.match(/\./g)) {
      return;
    }
    setFieldValue(name, value);
  };

  return (
    <PriceFieldBox>
      <TextField
        type="number"
        InputProps={{
          inputProps: {
            style: {
              textAlign: "center",
              color: theme.palette.colors.text.darkGrey,
              fontSize: theme.fonts.fontSizes.size18,
            },
          },
        }}
        onKeyDown={keyPress}
        error={showError}
        variant="outlined"
        onBlur={() => setFieldTouched(name)}
        name={name}
        onChange={handleChange}
        value={values[name] === null ? "" : values[name]}
      />
      {showError && (
        <FormHelperText sx={{ color: theme.palette.colors.text.error }}>
          {errors[name]}
        </FormHelperText>
      )}
    </PriceFieldBox>
  );
}

MerchantCommissionField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default MerchantCommissionField;
