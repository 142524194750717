import React from "react";
import PropTypes from "prop-types";
import { Box, FormHelperText, TextField, useTheme } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { useFormikContext } from "formik";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { bookingSelector } from "../../../services/booking/booking.slice.services";

export default function BookingDatePicker({ disabled }) {
  const { getBookingDetailObj } = useSelector(bookingSelector);
  const dateArray = [];
  const { setFieldValue, values, setFieldTouched, touched, errors } = useFormikContext();
  const showDateError = touched.date && typeof errors.date === "string";
  const theme = useTheme();

  Object.keys(getBookingDetailObj.data.availableSlots).map((key) =>
    dateArray.push(`${key}T00:00:00`),
  );

  const handleDateChange = (newValue) => {
    if (newValue) {
      newValue.setHours(0, 0, 0);
      setFieldValue(
        "slotList",
        getBookingDetailObj.data.availableSlots[format(newValue, "yyyy-MM-dd")],
      );
    } else {
      setFieldValue("slotList", []);
    }

    setFieldValue("slotId", "");
    setFieldValue("date", newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box>
        <DatePicker
          disabled={disabled}
          minDate={new Date()}
          shouldDisableDate={(date) =>
            !dateArray.map((item) => new Date(item).getTime()).includes(date.getTime())
          }
          disableHighlightToday={true}
          inputFormat="dd/MM/yyyy"
          value={values.date}
          onChange={handleDateChange}
          InputProps={{
            error: showDateError,
            style: { borderRadius: theme.shape.borderRadius[0] },
          }}
          renderInput={(params) => (
            <TextField
              sx={{
                width: "100%",
                maxWidth: "450px",
                borderTopLeftRadius: theme.shape.borderRadius[0],
                borderTopRightRadius: theme.shape.borderRadius[0],
                borderBottomLeftRadius: theme.shape.borderRadius[0],
                borderBottomRightRadius: theme.shape.borderRadius[0],
                backgroundColor: theme.palette.colors.bg.white,
              }}
              InputLabelProps={{ error: showDateError }}
              inputProps={{ readOnly: true }}
              onBlur={() => setFieldTouched("date")}
              placeholder="Select Date"
              {...params}
            />
          )}
        />
        {showDateError && <FormHelperText error>{errors.date}</FormHelperText>}
      </Box>
    </LocalizationProvider>
  );
}

BookingDatePicker.propTypes = {
  disabled: PropTypes.bool.isRequired,
};
