import React, { useContext } from "react";
import { Box, Grid, styled, useMediaQuery, useTheme, Link } from "@mui/material";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import routes from "../../../../navigation/routes";
import PaddedView from "../../../../components/padded-view.component";
import { SnackbarContext } from "../../../../components/notifications/snackbar.context";
import Form from "../../../../components/forms/form.component";
import YoloLogo from "../../../../assets/images/yolo-logo";
import { forgotPassword } from "../../../../services/auth/auth-slice.services";
import ForgotPasswordEmailForm from "../components/forgot-password-email-form.component";
import Text from "../../../../components/text.component";

const StyledMainContainer = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  width: "100%",
  backgroundColor: theme.palette.colors.bg.primary,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
}));

const StyledMainGrid = styled(Grid)({
  justifyContent: "center",
  height: "100%",
  alignItems: "center",
  flexDirection: "column",
});

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
});

const AbosluteTopLeftBox = styled(Box)({ position: "absolute", left: "40px", top: "20px" });

function ForgotPasswordScreen() {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const createSnackBar = useContext(SnackbarContext);

  const handleSubmitEmail = (values) => {
    dispatch(forgotPassword(values)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });

        history.push(routes.RESETPASSWORD, { email: values.email });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  return (
    <StyledMainContainer>
      <PaddedView multiples={isMobile ? 2 : 4}>
        <AbosluteTopLeftBox>
          <Link href={routes.LOGIN} style={{ textDecoration: "none" }}>
            <Text type="BrandColor">{`< Back`}</Text>
          </Link>
        </AbosluteTopLeftBox>
        <StyledMainGrid container spacing={4}>
          <Grid item xs={12} sx={{ height: isMobile ? 45 : 60, textAlign: "center" }}>
            <YoloLogo />
          </Grid>
          <Grid item xs={12}>
            <Form
              initialValues={{
                email: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmitEmail}
            >
              <ForgotPasswordEmailForm />
            </Form>
          </Grid>
        </StyledMainGrid>
      </PaddedView>
    </StyledMainContainer>
  );
}

export default ForgotPasswordScreen;
