import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../../api-services";

export const getFeaturedExperienceList = createAsyncThunk(
  "marketing/featured-experience/list",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/marketings/experiences`,
      payload,
    );
    return response;
  },
);

export const removeFeaturedExperience = createAsyncThunk(
  "marketing/featured-experience/remove",
  async (featuredExperienceId) => {
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/marketings/experiences/${featuredExperienceId}`,
    );
    return response;
  },
);

export const addFeaturedExperience = createAsyncThunk(
  "marketing/featured-experience/add",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/marketings/experiences`,
      payload,
    );
    return response;
  },
);

export const updateFeaturedExperienceOrder = createAsyncThunk(
  "marketing/featured-experience/update-order",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/marketings/experiences/${payload.featuredExperienceId}/order_by?type=${payload.type}`,
    );
    return response;
  },
);

const featuredExperienceSlice = createSlice({
  name: "featuredExperience",
  initialState: {
    getFeaturedExperienceListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    removeFeaturedExperienceObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    addFeaturedExperienceObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateFeaturedExperienceOrderObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getFeaturedExperienceList.pending]: (state) => {
      state.getFeaturedExperienceListObj.status = "pending";
    },
    [getFeaturedExperienceList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getFeaturedExperienceListObj.status = "succeeded";
      state.getFeaturedExperienceListObj.data = data;
      state.getFeaturedExperienceListObj.successMessage = message;
    },
    [getFeaturedExperienceList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getFeaturedExperienceListObj.status = "failed";
      state.getFeaturedExperienceListObj.errorMessage = message;
    },
    [removeFeaturedExperience.pending]: (state) => {
      state.removeFeaturedExperienceObj.status = "pending";
    },
    [removeFeaturedExperience.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.removeFeaturedExperienceObj.status = "succeeded";
      state.removeFeaturedExperienceObj.data = data;
      state.removeFeaturedExperienceObj.successMessage = message;

      state.getFeaturedExperienceListObj.data = data;
    },
    [removeFeaturedExperience.rejected]: (state, action) => {
      const { message } = action.error;

      state.removeFeaturedExperienceObj.status = "failed";
      state.removeFeaturedExperienceObj.errorMessage = message;
    },
    [addFeaturedExperience.pending]: (state) => {
      state.addFeaturedExperienceObj.status = "pending";
    },
    [addFeaturedExperience.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.addFeaturedExperienceObj.status = "succeeded";
      state.addFeaturedExperienceObj.data = data;
      state.addFeaturedExperienceObj.successMessage = message;

      state.getFeaturedExperienceListObj.data = data;
    },
    [addFeaturedExperience.rejected]: (state, action) => {
      const { message } = action.error;

      state.addFeaturedExperienceObj.status = "failed";
      state.addFeaturedExperienceObj.errorMessage = message;
    },
    [updateFeaturedExperienceOrder.pending]: (state) => {
      state.updateFeaturedExperienceOrderObj.status = "pending";
    },
    [updateFeaturedExperienceOrder.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateFeaturedExperienceOrderObj.status = "succeeded";
      state.updateFeaturedExperienceOrderObj.data = data;
      state.updateFeaturedExperienceOrderObj.successMessage = message;

      state.getFeaturedExperienceListObj.data = data;
    },
    [updateFeaturedExperienceOrder.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateFeaturedExperienceOrderObj.status = "failed";
      state.updateFeaturedExperienceOrderObj.errorMessage = message;
    },
  },
});

export default featuredExperienceSlice.reducer;

// state
export const featuredExperienceSelector = (state) => state.featuredExperience;
