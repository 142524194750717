import {
  Box,
  Grid,
  Pagination,
  styled,
  Tab,
  Table,
  TableBody,
  Tabs,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import BorderButton from "../../../components/buttons/border-button.component";
import CtaButton from "../../../components/buttons/cta-button.component";
import DetailCardContainer from "../../../components/detail-card-container.component";
import FormMultipleValueSelect from "../../../components/forms/form-multiple-value-select.component";
import Form from "../../../components/forms/form.component";
import DialogModal from "../../../components/notifications/dialog-modal.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import PaddedView from "../../../components/padded-view.component";
import Spacer from "../../../components/spacer.component";
import TableHeader from "../../../components/table/table-header.component";
import TableTitle from "../../../components/table/table-title.component";
import TableWrapper from "../../../components/table/table-wrapper.component";
import Text from "../../../components/text.component";
import {
  getReviewList,
  reviewSelector,
  updateReview,
} from "../../../services/review/review-slice.services";
import ReviewRow from "../components/review-row.component";
import SearchBar from "../components/search-bar.component";
import TableLoader from "../loader/table-loader.component";

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const AntTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.colors.brand.primary,
    height: "2px",
  },
}));

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size18,
  fontWeight: theme.fonts.fontWeights.regular,
  "&.Mui-selected": {
    color: theme.palette.colors.brand.primary,
  },
}));

const validationSchema = Yup.object().shape({
  experience: Yup.string().nullable().label("Experience ID / title"),
  ratings: Yup.array().of(Yup.number()).label("Ratings"),
  q: Yup.string().nullable().label("Comments's keywords"),
});

export default function ReviewListScreen() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const createSnackBar = useContext(SnackbarContext);
  const formRef = useRef();
  const [page, setPage] = useState(1);
  const { getReviewListObj, updateReviewObj } = useSelector(reviewSelector);
  const [currentStatus, setCurrentStatus] = useState("all");
  const [showUpdateConfirmModal, setShowUpdateConfirmModal] = useState(false);
  const [reviewItem, setReviewItem] = useState(null);

  useEffect(() => {
    const params = `?experience=&ratings=&q=&status=all`;
    dispatch(getReviewList({ params, page: 1 })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  }, []);

  const onSearchReview = (values) => {
    setPage(1);
    const params = `?experience=${values.experience}&ratings=${values.ratings}&q=${values.q}&status=${currentStatus}`;
    dispatch(
      getReviewList({
        params,
        page: 1,
      }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onChangeStatus = (e, status) => {
    const params = `?experience=${formRef.current.values.experience}&ratings=${formRef.current.values.ratings}&q=${formRef.current.values.q}&status=${status}`;

    dispatch(getReviewList({ params, page: 1 })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
    setCurrentStatus(status);
  };

  const onPageChange = (e, newPage) => {
    const params = `?experience=${formRef.current.values.experience}&ratings=${formRef.current.values.ratings}&q=${formRef.current.values.q}&status=${currentStatus}`;
    setPage(newPage);
    dispatch(
      getReviewList({
        params,
        page: newPage,
      }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onUpdateReview = (reviewId, status) => {
    dispatch(
      updateReview({
        reviewId,
        status,
      }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
      onPageChange(null, page);
      setShowUpdateConfirmModal(false);
    });
  };

  const renderContent = () => {
    if (getReviewListObj.status === "succeeded") {
      return (
        <>
          <Table>
            <TableHeader
              headerCells={[
                "Review ID",
                "Experience Name",
                "Username",
                "Comment",
                "Rating",
                "Status",
                "Date Created",
                "Action",
                "",
              ]}
            />
            <TableBody>
              {getReviewListObj.data.items.map((item) => (
                <ReviewRow
                  record={item}
                  key={item.id}
                  setShowUpdateConfirmModal={setShowUpdateConfirmModal}
                  setReviewItem={setReviewItem}
                />
              ))}
            </TableBody>
          </Table>
          <CustomFooter>
            <Pagination
              sx={{
                "&& .Mui-selected": {
                  backgroundColor: theme.palette.colors.brand.primary,
                  color: theme.palette.colors.text.white,
                },
              }}
              page={page}
              onChange={onPageChange}
              count={getReviewListObj.data.pagination.totalPages}
              variant="outlined"
            />
          </CustomFooter>
        </>
      );
    }
    return <TableLoader />;
  };

  return (
    <PaddedView multiples={3}>
      <Spacer position="top" size="m" />
      <TableTitle title="Reviews" />
      <Spacer position="top" size="xl" />
      <Form
        innerRef={formRef}
        onSubmit={onSearchReview}
        validationSchema={validationSchema}
        initialValues={{
          experience: "",
          ratings: [],
          q: "",
        }}
      >
        <DetailCardContainer>
          <Grid container spacing={2}>
            <Grid item sx={{ display: "flex", flex: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={isMobile ? 12 : 9}>
                  <SearchBar width="100%" name="experience" placeholder="Experience ID / title" />
                </Grid>
                <Grid item xs={isMobile ? 12 : 3}>
                  <FormMultipleValueSelect
                    itemList={[
                      { label: "1 Star", value: "1" },
                      { label: "2 Stars", value: "2" },
                      { label: "3 Stars", value: "3" },
                      { label: "4 Stars", value: "4" },
                      { label: "5 Stars", value: "5" },
                    ]}
                    name="ratings"
                    placeholder="Ratings"
                  />
                </Grid>
                <Grid item xs={12}>
                  <SearchBar width="100%" name="q" placeholder="Comment's keywords" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item width={useMediaQuery(theme.breakpoints.down("990")) ? "100%" : "150px"}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <BorderButton
                    height={isMobile ? "40px" : "56px"}
                    onClickButton={() => {
                      formRef.current.resetForm();
                      setTimeout(() => {
                        formRef.current.handleSubmit();
                      }, 50);
                    }}
                  >
                    <Text type="BrandColor">Clear</Text>
                  </BorderButton>
                </Grid>
                <Grid item xs={12}>
                  <CtaButton
                    height={isMobile ? "40px" : "56px"}
                    onClickButton={() => formRef.current.handleSubmit()}
                  >
                    <Text type="WhiteColor">Search</Text>
                  </CtaButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DetailCardContainer>
      </Form>
      <Spacer position="top" size="l" />
      <DialogModal
        showModal={showUpdateConfirmModal}
        setShowModal={setShowUpdateConfirmModal}
        title="Update Review Confirmation"
        message="Are you sure to approve / reject this review?"
        hint="Once update, the review cannot be approve / reject anymore."
        onConfirm={() => onUpdateReview(reviewItem.id, reviewItem.toStatus)}
        disabled={updateReviewObj.status === "pending"}
        buttonText="Confirm"
      />
      <TableWrapper>
        <Spacer position="top" size="m" />
        <AntTabs value={currentStatus} onChange={onChangeStatus}>
          <AntTab value="all" label="All" />
          <AntTab value="approve" label="Approved" />
          <AntTab value="pending" label="Pending" />
          <AntTab value="reject" label="Rejected" />
        </AntTabs>
        <Spacer position="top" size="xl" />
        {renderContent()}
      </TableWrapper>{" "}
    </PaddedView>
  );
}
