import { Box, Grid, styled, useTheme } from "@mui/material";
import React from "react";

import DetailCardContainer from "../../../components/detail-card-container.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";

import CenteredRowBox from "../../../components/centered-row-box.component";
import SkeletonLoader from "../../../components/skeleton.component";
import PromoRedemptionListLoader from "./promo-redemption-list-loader.component";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-end",
});

const FlexStartRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
});

export default function PromoCodeEditLoader() {
  const theme = useTheme();

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <SpaceBetweenBox>
            <Text variant="h4">Edit Promo Code</Text>
          </SpaceBetweenBox>
        </Grid>
        <Spacer size="l" position="top" />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <DetailCardContainer>
              <Grid item xs={12}>
                <CenteredRowBox>
                  <Text sx={{ fontSize: theme.fonts.fontSizes.size18 }}>Code</Text>
                  <Spacer size="s" position="left" />
                </CenteredRowBox>
                <Spacer size="xs" position="top" />
                <SkeletonLoader width="100%" height="40px" />
              </Grid>
            </DetailCardContainer>
          </Grid>
          <Grid item xs={12}>
            <DetailCardContainer>
              <Grid item xs={12}>
                <CenteredRowBox>
                  <Text sx={{ fontSize: theme.fonts.fontSizes.size18 }}>Title</Text>
                  <Spacer size="s" position="left" />
                </CenteredRowBox>
                <Spacer size="xs" position="top" />
                <SkeletonLoader width="100%" height="40px" />
              </Grid>
            </DetailCardContainer>
          </Grid>
          <Grid item xs={12}>
            <DetailCardContainer>
              <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                <CenteredRowBox>
                  <Text sx={{ fontSize: theme.fonts.fontSizes.size18 }}>Description</Text>
                  <Spacer size="s" position="left" />
                </CenteredRowBox>
                <Spacer size="xs" position="top" />
                <SkeletonLoader width="100%" height="200px" />
              </Box>
            </DetailCardContainer>
          </Grid>
          <Grid item xs={12}>
            <DetailCardContainer>
              <CenteredRowBox>
                <Text sx={{ fontSize: theme.fonts.fontSizes.size18 }}>Promo Period</Text>
              </CenteredRowBox>
              <Spacer size="m" position="top" />
              <FlexStartRowBox>
                <Grid item xs={6}>
                  <Text>Start Date</Text>
                  <Spacer size="xs" position="top" />
                  <SkeletonLoader width="100%" height="40px" />
                </Grid>
                <Spacer size="xl" position="left" />
                <Grid item xs={6}>
                  <Text>End Date</Text>
                  <Spacer size="xs" position="top" />
                  <SkeletonLoader width="100%" height="40px" />
                </Grid>
              </FlexStartRowBox>
              <Spacer size="l" position="top" />
              <FlexStartRowBox>
                <Grid item xs={6}>
                  <Text>Start Time</Text>
                  <Spacer size="xs" position="top" />
                  <SkeletonLoader width="100%" height="40px" />
                </Grid>
                <Spacer size="xl" position="left" />
                <Grid item xs={6}>
                  <Text>End Time</Text>
                  <Spacer size="xs" position="top" />
                  <SkeletonLoader width="100%" height="40px" />
                </Grid>
              </FlexStartRowBox>
            </DetailCardContainer>
          </Grid>
          <Grid item xs={12}>
            <DetailCardContainer>
              <CenteredRowBox>
                <Text sx={{ fontSize: theme.fonts.fontSizes.size18 }}>Promo Details</Text>
              </CenteredRowBox>
              <Spacer size="m" position="top" />
              <FlexStartRowBox>
                <Grid item xs={4}>
                  <Text>Type</Text>
                  <Spacer size="xs" position="top" />
                  <SkeletonLoader width="100%" height="40px" />
                </Grid>
                <Spacer size="xl" position="left" />
                <Grid item xs={4}>
                  <Text>Discount</Text>
                  <Spacer size="xs" position="top" />
                  <SkeletonLoader width="100%" height="40px" />
                </Grid>
                <Spacer size="xl" position="left" />
                <Grid item xs={4}>
                  <Text>Max cap amount</Text>
                  <Spacer size="xs" position="top" />
                  <SkeletonLoader width="100%" height="40px" />
                </Grid>
              </FlexStartRowBox>
              <Spacer size="l" position="top" />
              <FlexStartRowBox>
                <Grid item xs={4}>
                  <Text>Max No. Claims</Text>
                  <Spacer size="xs" position="top" />
                  <SkeletonLoader width="100%" height="40px" />
                </Grid>
                <Spacer size="xl" position="left" />
                <Grid item xs={4}>
                  <Text>Max No. Claims Per User</Text>
                  <Spacer size="xs" position="top" />
                  <SkeletonLoader width="100%" height="40px" />
                </Grid>
                <Spacer size="xl" position="left" />
                <Grid item xs={4}>
                  <Text>New User Only</Text>
                  <Spacer size="xs" position="top" />
                  <SkeletonLoader width="100%" height="40px" />
                </Grid>
              </FlexStartRowBox>
              <Spacer size="l" position="top" />
              <FlexStartRowBox>
                <Grid item xs={4}>
                  <Text>Min Spent Amount</Text>
                  <Spacer size="xs" position="top" />
                  <SkeletonLoader width="100%" height="40px" />
                </Grid>
                <Grid item xs={4} />
                <Spacer size="xl" position="left" />
                <Grid item xs={4} />
                <Spacer size="xl" position="left" />
              </FlexStartRowBox>
              <Spacer size="l" position="top" />
              <FlexStartRowBox>
                <Grid item xs={4}>
                  <Text>Users Specific</Text>
                  <Spacer size="xs" position="top" />
                  <SkeletonLoader width="100%" height="40px" />
                </Grid>
                <Spacer size="xl" position="left" />
                <Grid item xs={12}>
                  <Text>Specific User List</Text>
                  <Spacer size="xs" position="top" />
                  <SkeletonLoader width="100%" height="40px" />
                </Grid>
              </FlexStartRowBox>
              <Spacer size="l" position="top" />
              <FlexStartRowBox>
                <Grid item xs={4}>
                  <Text>Experiences Specific</Text>
                  <Spacer size="xs" position="top" />
                  <SkeletonLoader width="100%" height="40px" />
                </Grid>
                <Spacer size="xl" position="left" />
                <Grid item xs={12}>
                  <Text>Specific Experience List</Text>
                  <Spacer size="xs" position="top" />
                  <SkeletonLoader width="100%" height="40px" />
                </Grid>
              </FlexStartRowBox>
            </DetailCardContainer>
          </Grid>
          <PromoRedemptionListLoader />
          <Grid item xs={12} />
        </Grid>
      </Grid>
    </Grid>
  );
}
