import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../api-services";

export const getCategoryList = createAsyncThunk("employees/category/list", async () => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/categories/list`,
  );
  return response;
});

export const getSubcategoryList = createAsyncThunk(
  "employees/category/subcategory/list",
  async (categoryId) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/categories/${categoryId}/subcategories/list`,
    );
    return response;
  },
);

export const searchCategory = createAsyncThunk("employees/category/search", async (payload) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/categories?q=${payload.q}&page=${payload.page}`,
  );
  return response;
});

export const getSubcategoryIndex = createAsyncThunk(
  "employees/category/subcategory",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/categories/${payload.categoryId}/subcategories?page=${payload.page}`,
    );
    return response;
  },
);

export const deleteCategory = createAsyncThunk("employees/category/delete", async (categoryId) => {
  const response = await delReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/categories/${categoryId}`,
  );
  return response;
});

export const deleteSubcategory = createAsyncThunk(
  "employees/category/subcategory/delete",
  async (payload) => {
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/categories/${payload.categoryId}/subcategories/${payload.deleteSubcategoryId}`,
    );
    return response;
  },
);

export const createCategory = createAsyncThunk("employees/category/create", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/categories`,
    payload,
  );
  return response;
});

export const createSubcategory = createAsyncThunk(
  "employees/category/subcategory/create",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/categories/${payload.categoryId}/subcategories`,
      payload,
    );
    return response;
  },
);

export const getCategoryDetail = createAsyncThunk(
  "employees/category/detail",
  async (categoryId) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/categories/${categoryId}`,
    );
    return response;
  },
);

export const getSubcategoryDetail = createAsyncThunk(
  "employees/category/subcategory/detail",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/categories/${payload.categoryId}/subcategories/${payload.subcategoryId}`,
    );
    return response;
  },
);

export const getCategoryExperienceList = createAsyncThunk(
  "employees/category/experienceList",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/categories/${payload.categoryId}/experiences?page=${payload.page}`,
    );
    return response;
  },
);

export const getSubcategoryExperienceList = createAsyncThunk(
  "employees/category/subcategory/experienceList",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/categories/${payload.categoryId}/subcategories/${payload.subcategoryId}/experiences?page=${payload.page}`,
    );
    return response;
  },
);

export const updateCategory = createAsyncThunk("employees/category/update", async (payload) => {
  const response = await putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/categories/${payload.categoryId}`,
    payload,
  );
  return response;
});

export const updateSubcategory = createAsyncThunk(
  "employees/category/subcategory/update",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/categories/${payload.categoryId}/subcategories/${payload.subcategoryId}`,
      payload,
    );
    return response;
  },
);

export const uploadCategoryImage = createAsyncThunk(
  "employees/category/upload_image",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/categories/${payload.categoryId}/images`,
      payload,
    );
    return response;
  },
);

export const uploadSubcategoryImage = createAsyncThunk(
  "employees/category/subcategory/upload_image",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/categories/${payload.categoryId}/subcategories/${payload.subcategoryId}/images`,
      payload,
    );
    return response;
  },
);

export const deleteCategoryImage = createAsyncThunk(
  "employees/category/delete_image",
  async (payload) => {
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/categories/${payload.categoryId}/images/${payload.imageId}`,
      payload,
    );
    return response;
  },
);

export const deleteSubcategoryImage = createAsyncThunk(
  "employees/category/subcategory/delete_image",
  async (payload) => {
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/categories/${payload.categoryId}/subcategories/${payload.subcategoryId}/images/${payload.imageId}`,
      payload,
    );
    return response;
  },
);

export const updateCategoryImageOrder = createAsyncThunk(
  "employees/category/update_image_order",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/categories/${payload.categoryId}/images/${payload.imageId}/reorder?type=${payload.orderType}`,
      payload,
    );
    return response;
  },
);

export const updateSubcategoryImageOrder = createAsyncThunk(
  "employees/category/subcategory/update_image_order",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/categories/${payload.categoryId}/subcategories/${payload.subcategoryId}/images/${payload.imageId}/reorder?type=${payload.orderType}`,
      payload,
    );
    return response;
  },
);

const categorySlice = createSlice({
  name: "category",
  initialState: {
    getCategoryListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getSubcategoryListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    searchCategoryObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getSubcategoryIndexObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteCategoryObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteSubcategoryObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createCategoryObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createSubcategoryObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getCategoryDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getSubcategoryDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getCategoryExperienceListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getSubcategoryExperienceListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateCategoryObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateSubcategoryObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    uploadCategoryImageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    uploadSubcategoryImageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteCategoryImageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteSubcategoryImageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateCategoryImageOrderObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateSubcategoryImageOrderObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getCategoryList.pending]: (state) => {
      state.getCategoryListObj.status = "pending";
    },
    [getCategoryList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getCategoryListObj.status = "succeeded";
      state.getCategoryListObj.data = data;
      state.getCategoryListObj.successMessage = message;
    },
    [getCategoryList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getCategoryListObj.status = "failed";
      state.getCategoryListObj.errorMessage = message;
    },
    [getSubcategoryList.pending]: (state) => {
      state.getSubcategoryListObj.status = "pending";
    },
    [getSubcategoryList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getSubcategoryListObj.status = "succeeded";
      state.getSubcategoryListObj.data = data;
      state.getSubcategoryListObj.successMessage = message;
    },
    [getSubcategoryList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getSubcategoryListObj.status = "failed";
      state.getSubcategoryListObj.errorMessage = message;
    },
    [searchCategory.pending]: (state) => {
      state.searchCategoryObj.status = "pending";
    },
    [searchCategory.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.searchCategoryObj.status = "succeeded";
      state.searchCategoryObj.data = data;
      state.searchCategoryObj.successMessage = message;
    },
    [searchCategory.rejected]: (state, action) => {
      const { message } = action.error;

      state.searchCategoryObj.status = "failed";
      state.searchCategoryObj.errorMessage = message;
    },
    [getSubcategoryIndex.pending]: (state) => {
      state.getSubcategoryIndexObj.status = "pending";
    },
    [getSubcategoryIndex.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getSubcategoryIndexObj.status = "succeeded";
      state.getSubcategoryIndexObj.data = data;
      state.getSubcategoryIndexObj.successMessage = message;
    },
    [getSubcategoryIndex.rejected]: (state, action) => {
      const { message } = action.error;

      state.getSubcategoryIndexObj.status = "failed";
      state.getSubcategoryIndexObj.errorMessage = message;
    },
    [deleteCategory.pending]: (state) => {
      state.deleteCategoryObj.status = "pending";
    },
    [deleteCategory.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteCategoryObj.status = "succeeded";
      state.deleteCategoryObj.data = data;
      state.deleteCategoryObj.successMessage = message;

      state.searchCategoryObj.data = data;
    },
    [deleteCategory.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteCategoryObj.status = "failed";
      state.deleteCategoryObj.errorMessage = message;
    },
    [deleteSubcategory.pending]: (state) => {
      state.deleteSubcategoryObj.status = "pending";
    },
    [deleteSubcategory.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteSubcategoryObj.status = "succeeded";
      state.deleteSubcategoryObj.data = data;
      state.deleteSubcategoryObj.successMessage = message;

      state.getSubcategoryIndexObj.data = data;
    },
    [deleteSubcategory.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteSubcategoryObj.status = "failed";
      state.deleteSubcategoryObj.errorMessage = message;
    },
    [createCategory.pending]: (state) => {
      state.createCategoryObj.status = "pending";
    },
    [createCategory.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createCategoryObj.status = "succeeded";
      state.createCategoryObj.data = data;
      state.createCategoryObj.successMessage = message;

      state.searchCategoryObj.data = data;
    },
    [createCategory.rejected]: (state, action) => {
      const { message } = action.error;

      state.createCategoryObj.status = "failed";
      state.createCategoryObj.errorMessage = message;
    },
    [createSubcategory.pending]: (state) => {
      state.createSubcategoryObj.status = "pending";
    },
    [createSubcategory.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createSubcategoryObj.status = "succeeded";
      state.createSubcategoryObj.data = data;
      state.createSubcategoryObj.successMessage = message;

      state.getSubcategoryIndexObj.data = data;
    },
    [createSubcategory.rejected]: (state, action) => {
      const { message } = action.error;

      state.createSubcategoryObj.status = "failed";
      state.createSubcategoryObj.errorMessage = message;
    },
    [getCategoryDetail.pending]: (state) => {
      state.getCategoryDetailObj.status = "pending";
    },
    [getCategoryDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getCategoryDetailObj.status = "succeeded";
      state.getCategoryDetailObj.data = data;
      state.getCategoryDetailObj.successMessage = message;
    },
    [getCategoryDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.getCategoryDetailObj.status = "failed";
      state.getCategoryDetailObj.errorMessage = message;
    },
    [getSubcategoryDetail.pending]: (state) => {
      state.getSubcategoryDetailObj.status = "pending";
    },
    [getSubcategoryDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getSubcategoryDetailObj.status = "succeeded";
      state.getSubcategoryDetailObj.data = data;
      state.getSubcategoryDetailObj.successMessage = message;
    },
    [getSubcategoryDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.getSubcategoryDetailObj.status = "failed";
      state.getSubcategoryDetailObj.errorMessage = message;
    },
    [getCategoryExperienceList.pending]: (state) => {
      state.getCategoryExperienceListObj.status = "pending";
    },
    [getCategoryExperienceList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getCategoryExperienceListObj.status = "succeeded";
      state.getCategoryExperienceListObj.data = data;
      state.getCategoryExperienceListObj.successMessage = message;
    },
    [getCategoryExperienceList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getCategoryExperienceListObj.status = "failed";
      state.getCategoryExperienceListObj.errorMessage = message;
    },
    [getSubcategoryExperienceList.pending]: (state) => {
      state.getSubcategoryExperienceListObj.status = "pending";
    },
    [getSubcategoryExperienceList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getSubcategoryExperienceListObj.status = "succeeded";
      state.getSubcategoryExperienceListObj.data = data;
      state.getSubcategoryExperienceListObj.successMessage = message;
    },
    [getSubcategoryExperienceList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getSubcategoryExperienceListObj.status = "failed";
      state.getSubcategoryExperienceListObj.errorMessage = message;
    },
    [updateCategory.pending]: (state) => {
      state.updateCategoryObj.status = "pending";
    },
    [updateCategory.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateCategoryObj.status = "succeeded";
      state.updateCategoryObj.data = data;
      state.updateCategoryObj.successMessage = message;
    },
    [updateCategory.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateCategoryObj.status = "failed";
      state.updateCategoryObj.errorMessage = message;
    },
    [updateSubcategory.pending]: (state) => {
      state.updateSubcategoryObj.status = "pending";
    },
    [updateSubcategory.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateSubcategoryObj.status = "succeeded";
      state.updateSubcategoryObj.data = data;
      state.updateSubcategoryObj.successMessage = message;
    },
    [updateSubcategory.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateSubcategoryObj.status = "failed";
      state.updateSubcategoryObj.errorMessage = message;
    },
    [uploadCategoryImage.pending]: (state) => {
      state.uploadCategoryImageObj.status = "pending";
    },
    [uploadCategoryImage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.uploadCategoryImageObj.status = "succeeded";
      state.uploadCategoryImageObj.data = data;
      state.uploadCategoryImageObj.successMessage = message;

      state.getCategoryDetailObj.data = data;
    },
    [uploadCategoryImage.rejected]: (state, action) => {
      const { message } = action.error;

      state.uploadCategoryImageObj.status = "failed";
      state.uploadCategoryImageObj.errorMessage = message;
    },
    [uploadSubcategoryImage.pending]: (state) => {
      state.uploadSubcategoryImageObj.status = "pending";
    },
    [uploadSubcategoryImage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.uploadSubcategoryImageObj.status = "succeeded";
      state.uploadSubcategoryImageObj.data = data;
      state.uploadSubcategoryImageObj.successMessage = message;

      state.getSubcategoryDetailObj.data = data;
    },
    [uploadSubcategoryImage.rejected]: (state, action) => {
      const { message } = action.error;

      state.uploadSubcategoryImageObj.status = "failed";
      state.uploadSubcategoryImageObj.errorMessage = message;
    },
    [deleteCategoryImage.pending]: (state) => {
      state.deleteCategoryImageObj.status = "pending";
    },
    [deleteCategoryImage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteCategoryImageObj.status = "succeeded";
      state.deleteCategoryImageObj.data = data;
      state.deleteCategoryImageObj.successMessage = message;

      state.getCategoryDetailObj.data = data;
    },
    [deleteCategoryImage.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteCategoryImageObj.status = "failed";
      state.deleteCategoryImageObj.errorMessage = message;
    },
    [deleteSubcategoryImage.pending]: (state) => {
      state.deleteSubcategoryImageObj.status = "pending";
    },
    [deleteSubcategoryImage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteSubcategoryImageObj.status = "succeeded";
      state.deleteSubcategoryImageObj.data = data;
      state.deleteSubcategoryImageObj.successMessage = message;

      state.getSubcategoryDetailObj.data = data;
    },
    [deleteSubcategoryImage.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteSubcategoryImageObj.status = "failed";
      state.deleteSubcategoryImageObj.errorMessage = message;
    },
    [updateCategoryImageOrder.pending]: (state) => {
      state.updateCategoryImageOrderObj.status = "pending";
    },
    [updateCategoryImageOrder.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateCategoryImageOrderObj.status = "succeeded";
      state.updateCategoryImageOrderObj.data = data;
      state.updateCategoryImageOrderObj.successMessage = message;

      state.getCategoryDetailObj.data = data;
    },
    [updateCategoryImageOrder.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateCategoryImageOrderObj.status = "failed";
      state.updateCategoryImageOrderObj.errorMessage = message;
    },
    [updateSubcategoryImageOrder.pending]: (state) => {
      state.updateSubcategoryImageOrderObj.status = "pending";
    },
    [updateSubcategoryImageOrder.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateSubcategoryImageOrderObj.status = "succeeded";
      state.updateSubcategoryImageOrderObj.data = data;
      state.updateSubcategoryImageOrderObj.successMessage = message;

      state.getSubcategoryDetailObj.data = data;
    },
    [updateSubcategoryImageOrder.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateSubcategoryImageOrderObj.status = "failed";
      state.updateSubcategoryImageOrderObj.errorMessage = message;
    },
  },
});

export default categorySlice.reducer;

// state
export const categorySelector = (state) => state.category;
