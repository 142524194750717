import {
  Box,
  Grid,
  IconButton,
  Link,
  styled,
  useTheme,
  Table,
  TableBody,
  Pagination,
  TextField,
} from "@mui/material";
import { format } from "date-fns";
import React, { useContext, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import * as Yup from "yup";

import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DetailCardContainer from "../../../components/detail-card-container.component";
import FormFieldTextWithCounter from "../../../components/forms/form-field-text-with-counter.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import PaddedView from "../../../components/padded-view.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";

import FormFieldTextMultiline from "../../../components/forms/form-field-text-with-multiline.component";
import Loading from "../../../components/notifications/loading.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import {
  getPromoCodeDetail,
  updatePromoCode,
  deletePromoCode,
  getPromoCodeRedemptionList,
  promoCodeSelector,
} from "../../../services/promo-code/promo-code-slice.services";
import PromoDetailsForm from "../components/promo-details-form.component";
import PromoPeriodForm from "../components/promo-period-form.component";
import CenteredRowBox from "../../../components/centered-row-box.component";
import BorderButton from "../../../components/buttons/border-button.component";
import RedBorderButton from "../../../components/buttons/red-border-button.component";
import DeleteConfirmationModal from "../../../components/notifications/delete-confirmation-modal.component";
import TableHeader from "../../../components/table/table-header.component";
import PromoCodeRedemptionRow from "../components/promo-code-redemption-row.component";
import PromoCodeEditLoader from "../loader/promo-edit-loader.component";
import PromoRedemptionListLoader from "../loader/promo-redemption-list-loader.component";

const ButtonContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  marginTop: "20px",
});

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-end",
});

const StyledTextField = styled(TextField)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    backgroundColor: theme.palette.colors.text.white,
  },
}));

const titleValidationSchema = Yup.object().shape({
  title: Yup.string().required().max(50).label("Title"),
});

const descriptionValidationSchema = Yup.object().shape({
  description: Yup.string().required().label("Description"),
});

const promoPeriodValidationSchema = Yup.object().shape({
  startDate: Yup.date().label("Start Date").nullable(),
  startTime: Yup.date().required().label("Start Time"),
  endDate: Yup.date()
    .label("End Date")
    .min(Yup.ref("startDate"), "End date can't earlier than start date")
    .required(),
  endTime: Yup.date().label("End Time").required(),
});

const promoRulesValidationSchema = Yup.object().shape({
  type: Yup.string().label("Type").required(),
  discount: Yup.number()
    .required()
    .when("type", {
      is: "percentage",
      then: Yup.number().min(0).max(100).required(),
    })
    .when("type", {
      is: "fixed amount",
      then: Yup.number().min(1).required(),
    })
    .label("Discount"),
  maxCapAmount: Yup.number().when("type", {
    is: "percentage",
    then: Yup.number().min(0).required(),
  }),
  maxNoClaims: Yup.number().min(0).required(),
  maxNoClaimsPerUser: Yup.number().min(0).required(),
  newUserOnly: Yup.string().label("New User Only").required(),
  minSpentAmount: Yup.number().min(0).required(),
  usersSpecific: Yup.string().label("Users Specific").required(),
  experiencesSpecific: Yup.string().label("Experiences Specific").required(),
  usersList: Yup.array()
    .of(Yup.object().shape({ id: Yup.number() }))
    .when("usersSpecific", {
      is: "Yes",
      then: Yup.array()
        .of(Yup.object().shape({ id: Yup.number() }))
        .required()
        .min(1),
    })
    .label("Specific User List"),
  experiencesList: Yup.array()
    .of(Yup.object().shape({ id: Yup.number() }))
    .when("experiencesSpecific", {
      is: "Yes",
      then: Yup.array()
        .of(Yup.object().shape({ id: Yup.number() }))
        .required()
        .min(1),
    })
    .label("Specific Experience List"),
});

export default function PromoCodeEditScreen() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const createSnackBar = useContext(SnackbarContext);
  const location = useLocation();
  const history = useHistory();
  const promoCodeId = new URLSearchParams(location.search).get("promoCodeId");
  const { getPromoCodeDetailObj, deletePromoCodeObj, getPromoCodeRedemptionListObj } =
    useSelector(promoCodeSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [isUserSpecific, setIsUserSpecific] = useState(false);
  const [isExperienceSpecific, setIsExperienceSpecific] = useState(false);
  const [isEditing, setIsEditing] = useState({
    title: false,
    description: false,
    promoPeriod: false,
    promoRules: false,
  });
  const formRef = useRef();
  const titleFormRef = useRef();
  const descriptionFormRef = useRef();
  const [page, setPage] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    dispatch(getPromoCodeDetail(promoCodeId)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
      if (meta.requestStatus === "fulfilled") {
        setSelectedType(payload.data.type.replace("_", " "));
        setIsUserSpecific(payload.data.usersSpecific);
        setIsExperienceSpecific(payload.data.experiencesSpecific);
      }
    });
    dispatch(getPromoCodeRedemptionList({ promoCodeId, page: 1 })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  }, []);

  const onUpdatePromoCode = (values) => {
    setIsLoading(true);
    let usersList = [];
    let experiencesList = [];
    if (values.params === "promoRules") {
      if (isUserSpecific) {
        usersList = values.usersList.map((item) => item.id);
      }
      if (isExperienceSpecific) {
        experiencesList = values.experiencesList.map((item) => item.id);
      }
      if (selectedType === "fixed amount") values.maxCapAmount = values.discount;
    } else if (values.params === "promoPeriod") {
      values.startAt = `${format(new Date(values.startDate), "yyyy-MM-dd")} ${format(
        new Date(values.startTime),
        "HH:mm:ss",
      )}`;
      values.endAt = `${format(new Date(values.endDate), "yyyy-MM-dd")} ${format(
        new Date(values.endTime),
        "HH:mm:ss",
      )}`;
    }

    if (values.params !== "promoRules") {
      dispatch(
        updatePromoCode({
          ...values,
          promoCodeId,
        }),
      ).then(({ meta, error, payload }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          setIsEditing((i) => ({ ...i, [values.params]: false }));
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
    } else {
      dispatch(
        updatePromoCode({
          ...values,
          promoCodeId,
          newUserOnly: values.newUserOnly === "Yes",
          usersSpecific: isUserSpecific,
          experiencesSpecific: isExperienceSpecific,
          usersList,
          experiencesList,
        }),
      ).then(({ meta, error, payload }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          setIsEditing((i) => ({ ...i, [values.params]: false }));
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
    }
  };

  const onConfirmDelete = () => {
    setIsLoading(true);
    dispatch(deletePromoCode(promoCodeId)).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        history.push(routes.PROMO_CODE_LIST);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const onPageChange = (e, newPage) => {
    setPage(newPage);
    dispatch(getPromoCodeRedemptionList({ promoCodeId, page: newPage })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const renderContent = () => (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <SpaceBetweenBox>
            <Text variant="h4">Edit Promo Code</Text>
            {getPromoCodeDetailObj.data.deletable && (
              <RedBorderButton width="100px" onClickButton={() => setShowDeleteModal(true)}>
                <Text type="RejectText">Delete</Text>
              </RedBorderButton>
            )}
          </SpaceBetweenBox>
        </Grid>
        <Spacer size="l" position="top" />
        <DeleteConfirmationModal
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          label="Are you sure to delete this promo code?"
          isLoading={deletePromoCodeObj.status === "pending"}
          onConfirmClicked={onConfirmDelete}
        />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <DetailCardContainer>
              <Grid item xs={12}>
                <Text sx={{ fontSize: theme.fonts.fontSizes.size18 }}>Code</Text>
                <Spacer size="xs" position="top" />
                <StyledTextField
                  disabled={true}
                  maxLength={6}
                  value={getPromoCodeDetailObj.data.code}
                  width="100%"
                />
              </Grid>
            </DetailCardContainer>
          </Grid>
          <Grid item xs={12}>
            <Form
              innerRef={titleFormRef}
              validationSchema={titleValidationSchema}
              onSubmit={onUpdatePromoCode}
              initialValues={{
                params: "title",
                title: getPromoCodeDetailObj.data.title,
              }}
            >
              <DetailCardContainer>
                <Grid item xs={12}>
                  <CenteredRowBox>
                    <Text sx={{ fontSize: theme.fonts.fontSizes.size18 }}>Title</Text>
                    <Spacer size="s" position="left" />
                    <IconButton
                      sx={{ color: theme.palette.colors.text.primary }}
                      disabled={isEditing.title}
                      onClick={() => setIsEditing((i) => ({ ...i, title: true }))}
                    >
                      <ModeEditIcon />
                    </IconButton>
                  </CenteredRowBox>
                  <Spacer size="xs" position="top" />
                  <FormFieldTextWithCounter
                    disabled={!isEditing.title}
                    maxLength={50}
                    name="title"
                    placeholder="Enter Promo Code Title"
                    width="100%"
                  />
                  {isEditing.title && (
                    <ButtonContainer>
                      <BorderButton
                        width="100px"
                        onClickButton={() => {
                          titleFormRef.current.resetForm();
                          setIsEditing((i) => ({ ...i, title: false }));
                        }}
                      >
                        <Text type="BrandColor">Cancel</Text>
                      </BorderButton>
                      <Spacer size="s" position="left" />
                      <FormSubmitButton width="100px">
                        <Text type="WhiteColor">Save</Text>
                      </FormSubmitButton>
                    </ButtonContainer>
                  )}
                </Grid>
              </DetailCardContainer>
            </Form>
          </Grid>
          <Grid item xs={12}>
            <Form
              innerRef={descriptionFormRef}
              validationSchema={descriptionValidationSchema}
              onSubmit={onUpdatePromoCode}
              initialValues={{
                params: "description",
                description: getPromoCodeDetailObj.data.description,
              }}
            >
              <DetailCardContainer>
                <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                  <CenteredRowBox>
                    <Text sx={{ fontSize: theme.fonts.fontSizes.size18 }}>Description</Text>
                    <Spacer size="s" position="left" />
                    <IconButton
                      sx={{ color: theme.palette.colors.text.primary }}
                      disabled={isEditing.description}
                      onClick={() => setIsEditing((i) => ({ ...i, description: true }))}
                    >
                      <ModeEditIcon />
                    </IconButton>
                  </CenteredRowBox>
                  <Spacer size="xs" position="top" />
                  <FormFieldTextMultiline
                    disabled={!isEditing.description}
                    name="description"
                    placeholder="Enter promo code description here..."
                    multiline
                    rows={5}
                  />
                  {isEditing.description && (
                    <ButtonContainer>
                      <BorderButton
                        width="100px"
                        onClickButton={() => {
                          descriptionFormRef.current.resetForm();
                          setIsEditing((i) => ({ ...i, description: false }));
                        }}
                      >
                        <Text type="BrandColor">Cancel</Text>
                      </BorderButton>
                      <Spacer size="s" position="left" />
                      <FormSubmitButton width="100px">
                        <Text type="WhiteColor">Save</Text>
                      </FormSubmitButton>
                    </ButtonContainer>
                  )}
                </Box>
              </DetailCardContainer>
            </Form>
          </Grid>
          <Form
            validationSchema={promoPeriodValidationSchema}
            onSubmit={onUpdatePromoCode}
            initialValues={{
              params: "promoPeriod",
              startDate: getPromoCodeDetailObj.data.startAt,
              endDate: getPromoCodeDetailObj.data.endAt,
              startTime: getPromoCodeDetailObj.data.startAt,
              endTime: getPromoCodeDetailObj.data.endAt,
            }}
          >
            <PromoPeriodForm
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              forCreateScreen={false}
            />
          </Form>
          <Form
            innerRef={formRef}
            validationSchema={promoRulesValidationSchema}
            onSubmit={onUpdatePromoCode}
            initialValues={{
              params: "promoRules",
              type: getPromoCodeDetailObj.data.type.replace("_", " "),
              discount: parseFloat(getPromoCodeDetailObj.data.discount.replace(",", "")),
              maxCapAmount: parseFloat(getPromoCodeDetailObj.data.maxCapAmount.replace(",", "")),
              maxNoClaims: getPromoCodeDetailObj.data.maxNoClaims,
              maxNoClaimsPerUser: getPromoCodeDetailObj.data.maxNoClaimsPerUser,
              newUserOnly: getPromoCodeDetailObj.data.newUserOnly ? "Yes" : "No",
              minSpentAmount: parseFloat(
                getPromoCodeDetailObj.data.minSpentAmount.replace(",", ""),
              ),
              usersSpecific: getPromoCodeDetailObj.data.usersSpecific ? "Yes" : "No",
              experiencesSpecific: getPromoCodeDetailObj.data.experiencesSpecific ? "Yes" : "No",
              usersList: getPromoCodeDetailObj.data.usersList,
              experiencesList: getPromoCodeDetailObj.data.experiencesList,
            }}
          >
            <PromoDetailsForm
              forCreateScreen={false}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              selectedType={selectedType}
              setSelectedType={setSelectedType}
              isUserSpecific={isUserSpecific}
              setIsUserSpecific={setIsUserSpecific}
              isExperienceSpecific={isExperienceSpecific}
              setIsExperienceSpecific={setIsExperienceSpecific}
            />
          </Form>
          {getPromoCodeRedemptionListObj.status === "succeeded" ? (
            <Grid item xs={12}>
              <DetailCardContainer>
                <Grid item xs={12}>
                  <Text variant="h5">Redemption List</Text>
                </Grid>
                <Table>
                  <TableHeader
                    headerCells={[
                      "Redemption ID",
                      "User Id",
                      "Username",
                      "Status",
                      "Date Redeemed",
                      "Booking Id",
                      "",
                    ]}
                  />
                  <TableBody>
                    {getPromoCodeRedemptionListObj.data.items.map((item) => (
                      <PromoCodeRedemptionRow record={item} key={item.id} />
                    ))}
                  </TableBody>
                </Table>
                <CustomFooter>
                  <Pagination
                    sx={{
                      "&& .Mui-selected": {
                        backgroundColor: theme.palette.colors.brand.primary,
                        color: theme.palette.colors.text.white,
                      },
                    }}
                    page={page}
                    onChange={onPageChange}
                    count={getPromoCodeRedemptionListObj.data.pagination.totalPages}
                    variant="outlined"
                  />
                </CustomFooter>
              </DetailCardContainer>
            </Grid>
          ) : (
            <PromoRedemptionListLoader />
          )}

          <Grid item xs={12} />
          <Grid item xs={12} />
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <PaddedView multiples={3}>
      <Loading isLoading={isLoading} />
      <Link href={routes.PROMO_CODE_LIST} style={{ textDecoration: "none" }}>
        <Text type="GreyColor">{`< Back`}</Text>
      </Link>
      <Spacer size="xl" position="top" />
      {getPromoCodeDetailObj.status === "succeeded" ? renderContent() : <PromoCodeEditLoader />}
    </PaddedView>
  );
}
