import { Box, Pagination, styled, Table, TableBody, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import PaddedView from "../../../components/padded-view.component";
import Spacer from "../../../components/spacer.component";
import TableHeader from "../../../components/table/table-header.component";
import TableTitle from "../../../components/table/table-title.component";
import TableWrapper from "../../../components/table/table-wrapper.component";
import { getUserList, userSelector } from "../../../services/user/user-slice.services";
import UserRow from "../components/user-row.component";
import TableLoader from "../loader/table-loader.component";

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

function UserListScreen() {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [page, setPage] = useState(1);
  const { getUserListObj } = useSelector(userSelector);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const theme = useTheme();

  const onChangeSearch = (q) => {
    dispatch(getUserList({ q, page: 1 })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
    setSearchKeyword(q);
    setPage(1);
  };

  const onPageChange = (e, newPage) => {
    dispatch(getUserList({ q: searchKeyword, page: newPage })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
    setPage(newPage);
  };

  const renderContent = () => {
    if (getUserListObj.status === "succeeded") {
      return (
        <>
          <Table>
            <TableHeader
              headerCells={[
                "User ID",
                "Name",
                "Email",
                "Phone Number",
                "Date Registered",
                "Email Verified",
                "Action",
              ]}
            />
            <TableBody>
              {getUserListObj.data.items.map((item) => (
                <UserRow record={item} key={item.id} />
              ))}
            </TableBody>
          </Table>
          <CustomFooter>
            <Pagination
              sx={{
                "&& .Mui-selected": {
                  backgroundColor: theme.palette.colors.brand.primary,
                  color: theme.palette.colors.text.white,
                },
              }}
              page={page}
              onChange={onPageChange}
              count={getUserListObj.data.pagination.totalPages}
              variant="outlined"
            />
          </CustomFooter>
        </>
      );
    }

    return <TableLoader />;
  };

  useEffect(() => {
    onChangeSearch("");
  }, []);

  return (
    <PaddedView multiples={3}>
      <Spacer position="top" size="m" />
      <TableTitle
        title="Users"
        onChangeSearch={onChangeSearch}
        searchKeyword={searchKeyword}
        placeholder="Search Users"
      />
      <Spacer position="top" size="xl" />
      <TableWrapper>{renderContent()}</TableWrapper>
    </PaddedView>
  );
}

export default UserListScreen;
