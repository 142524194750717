import { Avatar, Card, CardContent, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";
import React from 'react';

const CardContainer = styled(Card)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
}));

const IconContainer = styled(Avatar)(({ theme, bgcolor }) => ({
    marginRight: theme.spacing(2),
    backgroundColor: bgcolor,
    color: '#fff',
}));

function InfoCard({ record }) {
    //   const InfoCard = ({ record }) => {
    const { title, value, icon, color } = record;

    return (
        <CardContainer>
            <IconContainer bgcolor={color}>
                {icon}
            </IconContainer>
            <CardContent>
                <Typography variant="body1" component="div">
                    {title}
                </Typography>
                <Typography variant="h5" component="div">
                    {value}
                </Typography>
            </CardContent>
        </CardContainer>
    );
};

InfoCard.defaultProps = {
    record: {
        id: null,
        title: null,
        value: null,
        icon: null,
        color: null,
    },
};

InfoCard.propTypes = {
    record: PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        value: PropTypes.number,
        icon: PropTypes.element,
        color: PropTypes.string,
    }),
};

export default InfoCard;


// function InfoCard({ record }) {
//     const classes = useStyles();
//     return (
//         // <TableRow
//         //     key={record.statementId}
//         //     sx={{
//         //         "& .MuiTableCell-root": {
//         //             borderBottom: "no-set",
//         //         },
//         //     }}
//         // >
//         //     <TableCell width="100px">
//         //         <Text variant="body2" sx={{ textAlign: "center" }}>
//         //             {record.statementId}
//         //         </Text>
//         //     </TableCell>
//         //     <TableCell>
//         //         <Text variant="body2" sx={{ textAlign: "center" }}>
//         //             {record.revenue}
//         //         </Text>
//         //     </TableCell>
//         //     <TableCell>
//         //         <Text variant="body2" sx={{ textAlign: "center" }}>
//         //             ({record.merchantSum})
//         //         </Text>
//         //     </TableCell>
//         //     <TableCell>
//         //         <Text variant="body2" sx={{ textAlign: "center" }}>
//         //             {record.platformCommission}
//         //         </Text>
//         //     </TableCell>
//         //     <TableCell>
//         //         <Text variant="body2" sx={{ textAlign: "center" }}>
//         //             ({record.promoCode})
//         //         </Text>
//         //     </TableCell>
//         //     <TableCell>
//         //         <Text variant="body2" sx={{ textAlign: "center" }}>
//         //             {record.grossProfit}
//         //         </Text>
//         //     </TableCell>
//         // </TableRow>
//         <Card>
//             <div className={classes.card} style={{ backgroundColor: '#fff' }}>
//                 <Avatar className={classes.icon} style={{ backgroundColor: '#ffff00' }}>
//                     {/* {record.icon} */}
//                     {Person}
//                 </Avatar>
//                 <CardContent className={classes.cardContent}>
//                     <Typography variant="body1" component="div">
//                         {record.title}
//                     </Typography>
//                     <Typography variant="h5" component="div">
//                         {record.value}
//                     </Typography>
//                 </CardContent>
//             </div>
//         </Card>
//     );
// }


// InfoCard.defaultProps = {
//     record: {
//         id: null,
//         title: null,
//         value: null,
//         icon: null
//     },
// };

// InfoCard.propTypes = {
//     record: PropTypes.shape({
//         id: PropTypes.number,
//         title: PropTypes.string,
//         value: PropTypes.number,
//         icon: PropTypes.string,
//     }),
// };

// function InfoCard({ record }) {
//     const classes = useStyles();
//     const { title, value, icon, color } = record;

//     return (
//         <Card>
//             <div className={classes.card} style={{ backgroundColor: color }}>
//                 <Avatar className={classes.icon} style={{ backgroundColor: color }}>
//                     {icon}
//                 </Avatar>
//                 <CardContent className={classes.cardContent}>
//                     <Typography variant="body1" component="div">
//                         {title}
//                     </Typography>
//                     <Typography variant="h5" component="div">
//                         {value}
//                     </Typography>
//                 </CardContent>
//             </div>
//         </Card>
//     );
// }


// InfoCard.defaultProps = {
//     record: {
//         id: null,
//         title: null,
//         value: null,
//         icon: null,
//         color: null,
//     },
// };

// InfoCard.propTypes = {
//     record: PropTypes.shape({
//         id: PropTypes.number,
//         title: PropTypes.string,
//         value: PropTypes.number,
//         icon: PropTypes.element,
//         color: PropTypes.string,
//     }),
// };

// export default InfoCard;



