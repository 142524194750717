import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  styled,
  useTheme,
  Divider,
} from "@mui/material";
import PropTypes from "prop-types";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useFormikContext } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import { categorySelector } from "../../../services/category/category-slice.services";
import CenteredRowBox from "../../../components/centered-row-box.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import BorderButton from "../../../components/buttons/border-button.component";

const CategoriesCard = styled(Box)(({ theme, isMobile }) => ({
  display: "flex",
  flexDirection: "row",
  backgroundColor: theme.palette.colors.bg.white,
  alignItems: "center",
  paddingLeft: theme.padding.paddingX[2],
  paddingRight: theme.padding.paddingX[2],
  width: isMobile ? "100%" : null,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  " .MuiMenuItem-root": {
    padding: "0px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  " .MuiCheckbox-root": {
    paddingLeft: "0px",
  },
}));

const ButtonContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  marginTop: "20px",
});

function ExperienceCategoryList({ setIsEditing, isEditing }) {
  const theme = useTheme();
  const { values, setFieldValue, touched, errors, resetForm } = useFormikContext();
  const { getCategoryListObj } = useSelector(categorySelector);
  const showError = touched.categories && typeof errors.categories === "string";

  const checkSubcategoryShouldShow = () => {
    const subCategoryArr = [];
    values.categories.map((checkedCategory) =>
      getCategoryListObj.data.some(
        (category) => checkedCategory.id === category.id && subCategoryArr.push(category),
      ),
    );
    return subCategoryArr;
  };

  const handleCheckbox = (data) => {
    const isChecked = values.categories.some((checkedCheckbox) => checkedCheckbox.id === data.id);
    if (isChecked) {
      setFieldValue(
        "categories",
        values.categories.filter((checkedCheckbox) => checkedCheckbox.id !== data.id),
      );
    } else {
      setFieldValue("categories", values.categories.concat({ ...data, subcategories: [] }));
    }
  };

  const handleSubcategoryCheckbox = (categoryIndex, currentSubcategory) => {
    const isChecked = values.categories[categoryIndex].subcategories.some(
      (checkedCheckbox) => checkedCheckbox.id === currentSubcategory.id,
    );
    if (isChecked) {
      setFieldValue(
        `categories[${categoryIndex}].subcategories`,
        values.categories[categoryIndex].subcategories.filter(
          (checkedCheckbox) => checkedCheckbox.id !== currentSubcategory.id,
        ),
      );
    } else {
      setFieldValue(
        `categories[${categoryIndex}].subcategories`,
        values.categories[categoryIndex].subcategories.concat(currentSubcategory),
      );
    }
  };

  const checkSubcategoryCheckboxShouldCheck = (categoryIndex, currentSubcategory) =>
    values.categories[categoryIndex].subcategories.some(
      (checkedCheckbox) => checkedCheckbox.id === currentSubcategory.id,
    );

  return (
    <>
      <CenteredRowBox>
        <Text sx={{ fontSize: theme.fonts.fontSizes.size18 }}>Category</Text>
        <Spacer size="s" position="left" />
        <IconButton
          sx={{ color: theme.palette.colors.text.primary }}
          disabled={isEditing.categories}
          onClick={() => setIsEditing((i) => ({ ...i, categories: true }))}
        >
          <ModeEditIcon />
        </IconButton>
      </CenteredRowBox>
      <Spacer size="s" position="top" />
      <FormControl required error={showError}>
        <FormGroup>
          <Grid container spacing={3}>
            {getCategoryListObj.data.map((item) => (
              <Grid item key={item.id}>
                <CategoriesCard
                  sx={{
                    border: `1px solid ${
                      values.categories.some((checkedCheckbox) => checkedCheckbox.id === item.id) &&
                      isEditing.categories
                        ? theme.palette.colors.brand.secondary
                        : theme.palette.colors.ui.border
                    }`,
                  }}
                >
                  <FormControlLabel
                    disabled={!isEditing.categories}
                    control={
                      <Checkbox
                        disableRipple
                        sx={{
                          color: theme.palette.colors.brand.secondary,
                          "&.Mui-checked": {
                            color: theme.palette.colors.brand.secondary,
                          },
                          "&.Mui-disabled": {
                            color: theme.palette.colors.ui.border,
                          },
                          ":hover": { backgroundColor: "transparent" },
                        }}
                        onChange={() => handleCheckbox(item)}
                        checked={values.categories.some(
                          (checkedCheckbox) => checkedCheckbox.id === item.id,
                        )}
                      />
                    }
                    label={<Text type={isEditing.categories ? "" : "GreyColor"}>{item.label}</Text>}
                  />
                </CategoriesCard>
              </Grid>
            ))}
          </Grid>
          <FormHelperText sx={{ marginLeft: "0px", marginTop: "10px" }}>
            {errors.categories}
          </FormHelperText>
        </FormGroup>
      </FormControl>
      <Spacer size="l" position="top" />
      <Divider />
      <Spacer size="l" position="top" />
      <Text sx={{ fontSize: theme.fonts.fontSizes.size18 }}>Subcategories</Text>
      <Spacer size="s" position="top" />
      <FormControl required error={showError}>
        <FormGroup>
          <Grid container spacing={3}>
            {checkSubcategoryShouldShow().map((category, index) =>
              category.subcategories.map((subcategory) => (
                <Grid item key={subcategory.id}>
                  <CategoriesCard
                    sx={{
                      border: `1px solid ${
                        checkSubcategoryCheckboxShouldCheck(index, subcategory) &&
                        isEditing.categories
                          ? theme.palette.colors.brand.secondary
                          : theme.palette.colors.ui.border
                      }`,
                      padding: "5px 20px",
                    }}
                  >
                    <FormControlLabel
                      disabled={!isEditing.categories}
                      control={
                        <Checkbox
                          disableRipple
                          sx={{
                            color: theme.palette.colors.brand.secondary,
                            "&.Mui-checked": {
                              color: theme.palette.colors.brand.secondary,
                            },
                            "&.Mui-disabled": {
                              color: theme.palette.colors.ui.border,
                            },
                            ":hover": { backgroundColor: "transparent" },
                          }}
                          onChange={() => handleSubcategoryCheckbox(index, subcategory)}
                          checked={checkSubcategoryCheckboxShouldCheck(index, subcategory)}
                        />
                      }
                      label={
                        <>
                          <Text type={isEditing.categories ? "" : "GreyColor"}>
                            {subcategory.label}
                          </Text>
                          <Text type="GreyColor" sx={{ fontSize: theme.fonts.fontSizes.size12 }}>
                            {category.label}
                          </Text>
                        </>
                      }
                    />
                  </CategoriesCard>
                </Grid>
              )),
            )}
          </Grid>
          <FormHelperText sx={{ marginLeft: "0px", marginTop: "20px" }}>
            {errors.categories}
          </FormHelperText>
        </FormGroup>
      </FormControl>
      {isEditing.categories && (
        <ButtonContainer>
          <BorderButton
            width="100px"
            onClickButton={() => {
              resetForm();
              setIsEditing((i) => ({ ...i, categories: false }));
            }}
          >
            <Text type="BrandColor">Cancel</Text>
          </BorderButton>
          <Spacer size="s" position="left" />
          <FormSubmitButton width="100px">
            <Text type="WhiteColor">Save</Text>
          </FormSubmitButton>
        </ButtonContainer>
      )}
    </>
  );
}

ExperienceCategoryList.propTypes = {
  setIsEditing: PropTypes.func.isRequired,
  isEditing: PropTypes.shape({
    categories: PropTypes.bool,
  }).isRequired,
};

export default ExperienceCategoryList;
