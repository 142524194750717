import { Box, Grid, IconButton, styled, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { useSelector } from "react-redux";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import ExperienceAddOnsItem from "./experience-add-ons-item.component";
import Form from "../../../components/forms/form.component";
import BorderButton from "../../../components/buttons/border-button.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import { experienceSelector } from "../../../services/experience/experience-slice.services";

const CenteredRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const validationSchema = Yup.object().shape({
  label: Yup.string().required().label("Label"),
  sublabel: Yup.string().required().label("Sub-Label"),
  price: Yup.string().required().label("Price"),
});

const ButtonContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  marginTop: "20px",
});

export default function ExperienceAddOnsList({ setIsEditing, isEditing }) {
  const theme = useTheme();
  const { values, setFieldValue, resetForm } = useFormikContext();
  const [editIndex, setEditIndex] = useState(null);
  const [isAddingAddOns, setIsAddingAddOns] = useState(false);
  const { getExperienceDetailObj } = useSelector(experienceSelector);

  const submitAddOns = (value) => {
    const index = values.addOns.findIndex((item) =>
      item.uniqueId ? value.uniqueId === item.uniqueId : item.id === value.id,
    );
    setFieldValue(`addOns[${index}]`, value);
    setEditIndex(null);
  };

  const onPushAddOns = (value, i) => {
    const tempArr = [...values.addOns];
    setFieldValue("addOns", tempArr.concat([value]));
    i.resetForm();
    setIsAddingAddOns(false);
  };

  const onRemoveAddOns = (uniqueId) => {
    setFieldValue(
      "addOns",
      values.addOns.filter((addOn) =>
        addOn.uniqueId ? addOn.uniqueId !== uniqueId : addOn.id !== uniqueId,
      ),
    );
  };

  return (
    <>
      <CenteredRowBox>
        <Text sx={{ fontSize: theme.fonts.fontSizes.size18 }}>Add-on(s)</Text>
        <Spacer size="s" position="left" />

        {isEditing.addOns ? (
          <IconButton
            disabled={isAddingAddOns}
            sx={{ color: theme.palette.colors.brand.primary }}
            onClick={() => setIsAddingAddOns(true)}
          >
            <AddBoxOutlinedIcon />
          </IconButton>
        ) : (
          <IconButton
            disabled={isEditing.addOns}
            sx={{ color: theme.palette.colors.text.primary }}
            onClick={() => setIsEditing((i) => ({ ...i, addOns: true }))}
          >
            <ModeEditIcon />
          </IconButton>
        )}
      </CenteredRowBox>

      <Spacer size="s" position="top" />
      <Grid container spacing={2}>
        {values.addOns.map((item) => (
          <Form
            key={item.uniqueId ? item.uniqueId : item.id}
            initialValues={{
              id: item.id || "",
              uniqueId: item.uniqueId || null,
              label: item.label,
              sublabel: item.sublabel,
              price: item.price,
            }}
            onSubmit={submitAddOns}
            validationSchema={validationSchema}
          >
            <Grid item xs={12}>
              <ExperienceAddOnsItem
                onRemoveAddOns={onRemoveAddOns}
                editIndex={editIndex}
                setEditIndex={setEditIndex}
                disabled={!isEditing.addOns}
              />
            </Grid>
          </Form>
        ))}

        {isEditing.addOns && (
          <>
            {isAddingAddOns && (
              <Form
                initialValues={{
                  label: "",
                  sublabel: "",
                  price: "",
                  uniqueId: uuidv4(),
                  id: "",
                }}
                onSubmit={onPushAddOns}
                validationSchema={validationSchema}
              >
                <Grid item xs={12}>
                  <ExperienceAddOnsItem isEmptyForm={true} />
                </Grid>
              </Form>
            )}
            <Grid item xs={12}>
              <ButtonContainer>
                <BorderButton
                  width="100px"
                  onClickButton={() => {
                    resetForm();
                    setEditIndex(null);
                    setIsEditing((i) => ({ ...i, addOns: false }));
                    setFieldValue("addOns", getExperienceDetailObj.data.addOns);
                  }}
                >
                  <Text type="BrandColor">Cancel</Text>
                </BorderButton>
                <Spacer size="s" position="left" />
                <FormSubmitButton width="100px">
                  <Text type="WhiteColor">Save</Text>
                </FormSubmitButton>
              </ButtonContainer>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}

ExperienceAddOnsList.propTypes = {
  setIsEditing: PropTypes.func.isRequired,
  isEditing: PropTypes.shape({
    addOns: PropTypes.bool,
  }).isRequired,
};
