import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import CtaButton from "../../../components/buttons/cta-button.component";
import RedBorderButton from "../../../components/buttons/red-border-button.component";
import DetailCardContainer from "../../../components/detail-card-container.component";
import SkeletonLoader from "../../../components/skeleton.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
});

const RowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

function ExperienceDetailsLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <SpaceBetweenBox>
          <Box>
            <SkeletonLoader height="40px" width="200px" />
            <SkeletonLoader height="20px" width="125px" />
          </Box>
          {!isMobile && (
            <RowBox>
              <RedBorderButton width="125px" isLoading={true}>
                <Text type="RejectText">Reject</Text>
              </RedBorderButton>
              <Spacer size="s" position="left" />
              <CtaButton width="125px" isLoading={true}>
                <Text type="WhiteColor">Approve</Text>
              </CtaButton>
            </RowBox>
          )}
        </SpaceBetweenBox>
      </Grid>
      {isMobile && (
        <Grid item xs={12}>
          <RowBox>
            <RedBorderButton width="125px" isLoading={true}>
              <Text type="RejectText">Reject</Text>
            </RedBorderButton>
            <Spacer size="s" position="left" />
            <CtaButton width="125px" isLoading={true}>
              <Text type="WhiteColor">Approve</Text>
            </CtaButton>
          </RowBox>
        </Grid>
      )}
      <Grid item xs={12}>
        <DetailCardContainer>
          <Text sx={{ fontSize: theme.fonts.fontSizes.size18 }}>Category</Text>
          <Spacer size="s" position="top" />
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <SkeletonLoader width="100%" height="56px" />
            </Grid>
            <Grid item xs={4}>
              <SkeletonLoader width="100%" height="56px" />
            </Grid>
            <Grid item xs={4}>
              <SkeletonLoader width="100%" height="56px" />
            </Grid>
            <Grid item xs={4}>
              <SkeletonLoader width="100%" height="56px" />
            </Grid>
            <Grid item xs={4}>
              <SkeletonLoader width="100%" height="56px" />
            </Grid>
            <Grid item xs={4}>
              <SkeletonLoader width="100%" height="56px" />
            </Grid>
          </Grid>
        </DetailCardContainer>
      </Grid>
      <Grid item xs={12}>
        <DetailCardContainer>
          <Text sx={{ fontSize: theme.fonts.fontSizes.size18 }}>Uploaded Photos</Text>
          <Spacer size="s" position="top" />
          <Grid container spacing={3}>
            <Grid item xs={isMobile ? 12 : 4}>
              <SkeletonLoader width="100%" height="200px" />
            </Grid>
            <Grid item xs={isMobile ? 12 : 4}>
              <SkeletonLoader width="100%" height="200px" />
            </Grid>
            <Grid item xs={isMobile ? 12 : 4}>
              <SkeletonLoader width="100%" height="200px" />
            </Grid>
          </Grid>
        </DetailCardContainer>
      </Grid>
      <Grid item xs={12}>
        <DetailCardContainer>
          <Text sx={{ fontSize: theme.fonts.fontSizes.size18 }}>Description</Text>
          <Spacer size="s" position="top" />
          <SkeletonLoader width={isMobile ? "100%" : "60%"} height="200px" />
        </DetailCardContainer>
      </Grid>
      <Grid item xs={12}>
        <DetailCardContainer>
          <Text sx={{ fontSize: theme.fonts.fontSizes.size18 }}>Important Info</Text>
          <Spacer size="s" position="top" />
          <SkeletonLoader width={isMobile ? "100%" : "60%"} height="200px" />
        </DetailCardContainer>
      </Grid>
      <Grid item xs={12}>
        <DetailCardContainer>
          <Text sx={{ fontSize: theme.fonts.fontSizes.size18 }}>Location</Text>
          <Spacer size="s" position="top" />
          <SkeletonLoader width={isMobile ? "100%" : "60%"} height="56px" />
        </DetailCardContainer>
      </Grid>
      <Grid item xs={12}>
        <DetailCardContainer>
          <Text sx={{ fontSize: theme.fonts.fontSizes.size18 }}>Type of Package</Text>
          <Spacer size="s" position="top" />
          <Grid container sx={{ width: isMobile ? "100%" : "60%" }} spacing={3}>
            <Grid item xs={isMobile ? 12 : 4}>
              <SkeletonLoader width="100%" height="56px" />
            </Grid>
          </Grid>
        </DetailCardContainer>
      </Grid>
      <Grid item xs={12}>
        <DetailCardContainer>
          <Text sx={{ fontSize: theme.fonts.fontSizes.size18 }}>Price</Text>
          <Spacer size="s" position="top" />
          <Grid container sx={{ width: isMobile ? "100%" : "60%" }} spacing={3}>
            <Grid item xs={isMobile ? 12 : 4}>
              <SkeletonLoader width="100%" height="56px" />
            </Grid>
          </Grid>
        </DetailCardContainer>
      </Grid>
    </Grid>
  );
}

export default ExperienceDetailsLoader;
