import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import CtaButton from "../../../components/buttons/cta-button.component";
import SkeletonLoader from "../../../components/skeleton.component";
import Text from "../../../components/text.component";

const FlexEndButtonContainer = styled(Box)({
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
});

function CreatorDetailsLoader() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    return (
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={isMobile ? 12 : 3}>
                        <Text>Creator ID</Text>
                        <SkeletonLoader height="25px" width="175px" />
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 3}>
                        <Text>Email Address</Text>
                        <SkeletonLoader height="25px" width="175px" />
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 3}>
                        <Text>Contact Number</Text>
                        <SkeletonLoader height="25px" width="175px" />
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 3}>
                        <Text>Date Registered</Text>
                        <SkeletonLoader height="25px" width="175px" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={isMobile ? 12 : 3}>
                        <Text>First Name</Text>
                        <SkeletonLoader height="25px" width="175px" />
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 4.2}>
                        <Text>Last Name</Text>
                        <SkeletonLoader height="25px" width="175px" />
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 4.2}>
                        <Text>Nick Name</Text>
                        <SkeletonLoader height="25px" width="175px" />
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 3}>
                        <Text>Website</Text>
                        <SkeletonLoader height="25px" width="175px" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <FlexEndButtonContainer sx={{ marginTop: "-30px" }}>
                    <CtaButton width="125px" isLoading={true}>
                        <Text type="WhiteColor">Save</Text>
                    </CtaButton>
                </FlexEndButtonContainer>
            </Grid>
        </Grid>
    );
}

export default CreatorDetailsLoader;
