import { Box, Grid, Link, Pagination, styled, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import CtaButton from "../../../components/buttons/cta-button.component";
import RedBorderButton from "../../../components/buttons/red-border-button.component";
import FormFieldTextWithCounter from "../../../components/forms/form-field-text-with-counter.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import Loading from "../../../components/notifications/loading.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import PaddedView from "../../../components/padded-view.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import {
  getTagDetail,
  getTagExperiences,
  searchTagSelector,
  updateTag,
} from "../../../services/search-tag/search-tag-slice.services";
import ExperienceSelectionModal from "../components/experience-selection-modal.component";
import SelectedExperienceList from "../components/selected-experience-list.component";
import ExperienceCardLoader from "../loader/experience-card-loader.component";
import TagDetailLoader from "../loader/tag-detail-loader.component";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-end",
});

const validationSchema = Yup.object().shape({
  label: Yup.string().required().max(80).label("Label Name"),
  experiencesObj: Yup.array()
    .of(Yup.object().shape({}))
    .required()
    .min(1)
    .label("Targeted Experiences"),
});

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

export default function SearchTagEditScreen() {
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);
  const { getTagDetailObj, updateTagObj, getTagExperiencesObj } = useSelector(searchTagSelector);
  const [showExperienceModal, setShowExperienceModal] = useState(false);
  const location = useLocation();
  const tagId = new URLSearchParams(location.search).get("tagId");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [page, setPage] = useState(1);
  const formRef = useRef();
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    dispatch(getTagDetail(tagId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({ message: error.message, type: "error" });
      }
    });
  }, []);

  useEffect(() => {
    if (!formRef.current) {
      dispatch(getTagExperiences({ tagId })).then(({ payload, meta, error }) => {
        if (meta.requestStatus === "fulfilled") {
          formRef.current.values.experiencesObj = payload.data.items;
          formRef.current.values.originalExperiencesObj = payload.data.items;
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({ message: error.message, type: "error" });
        }
      });
    }
  }, [formRef.current]);

  const onPageChange = (e, newPage) => {
    setPage(newPage);
    dispatch(getTagExperiences({ tagId, page: newPage })).then(({ payload, meta, error }) => {
      if (meta.requestStatus === "fulfilled") {
        formRef.current.values.originalExperiencesObj = payload.data.items;
        formRef.current.values.experiencesObj = payload.data.items.filter((item) =>
          formRef.current.values.experiencesId.includes(item.id),
        );
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({ message: error.message, type: "error" });
      }
    });
  };

  const onUpdate = (values) => {
    setIsLoading(true);
    dispatch(updateTag({ label: values.label, experiencesId: values.experiencesId, tagId })).then(
      ({ meta, error, payload }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          onPageChange(null, 1);
          formRef.current.values.originalExperiencesId = values.experiencesId;
          formRef.current.values.newAddedExperiencesObj = [];
          setIsEditing(false);
          createSnackBar({ message: payload.message, type: "success" });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({ message: error.message, type: "error" });
        }
      },
    );
  };

  const renderContent = () => {
    if (getTagDetailObj.status === "succeeded") {
      return (
        <Form
          innerRef={formRef}
          validationSchema={validationSchema}
          onSubmit={onUpdate}
          initialValues={{
            label: getTagDetailObj.data.label,
            experiencesId: getTagDetailObj.data.experiencesId,
            originalExperiencesId: getTagDetailObj.data.experiencesId,
            experiencesObj: [],
            originalExperiencesObj: [],
            newAddedExperiencesObj: [],
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SpaceBetweenBox>
                <Text variant="h4">Edit Search Tag</Text>
                {isEditing ? (
                  <Box>
                    <Grid container>
                      <RedBorderButton
                        width="100px"
                        onClickButton={() => {
                          setIsEditing(false);
                          formRef.current.values.experiencesId =
                            formRef.current.values.originalExperiencesId;
                          formRef.current.values.experiencesObj =
                            formRef.current.values.originalExperiencesObj;
                          formRef.current.values.newAddedExperiencesObj = [];
                        }}
                      >
                        <Text type="RejectText">Cancel</Text>
                      </RedBorderButton>
                      <Spacer position="right" />
                      <FormSubmitButton width="125px" isLoading={updateTagObj.status === "pending"}>
                        <Text type="WhiteColor">Confirm</Text>
                      </FormSubmitButton>
                    </Grid>
                  </Box>
                ) : (
                  <CtaButton width="125px" onClickButton={() => setIsEditing(true)}>
                    <Text type="WhiteColor">Edit</Text>
                  </CtaButton>
                )}
              </SpaceBetweenBox>
            </Grid>
            <Grid item xs={12}>
              <Text>Tag Name</Text>
              <Spacer size="xs" position="top" />
              <FormFieldTextWithCounter
                disabled={!isEditing}
                maxLength={50}
                name="label"
                placeholder="Enter Tag Name here"
                width="350px"
              />
            </Grid>
            <Grid item xs={12}>
              <Text>Targeted Experiences</Text>
              <Spacer size="xs" position="top" />
              {getTagExperiencesObj.status === "succeeded" ? (
                <>
                  <SelectedExperienceList
                    setShowExperienceModal={setShowExperienceModal}
                    isEditing={isEditing}
                    forEditScreen={true}
                  />
                  <CustomFooter>
                    <Pagination
                      sx={{
                        "&& .Mui-selected": {
                          backgroundColor: theme.palette.colors.brand.primary,
                          color: theme.palette.colors.text.white,
                        },
                      }}
                      page={page}
                      onChange={onPageChange}
                      count={getTagExperiencesObj.data.pagination.totalPages}
                      variant="outlined"
                    />
                  </CustomFooter>
                </>
              ) : (
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <ExperienceCardLoader />
                  </Grid>
                  <Grid item xs={4}>
                    <ExperienceCardLoader />
                  </Grid>
                  <Grid item xs={4}>
                    <ExperienceCardLoader />
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} />
          </Grid>
          {showExperienceModal && (
            <ExperienceSelectionModal
              showModal={showExperienceModal}
              setShowModal={setShowExperienceModal}
            />
          )}
        </Form>
      );
    }
    return <TagDetailLoader />;
  };

  return (
    <PaddedView multiples={isMobile ? 1 : 3}>
      <Loading isLoading={isLoading} />
      <Link href={routes.SEARCH_TAG_LIST} style={{ textDecoration: "none" }}>
        <Text type="GreyColor">{`< Back`}</Text>
      </Link>
      <Spacer size="xl" position="top" />
      {renderContent()}
    </PaddedView>
  );
}
