import React from "react";
import { Button, FormHelperText, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import PropTypes from "prop-types";
import ExperienceCard from "./experience-card.component";

const UploadButton = styled(Button)(({ theme }) => ({
  backgroundColor: "transparent",
  borderTopLeftRadius: theme.shape.borderRadius[1],
  borderTopRightRadius: theme.shape.borderRadius[1],
  borderBottomLeftRadius: theme.shape.borderRadius[1],
  borderBottomRightRadius: theme.shape.borderRadius[1],
  border: `1px dashed ${theme.palette.colors.ui.disabled}`,
  minHeight: "250px",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: theme.palette.colors.ui.disabled,
  padding: "0px",
  width: "100%",
  "&:hover": {
    background: "transparent",
  },
}));

export default function SelectedExperienceList({
  setShowExperienceModal,
  isEditing,
  forEditScreen,
}) {
  const { values, setFieldValue, touched, errors } = useFormikContext();
  const name = "experiencesObj";
  const showError = touched[name] && typeof errors[name] === "string";
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const onToggleExperienceAdd = (experience) => {
    const isChecked = values[name].some((checkedCheckbox) => checkedCheckbox.id === experience.id);
    if (isChecked) {
      setFieldValue(
        name,
        values[name].filter((checkedCheckbox) => checkedCheckbox.id !== experience.id),
      );

      values.experiencesId = values.experiencesId.filter(
        (experienceId) => experienceId !== experience.id,
      );
      if (values.originalExperiencesId) {
        values.newAddedExperiencesObj = values.newAddedExperiencesObj.filter(
          (checkedCheckbox) => checkedCheckbox.id !== experience.id,
        );
      }
    } else {
      setFieldValue(name, values[name].concat(experience));
      values.experiencesId = values.experiencesId.concat(experience.id);
      if (values.originalExperiencesId && !values.originalExperiencesId.includes(experience.id)) {
        values.newAddedExperiencesObj = values.newAddedExperiencesObj.concat(experience);
      }
    }
  };

  return (
    <>
      {showError && <FormHelperText error>{errors[name]}</FormHelperText>}
      <Grid container spacing={3}>
        {forEditScreen
          ? values.originalExperiencesObj
              .filter((item) => values.experiencesId.includes(item.id))
              .map((item) => (
                <Grid item xs={isMobile ? 12 : 4} key={item.id}>
                  <ExperienceCard
                    experience={item}
                    isShowAddIcon
                    onToggleExperienceAdd={onToggleExperienceAdd}
                    isEditing={isEditing}
                  />
                </Grid>
              ))
          : values.experiencesObj.map((item) => (
              <Grid item xs={isMobile ? 12 : 4} key={item.id}>
                <ExperienceCard
                  experience={item}
                  isShowAddIcon
                  onToggleExperienceAdd={onToggleExperienceAdd}
                  isEditing={isEditing}
                />
              </Grid>
            ))}
        {forEditScreen &&
          values.newAddedExperiencesObj.map((item) => (
            <Grid item xs={isMobile ? 12 : 4} key={item.id}>
              <ExperienceCard
                experience={item}
                isShowAddIcon
                onToggleExperienceAdd={onToggleExperienceAdd}
                isEditing={isEditing}
              />
            </Grid>
          ))}
        <Grid item xs={isMobile ? 12 : 4}>
          <UploadButton onClick={() => setShowExperienceModal(true)} disabled={!isEditing}>
            <AddCircleOutlineRoundedIcon />
          </UploadButton>
        </Grid>
      </Grid>
    </>
  );
}

SelectedExperienceList.defaultProps = {
  isEditing: true,
};

SelectedExperienceList.propTypes = {
  setShowExperienceModal: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
  forEditScreen: PropTypes.bool.isRequired,
};
