import React from "react";
import PropTypes from "prop-types";
import { TableCell, IconButton, TableRow, Box } from "@mui/material";
import { Link } from "react-router-dom";
import Text from "../../../../components/text.component";
import EditIcon from "../../../../assets/images/edit-icon";
import routes from "../../../../navigation/routes";
import DeleteIcon from "../../../../assets/images/delete-icon";
import Spacer from "../../../../components/spacer.component";

function FestivalSectionRow({ record, onToggleDelete }) {
  return (
    <TableRow
      key={record.id}
      sx={{
        "& .MuiTableCell-root": {
          borderBottom: "no-set",
        },
      }}
    >
      <TableCell sx={{ width: "200px" }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img src={record.iconPath} alt="BannerImage" style={{ width: "50px" }} />
        </Box>
      </TableCell>
      <TableCell>
        <Text
          variant="body2"
          sx={{
            textAlign: "center",
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
          }}
        >
          {record.label}
        </Text>
      </TableCell>
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center", minWidth: "100px" }}>
          {`${record.startAt.split(" ")[0]} - ${record.endAt.split(" ")[0]}`}
        </Text>
      </TableCell>
      <TableCell>
        <Text
          variant="body2"
          sx={{ textAlign: "center", minWidth: "100px", textTransform: "capitalize" }}
        >
          {record.status}
        </Text>
      </TableCell>
      <TableCell>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <Spacer position="left" size="s" />
          <Link
            to={{
              pathname: `${routes.MARKETING_FESTIVAL_SECTION_EDIT}`,
              search: `festivalSectionId=${record.id}`,
            }}
          >
            <IconButton>
              <EditIcon />
            </IconButton>
          </Link>

          <IconButton onClick={() => onToggleDelete(record.id)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
}

FestivalSectionRow.defaultProps = {
  record: PropTypes.shape({
    id: null,
    label: "",
    iconPath: "",
    startAt: "",
    endAt: "",
  }),
};

FestivalSectionRow.propTypes = {
  onToggleDelete: PropTypes.func.isRequired,
  record: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
    iconPath: PropTypes.string,
    startAt: PropTypes.string,
    endAt: PropTypes.string,
    status: PropTypes.string,
  }),
};

export default FestivalSectionRow;
