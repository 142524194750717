import { Box, Grid, IconButton, styled, useTheme } from "@mui/material";
import React from "react";
import { useFormikContext } from "formik";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import PropTypes from "prop-types";
import DetailCardContainer from "../../../components/detail-card-container.component";
import FormDatePicker from "../../../components/forms/form-date-picker.component";
import FormTimePicker from "../../../components/forms/form-time-picker.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import BorderButton from "../../../components/buttons/border-button.component";
import CenteredRowBox from "../../../components/centered-row-box.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";

const ButtonContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  marginTop: "20px",
});

const FlexStartRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
});

export default function PromoPeriodForm({ isEditing, setIsEditing, forCreateScreen }) {
  const theme = useTheme();
  const { resetForm } = useFormikContext();

  return (
    <Grid item xs={12}>
      <DetailCardContainer>
        <CenteredRowBox>
          <Text sx={{ fontSize: theme.fonts.fontSizes.size18 }}>Promo Period</Text>
          {!forCreateScreen && (
            <>
              <Spacer size="s" position="left" />
              <IconButton
                sx={{ color: theme.palette.colors.text.primary }}
                disabled={isEditing.promoPeriod}
                onClick={() => setIsEditing((i) => ({ ...i, promoPeriod: true }))}
              >
                <ModeEditIcon />
              </IconButton>
            </>
          )}
        </CenteredRowBox>
        <Spacer size="m" position="top" />
        <FlexStartRowBox>
          <Grid item xs={6}>
            <Text>Start Date</Text>
            <Spacer size="xs" position="top" />
            <FormDatePicker
              name="startDate"
              width="100%"
              placeholder="Start Date"
              disabled={forCreateScreen ? false : !isEditing.promoPeriod}
            />
          </Grid>
          <Spacer size="xl" position="left" />
          <Grid item xs={6}>
            <Text>End Date</Text>
            <Spacer size="xs" position="top" />
            <FormDatePicker
              name="endDate"
              width="100%"
              placeholder="End Date"
              disabled={forCreateScreen ? false : !isEditing.promoPeriod}
            />
          </Grid>
        </FlexStartRowBox>
        <Spacer size="l" position="top" />
        <FlexStartRowBox>
          <Grid item xs={6}>
            <Text>Start Time</Text>
            <Spacer size="xs" position="top" />
            <FormTimePicker
              width="100%"
              name="startTime"
              placeholder="Promo Start Time"
              disabled={forCreateScreen ? false : !isEditing.promoPeriod}
            />
          </Grid>
          <Spacer size="xl" position="left" />
          <Grid item xs={6}>
            <Text>End Time</Text>
            <Spacer size="xs" position="top" />
            <FormTimePicker
              width="100%"
              name="endTime"
              placeholder="Promo End Time"
              disabled={forCreateScreen ? false : !isEditing.promoPeriod}
            />
          </Grid>
        </FlexStartRowBox>
        {!forCreateScreen && isEditing.promoPeriod && (
          <ButtonContainer>
            <BorderButton
              width="100px"
              onClickButton={() => {
                resetForm();
                setIsEditing((i) => ({ ...i, promoPeriod: false }));
              }}
            >
              <Text type="BrandColor">Cancel</Text>
            </BorderButton>
            <Spacer size="s" position="left" />
            <FormSubmitButton width="100px">
              <Text type="WhiteColor">Save</Text>
            </FormSubmitButton>
          </ButtonContainer>
        )}
      </DetailCardContainer>
    </Grid>
  );
}

PromoPeriodForm.defaultProps = {
  setIsEditing: null,
  isEditing: {
    promoPeriod: false,
  },
};

PromoPeriodForm.propTypes = {
  setIsEditing: PropTypes.func,
  isEditing: PropTypes.shape({
    promoPeriod: PropTypes.bool,
  }),
  forCreateScreen: PropTypes.bool.isRequired,
};
