import React from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { useFormikContext } from "formik";

const TimeField = styled(Select)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  backgroundColor: theme.palette.colors.bg.white,
}));

export default function BookingSlotList({ disabled }) {
  const { values, setFieldTouched, errors, touched, setFieldValue } = useFormikContext();
  const theme = useTheme();
  const showTimeSlotError = touched.slotId && typeof errors.slotId === "string";

  const handleSlotChange = (event) => {
    setFieldValue("slotId", event.target.value);
  };

  return (
    <FormControl sx={{ width: "100%", maxWidth: "450px" }}>
      <TimeField
        disabled={disabled}
        displayEmpty
        error={showTimeSlotError}
        onBlur={() => setFieldTouched("slotId")}
        variant="outlined"
        value={values.slotId}
        onChange={handleSlotChange}
        renderValue={(selected) =>
          selected === "" || selected === null || !selected ? (
            <Typography>Select Slot</Typography>
          ) : (
            <Typography>{values.slotList.find((item) => item.id === selected).time}</Typography>
          )
        }
      >
        {values.slotList.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.time}
          </MenuItem>
        ))}
        {values.slotList.length === 0 && (
          <MenuItem value="">
            <em>No Availabe Time Slot</em>
          </MenuItem>
        )}
      </TimeField>
      {showTimeSlotError && errors.slotId && (
        <FormHelperText sx={{ color: theme.palette.darkColors.text.error }}>
          {errors.slotId}
        </FormHelperText>
      )}
    </FormControl>
  );
}

BookingSlotList.propTypes = {
  disabled: PropTypes.bool.isRequired,
};
