import React from "react";
import { Grid } from "@mui/material";
import Skeleton from "react-loading-skeleton";
import Text from "../../../components/text.component";

export default function BookingDetailLoader() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Text variant="h4">Booking Details</Text>
      </Grid>
      <Grid item xs={12}>
        <Skeleton width="350px" height="56px" />
      </Grid>
      <Grid item xs={12}>
        <Skeleton width="350px" height="56px" />
      </Grid>
      <Grid item xs={12}>
        <Skeleton width="350px" height="56px" />
      </Grid>
      <Grid item xs={12}>
        <Skeleton width="350px" height="56px" />
      </Grid>
      <Grid item xs={12}>
        <Skeleton width="350px" height="56px" />
      </Grid>
      <Grid item xs={12}>
        <Skeleton width="350px" height="56px" />
      </Grid>
      <Grid item xs={12}>
        <Skeleton width="350px" height="56px" />
      </Grid>
    </Grid>
  );
}
