import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { InputAdornment, styled, TextField } from "@mui/material";
import Text from "../../../components/text.component";

const StyledTextField = styled(TextField)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    backgroundColor: theme.palette.colors.text.white,
    padding: "5px 14px",
  },
}));

function AddOnsFormFieldText({ name, placeholder, disabled }) {
  const { setFieldTouched, setFieldValue, errors, touched, values, handleSubmit } =
    useFormikContext();

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  const showError = touched[name] && typeof errors[name] === "string";

  const handleChange = (evt) => {
    const { value } = evt.target;
    if (value.match(/\./g)) {
      const [, decimal] = value.split(".");
      if (decimal?.length > 2) {
        return;
      }
    }
    setFieldValue(name, value);
  };

  if (name === "price") {
    return (
      <StyledTextField
        sx={{
          "& .MuiOutlinedInput-input": {
            padding: "0px",
          },
        }}
        disabled={disabled}
        error={showError}
        variant="outlined"
        type="number"
        helperText={showError ? errors[name] : null}
        onBlur={() => setFieldTouched(name)}
        placeholder={placeholder}
        onChange={handleChange}
        value={values[name] === null ? "" : values[name]}
        InputProps={{
          inputMode: "decimal",
          startAdornment: (
            <InputAdornment position="start">
              <Text type="DarkGreyColor">RM</Text>
            </InputAdornment>
          ),
        }}
      />
    );
  }

  return (
    <StyledTextField
      multiline
      disabled={disabled}
      onKeyDown={keyPress}
      error={showError}
      variant="outlined"
      helperText={showError ? errors[name] : null}
      onBlur={() => setFieldTouched(name)}
      name={name}
      placeholder={placeholder}
      onChange={(e) => {
        setFieldValue(name, e.target.value);
      }}
      value={values[name] === null ? "" : values[name]}
    />
  );
}

AddOnsFormFieldText.propTypes = {
  disabled: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default AddOnsFormFieldText;
