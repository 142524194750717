import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postReq, setAxiosToken } from "../api-services";

export const login = createAsyncThunk("auth/employees/login", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/login`,
    payload,
  );
  return response;
});

export const forgotPassword = createAsyncThunk(
  "auth/employees/forgot_password",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/forgot_password`,
      payload,
    );
    return response;
  },
);

export const logout = createAsyncThunk("auth/employees/logout", async () => {
  const response = await postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/employees/logout`);
  return response;
});

export const resendVerificationCode = createAsyncThunk(
  "auth/employees/resend_verification_code",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/resend_verification_code`,
      payload,
    );
    return response;
  },
);

export const resetPassword = createAsyncThunk("auth/employees/reset_password", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/reset_password`,
    payload,
  );
  return response;
});

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    loginObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    logoutObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    forgotPasswordObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    resendVerificationCodeObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    resetPasswordObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {
    resetUser: (state) => {
      state.user = null;
      state.loginObj.data = null;
      setAxiosToken(null);
      localStorage.removeItem("yoloAdminAuthToken");
    },
  },
  extraReducers: {
    [login.pending]: (state) => {
      state.loginObj.status = "pending";
    },
    [login.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.loginObj.status = "succeeded";
      state.loginObj.data = data;
      state.user = data;
      localStorage.setItem("yoloAdminAuthToken", data.authToken);
      setAxiosToken(data.authToken);
      state.loginObj.successMessage = message;
    },
    [login.rejected]: (state, action) => {
      const { message } = action.error;

      localStorage.removeItem("yoloAdminAuthToken");
      state.loginObj.status = "failed";
      state.loginObj.errorMessage = message;
    },
    [logout.pending]: (state) => {
      state.logoutObj.status = "pending";
    },
    [logout.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.logoutObj.status = "succeeded";
      state.logoutObj.data = data;
      state.logoutObj.successMessage = message;

      state.user = null;
      state.loginObj.data = null;
      setAxiosToken(null);
      localStorage.removeItem("yoloAdminAuthToken");
    },
    [logout.rejected]: (state, action) => {
      const { message } = action.error;

      state.logoutObj.status = "failed";
      state.logoutObj.errorMessage = message;
    },
    [forgotPassword.pending]: (state) => {
      state.forgotPasswordObj.status = "pending";
    },
    [forgotPassword.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.forgotPasswordObj.status = "succeeded";
      state.forgotPasswordObj.data = data;
      state.forgotPasswordObj.successMessage = message;
    },
    [forgotPassword.rejected]: (state, action) => {
      const { message } = action.error;

      state.forgotPasswordObj.status = "failed";
      state.forgotPasswordObj.errorMessage = message;
    },
    [resendVerificationCode.pending]: (state) => {
      state.resendVerificationCodeObj.status = "pending";
    },
    [resendVerificationCode.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.resendVerificationCodeObj.status = "succeeded";
      state.resendVerificationCodeObj.data = data;
      state.resendVerificationCodeObj.successMessage = message;
    },
    [resendVerificationCode.rejected]: (state, action) => {
      const { message } = action.error;

      state.resendVerificationCodeObj.status = "failed";
      state.resendVerificationCodeObj.errorMessage = message;
    },
    [resetPassword.pending]: (state) => {
      state.resetPasswordObj.status = "pending";
    },
    [resetPassword.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.resetPasswordObj.status = "succeeded";
      state.resetPasswordObj.data = data;
      state.resetPasswordObj.successMessage = message;
    },
    [resetPassword.rejected]: (state, action) => {
      const { message } = action.error;

      state.resetPasswordObj.status = "failed";
      state.resetPasswordObj.errorMessage = message;
    },
  },
});

export default authSlice.reducer;

// state
export const authSelector = (state) => state.auth;

export const { resetUser } = authSlice.actions;
