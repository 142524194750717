import React, { useContext, useState } from "react";
import { Box, Grid, Link, styled } from "@mui/material";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PaddedView from "../../../components/padded-view.component";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import Spacer from "../../../components/spacer.component";
import Form from "../../../components/forms/form.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import FormFieldTextWithCounter from "../../../components/forms/form-field-text-with-counter.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import Loading from "../../../components/notifications/loading.component";
import {
  createTag,
  searchTagSelector,
} from "../../../services/search-tag/search-tag-slice.services";
import ExperienceSelectionModal from "../components/experience-selection-modal.component";
import SelectedExperienceList from "../components/selected-experience-list.component";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-end",
});

const validationSchema = Yup.object().shape({
  label: Yup.string().required().max(80).label("Label Name"),
  experiencesObj: Yup.array()
    .of(Yup.object().shape({}))
    .required()
    .min(1)
    .label("Targeted Experiences"),
});

export default function SearchTagCreateScreen() {
  const dispatch = useDispatch();
  const history = useHistory();
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);
  const { createTagObj } = useSelector(searchTagSelector);
  const [showExperienceModal, setShowExperienceModal] = useState(false);

  const onCreateTag = (values) => {
    setIsLoading(true);

    dispatch(createTag({ label: values.label, experiencesId: values.experiencesId })).then(
      ({ meta, error }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          history.push(routes.SEARCH_TAG_LIST);
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  return (
    <PaddedView multiples={3}>
      <Loading isLoading={isLoading} />
      <Link href={routes.SEARCH_TAG_LIST} style={{ textDecoration: "none" }}>
        <Text type="GreyColor">{`< Back`}</Text>
      </Link>
      <Spacer size="xl" position="top" />
      <Form
        validationSchema={validationSchema}
        onSubmit={onCreateTag}
        initialValues={{
          label: "",
          experiencesObj: [],
          experiencesId: [],
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SpaceBetweenBox>
              <Text variant="h4">Create Search Tag</Text>
              <FormSubmitButton width="125px" isLoading={createTagObj.status === "pending"}>
                <Text type="WhiteColor">Confirm</Text>
              </FormSubmitButton>
            </SpaceBetweenBox>
          </Grid>
          <Grid item xs={12}>
            <Text>Search Tag Name</Text>
            <Spacer size="xs" position="top" />
            <FormFieldTextWithCounter
              maxLength={50}
              name="label"
              placeholder="Enter Tag Name here"
              width="350px"
            />
          </Grid>
          <Grid item xs={12}>
            <Text>Targeted Experiences</Text>
            <Spacer size="xs" position="top" />
            <SelectedExperienceList
              setShowExperienceModal={setShowExperienceModal}
              forEditScreen={false}
            />
          </Grid>

          <Grid item xs={12} />
        </Grid>
        {showExperienceModal && (
          <ExperienceSelectionModal
            showModal={showExperienceModal}
            setShowModal={setShowExperienceModal}
          />
        )}
      </Form>
    </PaddedView>
  );
}
