import { TableCell, TableRow } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import Text from "../../../components/text.component";

function socialList({ record }) {
    return (
        <TableRow
            key={record.id}
            sx={{
                "& .MuiTableCell-root": {
                    borderBottom: "no-set",
                },
            }}
        >
            <TableCell>
                <Text variant="body2" sx={{ textAlign: "center" }}>
                    {record.id}
                </Text>
            </TableCell>
            <TableCell>
                <Text variant="body2" sx={{ textAlign: "center" }}>
                    {record.socialName}
                </Text>
            </TableCell>
            <TableCell>
                <Text variant="body2" sx={{ textAlign: "center" }}>
                    {record.socialID}
                </Text>
            </TableCell>
            <TableCell>
                <Text variant="body2" sx={{ textAlign: "center" }}>
                    {record.status}
                </Text>
            </TableCell>
            {/* <TableCell>
                <Text variant="body2" sx={{ textAlign: "center" }}>
                    {record.amount}
                </Text>
            </TableCell> */}
            {/* <TableCell>
                <Link to={{ pathname: `${routes.UNIQUE_LINK_DETAIL}`, search: `socialNameId=${record.id}` }}>
                    <IconButton>
                        <EditIcon />
                    </IconButton>
                </Link>
            </TableCell> */}
        </TableRow>
    );
}

socialList.defaultProps = {
    record: {
        id: null,
        socialName: "",
        socialID: "",
        status: "",
        // amount: "",
    },
};

socialList.propTypes = {
    record: PropTypes.shape({
        id: PropTypes.number,
        socialName: PropTypes.string,
        socialID: PropTypes.string,
        status: PropTypes.string,
        // amount: PropTypes.string,
    }),
};

export default socialList;
