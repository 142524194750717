import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq, putReq } from "../api-services";

export const getUserList = createAsyncThunk("employees/users", async (payload) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/users?q=${payload.q}&page=${payload.page}`,
    payload,
  );
  return response;
});

export const getUserDetails = createAsyncThunk("employees/user-details", async (payload) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/users/${payload}`,
  );
  return response;
});

export const updateUser = createAsyncThunk("employees/user-update", async (payload) => {
  const response = await putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/users/${payload.userId}`,
    payload,
  );
  return response;
});

const userSlice = createSlice({
  name: "user",
  initialState: {
    getUserListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getUserDetailsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateUserObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getUserList.pending]: (state) => {
      state.getUserListObj.status = "pending";
    },
    [getUserList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getUserListObj.status = "succeeded";
      state.getUserListObj.data = data;
      state.getUserListObj.successMessage = message;
    },
    [getUserList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getUserListObj.status = "failed";
      state.getUserListObj.errorMessage = message;
    },
    [getUserDetails.pending]: (state) => {
      state.getUserDetailsObj.status = "pending";
    },
    [getUserDetails.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getUserDetailsObj.status = "succeeded";
      state.getUserDetailsObj.data = data;
      state.getUserDetailsObj.successMessage = message;
    },
    [getUserDetails.rejected]: (state, action) => {
      const { message } = action.error;

      state.getUserDetailsObj.status = "failed";
      state.getUserDetailsObj.errorMessage = message;
    },
    [updateUser.pending]: (state) => {
      state.updateUserObj.status = "pending";
    },
    [updateUser.fulfilled]: (state, action) => {
      const { data, message } = action.payload;
      state.updateUserObj.status = "succeeded";
      state.updateUserObj.data = data;
      state.updateUserObj.successMessage = message;

      state.getUserDetailsObj.data = data;
    },
    [updateUser.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateUserObj.status = "failed";
      state.updateUserObj.errorMessage = message;
    },
  },
});

export default userSlice.reducer;

// state
export const userSelector = (state) => state.user;
