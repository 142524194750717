import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../api-services";

export const getCreatorList = createAsyncThunk("employees/creators", async (payload) => {
    const response = await getReq(
        `${process.env.REACT_APP_API_PRIVATE_V1}/employees/creators?status=${payload.status}&q=${payload.q}&page=${payload.page}`,
        payload,
    );
    return response;
});

export const getCreatorDetails = createAsyncThunk(
    "employees/creators-details",
    async (payload) => {
        const response = await getReq(
            `${process.env.REACT_APP_API_PRIVATE_V1}/employees/creators/${payload}`,
        );
        return response;
    },
);

export const updateCreator = createAsyncThunk("employees/creators-update", async (payload) => {
    const response = await putReq(
        `${process.env.REACT_APP_API_PRIVATE_V1}/employees/creators/${payload.creatorId}?params=${payload.type}`,
        payload,
    );
    return response;
});

export const deleteCreator = createAsyncThunk("employees/creators-delete", async (creatorId) => {
    const response = await delReq(
        `${process.env.REACT_APP_API_PRIVATE_V1}/employees/creators/${creatorId}`,
    );
    return response;
});

export const getCreatorExperienceList = createAsyncThunk(
    "employees/creators-experiences-list",
    async (payload) => {
        const response = await getReq(
            `${process.env.REACT_APP_API_PRIVATE_V1}/employees/creators/${payload.creatorId}/experiences?page=${payload.page}`,
        );
        return response;
    },
);

export const searchCreator = createAsyncThunk("employees/creators/search", async (q) => {
    const response = await getReq(
        `${process.env.REACT_APP_API_PRIVATE_V1}/employees/creators/search?q=${q}`,
    );
    return response;
});

export const createCreator = createAsyncThunk("employees/creators/create", async (payload) => {
    const response = await postReq(
        `${process.env.REACT_APP_API_PRIVATE_V1}/employees/creators`,
        payload,
    );
    return response;
});

export const getCreatorStatementList = createAsyncThunk(
    "employees/creators-statements-list",
    async (payload) => {
        const response = await getReq(
            `${process.env.REACT_APP_API_PRIVATE_V1}/employees/creators/${payload.creatorId}/statements?q=${payload.q}&page=${payload.page}`,
        );
        return response;
    },
);

export const getCreatorUniqueLinks = createAsyncThunk(
    "employees/creators/uniqueLinks",
    async (payload) => {
        const response = await getReq(
            `${process.env.REACT_APP_API_PRIVATE_V1}/employees/creators/${payload.creatorId}/unique-links?q=${payload.q}&page=${payload.page}`,
        );
        return response;
    },
);

export const createCreatorSocial = createAsyncThunk("employees/creators/social/create", async (payload) => {
    const response = await postReq(
        `${process.env.REACT_APP_API_PRIVATE_V1}/employees/creators/${payload.creatorId}/socials`,
        payload,
    );
    return response;
});

export const getCreatorSocialList = createAsyncThunk(
    "employees/creators-social-list",
    async (payload) => {
        const response = await getReq(
            `${process.env.REACT_APP_API_PRIVATE_V1}/employees/creators/${payload.creatorId}/socials?q=${payload.q}&page=${payload.page}`,
        );
        return response;
    },
);

export const createCreatorUniqueLinks = createAsyncThunk("employees/creators/uniqueLinks", async (payload) => {
    const response = await postReq(
        `${process.env.REACT_APP_API_PRIVATE_V1}/employees/creators/${payload.creatorId}/unique-links`,
        payload,
    );
    return response;
});

export const getCreatorLinksList = createAsyncThunk(
    "employees/creators/links",
    async (payload) => {
        const response = await getReq(
            `${process.env.REACT_APP_API_PRIVATE_V1}/employees/creators/${payload.creatorId}/unique-links?q=${payload.q}&page=${payload.page}`,
        );
        return response;
    },
);

const creatorSlice = createSlice({
    name: "creator",
    initialState: {
        getCreatorListObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
        getCreatorDetailsObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
        updateCreatorObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
        deleteCreatorObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
        getCreatorExperienceListObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
        searchCreatorObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
        createCreatorObj: { status: "idle", data: null, successMessage: null, errorMessage: null },
        getCreatorStatementListObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
        getCreatorUniqueLinksObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
        getCreatorSocialListObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
        createCreatorUniqueLinksObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
        getCreatorLinksObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null
        },
    },
    reducers: {},
    extraReducers: {
        [getCreatorList.pending]: (state) => {
            state.getCreatorListObj.status = "pending";
        },
        [getCreatorList.fulfilled]: (state, action) => {
            const { data, message } = action.payload;

            state.getCreatorListObj.status = "succeeded";
            state.getCreatorListObj.data = data;
            state.getCreatorListObj.successMessage = message;
        },
        [getCreatorList.rejected]: (state, action) => {
            const { message } = action.error;

            state.getCreatorListObj.status = "failed";
            state.getCreatorListObj.errorMessage = message;
        },
        [getCreatorDetails.pending]: (state) => {
            state.getCreatorDetailsObj.status = "pending";
        },
        [getCreatorDetails.fulfilled]: (state, action) => {
            const { data, message } = action.payload;

            state.getCreatorDetailsObj.status = "succeeded";
            state.getCreatorDetailsObj.data = data;
            state.getCreatorDetailsObj.successMessage = message;
        },
        [getCreatorDetails.rejected]: (state, action) => {
            const { message } = action.error;

            state.getCreatorDetailsObj.status = "failed";
            state.getCreatorDetailsObj.errorMessage = message;
        },
        [updateCreator.pending]: (state) => {
            state.updateCreatorObj.status = "pending";
        },
        [updateCreator.fulfilled]: (state, action) => {
            const { data, message } = action.payload;
            state.updateCreatorObj.status = "succeeded";
            state.updateCreatorObj.data = data;
            state.updateCreatorObj.successMessage = message;

            state.getCreatorDetailsObj.data = data;
        },
        [updateCreator.rejected]: (state, action) => {
            const { message } = action.error;

            state.updateCreatorObj.status = "failed";
            state.updateCreatorObj.errorMessage = message;
        },
        [deleteCreator.pending]: (state) => {
            state.deleteCreatorObj.status = "pending";
        },
        [deleteCreator.fulfilled]: (state, action) => {
            const { data, message } = action.payload;
            state.deleteCreatorObj.status = "succeeded";
            state.deleteCreatorObj.data = data;
            state.deleteCreatorObj.successMessage = message;

            state.getCreatorDetailsObj.data = data;
        },
        [deleteCreator.rejected]: (state, action) => {
            const { message } = action.error;

            state.deleteCreatorObj.status = "failed";
            state.deleteCreatorObj.errorMessage = message;
        },
        [getCreatorExperienceList.pending]: (state) => {
            state.getCreatorExperienceListObj.status = "pending";
        },
        [getCreatorExperienceList.fulfilled]: (state, action) => {
            const { data, message } = action.payload;
            state.getCreatorExperienceListObj.status = "succeeded";
            state.getCreatorExperienceListObj.data = data;
            state.getCreatorExperienceListObj.successMessage = message;
        },
        [getCreatorExperienceList.rejected]: (state, action) => {
            const { message } = action.error;

            state.getCreatorExperienceListObj.status = "failed";
            state.getCreatorExperienceListObj.errorMessage = message;
        },
        [searchCreator.pending]: (state) => {
            state.searchCreatorObj.status = "pending";
        },
        [searchCreator.fulfilled]: (state, action) => {
            const { data, message } = action.payload;
            state.searchCreatorObj.status = "succeeded";
            state.searchCreatorObj.data = data;
            state.searchCreatorObj.successMessage = message;
        },
        [searchCreator.rejected]: (state, action) => {
            const { message } = action.error;

            state.searchCreatorObj.status = "failed";
            state.searchCreatorObj.errorMessage = message;
        },
        [createCreator.pending]: (state) => {
            state.createCreatorObj.status = "pending";
        },
        [createCreator.fulfilled]: (state, action) => {
            const { data, message } = action.payload;
            state.createCreatorObj.status = "succeeded";
            state.createCreatorObj.data = data;
            state.createCreatorObj.successMessage = message;
        },
        [createCreator.rejected]: (state, action) => {
            const { message } = action.error;

            state.createCreatorObj.status = "failed";
            state.createCreatorObj.errorMessage = message;
        },
        [getCreatorStatementList.pending]: (state) => {
            state.getCreatorStatementListObj.status = "pending";
        },
        [getCreatorStatementList.fulfilled]: (state, action) => {
            const { data, message } = action.payload;
            state.getCreatorStatementListObj.status = "succeeded";
            state.getCreatorStatementListObj.data = data;
            state.getCreatorStatementListObj.successMessage = message;
        },
        [getCreatorStatementList.rejected]: (state, action) => {
            const { message } = action.error;

            state.getCreatorStatementListObj.status = "failed";
            state.getCreatorStatementListObj.errorMessage = message;
        },
        [getCreatorUniqueLinks.pending]: (state) => {
            state.getCreatorUniqueLinksObj.status = "pending";
        },
        [getCreatorUniqueLinks.fulfilled]: (state, action) => {
            // Log the action payload for debugging
            console.log('Action Payload:', action.payload);
            const { data, message } = action.payload;


            console.log('Data:', data, 'Message:', message);

            state.getCreatorUniqueLinksObj.status = "succeeded";
            state.getCreatorUniqueLinksObj.data = data;
            state.getCreatorUniqueLinksObj.successMessage = message;
        },
        [getCreatorUniqueLinks.rejected]: (state, action) => {
            // Log the error for debugging
            console.log("getCreatorUniqueLinks rejected", action.error);

            const { message } = action.error;
            state.getCreatorUniqueLinksObj.status = "failed";
            state.getCreatorUniqueLinksObj.errorMessage = message;
        },
        [getCreatorSocialList.pending]: (state) => {
            state.getCreatorSocialListObj.status = "pending";
        },
        [getCreatorSocialList.fulfilled]: (state, action) => {
            const { data, message } = action.payload;
            state.getCreatorSocialListObj.status = "succeeded";
            state.getCreatorSocialListObj.data = data;
            state.getCreatorSocialListObj.successMessage = message;
        },
        [getCreatorSocialList.rejected]: (state, action) => {
            const { message } = action.error;

            state.getCreatorSocialListObj.status = "failed";
            state.getCreatorSocialListObj.errorMessage = message;
        },
        [createCreatorUniqueLinks.pending]: (state) => {
            state.createCreatorUniqueLinksObj.status = "pending";
        },
        [createCreatorUniqueLinks.fulfilled]: (state, action) => {
            const { data, message } = action.payload;
            state.createCreatorUniqueLinksObj.status = "succeeded";
            state.createCreatorUniqueLinksObj.data = data;
            state.createCreatorUniqueLinksObj.successMessage = message;
        },
        [createCreatorUniqueLinks.rejected]: (state, action) => {
            const { message } = action.error;
            state.createCreatorUniqueLinksObj.status = "failed";
            state.createCreatorUniqueLinksObj.errorMessage = message;
        },
        [getCreatorLinksList.pending]: (state) => {
            state.getCreatorLinksObj.status = "pending";
        },
        [getCreatorLinksList.fulfilled]: (state, action) => {
            const { data, message } = action.payload;
            state.getCreatorLinksObj.status = "succeeded";
            state.getCreatorLinksObj.data = data;
            state.getCreatorLinksObj.successMessage = message;
        },
        [getCreatorLinksList.rejected]: (state, action) => {
            const { message } = action.error;
            state.getCreatorLinksObj.status = "failed";
            state.getCreatorLinksObj.errorMessage = message;
        },
    },
});

export default creatorSlice.reducer;

// state
export const creatorSelector = (state) => state.creator;
