import { Box, Grid, Link, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import DetailCardContainer from "../../../components/detail-card-container.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import PaddedView from "../../../components/padded-view.component";
import SkeletonLoader from "../../../components/skeleton.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import { getUserDetails, userSelector } from "../../../services/user/user-slice.services";
import BookingList from "../components/booking-list.component";
import UserDetails from "../components/user-details.component";
import UserDetailsLoader from "../loader/user-details-loader.component";

function UserDetailScreen() {
  const location = useLocation();
  const userId = new URLSearchParams(location.search).get("userId");
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const { getUserDetailsObj } = useSelector(userSelector);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getUserDetails(userId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  const renderTitleAndStatus = () => {
    if (getUserDetailsObj.status === "succeeded") {
      return (
        <Grid container spacing={3}>
          <Grid item>
            <Text variant="h4">
              {getUserDetailsObj.data.firstName} {getUserDetailsObj.data.lastName}
            </Text>
            {getUserDetailsObj.data.emailVerified ? (
              <Text type="GreyColor">Verified</Text>
            ) : (
              <Text type="RejectText">Non-verified</Text>
            )}
          </Grid>
        </Grid>
      );
    }
    return (
      <Box>
        <SkeletonLoader height="40px" width="200px" />
        <SkeletonLoader height="20px" width="125px" />
      </Box>
    );
  };

  const renderUserDetails = () => {
    if (getUserDetailsObj.status === "succeeded") {
      return <UserDetails />;
    }
    return <UserDetailsLoader />;
  };

  return (
    <PaddedView multiples={isMobile ? 1 : 3}>
      <Link href={routes.USERLIST} style={{ textDecoration: "none" }}>
        <Text type="GreyColor">{`< Back`}</Text>
      </Link>
      <Spacer size="xl" position="top" />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {renderTitleAndStatus()}
        </Grid>
        <Grid item xs={12}>
          <DetailCardContainer>{renderUserDetails()}</DetailCardContainer>
        </Grid>
        <Grid item xs={12}>
          <BookingList />
        </Grid>
      </Grid>
    </PaddedView>
  );
}

export default UserDetailScreen;
