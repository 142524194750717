import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import Skeleton from "react-loading-skeleton";
import CtaButton from "../../../components/buttons/cta-button.component";
import RedBorderButton from "../../../components/buttons/red-border-button.component";
import DetailCardContainer from "../../../components/detail-card-container.component";
import SkeletonLoader from "../../../components/skeleton.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import StatementTransactionListLoader from "./statement-transaction-list-loader.component";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
});

const RowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

function StatementDetailLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <SpaceBetweenBox>
          <Box>
            <SkeletonLoader height="40px" width="200px" />
            <SkeletonLoader height="30px" width="125px" />
          </Box>
          {!isMobile && (
            <RowBox>
              <RedBorderButton width="125px" isLoading={true}>
                <Skeleton />
              </RedBorderButton>

              <Spacer size="s" position="left" />
              <CtaButton width="125px" isLoading={true}>
                <Skeleton />
              </CtaButton>
            </RowBox>
          )}
        </SpaceBetweenBox>
      </Grid>
      {isMobile && (
        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <RowBox>
              <RedBorderButton width="125px" isLoading={true}>
                <Skeleton />
              </RedBorderButton>

              <Spacer size="s" position="left" />
              <CtaButton width="125px" isLoading={true}>
                <Skeleton />
              </CtaButton>
            </RowBox>
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        <DetailCardContainer>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={isMobile ? 12 : 3}>
                  <Box>
                    <Skeleton />
                    <Text type="DarkGreyColor">
                      <Skeleton />
                    </Text>
                  </Box>
                </Grid>
                <Grid item xs={isMobile ? 12 : 3}>
                  <Box>
                    <Skeleton />
                    <Text type="DarkGreyColor">
                      <Skeleton />
                    </Text>
                  </Box>
                </Grid>
                <Grid item xs={isMobile ? 12 : 3}>
                  <Box>
                    <Skeleton />
                    <Text type="DarkGreyColor">
                      <Skeleton />
                    </Text>
                  </Box>
                </Grid>
                <Grid item xs={isMobile ? 12 : 3}>
                  <Box>
                    <Skeleton />
                    <Text type="DarkGreyColor">
                      <Skeleton />
                    </Text>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={isMobile ? 12 : 4}>
                  <Box>
                    <Skeleton />
                    <Text variant="h6" type="DarkGreyColor">
                      <Skeleton />
                    </Text>
                  </Box>
                </Grid>
                <Grid item xs={isMobile ? 12 : 4}>
                  <Box>
                    <Skeleton />
                    <Text variant="h6" type="DarkGreyColor">
                      <Skeleton />
                    </Text>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DetailCardContainer>
      </Grid>
      <Grid item xs={12}>
        <StatementTransactionListLoader />
      </Grid>
    </Grid>
  );
}

export default StatementDetailLoader;
