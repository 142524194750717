import React from "react";
import { Box, IconButton, styled, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useFormikContext } from "formik";
import HTMLRenderer from "react-html-renderer";
import Text from "../../../components/text.component";
import CenteredRowBox from "../../../components/centered-row-box.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import BorderButton from "../../../components/buttons/border-button.component";
import Spacer from "../../../components/spacer.component";

const ButtonContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  marginTop: "20px",
});

export default function ExperienceShoutOut({ setIsEditing, isEditing }) {
  const theme = useTheme();
  const { resetForm, values } = useFormikContext();

  return (
    <Box>
      <CenteredRowBox>
        <Text sx={{ fontSize: theme.fonts.fontSizes.size18 }}>Shout-Out</Text>
        <Spacer size="s" position="left" />
        <IconButton
          sx={{ color: theme.palette.colors.text.primary }}
          disabled={isEditing.shoutOut}
          onClick={() => setIsEditing((i) => ({ ...i, shoutOut: true }))}
        >
          <ModeEditIcon />
        </IconButton>
      </CenteredRowBox>
      <Spacer size="s" position="top" />
      {!isEditing.shoutOut ? (
        <Box
          sx={{
            padding: "16.5px 14px",
            borderRadius: "4px",
            border: `1px solid ${theme.palette.colors.ui.border}`,
          }}
        >
          <HTMLRenderer html={values.shoutOut || ""} />
        </Box>
      ) : (
        <FormFieldText name="shoutOut" width="100%" placeholder="Enter shoutout" />
      )}
      {isEditing.shoutOut && (
        <ButtonContainer>
          <BorderButton
            width="100px"
            onClickButton={() => {
              resetForm();
              setIsEditing((i) => ({ ...i, shoutOut: false }));
            }}
          >
            <Text type="BrandColor">Cancel</Text>
          </BorderButton>
          <Spacer size="s" position="left" />
          <FormSubmitButton width="100px">
            <Text type="WhiteColor">Save</Text>
          </FormSubmitButton>
        </ButtonContainer>
      )}
    </Box>
  );
}

ExperienceShoutOut.propTypes = {
  setIsEditing: PropTypes.func.isRequired,
  isEditing: PropTypes.shape({
    shoutOut: PropTypes.bool,
  }).isRequired,
};
