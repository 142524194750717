import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  styled,
  TextField,
  useTheme,
} from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

import Text from "../../../components/text.component";
import Spacer from "../../../components/spacer.component";
import FormDatePicker from "../../../components/forms/form-date-picker.component";

const CenterRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const StyledEditButton = styled(IconButton)({
  padding: "2px",
});

const ColumnBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  paddingLeft: "10px",
  paddingRight: "10px",
  "&& .form-control.invalid-number": {
    boxShadow: "none",
  },
  " .form-control:focus": {
    boxShadow: "none",
  },
});

function UserDetailsFormFieldText({ name, disabled, label, isShowIcon, editing, setEditing }) {
  const { setFieldValue, values, touched, errors, handleSubmit } = useFormikContext();
  const showError = touched[name] && typeof errors[name] === "string";
  const theme = useTheme();
  const [isPhoneFocus, setIsPhoneFocus] = useState(false);

  const keyPress = (e) => {
    if (e.keyCode === 13 && editing) {
      handleSubmit();
    }
  };

  const PhoneBottomBorderStyle = () => {
    if (showError) {
      return `2px solid ${theme.palette.colors.text.error}`;
    }
    if (isPhoneFocus) {
      return `2px solid ${theme.palette.colors.text.defaultFocusedBorder}`;
    }
    return `1px solid ${theme.palette.colors.text.defaultFieldBorder}`;
  };

  const handleChange = (event) => {
    setFieldValue(name, event.target.value);
  };

  if (name === "gender" && editing) {
    return (
      <ColumnBox>
        <CenterRowBox>
          <Text>{label}</Text>
          <Spacer size="s" position="left" />
          {isShowIcon && (
            <StyledEditButton onClick={() => setEditing(true)}>
              <ModeEditIcon
                sx={{
                  fontSize: theme.fonts.fontSizes.size18,
                  color: editing
                    ? theme.palette.colors.text.placeholder
                    : theme.palette.colors.brand.secondary,
                }}
              />
            </StyledEditButton>
          )}
        </CenterRowBox>
        <FormControl variant="standard">
          <Select
            value={values[name] === null ? "" : values[name]}
            onChange={handleChange}
            sx={{
              color: theme.palette.colors.text.darkGrey,
              fontSize: theme.fonts.fontSizes.size14,
            }}
          >
            <MenuItem value="male">Male</MenuItem>
            <MenuItem value="female">Female</MenuItem>
            <MenuItem value="others">Others</MenuItem>
          </Select>
        </FormControl>
      </ColumnBox>
    );
  }

  if (name === "phoneNumber" && editing) {
    return (
      <ColumnBox>
        <CenterRowBox>
          <Text>{label}</Text>
          <Spacer size="s" position="left" />
          {isShowIcon && (
            <StyledEditButton onClick={() => setEditing(true)}>
              <ModeEditIcon
                sx={{
                  fontSize: theme.fonts.fontSizes.size18,
                  color: editing
                    ? theme.palette.colors.text.placeholder
                    : theme.palette.colors.brand.secondary,
                }}
              />
            </StyledEditButton>
          )}
        </CenterRowBox>
        <PhoneInput
          onKeyDown={keyPress}
          containerStyle={{
            borderBottom: PhoneBottomBorderStyle(),
          }}
          onFocus={() => setIsPhoneFocus(true)}
          onBlur={() => setIsPhoneFocus(false)}
          buttonStyle={{ marginLeft: "-8px" }}
          specialLabel=""
          value={values[name] === null ? "" : values[name]}
          isValid={() => !showError}
          country="my"
          onChange={(e) => setFieldValue(name, e)}
          inputStyle={{
            width: "100%",
            borderRadius: theme.shape.borderRadius[0],
            border: "0px",
            padding: "6px 0px 5px 50px",
            marginLeft: "-5px",
            fontSize: theme.fonts.fontSizes.size14,
            color: theme.palette.colors.text.darkGrey,
          }}
        />
        {showError && (
          <FormHelperText
            sx={{
              color: theme.palette.colors.text.error,
              margin: "3px 0px 0px 1px",
            }}
          >
            {errors[name]}
          </FormHelperText>
        )}
      </ColumnBox>
    );
  }

  if (name === "dateOfBirth" && editing) {
    return (
      <ColumnBox>
        <CenterRowBox>
          <Text>{label}</Text>
          <Spacer size="s" position="left" />
          {isShowIcon && (
            <StyledEditButton onClick={() => setEditing(true)}>
              <ModeEditIcon
                sx={{
                  fontSize: theme.fonts.fontSizes.size18,
                  color: editing
                    ? theme.palette.colors.text.placeholder
                    : theme.palette.colors.brand.secondary,
                }}
              />
            </StyledEditButton>
          )}
        </CenterRowBox>
        <FormDatePicker name={name} disablePast={false} width="100%" placeholder="Date of birth" />{" "}
        {showError && (
          <FormHelperText
            sx={{
              color: theme.palette.colors.text.error,
              margin: "3px 0px 0px 1px",
            }}
          >
            {errors[name]}
          </FormHelperText>
        )}
      </ColumnBox>
    );
  }

  return (
    <ColumnBox>
      <CenterRowBox>
        <Text>{label}</Text>
        <Spacer size="s" position="left" />
        {isShowIcon && (
          <StyledEditButton onClick={() => setEditing(true)}>
            <ModeEditIcon
              sx={{
                fontSize: theme.fonts.fontSizes.size18,
                color: editing
                  ? theme.palette.colors.text.placeholder
                  : theme.palette.colors.brand.secondary,
              }}
            />
          </StyledEditButton>
        )}
      </CenterRowBox>
      <TextField
        onKeyDown={keyPress}
        disabled={disabled || !editing}
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
        variant="standard"
        value={values[name] === null ? "" : values[name]}
        error={showError}
        helperText={showError ? errors[name] : null}
        sx={{
          width: "100%",
          "&& .Mui-disabled": {
            WebkitTextFillColor: theme.palette.colors.text.darkGrey,
          },
        }}
        InputProps={{
          disableUnderline: !editing,
          style: {
            fontSize: "14px",
            color: theme.palette.colors.text.darkGrey,
          },
        }}
      />
    </ColumnBox>
  );
}

UserDetailsFormFieldText.defaultProps = {
  disabled: false,
  isShowIcon: false,
  editing: false,
  setEditing: null,
};

UserDetailsFormFieldText.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isShowIcon: PropTypes.bool,
  editing: PropTypes.bool,
  setEditing: PropTypes.func,
};

export default UserDetailsFormFieldText;
