import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, styled, Link, useTheme, useMediaQuery } from "@mui/material";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import Text from "../../../../components/text.component";
import PaddedView from "../../../../components/padded-view.component";
import Loading from "../../../../components/notifications/loading.component";
import routes from "../../../../navigation/routes";
import Spacer from "../../../../components/spacer.component";
import FormFieldTextWithCounter from "../../../../components/forms/form-field-text-with-counter.component";
import FormImageCropper from "../../../../components/forms/form-image-cropper.component";
import Form from "../../../../components/forms/form.component";
import FormDatePicker from "../../../../components/forms/form-date-picker.component";
import { SnackbarContext } from "../../../../components/notifications/snackbar.context";
import {
  createFestivalCategory,
  deleteFestivalCategory,
  festivalSectionSelector,
  getFestivalCategory,
  getFestivalSectionDetail,
  updateFestivalCategory,
  updateFestivalSection,
} from "../../../../services/marketing/festival-section/festival-section-slice.services";
import FestivalCategoryList from "../components/festival-category-list.component";
import {
  categorySelector,
  getCategoryList,
} from "../../../../services/category/category-slice.services";
import { getTagList, tagSelector } from "../../../../services/tag/tag-slice.services";
import DeleteConfirmationModal from "../../../../components/notifications/delete-confirmation-modal.component";
import FestivalSectionDetailLoader from "../loader/festival-section-detail-loader.component";
import DetailCardContainer from "../../../../components/detail-card-container.component";

const FlexEndButtonContainer = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  height: "100%",
  alignItems: "flex-end",
});

const validationSchema = Yup.object().shape({
  label: Yup.string().required().max(80).label("Title"),
  iconPath: Yup.string().required().label("Festival Icon"),
  startAt: Yup.date().required().label("Start from"),
  endAt: Yup.date()
    .min(Yup.ref("startAt"), "End date must be after start date")
    .required()
    .label("End at"),
});

const categoryValidationSchema = Yup.object().shape({
  label: Yup.string().required().max(50).label("Title"),
  imagePath: Yup.string().required().label("Image"),
  queryType: Yup.string().required().label("Type"),
  categoryObj: Yup.string().nullable().label("Category").when("queryType", {
    is: "category",
    then: Yup.string().required(),
  }),
  tagObj: Yup.array()
    .of(Yup.string())
    .when("queryType", {
      is: "tag",
      then: Yup.array().of(Yup.string()).required().min(1),
    })
    .label("Tag"),
  urlObj: Yup.string().nullable().label("URL").when("queryType", {
    is: "url",
    then: Yup.string().required(),
  }),
});

export default function FestivalSectionEditScreen() {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const festivalSectionId = new URLSearchParams(location.search).get("festivalSectionId");
  const { getFestivalSectionDetailObj, getFestivalCategoryObj, deleteFestivalCategoryObj } =
    useSelector(festivalSectionSelector);
  const { getTagListObj } = useSelector(tagSelector);
  const { getCategoryListObj } = useSelector(categorySelector);
  const createSnackBar = useContext(SnackbarContext);
  const cropSetting = {
    unit: "px",
    aspect: 1,
    width: 50,
    height: 50,
    x: 0,
    y: 0,
  };
  const aspectRatio = 1;
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteCategoryId, setDeleteCategoryId] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    dispatch(getCategoryList()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
    dispatch(getTagList()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
    dispatch(getFestivalSectionDetail(festivalSectionId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
    dispatch(getFestivalCategory(festivalSectionId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  }, []);

  const onUpdateFestivalSection = (values) => {
    setIsLoading(true);
    dispatch(
      updateFestivalSection({
        imageChanged: values.iconPathChanged === "1",
        ...values,
      }),
    ).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onDeleteFestivalCategory = (festivalCategoryId) => {
    setDeleteCategoryId(festivalCategoryId);
    setShowDeleteModal(true);
  };

  const onConfirmDelete = () => {
    setIsLoading(true);
    dispatch(
      deleteFestivalCategory({ festivalSectionId, festivalCategoryId: deleteCategoryId }),
    ).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setShowDeleteModal(false);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onSubmitCategoryForm = (values, { resetForm }) => {
    setIsLoading(true);
    let queryValue = [];
    if (values.queryType === "category") {
      queryValue.push(values.categoryObj);
    }
    if (values.queryType === "url") {
      queryValue.push(`https://${values.urlObj}`);
    }
    if (values.queryType === "tag") {
      queryValue = queryValue.concat(values.tagObj);
    }

    if (values.postType === "create") {
      dispatch(createFestivalCategory({ queryValue, ...values })).then(
        ({ meta, error, payload }) => {
          setIsLoading(false);
          if (meta.requestStatus === "fulfilled") {
            resetForm();
            setShowCategoryModal(false);
            createSnackBar({
              message: payload.message,
              type: "success",
            });
          }
          if (meta.requestStatus === "rejected") {
            createSnackBar({
              message: error.message,
              type: "error",
            });
          }
        },
      );
      return;
    }

    dispatch(
      updateFestivalCategory({
        imageChanged: values.imagePathChanged === "1" && values.postType === "edit",
        queryValue,
        ...values,
      }),
    ).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const renderContent = () => {
    if (
      getFestivalSectionDetailObj.status === "succeeded" &&
      getFestivalCategoryObj.status === "succeeded" &&
      getTagListObj.status === "succeeded" &&
      getCategoryListObj.status === "succeeded"
    ) {
      return (
        <Form
          validationSchema={validationSchema}
          initialValues={{
            label: getFestivalSectionDetailObj.data.label,
            iconPath: getFestivalSectionDetailObj.data.iconPath,
            iconPathChanged: "0",
            startAt: getFestivalSectionDetailObj.data.startAt.split(" ")[0],
            endAt: getFestivalSectionDetailObj.data.endAt.split(" ")[0],
            festivalSectionId,
          }}
          onSubmit={onUpdateFestivalSection}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Text variant="h4">Edit Festival Section</Text>
            </Grid>
            <Grid item xs={12}>
              <DetailCardContainer>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", flexDirection: isMobile ? "column" : "row" }}>
                      <Box>
                        <FormImageCropper
                          name="iconPath"
                          label="Icon"
                          width="100px"
                          height="100px"
                          cropSetting={cropSetting}
                          aspectRatio={aspectRatio}
                        />
                      </Box>
                      {isMobile ? (
                        <Spacer size="s" position="top" />
                      ) : (
                        <Spacer size="l" position="left" />
                      )}
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <Text>Title</Text>
                        <Spacer size="xs" position="top" />
                        <FormFieldTextWithCounter
                          maxLength={50}
                          name="label"
                          placeholder="Enter title here"
                        />
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={isMobile ? 12 : 4.5}>
                    <Text>Start from</Text>
                    <Spacer size="xs" position="top" />
                    <FormDatePicker name="startAt" width="100%" disablePast={false} />
                  </Grid>
                  <Grid item xs={isMobile ? 12 : 4.5}>
                    <Text>End at</Text>
                    <Spacer size="xs" position="top" />
                    <FormDatePicker name="endAt" width="100%" disablePast={false} />
                  </Grid>
                  <Grid item xs={isMobile ? 12 : 3}>
                    <FlexEndButtonContainer>
                      <FormSubmitButton width="125px">
                        <Text type="WhiteColor">Save</Text>
                      </FormSubmitButton>
                    </FlexEndButtonContainer>
                  </Grid>
                </Grid>
              </DetailCardContainer>
            </Grid>
            <Grid item xs={12}>
              <Form
                onSubmit={onSubmitCategoryForm}
                validationSchema={categoryValidationSchema}
                initialValues={{
                  label: "",
                  imagePath: "",
                  imagePathChanged: "0",
                  tagObj: [],
                  categoryObj: "",
                  queryType: "category",
                  urlObj: "",
                  postType: "create",
                  festivalSectionId,
                  festivalCategoryId: "",
                }}
              >
                <FestivalCategoryList
                  onDeleteFestivalCategory={onDeleteFestivalCategory}
                  showModal={showCategoryModal}
                  setShowModal={setShowCategoryModal}
                />
              </Form>
            </Grid>
            <Grid item xs={12} />
          </Grid>
        </Form>
      );
    }
    return <FestivalSectionDetailLoader />;
  };
  return (
    <PaddedView multiples={isMobile ? 1 : 3}>
      <Loading isLoading={isLoading} />
      <DeleteConfirmationModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        label="Are you sure to delete this festival category?"
        isLoading={deleteFestivalCategoryObj.status === "pending"}
        onConfirmClicked={onConfirmDelete}
      />
      <Link href={routes.MARKETING_FESTIVAL_SECTION_LIST} style={{ textDecoration: "none" }}>
        <Text type="GreyColor">{`< Back`}</Text>
      </Link>
      <Spacer size="xl" position="top" />
      {renderContent()}
    </PaddedView>
  );
}
