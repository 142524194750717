
import { Box, Grid } from "@mui/material";
import React from "react";
import CtaButton from "../../../components/buttons/cta-button.component";
import SkeletonLoader from "../../../components/skeleton.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";

function CorporatePointsLoader() {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Text variant="h5">Credit Points</Text>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <SkeletonLoader width="175px" height="59px" />
                    <Spacer size="m" position="left" />
                    <CtaButton width="125px" isLoading={true}>
                        <Text type="WhiteColor">Save</Text>
                    </CtaButton>
                </Box>
            </Grid>
        </Grid>
    );
}

export default CorporatePointsLoader;
