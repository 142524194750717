import React from "react";
import { useTheme } from "@mui/material";
import PropTypes from "prop-types";

function ExperiencesIcon({ isSelected }) {
  const theme = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
      <path
        id="Icon_awesome-plane"
        data-name="Icon awesome-plane"
        d="M18.334,8.25H13.968L9.954.346A.6.6,0,0,0,9.423,0h-2.5a.675.675,0,0,0-.587.876L8.207,8.25H4.278L2.628,5.775A.591.591,0,0,0,2.139,5.5H.612a.673.673,0,0,0-.593.854L1.222,11l-1.2,4.646a.673.673,0,0,0,.593.854H2.139a.591.591,0,0,0,.489-.275l1.65-2.475H8.207L6.334,21.123A.676.676,0,0,0,6.921,22h2.5a.6.6,0,0,0,.531-.346l4.015-7.9h4.365C19.684,13.75,22,12.519,22,11S19.684,8.25,18.334,8.25Z"
        transform="translate(0)"
        fill={
          isSelected ? theme.palette.colors.brand.primary : theme.palette.colors.brand.secondary
        }
      />
    </svg>
  );
}

ExperiencesIcon.defaultProps = {
  isSelected: false,
};

ExperiencesIcon.propTypes = {
  isSelected: PropTypes.bool,
};

export default ExperiencesIcon;
