import { Box, IconButton, styled, TextField, useTheme } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useFormikContext } from "formik";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import CenteredRowBox from "../../../components/centered-row-box.component";
import BorderButton from "../../../components/buttons/border-button.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import FormAddressAutoComplete from "../../../components/forms/form-address-auto-complete.component";

const ButtonContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  marginTop: "20px",
});

export default function ExperienceLocation({ isEditing, setIsEditing }) {
  const theme = useTheme();
  const { values, resetForm } = useFormikContext();
  return (
    <>
      <CenteredRowBox>
        <Text sx={{ fontSize: theme.fonts.fontSizes.size18 }}>Location</Text>
        <Spacer size="s" position="left" />
        <IconButton
          sx={{ color: theme.palette.colors.text.primary }}
          disabled={isEditing.address}
          onClick={() => setIsEditing((i) => ({ ...i, address: true }))}
        >
          <ModeEditIcon />
        </IconButton>
      </CenteredRowBox>
      <Spacer size="s" position="top" />
      {!isEditing.address ? (
        <TextField
          multiline
          disabled
          sx={{
            width: "100%",
            borderTopLeftRadius: theme.shape.borderRadius[0],
            borderTopRightRadius: theme.shape.borderRadius[0],
            borderBottomLeftRadius: theme.shape.borderRadius[0],
            borderBottomRightRadius: theme.shape.borderRadius[0],
            "& .MuiOutlinedInput-root": {
              borderTopLeftRadius: theme.shape.borderRadius[0],
              borderTopRightRadius: theme.shape.borderRadius[0],
              borderBottomLeftRadius: theme.shape.borderRadius[0],
              borderBottomRightRadius: theme.shape.borderRadius[0],
              backgroundColor: theme.palette.colors.text.white,
            },
          }}
          variant="outlined"
          value={values.address || ""}
        />
      ) : (
        <FormAddressAutoComplete name="address" />
      )}
      {isEditing.address && (
        <ButtonContainer>
          <BorderButton
            width="100px"
            onClickButton={() => {
              resetForm();
              setIsEditing((i) => ({ ...i, address: false }));
            }}
          >
            <Text type="BrandColor">Cancel</Text>
          </BorderButton>
          <Spacer size="s" position="left" />
          <FormSubmitButton width="100px">
            <Text type="WhiteColor">Save</Text>
          </FormSubmitButton>
        </ButtonContainer>
      )}
    </>
  );
}

ExperienceLocation.propTypes = {
  setIsEditing: PropTypes.func.isRequired,
  isEditing: PropTypes.shape({
    address: PropTypes.bool,
  }).isRequired,
};
