import React from "react";
import { Box, IconButton, styled, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import StarRateIcon from "@mui/icons-material/StarRate";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useFormikContext } from "formik";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";

const StyledBox = styled(Box)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  padding: "10px",
  backgroundColor: theme.palette.colors.bg.recommendationsBg,
}));

const FullWidthBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
});

const BorderLine = styled(Box)(({ theme }) => ({
  width: "95%",
  height: "1px",
  backgroundColor: theme.palette.colors.ui.border,
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.brand.secondary,
  fontWeight: theme.fonts.fontWeights.bold,
  lineHeight: "22px",
  textAlign: "start",
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
  overflowWrap: "anywhere",
}));

const StyledPriceText = styled(Typography)(({ theme }) => ({
  textAlign: "start",
  color: theme.palette.colors.brand.primary,
  fontWeight: theme.fonts.fontWeights.bold,
}));

const StyledRatingText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size13,
  textAlign: "start",
}));

const StyledStateText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size12,
  textAlign: "start",
}));

const StyledBottomBox = styled(Box)({
  marginTop: "5px",
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

const SpaceBetweenRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-start",
});

const StyledExperienceDetailsBox = styled(Box)({
  width: "100%",
  "& .MuiButton-root": {
    textTransform: "none",
    padding: "0px",
    "&:hover": {
      backgroundColor: "transparent",
      borderColor: "transparent",
      boxShadow: "none",
    },
  },
});

const StyledIconButton = styled(IconButton)({
  "&:hover": {
    backgroundcolor: "transparent",
  },
  position: "absolute",
  bottom: 0,
  right: 0,
  padding: "0px",
});

const StyledRatingStar = styled(StarRateIcon)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.body,
  marginTop: "-5px",
  color: theme.palette.colors.brand.primary,
}));

const ReviewGreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontSize: theme.fonts.fontSizes.size14,
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
}));

const StyledRatingBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
});

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.brand.primary,
  fontSize: theme.fonts.fontSizes.size14,
}));

function ExperienceCard({ experience, onToggleExperienceAdd, isShowAddIcon, isEditing }) {
  const theme = useTheme();
  const { values } = useFormikContext();

  const renderRating = () => (
    <StyledRatingBox>
      <StyledRatingStar />
      <PrimaryColorText>{experience.ratings}</PrimaryColorText>
      <Spacer size="xs" position="left" />
      <ReviewGreyText>{`(${experience.noReviews})`}</ReviewGreyText>
    </StyledRatingBox>
  );

  const renderPrice = () => (
    <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="flex-end">
      <StyledRatingText>From</StyledRatingText>
      <Spacer size="xs" position="left" />
      <StyledPriceText>{`RM${experience.priceCents}`}</StyledPriceText>
      <Spacer size="xs" position="left" />
      <StyledRatingText>
        / {experience.type === "group" ? `group of ${experience.groupOfPeople}` : "person"}
      </StyledRatingText>
    </Box>
  );

  const renderIconButton = () =>
    values.experiencesId.includes(experience.id) ? (
      <StyledIconButton
        disabled={!isEditing}
        sx={{ color: theme.palette.colors.button.addedButton }}
        onClick={() => onToggleExperienceAdd(experience)}
      >
        <CheckCircleIcon sx={{ fontSize: theme.fonts.fontSizes.size32 }} />
      </StyledIconButton>
    ) : (
      <StyledIconButton
        disabled={!isEditing}
        sx={{ color: theme.palette.colors.brand.primary }}
        onClick={() => onToggleExperienceAdd(experience)}
      >
        <AddCircleIcon sx={{ fontSize: theme.fonts.fontSizes.size32 }} />
      </StyledIconButton>
    );

  return (
    <>
      <StyledBox>
        <Box sx={{ position: "relative" }}>
          {isShowAddIcon && renderIconButton()}
          <img src={experience.imagePath} alt="experience-img" style={{ width: "100%" }} />
          <StyledBottomBox>
            <StyledExperienceDetailsBox>
              <SpaceBetweenRowBox>
                <StyledTitle>{experience.title}</StyledTitle>
                {renderRating()}
              </SpaceBetweenRowBox>
              {experience.location.area !== "" && (
                <StyledStateText>{experience.location.area}</StyledStateText>
              )}
            </StyledExperienceDetailsBox>
            <Spacer size="s" position="top" />
            {renderPrice()}
            <Spacer size="s" position="top" />
            {values.newAddedExperiencesObj &&
              values.newAddedExperiencesObj.map((item) => item.id).includes(experience.id) && (
                <Text
                  type="RejectText"
                  sx={{ position: "absolute", bottom: 0, left: 0, padding: "0px" }}
                >
                  New Added
                </Text>
              )}
            <Spacer size="xl" position="top" />
          </StyledBottomBox>
        </Box>
      </StyledBox>

      <Spacer size="xs" position="top" />
      <FullWidthBox>
        <BorderLine />
      </FullWidthBox>
    </>
  );
}

ExperienceCard.defaultProps = {
  isShowAddIcon: false,
  isEditing: true,
  onToggleExperienceAdd: null,
};

ExperienceCard.propTypes = {
  isShowAddIcon: PropTypes.bool,
  isEditing: PropTypes.bool,
  onToggleExperienceAdd: PropTypes.func,
  experience: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    priceCents: PropTypes.string,
    type: PropTypes.string,
    groupOfPeople: PropTypes.number,
    imagePath: PropTypes.string,
    location: PropTypes.shape({
      area: PropTypes.string,
    }),
    ratings: PropTypes.number,
    noReviews: PropTypes.number,
  }).isRequired,
};

export default ExperienceCard;
