import React from "react";
import { Table, TableBody, TableRow, TableCell, Grid } from "@mui/material";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import TableHeader from "../../../components/table/table-header.component";

function TableLoader() {
  return (
    <Table sx={{ minWidth: 650 }} aria-label="collapsible table">
      <TableHeader
        headerCells={[
          "Listing ID",
          "Merchant",
          "Category",
          "Experience Name",
          "Date Listed",
          "Status",
          "",
        ]}
      />
      <TableBody>
        <TableRow>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Grid container spacing={1}>
              <Grid item>
                <Skeleton height={20} width={20} circle={true} />
              </Grid>
              <Grid item>
                <Skeleton height={20} width={20} circle={true} />
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

export default TableLoader;
