import { TableCell, TableRow } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import Text from "../../../components/text.component";

function TransactionRow({ record }) {
  return (
    <TableRow
      key={record.id}
      sx={{
        "& .MuiTableCell-root": {
          borderBottom: "no-set",
        },
      }}
    >
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center" }}>
          {record.id}
        </Text>
      </TableCell>
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center" }}>
          {record.description}
        </Text>
      </TableCell>
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center", minWidth: "100px" }}>
          {record.createdAt}
        </Text>
      </TableCell>
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center", minWidth: "100px" }}>
          {record.category === "platform_commission" ? "-" : "+"} RM
          {record.amount.replace("-", "")}
        </Text>
      </TableCell>
    </TableRow>
  );
}

TransactionRow.defaultProps = {
  record: {
    id: null,
    experienceTitle: PropTypes.string,
    amount: PropTypes.string,
    category: PropTypes.string,
    description: PropTypes.string,
    createdAt: PropTypes.string,
  },
};

TransactionRow.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    experienceTitle: PropTypes.string,
    amount: PropTypes.string,
    category: PropTypes.string,
    description: PropTypes.string,
    createdAt: PropTypes.string,
  }),
};

export default TransactionRow;
