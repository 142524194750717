import React from "react";
import PropTypes from "prop-types";
import { TableCell, IconButton, TableRow } from "@mui/material";
import { Link } from "react-router-dom";
import Text from "../../../components/text.component";
import EditIcon from "../../../assets/images/edit-icon";
import routes from "../../../navigation/routes";

function CategoryExperienceRow({ record }) {
  return (
    <TableRow
      key={record.id}
      sx={{
        "& .MuiTableCell-root": {
          borderBottom: "no-set",
        },
      }}
    >
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center" }}>
          {record.id}
        </Text>
      </TableCell>
      <TableCell>
        <Text
          variant="body2"
          sx={{
            textAlign: "center",
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
          }}
        >
          {record.title}
        </Text>
      </TableCell>
      <TableCell>
        <Link to={{ pathname: `${routes.EXPERIENCEDETAIL}`, search: `experienceId=${record.id}` }}>
          <IconButton>
            <EditIcon />
          </IconButton>
        </Link>
      </TableCell>
    </TableRow>
  );
}

CategoryExperienceRow.defaultProps = {
  record: {
    id: null,
    title: "",
  },
};

CategoryExperienceRow.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  }),
};

export default CategoryExperienceRow;
