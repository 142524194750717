import React from "react";
import PropTypes from "prop-types";
import { TableCell, IconButton, TableRow, Box, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import Text from "../../../../components/text.component";
import EditIcon from "../../../../assets/images/edit-icon";
import routes from "../../../../navigation/routes";
import DeleteIcon from "../../../../assets/images/delete-icon";
import CtaButton from "../../../../components/buttons/cta-button.component";
import Spacer from "../../../../components/spacer.component";
import BorderButton from "../../../../components/buttons/border-button.component";

function BannerRow({ record, onToggleStatusButton, onToggleDelete }) {
  const theme = useTheme();

  return (
    <TableRow
      key={record.id}
      sx={{
        "& .MuiTableCell-root": {
          borderBottom: "no-set",
        },
      }}
    >
      <TableCell sx={{ width: "300px" }}>
        <img src={record.imagePath} alt="BannerImage" style={{ width: "100%" }} />
      </TableCell>
      <TableCell>
        <Text
          variant="body2"
          sx={{
            textAlign: "center",
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
          }}
        >
          {record.description}
        </Text>
      </TableCell>
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center", width: "100px" }}>
          {record.updatedAt.split(",")[0]}
        </Text>
      </TableCell>
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center", textTransform: "capitalize" }}>
          {record.show ? "Published" : "Draft"}
        </Text>
      </TableCell>
      <TableCell>
        <Text variant="body2" sx={{ textAlign: "center", minWidth: "100px" }}>
          {`${record.startAt.split(" ")[0]} - ${record.endAt.split(" ")[0]}`}
        </Text>
      </TableCell>
      <TableCell>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          {record.show ? (
            <BorderButton
              onClickButton={() => onToggleStatusButton(record.id)}
              width="90px"
              height="35px"
            >
              <Text type="BrandColor" sx={{ fontSize: theme.fonts.fontSizes.size14 }}>
                Unpublish
              </Text>
            </BorderButton>
          ) : (
            <CtaButton
              onClickButton={() => onToggleStatusButton(record.id)}
              width="90px"
              height="35px"
            >
              <Text type="WhiteColor" sx={{ fontSize: theme.fonts.fontSizes.size14 }}>
                Publish
              </Text>
            </CtaButton>
          )}

          <Spacer position="left" size="s" />
          <Link
            to={{ pathname: `${routes.MARKETING_BANNER_EDIT}`, search: `bannerId=${record.id}` }}
          >
            <IconButton>
              <EditIcon />
            </IconButton>
          </Link>

          <IconButton onClick={() => onToggleDelete(record.id)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
}

BannerRow.defaultProps = {
  record: {
    id: null,
    description: "",
    imagePath: "",
    updatedAt: "",
    startAt: "",
    endAt: "",
    show: "",
  },
};

BannerRow.propTypes = {
  onToggleDelete: PropTypes.func.isRequired,
  onToggleStatusButton: PropTypes.func.isRequired,
  record: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    imagePath: PropTypes.string,
    updatedAt: PropTypes.string,
    startAt: PropTypes.string,
    endAt: PropTypes.string,
    show: PropTypes.bool,
  }),
};

export default BannerRow;
