import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../../api-services";

export const createBanner = createAsyncThunk("marketing/banner/create", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/marketings/banner`,
    payload,
  );
  return response;
});

export const getBannerDetail = createAsyncThunk("marketing/banner/detail", async (bannerId) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/marketings/banner/${bannerId}`,
  );
  return response;
});

export const updateBanner = createAsyncThunk("marketing/banner/update", async (payload) => {
  const response = await putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/marketings/banner/${payload.bannerId}?updateType=details`,
    payload,
  );
  return response;
});

export const getBannerList = createAsyncThunk("marketing/banner/list", async (payload) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/marketings/banner?q=${payload.q}&page=${payload.page}`,
    payload,
  );
  return response;
});

export const updateBannerStatus = createAsyncThunk(
  "marketing/banner/update_status",
  async (bannerId) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/marketings/banner/${bannerId}?updateType=status`,
    );
    return response;
  },
);

export const deleteBanner = createAsyncThunk("marketing/banner/delete", async (bannerId) => {
  const response = await delReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/marketings/banner/${bannerId}`,
  );
  return response;
});

const bannerSlice = createSlice({
  name: "banner",
  initialState: {
    createBannerObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getBannerDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateBannerObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getBannerListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateBannerStatusObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteBannerObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [createBanner.pending]: (state) => {
      state.createBannerObj.status = "pending";
    },
    [createBanner.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createBannerObj.status = "succeeded";
      state.createBannerObj.data = data;
      state.createBannerObj.successMessage = message;
    },
    [createBanner.rejected]: (state, action) => {
      const { message } = action.error;

      state.createBannerObj.status = "failed";
      state.createBannerObj.errorMessage = message;
    },
    [getBannerDetail.pending]: (state) => {
      state.getBannerDetailObj.status = "pending";
    },
    [getBannerDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getBannerDetailObj.status = "succeeded";
      state.getBannerDetailObj.data = data;
      state.getBannerDetailObj.successMessage = message;
    },
    [getBannerDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.getBannerDetailObj.status = "failed";
      state.getBannerDetailObj.errorMessage = message;
    },
    [updateBanner.pending]: (state) => {
      state.updateBannerObj.status = "pending";
    },
    [updateBanner.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateBannerObj.status = "succeeded";
      state.updateBannerObj.data = data;
      state.updateBannerObj.successMessage = message;
    },
    [updateBanner.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateBannerObj.status = "failed";
      state.updateBannerObj.errorMessage = message;
    },
    [getBannerList.pending]: (state) => {
      state.getBannerListObj.status = "pending";
    },
    [getBannerList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getBannerListObj.status = "succeeded";
      state.getBannerListObj.data = data;
      state.getBannerListObj.successMessage = message;
    },
    [getBannerList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getBannerListObj.status = "failed";
      state.getBannerListObj.errorMessage = message;
    },
    [updateBannerStatus.pending]: (state) => {
      state.updateBannerStatusObj.status = "pending";
    },
    [updateBannerStatus.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateBannerStatusObj.status = "succeeded";
      state.updateBannerStatusObj.data = data;
      state.updateBannerStatusObj.successMessage = message;
    },
    [updateBannerStatus.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateBannerStatusObj.status = "failed";
      state.updateBannerStatusObj.errorMessage = message;
    },
    [deleteBanner.pending]: (state) => {
      state.deleteBannerObj.status = "pending";
    },
    [deleteBanner.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteBannerObj.status = "succeeded";
      state.deleteBannerObj.data = data;
      state.deleteBannerObj.successMessage = message;

      state.getBannerListObj.data = data;
    },
    [deleteBanner.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteBannerObj.status = "failed";
      state.deleteBannerObj.errorMessage = message;
    },
  },
});

export default bannerSlice.reducer;

// state
export const bannerSelector = (state) => state.banner;
