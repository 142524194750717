import { Box, Grid, styled, useTheme } from "@mui/material";
import React from "react";
import Loader from "react-js-loader";
import Text from "../../../components/text.component";

const LoaderBox = styled(Box)({
  display: "flex",
  height: "300px",
  justifyContent: "center",
  alignItems: "center",
});

function MerchantExperiencesLoader() {
  const theme = useTheme();

  return (
    <Box sx={{ paddingTop: 3, paddingBottom: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Text variant="h4">Experiences</Text>
        </Grid>
        <Grid item xs={12}>
          <LoaderBox>
            <Loader type="bubble-top" bgColor={theme.palette.colors.brand.secondary} size={40} />
          </LoaderBox>
        </Grid>
      </Grid>
    </Box>
  );
}

export default MerchantExperiencesLoader;
