import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq, postReq, putReq, delReq } from "../api-services";

export const getMerchantList = createAsyncThunk("employees/merchants", async (payload) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants?status=${payload.status}&q=${payload.q}&page=${payload.page}`,
    payload,
  );
  return response;
});

export const getMerchantDetails = createAsyncThunk(
  "employees/merchants-details",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/${payload}`,
    );
    return response;
  },
);

export const updateMerchant = createAsyncThunk("employees/merchants-update", async (payload) => {
  const response = await putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/${payload.merchantId}?params=${payload.type}`,
    payload,
  );
  return response;
});

export const deleteMerchant = createAsyncThunk("employees/merchants-delete", async (merchantId) => {
  const response = await delReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/${merchantId}`,
  );
  return response;
});

export const getMerchantExperienceList = createAsyncThunk(
  "employees/merchants-experiences-list",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/${payload.merchantId}/experiences?page=${payload.page}`,
    );
    return response;
  },
);

export const searchMerchant = createAsyncThunk("employees/merchants/search", async (q) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/search?q=${q}`,
  );
  return response;
});

export const createMerchant = createAsyncThunk("employees/merchants/create", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants`,
    payload,
  );
  return response;
});

export const getMerchantStatementList = createAsyncThunk(
  "employees/merchants-statements-list",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/${payload.merchantId}/statements?q=${payload.q}&page=${payload.page}`,
    );
    return response;
  },
);

const merchantSlice = createSlice({
  name: "merchant",
  initialState: {
    getMerchantListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getMerchantDetailsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateMerchantObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteMerchantObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getMerchantExperienceListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    searchMerchantObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createMerchantObj: { status: "idle", data: null, successMessage: null, errorMessage: null },
    getMerchantStatementListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getMerchantList.pending]: (state) => {
      state.getMerchantListObj.status = "pending";
    },
    [getMerchantList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getMerchantListObj.status = "succeeded";
      state.getMerchantListObj.data = data;
      state.getMerchantListObj.successMessage = message;
    },
    [getMerchantList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getMerchantListObj.status = "failed";
      state.getMerchantListObj.errorMessage = message;
    },
    [getMerchantDetails.pending]: (state) => {
      state.getMerchantDetailsObj.status = "pending";
    },
    [getMerchantDetails.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getMerchantDetailsObj.status = "succeeded";
      state.getMerchantDetailsObj.data = data;
      state.getMerchantDetailsObj.successMessage = message;
    },
    [getMerchantDetails.rejected]: (state, action) => {
      const { message } = action.error;

      state.getMerchantDetailsObj.status = "failed";
      state.getMerchantDetailsObj.errorMessage = message;
    },
    [updateMerchant.pending]: (state) => {
      state.updateMerchantObj.status = "pending";
    },
    [updateMerchant.fulfilled]: (state, action) => {
      const { data, message } = action.payload;
      state.updateMerchantObj.status = "succeeded";
      state.updateMerchantObj.data = data;
      state.updateMerchantObj.successMessage = message;

      state.getMerchantDetailsObj.data = data;
    },
    [updateMerchant.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateMerchantObj.status = "failed";
      state.updateMerchantObj.errorMessage = message;
    },
    [deleteMerchant.pending]: (state) => {
      state.deleteMerchantObj.status = "pending";
    },
    [deleteMerchant.fulfilled]: (state, action) => {
      const { data, message } = action.payload;
      state.deleteMerchantObj.status = "succeeded";
      state.deleteMerchantObj.data = data;
      state.deleteMerchantObj.successMessage = message;

      state.getMerchantDetailsObj.data = data;
    },
    [deleteMerchant.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteMerchantObj.status = "failed";
      state.deleteMerchantObj.errorMessage = message;
    },
    [getMerchantExperienceList.pending]: (state) => {
      state.getMerchantExperienceListObj.status = "pending";
    },
    [getMerchantExperienceList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;
      state.getMerchantExperienceListObj.status = "succeeded";
      state.getMerchantExperienceListObj.data = data;
      state.getMerchantExperienceListObj.successMessage = message;
    },
    [getMerchantExperienceList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getMerchantExperienceListObj.status = "failed";
      state.getMerchantExperienceListObj.errorMessage = message;
    },
    [searchMerchant.pending]: (state) => {
      state.searchMerchantObj.status = "pending";
    },
    [searchMerchant.fulfilled]: (state, action) => {
      const { data, message } = action.payload;
      state.searchMerchantObj.status = "succeeded";
      state.searchMerchantObj.data = data;
      state.searchMerchantObj.successMessage = message;
    },
    [searchMerchant.rejected]: (state, action) => {
      const { message } = action.error;

      state.searchMerchantObj.status = "failed";
      state.searchMerchantObj.errorMessage = message;
    },
    [createMerchant.pending]: (state) => {
      state.createMerchantObj.status = "pending";
    },
    [createMerchant.fulfilled]: (state, action) => {
      const { data, message } = action.payload;
      state.createMerchantObj.status = "succeeded";
      state.createMerchantObj.data = data;
      state.createMerchantObj.successMessage = message;
    },
    [createMerchant.rejected]: (state, action) => {
      const { message } = action.error;

      state.createMerchantObj.status = "failed";
      state.createMerchantObj.errorMessage = message;
    },
    [getMerchantStatementList.pending]: (state) => {
      state.getMerchantStatementListObj.status = "pending";
    },
    [getMerchantStatementList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;
      state.getMerchantStatementListObj.status = "succeeded";
      state.getMerchantStatementListObj.data = data;
      state.getMerchantStatementListObj.successMessage = message;
    },
    [getMerchantStatementList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getMerchantStatementListObj.status = "failed";
      state.getMerchantStatementListObj.errorMessage = message;
    },
  },
});

export default merchantSlice.reducer;

// state
export const merchantSelector = (state) => state.merchant;
