import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, IconButton, Modal, styled, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import CtaButton from "../../../components/buttons/cta-button.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";

const ModalBox = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: theme.palette.colors.brand.white,
    borderRadius: theme.shape.borderRadius[0],
    boxShadow: 24,
    outline: "none",
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.colors.brand.secondary,
}));

export default function CorporateUnableDeleteModal({ showModal, setShowModal }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const handleCancel = () => {
        setShowModal(false);
    };

    return (
        <Modal open={showModal} onClose={handleCancel}>
            <ModalBox
                sx={{
                    padding: isMobile ? theme.padding.paddingX[1] : theme.padding.paddingX[0],
                    width: isMobile ? "350px" : "500px",
                }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                            <CloseIconButton onClick={handleCancel}>
                                <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
                            </CloseIconButton>
                        </Box>
                        <Text
                            variant="h5"
                            sx={{
                                textAlign: "center",
                                fontWeight: theme.fonts.fontWeights.bold,
                            }}
                        >
                            Corporate unable to delete
                        </Text>
                    </Grid>
                    <Grid item xs={12}>
                        <Spacer size="xl" position="top" />
                        <Text sx={{ textAlign: "center" }}>
                            There are links or data under this corporate therefore this corporate cannot be deleted.
                        </Text>
                    </Grid>
                    <Grid item xs={12}>
                        <Spacer size="l" position="top" />
                    </Grid>
                    <Grid item xs={12}>
                        <Spacer size="xxl" position="top" />
                        <CtaButton onClickButton={() => setShowModal(false)}>OK</CtaButton>
                    </Grid>
                </Grid>
            </ModalBox>
        </Modal>
    );
}

CorporateUnableDeleteModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
};
