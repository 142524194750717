import {
    Box,
    Grid,
    Pagination,
    styled,
    Table,
    TableBody,
    TableCell,
    TableRow,
    useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import Form from "../../../components/forms/form.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import TableHeader from "../../../components/table/table-header.component";
import Text from "../../../components/text.component";
import {
    corporateSelector,
    getCorporateStatementList,
} from "../../../services/corporate/corporate-slice.services";
import SearchBar from "../../statement/components/search-bar.component";
import StatementRow from "./statement-row.component";

const CustomFooter = styled(Box)({
    display: "flex",
    justifyContent: "center",
    width: "100%",
    minHeight: "70px",
    alignItems: "center",
});

const validationSchema = Yup.object().shape({
    q: Yup.number().nullable().label("Statement ID"),
});

export default function CorporateStatements() {
    const dispatch = useDispatch();
    const location = useLocation();
    const formRef = useRef();
    const corporateId = new URLSearchParams(location.search).get("corporateId");
    const createSnackBar = useContext(SnackbarContext);
    const { getCorporateStatementListObj } = useSelector(corporateSelector);
    const theme = useTheme();
    const [page, setPage] = useState(1);

    useEffect(() => {
        dispatch(getCorporateStatementList({ corporateId, q: "", page: 1 })).then(({ meta, error }) => {
            if (meta.requestStatus === "rejected") {
                createSnackBar({
                    message: error.message,
                    type: "error",
                    open: true,
                });
            }
        });
    }, []);

    const onSearchStatement = (values) => {
        setPage(1);
        dispatch(
            getCorporateStatementList({
                ...values,
                corporateId,
                page: 1,
            }),
        ).then(({ meta, error }) => {
            if (meta.requestStatus === "rejected") {
                createSnackBar({
                    message: error.message,
                    type: "error",
                });
            }
        });
    };

    const onPageChange = (e, newPage) => {
        dispatch(
            getCorporateStatementList({ ...formRef.current.values, corporateId, page: newPage }),
        ).then(({ meta, error }) => {
            if (meta.requestStatus === "rejected") {
                createSnackBar({
                    message: error.message,
                    type: "error",
                    open: true,
                });
            }
        });
        setPage(newPage);
    };

    const renderContent = () => {
        if (getCorporateStatementListObj.status === "succeeded") {
            return (
                <>
                    <Table>
                        <TableHeader headerCells={["Statement ID", "Status", "Amount (RM)", ""]} />
                        <TableBody>
                            {getCorporateStatementListObj.data.items.map((item) => (
                                <StatementRow record={item} key={item.id} />
                            ))}
                        </TableBody>
                    </Table>
                    <CustomFooter>
                        <Pagination
                            sx={{
                                "&& .Mui-selected": {
                                    backgroundColor: theme.palette.colors.brand.primary,
                                    color: theme.palette.colors.text.white,
                                },
                            }}
                            page={page}
                            onChange={onPageChange}
                            count={getCorporateStatementListObj.data.pagination.totalPages}
                            variant="outlined"
                        />
                    </CustomFooter>
                </>
            );
        }
        return (
            <Table>
                <TableHeader headerCells={["Statement ID", "Status", "Amount (RM)", ""]} />
                <TableBody>
                    <TableRow
                        sx={{
                            "& .MuiTableCell-root": {
                                borderBottom: "no-set",
                            },
                        }}
                    >
                        <TableCell>
                            <Text variant="body2" sx={{ textAlign: "center" }}>
                                <Skeleton />
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text variant="body2" sx={{ textAlign: "center" }}>
                                <Skeleton />
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text variant="body2" sx={{ textAlign: "center" }}>
                                <Skeleton />
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Grid container spacing={1} sx={{ justifyContent: "center" }}>
                                <Grid item>
                                    <Skeleton height={20} width={20} circle={true} />
                                </Grid>
                            </Grid>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    };

    return (
        <Grid item xs={12}>
            <Form
                innerRef={formRef}
                onSubmit={onSearchStatement}
                validationSchema={validationSchema}
                initialValues={{
                    q: "",
                }}
            >
                <Grid container>
                    <Grid item xs={9}>
                        <Text variant="h5">Statement List</Text>
                    </Grid>
                    <Grid item xs={3}>
                        <SearchBar name="q" placeholder="Statement ID" width="100%" />
                    </Grid>
                </Grid>
            </Form>

            {renderContent()}
        </Grid>
    );
}
