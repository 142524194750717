import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import CtaButton from "../../../components/buttons/cta-button.component";
import DetailCardContainer from "../../../components/detail-card-container.component";
import Form from "../../../components/forms/form.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import Text from "../../../components/text.component";
import {
    addCorporatePoints,
    corporateSelector,
    getCorporateDetails
} from "../../../services/corporate/corporate-slice.services";
import CorporateAddPointsModal from "./corporate-add-points-modal.component";
import CorporatePointTransferLogs from "./corporate-points-transfer-logs.component";

const CorporatePointsValidationSchema = Yup.object().shape({
    balance: Yup.number().min(1).required().label("Credit Points"),
});

function CorporatePoints() {
    const location = useLocation();
    const corporateId = new URLSearchParams(location.search).get("corporateId");
    const { getCorporateDetailsObj, addCorporatePointsObj } = useSelector(corporateSelector);
    const dispatch = useDispatch();
    const [showAddPointsModal, setShowAddPointsModal] = useState(false);
    const [showPointLogsModal, setShowPointLogsModal] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const createSnackBar = useContext(SnackbarContext);

    const resetSearch = () => {
        dispatch(getCorporateDetails(corporateId)).then(({ meta, error }) => {
            if (meta.requestStatus === "rejected") {
                createSnackBar({
                    message: error.message,
                    type: "error",
                    open: true,
                });
            }
        });
    };

    const onUpdateCorporatePoints = (values,  { resetForm }) => {
        const params = {
            id: corporateId,
            balance: null,
            reason: values.reason
        };
        dispatch(addCorporatePoints({ corporateId, type: "points", ...values })).then(
            ({ meta, error, payload }) => {
                if (meta.requestStatus === "fulfilled") {
                    setShowAddPointsModal(false);
                    resetForm();
                    resetSearch();
                    createSnackBar({
                        message: payload.message,
                        type: "success",
                        open: true,
                    });
                }
                if (meta.requestStatus === "rejected") {
                    createSnackBar({
                        message: error.message,
                        type: "error",
                        open: true,
                    });
                }
            },
        );
    };

    return (
        <Form
                validationSchema={CorporatePointsValidationSchema}
                onSubmit={onUpdateCorporatePoints}
                initialValues={{
                    id: getCorporateDetailsObj.data.corporate.id,
                    balance: null,
                    reason: 'added by yolo admin'
                }}
            >
                <CorporateAddPointsModal isShowModal={showAddPointsModal} setIsShowModal={setShowAddPointsModal} />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Text variant="h5">Credit Points</Text>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                        <Grid item xs={isMobile ? 10 : 2}>
                                <Box>
                                    <Text variant="h6">Total Balance: </Text>
                                    <Text type="DarkGreyColor" variant="h5">
                                        {getCorporateDetailsObj.data.corporate.points}
                                    </Text>
                                </Box>
                            </Grid>
                            
                        {/* <Text variant="h6">{getCorporateDetailsObj.data.corporate.points}</Text> */}
                        {/* <CorporatePointsField name="balance" /> */}
                        <CtaButton
                            width="145px"
                            // onClickButton={onUpdateCorporatePoints}
                            onClickButton={() => setShowAddPointsModal(true)}
                            isLoading={addCorporatePointsObj.status === "pending"}
                        >
                            <Text type="WhiteColor">Add Balance</Text>
                        </CtaButton>
                        
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <DetailCardContainer>
                        <CorporatePointTransferLogs />
                    </DetailCardContainer>
                </Grid>
            </Grid>
        </Form>
    );
}

export default CorporatePoints;
