import React, { useContext, useState } from "react";
import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import MerchantBankDetailsFormFieldText from "./merchant-details-form-field-text.component";
import Text from "../../../components/text.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import {
  merchantSelector,
  updateMerchant,
} from "../../../services/merchant/merchant-slice.services";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";

const FlexEndButtonContainer = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "flex-end",
});

const MerchantBankDetailsValidationSchema = Yup.object().shape({
  bankAccountNo: Yup.number().required().label("Bank Account Number"),
  bankName: Yup.string().required().label("Bank Name"),
  name: Yup.string().required().min(2).label("Name"),
});

function MerchantBankDetails() {
  const location = useLocation();
  const merchantId = new URLSearchParams(location.search).get("merchantId");
  const [isNameEditing, setIsNameEditing] = useState(false);
  const [isBankNameEditing, setIsBankNameEditing] = useState(false);
  const [isBankAccNoEditing, setIsBankAccNoEditing] = useState(false);
  const { getMerchantDetailsObj, updateMerchantObj } = useSelector(merchantSelector);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const resetState = () => {
    setIsNameEditing(false);
    setIsBankNameEditing(false);
    setIsBankAccNoEditing(false);
  };

  const onUpdateMerchantBankDetails = (values) => {
    dispatch(updateMerchant({ merchantId, type: "bankDetails", ...values })).then(
      ({ meta, error, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          resetState();
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  return (
    <Form
      validationSchema={MerchantBankDetailsValidationSchema}
      onSubmit={onUpdateMerchantBankDetails}
      initialValues={{
        id: getMerchantDetailsObj.data.merchant.id,
        bankAccountNo: getMerchantDetailsObj.data.bankDetails.bankAccountNo,
        bankName: getMerchantDetailsObj.data.bankDetails.bankName,
        name: getMerchantDetailsObj.data.bankDetails.name,
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Text variant="h5">Bank Details</Text>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={isMobile ? 12 : 3}>
              <MerchantBankDetailsFormFieldText
                name="name"
                label="Name"
                isShowIcon
                editing={isNameEditing}
                setEditing={setIsNameEditing}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 3}>
              <MerchantBankDetailsFormFieldText
                name="bankName"
                label="Bank Name"
                isShowIcon
                editing={isBankNameEditing}
                setEditing={setIsBankNameEditing}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 3}>
              <MerchantBankDetailsFormFieldText
                name="bankAccountNo"
                label="Bank Account No."
                isShowIcon
                editing={isBankAccNoEditing}
                setEditing={setIsBankAccNoEditing}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FlexEndButtonContainer sx={{ marginTop: "-30px" }}>
            <FormSubmitButton width="125px" isLoading={updateMerchantObj.status === "pending"}>
              <Text type="WhiteColor">Save</Text>
            </FormSubmitButton>
          </FlexEndButtonContainer>
        </Grid>
      </Grid>
    </Form>
  );
}

export default MerchantBankDetails;
