import React, { useContext, useEffect, useState, useRef } from "react";
import { Box, Grid, Link, styled, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import PaddedView from "../../../components/padded-view.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import RedBorderButton from "../../../components/buttons/red-border-button.component";
import Form from "../../../components/forms/form.component";
import {
  createExperienceImage,
  experienceSelector,
  getExperienceDetail,
  getExperienceTimeslot,
  removeExperienceImage,
  reOrderExperienceImage,
  updateExperience,
  updateExperienceSubcategories,
} from "../../../services/experience/experience-slice.services";
import RejectExperienceModal from "../components/reject-experience-modal.component";
import ExperienceDetailsLoader from "../loader/experience-details-loader.component";
import ExperienceCategoryList from "../components/experience-category-list.component";
import {
  categorySelector,
  getCategoryList,
} from "../../../services/category/category-slice.services";
import ExperiencePriceForm from "../components/experience-price-form.component";
import ExperienceTimeslotModal from "../components/experience-timeslot-modal.component";
import CtaButton from "../../../components/buttons/cta-button.component";
import Loading from "../../../components/notifications/loading.component";
import ExperienceDescription from "../components/experience-description.component";
import ExperienceImportantInfo from "../components/experience-important-info.component";
import ExperienceLocation from "../components/experience-location.component";
import ExperienceShoutOut from "../components/experience-shout-out.component";
import ExperienceNoOfPax from "../components/experience-no-of-pax.component";
import ExperienceAddOnsList from "../components/experience-add-ons-list.component";
import ExperienceImageList from "../components/experience-image-list.component";
import DetailCardContainer from "../../../components/detail-card-container.component";
import ExperienceTitleForm from "../components/experience-title-form.component";
import ExperiencePicContactNo from "../components/experience-pic-contact-no.component";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
});

const RowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const RejectedReasonBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.colors.bg.rejectReasonBg,
  padding: theme.padding.paddingX[2],
}));

const validationSchema = Yup.object().shape({
  status: Yup.string().required(),
  reason: Yup.string().required().label("Reason"),
});

const CategoryValidationSchema = Yup.object().shape({
  categories: Yup.array().of(Yup.object().shape({})).min(1).label("Categories"),
});

const PicContactNoValidationSchema = Yup.object().shape({
  picContactNo: Yup.string()
    .nullable()
    .required()
    .when({
      is: (value) => value?.length,
      then: (rule) => rule.min(10),
    })
    .label("Pic Contact Number"),
});

const DescriptionValidationSchema = Yup.object().shape({
  description: Yup.string().required().label("Description"),
});

const ImportantInfoValidationSchema = Yup.object().shape({
  importantInfo: Yup.string().required().label("Important Info"),
});

const LocationValidationSchema = Yup.object().shape({
  address: Yup.string().required().label("Address"),
});

const PriceValidationSchema = Yup.object().shape({
  price: Yup.number().required().label("Price"),
});

const ShoutOutValidationSchema = Yup.object().shape({
  shoutOut: Yup.string().required().label("Shout-Out"),
});

const NoOfPaxValidationSchema = Yup.object().shape({
  minNoPax: Yup.number().required().min(1).label("Min"),
  maxNoPax: Yup.number()
    .required()
    .min(Yup.ref("minNoPax"), "Max must be greater than Min")
    .label("Max"),
});

const AddOnsValidationSchema = Yup.object().shape({
  addOns: Yup.array().of(Yup.object().shape({})).label("Add-on(s)"),
});

const ImageValidationSchema = Yup.object().shape({
  image: Yup.string().label("Image"),
});

function ExperienceDetailScreen() {
  const location = useLocation();
  const experienceId = new URLSearchParams(location.search).get("experienceId");
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);
  const { getExperienceDetailObj } = useSelector(experienceSelector);
  const theme = useTheme();
  const [showRejectExperienceModal, setShowRejectExperienceModal] = useState(false);
  const { getCategoryListObj } = useSelector(categorySelector);
  const [showTimeslot, setShowTimeslot] = useState(false);
  const [isEditing, setIsEditing] = useState({
    categories: false,
    description: false,
    importantInfo: false,
    address: false,
    price: false,
    shoutOut: false,
    noPax: false,
    title: false,
    picContactNo: false,
  });
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const addOnFormRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getExperienceDetail(experienceId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
    dispatch(getCategoryList()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  const onShowTimeslotModal = () => {
    setShowTimeslot(true);
    dispatch(getExperienceTimeslot(experienceId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const onApproveMerchant = () => {
    setIsLoading(true);
    dispatch(updateExperience({ experienceId, params: "status", status: "publish" })).then(
      ({ meta, error, payload }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  const onRejectExperience = (values, { resetForm }) => {
    setIsLoading(true);
    dispatch(updateExperience({ experienceId, params: "status", ...values })).then(
      ({ meta, error, payload }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          resetForm();
          setShowRejectExperienceModal(false);
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  const onCreateExperienceImage = (values) => {
    setIsLoading(true);
    dispatch(createExperienceImage({ experienceId, ...values })).then(
      ({ meta, error, payload }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  const imageEventHandle = (values) => {
    setIsLoading(true);
    if (values.type === "remove") {
      dispatch(removeExperienceImage({ experienceId, ...values })).then(
        ({ meta, error, payload }) => {
          setIsLoading(false);
          if (meta.requestStatus === "fulfilled") {
            createSnackBar({
              message: payload.message,
              type: "success",
              open: true,
            });
          }
          if (meta.requestStatus === "rejected") {
            createSnackBar({
              message: error.message,
              type: "error",
              open: true,
            });
          }
        },
      );
      return;
    }
    dispatch(reOrderExperienceImage({ experienceId, ...values })).then(
      ({ meta, error, payload }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  const onUpdateExperience = (values) => {
    setIsLoading(true);

    const categoriesId = [];
    const subcategoriesId = [];

    values.categories?.map((item) => categoriesId.push(item.id));

    if (values.params === "categories") {
      values.categories.map((category) =>
        category.subcategories.map((subcategory) => subcategoriesId.push(subcategory.id)),
      );
      dispatch(updateExperienceSubcategories({ subcategoriesId, ...values })).then(
        ({ meta, error }) => {
          if (meta.requestStatus === "rejected") {
            createSnackBar({
              message: error.message,
              type: "error",
              open: true,
            });
          }
        },
      );
    }

    dispatch(updateExperience({ categoriesId, ...values })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        if (values.params === "categories") {
          setIsEditing((i) => ({ ...i, categories: false }));
        } else if (values.params === "description") {
          setIsEditing((i) => ({ ...i, description: false }));
        } else if (values.params === "importantInfo") {
          setIsEditing((i) => ({ ...i, importantInfo: false }));
        } else if (values.params === "location") {
          setIsEditing((i) => ({ ...i, address: false }));
        } else if (values.params === "price") {
          setIsEditing((i) => ({ ...i, price: false }));
        } else if (values.params === "shoutOut") {
          setIsEditing((i) => ({ ...i, shoutOut: false }));
        } else if (values.params === "noPax") {
          setIsEditing((i) => ({ ...i, noPax: false }));
        } else if (values.params === "addOns") {
          addOnFormRef.current.setFieldValue("addOns", payload.data.addOns);
          setIsEditing((i) => ({ ...i, addOns: false }));
        } else if (values.params === "title") {
          setIsEditing((i) => ({ ...i, title: false }));
        } else if (values.params === "picContactNo") {
          setIsEditing((i) => ({ ...i, picContactNo: false }));
        }

        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const renderStatusButton = () => (
    <>
      {getExperienceDetailObj.data.status === "rejected" && (
        <CtaButton width="125px" onClickButton={onApproveMerchant}>
          <Text type="WhiteColor">Approve</Text>
        </CtaButton>
      )}
      {getExperienceDetailObj.data.status === "publish" && (
        <RowBox>
          <RedBorderButton width="125px" onClickButton={() => setShowRejectExperienceModal(true)}>
            <Text type="RejectText">Reject</Text>
          </RedBorderButton>
          <Spacer size="s" position="left" />
          <CtaButton
            width="125px"
            onClickButton={() =>
              window.open(
                `${process.env.REACT_APP_USER}experiences/details?experienceId=${getExperienceDetailObj.data.id}`,
              )
            }
          >
            <Text type="WhiteColor">Preview</Text>
          </CtaButton>
        </RowBox>
      )}
      {(getExperienceDetailObj.data.status === "pending" ||
        getExperienceDetailObj.data.status === "unlisted") && (
        <RowBox>
          <RedBorderButton width="125px" onClickButton={() => setShowRejectExperienceModal(true)}>
            <Text type="RejectText">Reject</Text>
          </RedBorderButton>
          <Spacer size="s" position="left" />
          <CtaButton width="125px" onClickButton={onApproveMerchant}>
            <Text type="WhiteColor">Approve</Text>
          </CtaButton>
        </RowBox>
      )}
    </>
  );

  const renderTitleAndStatus = () => (
    <Grid item xs={12}>
      <Form
        initialValues={{
          experienceId,
          params: "title",
          title: getExperienceDetailObj.data.name,
        }}
        onSubmit={onUpdateExperience}
        validationSchema={CategoryValidationSchema}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SpaceBetweenBox>
              <ExperienceTitleForm setIsEditing={setIsEditing} isEditing={isEditing} />
              {!isMobile && renderStatusButton()}
            </SpaceBetweenBox>
          </Grid>
          {isMobile && (
            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                {renderStatusButton()}
              </Box>
            </Grid>
          )}

          {getExperienceDetailObj.data.reason && getExperienceDetailObj.data.status === "rejected" && (
            <Grid item xs={12}>
              <RejectedReasonBox>
                <Text
                  sx={{
                    fontWeight: theme.fonts.fontWeights.medium,
                    fontSize: theme.fonts.fontSizes.size18,
                  }}
                >
                  Reason for rejection
                </Text>
                <Text sx={{ fontSize: theme.fonts.fontSizes.size14 }}>
                  {getExperienceDetailObj.data.reason}
                </Text>
              </RejectedReasonBox>
            </Grid>
          )}
        </Grid>
      </Form>
    </Grid>
  );

  const renderContent = () => {
    if (
      getExperienceDetailObj.status === "succeeded" &&
      getCategoryListObj.status === "succeeded"
    ) {
      const processedCategory = [];

      getExperienceDetailObj.data.categories.map((item) =>
        processedCategory.push({
          id: item.categoryId,
          label: item.label,
          subcategories: item.subcategories,
        }),
      );

      return (
        <Grid container spacing={5}>
          {renderTitleAndStatus()}
          <Grid item xs={12}>
            <Form
              initialValues={{
                experienceId,
                params: "categories",
                categories: processedCategory,
              }}
              onSubmit={onUpdateExperience}
              validationSchema={CategoryValidationSchema}
            >
              <DetailCardContainer>
                <ExperienceCategoryList setIsEditing={setIsEditing} isEditing={isEditing} />
              </DetailCardContainer>
            </Form>
          </Grid>
          <Grid item xs={12}>
            <Form
              initialValues={{
                experienceId,
                image: "",
              }}
              onSubmit={onCreateExperienceImage}
              validationSchema={ImageValidationSchema}
            >
              <DetailCardContainer>
                <ExperienceImageList name="image" imageEventHandle={imageEventHandle} />
              </DetailCardContainer>
            </Form>
          </Grid>
          <Grid item xs={12}>
            <Form
              initialValues={{
                experienceId,
                params: "picContactNo",
                picContactNo: getExperienceDetailObj.data.picContactNo,
              }}
              onSubmit={onUpdateExperience}
              validationSchema={PicContactNoValidationSchema}
            >
              <DetailCardContainer>
                <ExperiencePicContactNo setIsEditing={setIsEditing} isEditing={isEditing} />
              </DetailCardContainer>
            </Form>
          </Grid>
          <Grid item xs={12}>
            <Form
              initialValues={{
                experienceId,
                params: "description",
                description: getExperienceDetailObj.data.description,
              }}
              onSubmit={onUpdateExperience}
              validationSchema={DescriptionValidationSchema}
            >
              <DetailCardContainer>
                <ExperienceDescription setIsEditing={setIsEditing} isEditing={isEditing} />
              </DetailCardContainer>
            </Form>
          </Grid>
          <Grid item xs={12}>
            <Form
              initialValues={{
                experienceId,
                params: "importantInfo",
                importantInfo: getExperienceDetailObj.data.importantInfo,
              }}
              onSubmit={onUpdateExperience}
              validationSchema={ImportantInfoValidationSchema}
            >
              <DetailCardContainer>
                <ExperienceImportantInfo setIsEditing={setIsEditing} isEditing={isEditing} />
              </DetailCardContainer>
            </Form>
          </Grid>
          <Grid item xs={12}>
            <Form
              initialValues={{
                experienceId,
                params: "location",
                address: getExperienceDetailObj.data.location.address,
                postCode: getExperienceDetailObj.data.location.postCode,
                city: getExperienceDetailObj.data.location.city,
                state: getExperienceDetailObj.data.location.state,
                country: getExperienceDetailObj.data.location.country,
                locationLat: getExperienceDetailObj.data.location.lat,
                locationLong: getExperienceDetailObj.data.location.long,
              }}
              onSubmit={onUpdateExperience}
              validationSchema={LocationValidationSchema}
            >
              <DetailCardContainer>
                <ExperienceLocation setIsEditing={setIsEditing} isEditing={isEditing} />
              </DetailCardContainer>
            </Form>
          </Grid>
          <Grid item xs={12}>
            <Form
              initialValues={{
                experienceId,
                price: getExperienceDetailObj.data.price.replace(/,/g, ""),
                params: "price",
              }}
              onSubmit={onUpdateExperience}
              validationSchema={PriceValidationSchema}
            >
              <DetailCardContainer>
                <ExperiencePriceForm
                  name="price"
                  onShowTimeslotModal={onShowTimeslotModal}
                  setIsEditing={setIsEditing}
                  isEditing={isEditing}
                />
              </DetailCardContainer>
            </Form>
          </Grid>
          <Grid item xs={12}>
            <Form
              initialValues={{
                experienceId,
                params: "shoutOut",
                shoutOut: getExperienceDetailObj.data.shoutOut,
              }}
              onSubmit={onUpdateExperience}
              validationSchema={ShoutOutValidationSchema}
            >
              <DetailCardContainer>
                <ExperienceShoutOut setIsEditing={setIsEditing} isEditing={isEditing} />
              </DetailCardContainer>
            </Form>
          </Grid>
          <Grid item xs={12}>
            <Form
              initialValues={{
                experienceId,
                minNoPax: getExperienceDetailObj.data.minNoPax,
                maxNoPax: getExperienceDetailObj.data.maxNoPax,
                params: "noPax",
              }}
              onSubmit={onUpdateExperience}
              validationSchema={NoOfPaxValidationSchema}
            >
              <DetailCardContainer>
                <ExperienceNoOfPax setIsEditing={setIsEditing} isEditing={isEditing} />
              </DetailCardContainer>
            </Form>
          </Grid>
          <Grid item xs={12}>
            <Form
              initialValues={{
                experienceId,
                addOns: getExperienceDetailObj.data.addOns,
                params: "addOns",
              }}
              onSubmit={onUpdateExperience}
              validationSchema={AddOnsValidationSchema}
              innerRef={addOnFormRef}
            >
              <DetailCardContainer>
                <ExperienceAddOnsList setIsEditing={setIsEditing} isEditing={isEditing} />
              </DetailCardContainer>
            </Form>
          </Grid>
        </Grid>
      );
    }
    return <ExperienceDetailsLoader />;
  };

  return (
    <PaddedView multiples={isMobile ? 1 : 3}>
      <Loading isLoading={isLoading} />
      <ExperienceTimeslotModal showModal={showTimeslot} setShowModal={setShowTimeslot} />
      <Form
        initialValues={{ status: "rejected", reason: "" }}
        onSubmit={onRejectExperience}
        validationSchema={validationSchema}
      >
        <RejectExperienceModal
          showModal={showRejectExperienceModal}
          setShowModal={setShowRejectExperienceModal}
        />
      </Form>
      <Link href={routes.EXPERIENCELIST} style={{ textDecoration: "none" }}>
        <Text type="GreyColor">{`< Back`}</Text>
      </Link>
      <Spacer size="xl" position="top" />
      {renderContent()}
    </PaddedView>
  );
}

export default ExperienceDetailScreen;
