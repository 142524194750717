import React from "react";
import { Box, IconButton, styled } from "@mui/material";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import Text from "../../../../components/text.component";
import Spacer from "../../../../components/spacer.component";
import DeleteIcon from "../../../../assets/images/delete-icon";
import EditIcon from "../../../../assets/images/edit-icon";

const CardContainer = styled(Box)(({ theme }) => ({
  padding: "0px",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  textTransform: "none",
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  backgroundColor: theme.palette.colors.brand.white,
}));

const PaddingBox = styled(Box)({
  paddingLeft: "15px",
  paddingRight: "15px",
  paddingTop: "10px",
  paddingBottom: "10px",
});

export default function FestivalCategoryCard({ item, onDeleteFestivalCategory, setShowModal }) {
  const { setFieldValue } = useFormikContext();

  const onEditClick = () => {
    setFieldValue("label", item.label);
    setFieldValue("imagePath", item.imagePath);
    setFieldValue("queryType", item.queryType);
    setFieldValue("festivalCategoryId", item.id);
    setFieldValue("postType", "edit");
    if (item.queryType === "url") {
      setFieldValue("urlObj", item.queryValue[0].replace("https://", ""));
    }
    if (item.queryType === "tag") {
      setFieldValue("tagObj", item.queryValue);
    }
    if (item.queryType === "category") {
      setFieldValue("categoryObj", item.queryValue[0]);
    }
    setShowModal(true);
  };
  return (
    <CardContainer sx={{ boxShadow: 3 }}>
      <img src={item.imagePath} alt="categoryImg" width="100%" />
      <PaddingBox>
        <Text
          sx={{
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
            textAlign: "start",
          }}
        >
          {item.label}
        </Text>
        <Spacer size="m" position="top" />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",

            width: "100%",
          }}
        >
          <Text sx={{ textAlign: "start", textTransform: "capitalize" }} type="GreyColor">
            {item.queryType === "url" ? item.queryType.toUpperCase() : item.queryType}
          </Text>
          <Box>
            <IconButton onClick={onEditClick}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => onDeleteFestivalCategory(item.id)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
      </PaddingBox>
    </CardContainer>
  );
}

FestivalCategoryCard.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  onDeleteFestivalCategory: PropTypes.func.isRequired,
  item: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
    imagePath: PropTypes.string,
    queryType: PropTypes.string,
    queryValue: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};
