import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Pagination,
  styled,
  Table,
  TableBody,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { format } from "date-fns";
import PaddedView from "../../../components/padded-view.component";
import Spacer from "../../../components/spacer.component";
import TableTitle from "../../../components/table/table-title.component";
import {
  getPromoCodeList,
  promoCodeSelector,
} from "../../../services/promo-code/promo-code-slice.services";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import Form from "../../../components/forms/form.component";
import DetailCardContainer from "../../../components/detail-card-container.component";
import FormDatePicker from "../../../components/forms/form-date-picker.component";
import SearchBar from "../components/search-bar.component";
import BorderButton from "../../../components/buttons/border-button.component";
import Text from "../../../components/text.component";
import CtaButton from "../../../components/buttons/cta-button.component";
import TableHeader from "../../../components/table/table-header.component";
import TableWrapper from "../../../components/table/table-wrapper.component";
import TableLoader from "../loader/table-loader.component";
import PromoCodeRow from "../components/promo-code-row.component";
import routes from "../../../navigation/routes";

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const validationSchema = Yup.object().shape({
  startDate: Yup.date().nullable().label("Start Date"),
  endDate: Yup.date()
    .nullable()
    .label("End Date")
    .min(Yup.ref("startDate"), "End date can't earlier than start date")
    .nullable(),
  q: Yup.string().label("Code/ Title"),
});

export default function PromoCodeListScreen() {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const createSnackBar = useContext(SnackbarContext);
  const formRef = useRef();
  const [page, setPage] = useState(1);
  const { getPromoCodeListObj } = useSelector(promoCodeSelector);

  useEffect(() => {
    dispatch(getPromoCodeList({ q: "", startAt: "", endAt: "", page: 1 })).then(
      ({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      },
    );
  }, []);

  const onSearchPromoCode = (values) => {
    setPage(1);
    let startAt = "";
    let endAt = "";
    if (values.startDate !== null) {
      startAt = format(new Date(values.startDate), "yyyy-MM-dd 00:00:00");
    }
    if (values.endDate !== null) {
      endAt = format(new Date(values.endDate), "yyyy-MM-dd 23:59:59");
    }
    dispatch(
      getPromoCodeList({
        ...values,
        startAt,
        endAt,
        page: 1,
      }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onPageChange = (e, newPage) => {
    setPage(newPage);
    let startAt = "";
    let endAt = "";
    if (formRef.current.values.startDate !== null) {
      startAt = format(new Date(formRef.current.values.startDate), "yyyy-MM-dd 00:00:00");
    }
    if (formRef.current.values.endDate !== null) {
      endAt = format(new Date(formRef.current.values.endDate), "yyyy-MM-dd 23:59:59");
    }
    dispatch(
      getPromoCodeList({
        ...formRef.current.values,
        startAt,
        endAt,
        page: newPage,
      }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const renderContent = () => {
    if (getPromoCodeListObj.status === "succeeded") {
      return (
        <>
          <Table>
            <TableHeader
              headerCells={[
                "ID",
                "Title",
                "Code",
                "Discount",
                "Start At",
                "End At",
                "Redeemed Count",
                "Action",
              ]}
            />
            <TableBody>
              {getPromoCodeListObj.data.items.map((item) => (
                <PromoCodeRow record={item} key={item.id} />
              ))}
            </TableBody>
          </Table>
          <CustomFooter>
            <Pagination
              sx={{
                "&& .Mui-selected": {
                  backgroundColor: theme.palette.colors.brand.primary,
                  color: theme.palette.colors.text.white,
                },
              }}
              page={page}
              onChange={onPageChange}
              count={getPromoCodeListObj.data.pagination.totalPages}
              variant="outlined"
            />
          </CustomFooter>
        </>
      );
    }
    return <TableLoader />;
  };

  return (
    <PaddedView multiples={3}>
      <Spacer position="top" size="m" />
      <TableTitle
        title="Promo Codes"
        onClickCreateButton={() => history.push(routes.PROMO_CODE_CREATE)}
      />
      <Spacer position="top" size="xl" />
      <Form
        innerRef={formRef}
        onSubmit={onSearchPromoCode}
        validationSchema={validationSchema}
        initialValues={{
          startDate: null,
          endDate: null,
          q: "",
        }}
      >
        <DetailCardContainer>
          <Grid container spacing={2}>
            <Grid item sx={{ display: "flex", flex: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={isMobile ? 12 : 6}>
                  <FormDatePicker
                    name="startDate"
                    disablePast={false}
                    width="100%"
                    placeholder="Start Date"
                  />
                </Grid>
                <Grid item xs={isMobile ? 12 : 6}>
                  <FormDatePicker
                    name="endDate"
                    disablePast={false}
                    width="100%"
                    placeholder="End Date"
                  />
                </Grid>
                <Grid item xs={12}>
                  <SearchBar width="100%" name="q" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item width={useMediaQuery(theme.breakpoints.down("990")) ? "100%" : "150px"}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <BorderButton
                    height={isMobile ? "40px" : "56px"}
                    onClickButton={() => {
                      formRef.current.resetForm();
                      setTimeout(() => {
                        formRef.current.handleSubmit();
                      }, 50);
                    }}
                  >
                    <Text type="BrandColor">Clear</Text>
                  </BorderButton>
                </Grid>
                <Grid item xs={12}>
                  <CtaButton
                    height={isMobile ? "40px" : "56px"}
                    onClickButton={() => formRef.current.handleSubmit()}
                  >
                    <Text type="WhiteColor">Search</Text>
                  </CtaButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DetailCardContainer>
      </Form>
      <Spacer position="top" size="l" />
      <TableWrapper>{renderContent()}</TableWrapper>
    </PaddedView>
  );
}
