import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import PaddedView from "../../../../components/padded-view.component";
import Spacer from "../../../../components/spacer.component";
import Text from "../../../../components/text.component";
import { SnackbarContext } from "../../../../components/notifications/snackbar.context";
import Loading from "../../../../components/notifications/loading.component";
import Form from "../../../../components/forms/form.component";
import FormFieldTextWithCounter from "../../../../components/forms/form-field-text-with-counter.component";
import {
  addExploreExperience,
  exploreSectionSelector,
  getExploreExperienceList,
  getExploreSectionList,
  removeExploreExperience,
  updateExploreSectionLabel,
} from "../../../../services/marketing/explore-section/explore-section-slice.services";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import ExploreSectionLoader from "../loader/explore-section-loader.component";
import ExploreSectionExperienceList from "../components/explore-section-experience-list.component";
import DetailCardContainer from "../../../../components/detail-card-container.component";

const validationSchema = Yup.object().shape({
  label: Yup.string().required().max(50).label("Title"),
});

export default function ExploreSectionScreen() {
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);
  const { getExploreSectionListObj, updateExploreSectionLabelObj, getExploreExperienceListObj } =
    useSelector(exploreSectionSelector);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    dispatch(getExploreSectionList()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
    dispatch(getExploreExperienceList()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  }, []);

  const onUpdateLabel = (values) => {
    setIsLoading(true);
    dispatch(updateExploreSectionLabel(values)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
    });
  };

  const onRemoveExploreExperience = (experienceId) => {
    setIsLoading(true);
    dispatch(removeExploreExperience(experienceId)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
    });
  };

  const onAddExploreExperience = (experienceId) => {
    dispatch(addExploreExperience({ experienceId })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
    });
  };

  const renderContent = () => {
    if (
      getExploreSectionListObj.status === "succeeded" &&
      getExploreExperienceListObj.status === "succeeded"
    ) {
      return (
        <Form
          onSubmit={onUpdateLabel}
          validationSchema={validationSchema}
          initialValues={{ label: getExploreSectionListObj.data.label }}
        >
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <DetailCardContainer>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Text>Main Title</Text>
                    <Spacer position="top" size="xs" />
                    <FormFieldTextWithCounter
                      name="label"
                      placeholder="Enter title here..."
                      maxLength={50}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <FormSubmitButton
                        width="125px"
                        isLoading={updateExploreSectionLabelObj.status === "pending"}
                      >
                        <Text type="WhiteColor">Save</Text>
                      </FormSubmitButton>
                    </Box>
                  </Grid>
                </Grid>
              </DetailCardContainer>
            </Grid>

            <Grid item xs={12}>
              <Text>Featured Experience</Text>
              <Spacer position="top" size="xs" />
              <ExploreSectionExperienceList
                onRemoveExploreExperience={onRemoveExploreExperience}
                onAddExploreExperience={onAddExploreExperience}
              />
            </Grid>
          </Grid>
        </Form>
      );
    }
    return <ExploreSectionLoader />;
  };

  return (
    <PaddedView multiples={isMobile ? 1 : 3}>
      <Loading isLoading={isLoading} />
      <Spacer position="top" size="m" />
      <Text variant="h3">Explore Section</Text>
      <Spacer position="top" size="l" />

      {renderContent()}
    </PaddedView>
  );
}
