import CloseIcon from "@mui/icons-material/Close";
import {
    Box,
    Button,
    Grid,
    IconButton,
    Modal,
    styled,
    TextField,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";
import Loader from "react-js-loader";
import { useSelector } from "react-redux";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import { corporateSelector } from "../../../services/corporate/corporate-slice.services";

const ButtonContainer = styled(Box)(({ theme }) => ({
    "& .MuiButton-root": {
        width: "100%",
        height: "50px",
        borderTopLeftRadius: theme.shape.borderRadius[0],
        borderTopRightRadius: theme.shape.borderRadius[0],
        borderBottomLeftRadius: theme.shape.borderRadius[0],
        borderBottomRightRadius: theme.shape.borderRadius[0],
        textTransform: "none",
        backgroundColor: theme.palette.colors.button.rejectButton,
        border: `1px solid ${theme.palette.colors.button.rejectButton}`,
        "&:hover": {
            backgroundColor: theme.palette.colors.button.rejectButton,
        },
        "&:disabled": {
            backgroundColor: "transparent",
        },
    },
}));

const LoadingBox = styled(Box)({
    height: "25px",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
});

const ModalBox = styled(Box)(({ theme, isMobile }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: theme.palette.colors.brand.white,
    borderRadius: theme.shape.borderRadius[0],
    boxShadow: 24,
    padding: isMobile ? theme.padding.paddingX[1] : theme.padding.paddingX[0],
    width: isMobile ? "350px" : "500px",
    outline: "none",
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.colors.brand.secondary,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    width: "100%",
    "& .MuiOutlinedInput-root": {
        height: "200px",
        borderTopLeftRadius: theme.shape.borderRadius[0],
        borderTopRightRadius: theme.shape.borderRadius[0],
        borderBottomLeftRadius: theme.shape.borderRadius[0],
        borderBottomRightRadius: theme.shape.borderRadius[0],
        backgroundColor: theme.palette.colors.text.inputField,
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
}));

export default function RejectCorporateModal({ showModal, setShowModal }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { values, setFieldValue, errors, touched, setFieldTouched, submitForm, resetForm } =
        useFormikContext();
    const showError = touched.reason && typeof errors.reason === "string";
    const { updateCorporateObj } = useSelector(corporateSelector);

    const keyPress = (e) => {
        if (e.keyCode === 13) {
            submitForm();
        }
    };

    const handleCancel = () => {
        resetForm();
        setShowModal(false);
    };

    return (
        <Modal open={showModal} onClose={handleCancel}>
            <ModalBox isMobile={isMobile}>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Box sx={{ width: "24px" }} />
                            <Text sx={{ textAlign: "center", fontSize: theme.fonts.fontSizes.size20 }}>
                                Notification to Corporate
                            </Text>
                            <CloseIconButton onClick={handleCancel}>
                                <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
                            </CloseIconButton>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Spacer size="xl" position="top" />
                        <Text>Please state reason for rejecting</Text>
                    </Grid>
                    <Grid item xs={12}>
                        <Spacer size="m" position="top" />
                        <StyledTextField
                            onKeyDown={keyPress}
                            onBlur={() => setFieldTouched("reason")}
                            error={showError}
                            variant="outlined"
                            helperText={showError ? errors.reason : null}
                            onChange={(e) => {
                                setFieldValue("reason", e.target.value);
                            }}
                            value={values.reason}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Spacer size="xl" position="top" />
                        <ButtonContainer>
                            {updateCorporateObj.status === "pending" ? (
                                <Button variant="contained" disabled>
                                    <LoadingBox>
                                        <Loader
                                            type="bubble-loop"
                                            bgColor={theme.palette.colors.button.rejectButton}
                                            size={30}
                                        />
                                    </LoadingBox>
                                </Button>
                            ) : (
                                <Button variant="contained" onClick={submitForm}>
                                    <Text type="WhiteColor">Confirm</Text>
                                </Button>
                            )}
                        </ButtonContainer>
                    </Grid>
                </Grid>
            </ModalBox>
        </Modal>
    );
}

RejectCorporateModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
};
