import { Box, Grid, IconButton, styled, useMediaQuery, useTheme } from "@mui/material";
import { format } from "date-fns";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useSelector } from "react-redux";
import CenteredRowBox from "../../../components/centered-row-box.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import { capitalize } from "../../../infrastructure/utils/capitalize";
import { experienceSelector } from "../../../services/experience/experience-slice.services";
import SlotCardContainer from "./slot-card-container.component";

const EmptyItemsBox = styled(Box)({
  height: "100px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const EditIconButton = styled(IconButton)(({ theme }) => ({
  padding: "0px",
  color: theme.palette.colors.text.primary,
}));

export default function SlotList({ setShowTimeSlotModal, setShowDeleteModal }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { getExperienceTimeslotObj } = useSelector(experienceSelector);
  const { setFieldValue } = useFormikContext();
  const dataArr = [];
  Object.keys(getExperienceTimeslotObj.data.bookingTimeSlots).map((item) => dataArr.push(item));

  const onEditHandle = (slot) => {
    setFieldValue("formType", "edit");
    setFieldValue("bookingType", slot.bookingType);
    setFieldValue("flexiTitle", "");
    setFieldValue("capacity", slot.massSlot.capacity.total);
    setFieldValue("massSlotId", slot.massSlot.id);
    setFieldValue("startDate", new Date(slot.startAt));
    setFieldValue("startTime", new Date(slot.startAt));
    setFieldValue("endDate", new Date(slot.endAt));
    setFieldValue("endTime", new Date(slot.endAt));
    setFieldValue("slotId", "");
    setShowTimeSlotModal(true);
  };

  const onDeletePress = (slot) => {
    setFieldValue("formType", "delete");
    setFieldValue("flexiTitle", "");
    setFieldValue("capacity", slot.massSlot.capacity.total);
    setFieldValue("massSlotId", slot.massSlot.id);
    setFieldValue("startDate", new Date(slot.startAt));
    setFieldValue("startTime", new Date(slot.startAt));
    setFieldValue("endDate", new Date(slot.endAt));
    setFieldValue("endTime", new Date(slot.endAt));
    setFieldValue("bookingType", slot.bookingType);
    setFieldValue("slotId", slot.id);
    setShowDeleteModal(true);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Text
          sx={{
            fontSize: theme.fonts.fontSizes.size18,
            fontWeight: theme.fonts.fontWeights.medium,
          }}
        >
          Standard Timeslot
        </Text>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {dataArr.length > 0 ? (
            dataArr.map((item) => (
              <Grid item xs={12} key={item}>
                <CenteredRowBox>
                  <Text>Date: </Text>
                  <Spacer size="s" position="left" />
                  <Text sx={{ fontWeight: theme.fonts.fontWeights.medium }}>{item}</Text>
                </CenteredRowBox>
                <Spacer size="s" position="top" />
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    {getExperienceTimeslotObj.data.bookingTimeSlots[item].map((timeSlot) => (
                      <Grid item xs={isMobile ? 12 : null} key={timeSlot.id}>
                        <SlotCardContainer>
                          <CenteredRowBox position="absolute" top="10px" right="10px">
                            <EditIconButton onClick={() => onEditHandle(timeSlot)}>
                              <ModeEditIcon />
                            </EditIconButton>
                            <Spacer position="left" size="xs" />
                            <EditIconButton
                              disabled={!timeSlot.deletable}
                              onClick={() => onDeletePress(timeSlot)}
                            >
                              <DeleteOutlineIcon />
                            </EditIconButton>
                          </CenteredRowBox>
                          <Text sx={{ fontSize: theme.fonts.fontSizes.size14 }}>
                            Start at: {format(new Date(timeSlot.startAt), "dd MMM, hh:mmaaaaa'm")}
                          </Text>
                          <Text sx={{ fontSize: theme.fonts.fontSizes.size14 }}>
                            End at: {format(new Date(timeSlot.endAt), "dd MMM, hh:mmaaaaa'm")}
                          </Text>
                          <Text sx={{ fontSize: theme.fonts.fontSizes.size14 }}>
                            Type: {capitalize(timeSlot.bookingType.toString())}
                          </Text>
                          <Text sx={{ fontSize: theme.fonts.fontSizes.size14 }}>
                            Capacity: {timeSlot.massSlot.capacity.total}
                          </Text>
                          {timeSlot.bookingType === "multiple" && (
                            <Text sx={{ fontSize: theme.fonts.fontSizes.size14 }}>
                              Occupied: {timeSlot.massSlot.capacity.occupied}
                            </Text>
                          )}
                        </SlotCardContainer>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <EmptyItemsBox>
                <Text>No Items</Text>
              </EmptyItemsBox>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

SlotList.propTypes = {
  setShowTimeSlotModal: PropTypes.func.isRequired,
  setShowDeleteModal: PropTypes.func.isRequired,
};
