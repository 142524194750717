import { Box, Grid, Link, styled, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import CtaButton from "../../../components/buttons/cta-button.component";
import RedBorderButton from "../../../components/buttons/red-border-button.component";
import DetailCardContainer from "../../../components/detail-card-container.component";
import Form from "../../../components/forms/form.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import PaddedView from "../../../components/padded-view.component";
import SkeletonLoader from "../../../components/skeleton.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import {
    corporateSelector,
    createCorporateSocial,
    createCorporateUniqueLinks,
    getCorporateDetails,
    updateCorporate
} from "../../../services/corporate/corporate-slice.services";
import CorporateBankDetails from "../components/corporate-bank-details.component";
import CorporateCommission from "../components/corporate-commission.component";
import CorporateDetails from "../components/corporate-details.component";
import CorporateStatements from "../components/corporate-statements.component";
import RejectCorporateModal from "../components/reject-corporate-modal.component";
import CorporateBankDetailsLoader from "../loaders/corporate-bank-details-loader.component";
import CorporateCommissionLoader from "../loaders/corporate-commission-loader.component";
import CorporateDetailsLoader from "../loaders/corporate-details-loader.component";

const SpaceBetweenBox = styled(Box)({
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
});

const RowBox = styled(Box)({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
});

const RejectedReasonBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.colors.bg.rejectReasonBg,
    padding: theme.padding.paddingX[2],
}));

const validationSchema = Yup.object().shape({
    statusType: Yup.string().required(),
    reason: Yup.string().required().label("Reason"),
});

const linksValidationSchema = Yup.object().shape({
    experienceID: Yup.string().required(),
});

const socialValidationSchema = Yup.object().shape({
    social_id: Yup.string().required(),
    social_type: Yup.string().required(),
});

function CorporateStaffDetailScreen() {
    const location = useLocation();
    const corporateId = new URLSearchParams(location.search).get("corporateId");
    const dispatch = useDispatch();
    const createSnackBar = useContext(SnackbarContext);
    const { getCorporateDetailsObj, updateCorporateObj } = useSelector(corporateSelector);
    const theme = useTheme();
    const [showRejectCorporateModal, setShowRejectCorporateModal] = useState(false);
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showSocialCreateModal, setShowSocialCreateModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

    useEffect(() => {
        window.scrollTo(0, 0);
        console.log("corporate id", corporateId);
        dispatch(getCorporateDetails(corporateId)).then(({ meta, error }) => {
            if (meta.requestStatus === "rejected") {
                createSnackBar({
                    message: error.message,
                    type: "error",
                    open: true,
                });
            }
        });

    }, []);

    // dispatch(getCorporateExperienceList({ corporateId, page: 1 })).then(({ meta, error }) => {
    //     if (meta.requestStatus === "rejected") {
    //         createSnackBar({
    //             message: error.message,
    //             type: "error",
    //             open: true,
    //         });
    //     }
    // });

    const onApproveCorporate = () => {
        dispatch(updateCorporate({ corporateId, type: "status", statusType: "approved" })).then(
            ({ meta, error, payload }) => {
                if (meta.requestStatus === "fulfilled") {
                    createSnackBar({
                        message: payload.message,
                        type: "success",
                        open: true,
                    });
                }
                if (meta.requestStatus === "rejected") {
                    createSnackBar({
                        message: error.message,
                        type: "error",
                        open: true,
                    });
                }
            },
        );
    };

    const onRejectCorporate = (values, { resetForm }) => {
        dispatch(updateCorporate({ corporateId, type: "status", ...values })).then(
            ({ meta, error, payload }) => {
                if (meta.requestStatus === "fulfilled") {
                    resetForm();
                    setShowRejectCorporateModal(false);
                    createSnackBar({
                        message: payload.message,
                        type: "success",
                        open: true,
                    });
                }
                if (meta.requestStatus === "rejected") {
                    createSnackBar({
                        message: error.message,
                        type: "error",
                        open: true,
                    });
                }
            },
        );
    };

    const renderStatusButton = () => (
        <>
            {getCorporateDetailsObj.data.corporate.status === "rejected" && (
                <CtaButton
                    width="125px"
                    onClickButton={onApproveCorporate}
                    isLoading={updateCorporateObj.status === "pending"}
                >
                    <Text type="WhiteColor">Approve</Text>
                </CtaButton>
            )}
            {getCorporateDetailsObj.data.corporate.status === "approved" && (
                <RedBorderButton
                    width="125px"
                    isLoading={updateCorporateObj.status === "pending"}
                    onClickButton={() => setShowRejectCorporateModal(true)}
                >
                    <Text type="RejectText">Reject</Text>
                </RedBorderButton>
            )}
            {getCorporateDetailsObj.data.corporate.status === "pending" && (
                <RowBox>
                    <RedBorderButton
                        width="125px"
                        isLoading={updateCorporateObj.status === "pending"}
                        onClickButton={() => setShowRejectCorporateModal(true)}
                    >
                        <Text type="RejectText">Reject</Text>
                    </RedBorderButton>
                    <Spacer size="s" position="left" />
                    <CtaButton
                        width="125px"
                        onClickButton={onApproveCorporate}
                        isLoading={updateCorporateObj.status === "pending"}
                    >
                        <Text type="WhiteColor">Approve</Text>
                    </CtaButton>
                </RowBox>
            )}
        </>
    );

    const renderTitleAndStatus = () => {
        if (getCorporateDetailsObj.status === "succeeded") {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <SpaceBetweenBox>
                            <Box>
                                <Text variant="h4">{getCorporateDetailsObj.data.corporate.name}</Text>
                                {getCorporateDetailsObj.data.corporate.status === "rejected" ? (
                                    <Text type="RejectText">
                                        {capitalize(getCorporateDetailsObj.data.corporate.status)}
                                    </Text>
                                ) : (
                                    <Text type="GreyColor">
                                        {capitalize(getCorporateDetailsObj.data.corporate.status)}
                                    </Text>
                                )}
                            </Box>
                            {!isMobile && renderStatusButton()}
                        </SpaceBetweenBox>
                    </Grid>
                    {isMobile && (
                        <Grid item xs={12}>
                            <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                                {renderStatusButton()}
                            </Box>
                        </Grid>
                    )}
                    {getCorporateDetailsObj.data.corporate.reason &&
                        getCorporateDetailsObj.data.corporate.status === "rejected" && (
                            <Grid item xs={12}>
                                <RejectedReasonBox>
                                    <Text
                                        sx={{
                                            fontWeight: theme.fonts.fontWeights.medium,
                                            fontSize: theme.fonts.fontSizes.size18,
                                        }}
                                    >
                                        Reason for rejection
                                    </Text>
                                    <Text sx={{ fontSize: theme.fonts.fontSizes.size14 }}>
                                        {getCorporateDetailsObj.data.corporate.reason}
                                    </Text>
                                </RejectedReasonBox>
                            </Grid>
                        )}
                </Grid>
            );
        }
        return (
            <>
                <SpaceBetweenBox>
                    <Box>
                        <SkeletonLoader height="40px" width="200px" />
                        <SkeletonLoader height="20px" width="125px" />
                    </Box>
                    {!isMobile && (
                        <RowBox>
                            <RedBorderButton width="125px" isLoading={true}>
                                <Text type="RejectText">Reject</Text>
                            </RedBorderButton>
                            <Spacer size="s" position="left" />
                            <CtaButton width="125px" isLoading={true}>
                                <Text type="WhiteColor">Approve</Text>
                            </CtaButton>
                        </RowBox>
                    )}
                </SpaceBetweenBox>
                {isMobile && (
                    <RowBox sx={{ marginTop: "25px" }}>
                        <RedBorderButton width="125px" isLoading={true}>
                            <Text type="RejectText">Reject</Text>
                        </RedBorderButton>
                        <Spacer size="s" position="left" />
                        <CtaButton width="125px" isLoading={true}>
                            <Text type="WhiteColor">Approve</Text>
                        </CtaButton>
                    </RowBox>
                )}
            </>
        );
    };

    const renderCorporateDetails = () => {
        if (getCorporateDetailsObj.status === "succeeded") {
            return <CorporateDetails />;
        }
        return <CorporateDetailsLoader />;
    };

    const renderCorporateBankDetails = () => {
        if (getCorporateDetailsObj.status === "succeeded") {
            return <CorporateBankDetails />;
        }
        return <CorporateBankDetailsLoader />;
    };

    const renderCorporateCommission = () => {
        if (getCorporateDetailsObj.status === "succeeded") {
            return <CorporateCommission />;
        }
        return <CorporateCommissionLoader />;
    };

    const onUniqueLinkCreate = (value, { resetForm }) => {
        const params = {
            experienceID: value.experienceID,
        };
        setIsLoading(true);
        dispatch(createCorporateUniqueLinks({ ...params })).then(({ meta, error, payload }) => {
            setIsLoading(false);
            if (meta.requestStatus === "fulfilled") {
                setShowCreateModal(false);
                resetForm();
                createSnackBar({
                    message: payload.message,
                    type: "success",
                });
            }
            if (meta.requestStatus === "rejected") {
                createSnackBar({
                    message: error.message,
                    type: "error",
                });
            }
        });
    };

    const onSocialMediaCreate = (value, { resetForm }) => {
        const params = {
            social_type: value.social_type,
            social_id: value.social_id
        };
        setIsLoading(true);
        dispatch(createCorporateSocial({ ...params })).then(({ meta, error, payload }) => {
            setIsLoading(false);
            if (meta.requestStatus === "fulfilled") {
                setShowCreateModal(false);
                resetForm();
                createSnackBar({
                    message: payload.message,
                    type: "success",
                });
            }
            if (meta.requestStatus === "rejected") {
                createSnackBar({
                    message: error.message,
                    type: "error",
                });
            }
        });
    };

    // const renderCorporateExperiences = () => {
    //     if (getCorporateDetailsObj.status === "succeeded") {
    //         return <CorporateExperiences corporateId={corporateId} />;
    //     }
    //     return <CorporateExperiencesLoader />;
    // };

    const renderAllStatementDetails = () => {
        if (getCorporateDetailsObj.status === "succeeded") {
            return (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Text variant="h5">Statement Details</Text>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={isMobile ? 12 : 3}>
                                <Box>
                                    <Text variant="h6">Open: </Text>
                                    <Text type="DarkGreyColor" variant="h5">
                                        RM {getCorporateDetailsObj.data.statementDetails.open}
                                    </Text>
                                </Box>
                            </Grid>
                            <Grid item xs={isMobile ? 12 : 3}>
                                <Box>
                                    <Text variant="h6">To Be Paid: </Text>
                                    <Text type="DarkGreyColor" variant="h5">
                                        RM {getCorporateDetailsObj.data.statementDetails.pending}
                                    </Text>
                                </Box>
                            </Grid>
                            <Grid item xs={isMobile ? 12 : 3}>
                                <Box>
                                    <Text variant="h6">Dispute: </Text>
                                    <Text type="DarkGreyColor" variant="h5">
                                        RM {getCorporateDetailsObj.data.statementDetails.dispute}
                                    </Text>
                                </Box>
                            </Grid>
                            <Grid item xs={isMobile ? 12 : 3}>
                                <Box>
                                    <Text variant="h6">Closed: </Text>
                                    <Text type="DarkGreyColor" variant="h5">
                                        RM {getCorporateDetailsObj.data.statementDetails.closed}
                                    </Text>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            );
        }

        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Text variant="h5">Statement Details</Text>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={isMobile ? 12 : 3}>
                            <Box>
                                <Skeleton width="100px" height="30px" />
                                <Skeleton width="150px" height="40px" />
                            </Box>
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 3}>
                            <Box>
                                <Skeleton width="100px" height="30px" />
                                <Skeleton width="150px" height="40px" />{" "}
                            </Box>
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 3}>
                            <Box>
                                <Skeleton width="100px" height="30px" />
                                <Skeleton width="150px" height="40px" />{" "}
                            </Box>
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 3}>
                            <Box>
                                <Skeleton width="100px" height="30px" />
                                <Skeleton width="150px" height="40px" />{" "}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    return (
        <PaddedView multiples={isMobile ? 1 : 3}>
            <Form
                initialValues={{ statusType: "rejected", reason: "" }}
                onSubmit={onRejectCorporate}
                validationSchema={validationSchema}
            >
                <RejectCorporateModal
                    showModal={showRejectCorporateModal}
                    setShowModal={setShowRejectCorporateModal}
                />
            </Form>
            <Link href={routes.CORPORATELIST} style={{ textDecoration: "none" }}>
                <Text type="GreyColor">{`< Back`}</Text>
            </Link>
            <Spacer size="xl" position="top" />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {renderTitleAndStatus()}
                </Grid>
                <Grid item xs={12}>
                    <DetailCardContainer>{renderCorporateDetails()}</DetailCardContainer>
                </Grid>
                <Grid item xs={12}>
                    <DetailCardContainer>{renderCorporateBankDetails()}</DetailCardContainer>
                </Grid>
                <Grid item xs={12}>
                    <DetailCardContainer>{renderCorporateCommission()}</DetailCardContainer>
                </Grid>
                <Grid item xs={12}>
                    <DetailCardContainer>{renderAllStatementDetails()}</DetailCardContainer>
                </Grid>
                <Grid item xs={12}>
                    <DetailCardContainer>
                        <CorporateStatements />
                    </DetailCardContainer>
                </Grid>
            </Grid>
        </PaddedView>
    );
}

export default CorporateStaffDetailScreen;
