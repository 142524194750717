import { Button, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import Loading from "../../../../components/notifications/loading.component";
import { SnackbarContext } from "../../../../components/notifications/snackbar.context";
import PaddedView from "../../../../components/padded-view.component";
import Spacer from "../../../../components/spacer.component";
import Text from "../../../../components/text.component";
import ExperienceCardLoader from "../../../tag/loader/experience-card-loader.component";
import ExperiencesCard from "../components/experience-card.component";
import ExperienceSelectionModal from "../components/experience-selection-modal.component";
import {
  addBestSeller,
  bestSellerSelector,
  getBestSellerList,
  removeBestSeller,
} from "../../../../services/best-seller/best-seller.slice.services";

const UploadButton = styled(Button)(({ theme }) => ({
  backgroundColor: "transparent",
  borderTopLeftRadius: theme.shape.borderRadius[1],
  borderTopRightRadius: theme.shape.borderRadius[1],
  borderBottomLeftRadius: theme.shape.borderRadius[1],
  borderBottomRightRadius: theme.shape.borderRadius[1],
  border: `1px dashed ${theme.palette.colors.ui.disabled}`,
  minHeight: "250px",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: theme.palette.colors.ui.disabled,
  padding: "0px",
  width: "100%",
  "&:hover": {
    background: "transparent",
  },
}));

export default function BestSellerListScreen() {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const { getBestSellerListObj } = useSelector(bestSellerSelector);
  const [showExperienceModal, setShowExperienceModal] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    dispatch(getBestSellerList()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  const onHandleRemove = (bestSellerID) => {
    if (!showExperienceModal) {
      setIsLoading(true);
    }
    dispatch(removeBestSeller(bestSellerID)).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const onHandleAdd = (experienceId) => {
    dispatch(addBestSeller({ experienceId })).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const renderContent = () => {
    if (getBestSellerListObj.status === "succeeded") {
      return (
        <Grid container spacing={3}>
          {showExperienceModal && (
            <ExperienceSelectionModal
              showModal={showExperienceModal}
              setShowModal={setShowExperienceModal}
              onHandleRemove={onHandleRemove}
              onHandleAdd={onHandleAdd}
            />
          )}
          {getBestSellerListObj.data.map((item) => (
            <Grid item xs={isMobile ? 12 : 4} key={item.id}>
              <ExperiencesCard
                experience={item.experience}
                onHandleRemove={onHandleRemove}
                onHandleAdd={onHandleAdd}
              />
            </Grid>
          ))}

          <Grid item xs={isMobile ? 12 : 4}>
            <UploadButton onClick={() => setShowExperienceModal(true)}>
              <AddCircleOutlineRoundedIcon />
            </UploadButton>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container spacing={3}>
        <Grid item xs={isMobile ? 12 : 4}>
          <ExperienceCardLoader />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <ExperienceCardLoader />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <ExperienceCardLoader />
        </Grid>
      </Grid>
    );
  };

  return (
    <PaddedView multiples={3}>
      <Loading isLoading={isLoading} />
      <Spacer position="top" size="m" />
      <Text variant="h3">Best Seller</Text>
      <Spacer position="top" size="xl" />
      {renderContent()}
    </PaddedView>
  );
}
