import { FormControl, FormHelperText, MenuItem, Select, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";
import Text from "../text.component";

const GENDERLIST = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Others", value: "others" },
];

export default function FormGenderDropDown({ name, width, placeholder }) {
    const theme = useTheme();
    const { setFieldValue, values, touched, errors, setFieldTouched } = useFormikContext();
    const handleChange = (event) => {
        setFieldValue(name, event.target.value);
    };
    const showError = touched[name] && typeof errors[name] === "string";

    return (
        <FormControl
            sx={{
                width,
            }}
        >
            <Select
                sx={{
                    borderTopLeftRadius: theme.shape.borderRadius[0],
                    borderTopRightRadius: theme.shape.borderRadius[0],
                    borderBottomLeftRadius: theme.shape.borderRadius[0],
                    borderBottomRightRadius: theme.shape.borderRadius[0],
                }}
                error={showError}
                onBlur={() => setFieldTouched(name)}
                displayEmpty
                renderValue={(i) =>
                    i === "" ? <Text type="Placeholder">{placeholder}</Text> : <Text>{values[name]}</Text>
                }
                value={values[name]}
                onChange={handleChange}
            >
                {GENDERLIST.map((item) => (
                    <MenuItem value={item.value} key={item.value}>
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
            {showError && <FormHelperText error>{errors[name]}</FormHelperText>}
        </FormControl>
    );
}

FormGenderDropDown.defaultProps = {
    width: "100%",
};

FormGenderDropDown.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    width: PropTypes.string,
};
