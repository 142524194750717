import { Grid, Table, TableBody, Box, useTheme, styled, Pagination } from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import DetailCardContainer from "../../../components/detail-card-container.component";
import TableHeader from "../../../components/table/table-header.component";
import Text from "../../../components/text.component";
import {
  getStatementTransactionList,
  statementSelector,
} from "../../../services/statement/statement-slice.services";
import TransactionRow from "./transaction-row.component";
import StatementTransactionListLoader from "../loader/statement-transaction-list-loader.component";

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

export default function TransactionTable() {
  const location = useLocation();
  const statementId = new URLSearchParams(location.search).get("statementId");
  const dispatch = useDispatch();
  const { getStatementTransactionListObj } = useSelector(statementSelector);
  const createSnackBar = useContext(SnackbarContext);
  const theme = useTheme();
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(getStatementTransactionList({ statementId, page: 1 })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  const onPageChange = (e, newPage) => {
    dispatch(getStatementTransactionList({ statementId, page: newPage })).then(
      ({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
    setPage(newPage);
  };

  const renderContent = () => {
    if (getStatementTransactionListObj.status === "succeeded") {
      return (
        <DetailCardContainer>
          <Grid container>
            <Grid item xs={10}>
              <Text variant="h5">Transactions List</Text>
            </Grid>
          </Grid>
          <Table>
            <TableHeader
              headerCells={["Transaction Id", "Experience / Commission", "Date Created", "Amount"]}
            />
            <TableBody>
              {getStatementTransactionListObj.data.items.map((item) => (
                <TransactionRow record={item} key={item.id} />
              ))}
            </TableBody>
          </Table>
          <CustomFooter>
            <Pagination
              sx={{
                "&& .Mui-selected": {
                  backgroundColor: theme.palette.colors.brand.primary,
                  color: theme.palette.colors.text.white,
                },
              }}
              page={page}
              onChange={onPageChange}
              count={getStatementTransactionListObj.data.pagination.totalPages}
              variant="outlined"
            />
          </CustomFooter>
        </DetailCardContainer>
      );
    }
    return <StatementTransactionListLoader />;
  };

  return <>{renderContent()}</>;
}
