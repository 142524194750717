import React, { useContext } from "react";
import { Box, Grid, IconButton, Modal, styled, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Text from "../../../components/text.component";
import Spacer from "../../../components/spacer.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import Form from "../../../components/forms/form.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import {
  createGiftVoucher,
  giftVoucherSelectore,
} from "../../../services/gift-voucher/gift-voucher-slice.services";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.brand.white,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  width: "90%",
  maxWidth: "380px",
  outline: "none",
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.colors.brand.secondary,
}));

const validationSchema = Yup.object().shape({
  label: Yup.string().required().label("Label"),
  amount: Yup.number().required().min(50).label("Value"),
});

export default function CreateGiftVoucherModal({ showModal, setShowModal }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const { createGiftVoucherObj } = useSelector(giftVoucherSelectore);

  const handleCancel = () => {
    setShowModal(false);
  };

  const onCreateGiftVoucher = (values, { resetForm }) => {
    dispatch(createGiftVoucher({ value: parseFloat(values.amount) * 100, ...values })).then(
      ({ meta, error, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          resetForm();
          setShowModal(false);
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      },
    );
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox sx={{ padding: isMobile ? theme.padding.paddingX[1] : theme.padding.paddingX[0] }}>
        <Form
          validationSchema={validationSchema}
          onSubmit={onCreateGiftVoucher}
          initialValues={{
            type: "credit",
            label: "",
            amount: "",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box sx={{ display: "flex" }} justifyContent="space-between" alignItems="center">
                <Box sx={{ width: "24px" }} />
                <Text sx={{ textAlign: "center", fontSize: theme.fonts.fontSizes.size20 }}>
                  Gift Voucher
                </Text>
                <CloseIconButton onClick={handleCancel}>
                  <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
                </CloseIconButton>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Text>Label</Text>
              <Spacer size="s" />
              <FormFieldText name="label" placeholder="Label" />
            </Grid>
            <Grid item xs={12}>
              <Text>Value</Text>
              <Spacer size="s" />
              <FormFieldText name="amount" placeholder="Value" />
            </Grid>
            <Grid item xs={12}>
              <Spacer size="xl" position="top" />
              <FormSubmitButton isLoading={createGiftVoucherObj.status === "pending"}>
                <Text type="WhiteColor">Create</Text>
              </FormSubmitButton>
            </Grid>
          </Grid>
        </Form>
      </ModalBox>
    </Modal>
  );
}

CreateGiftVoucherModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};
