import React from "react";
import { useTheme } from "@mui/material";
import PropTypes from "prop-types";

function MarketingIcon({ isSelected }) {
  const theme = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
      <g
        id="Icon_ionic-ios-megaphone"
        data-name="Icon ionic-ios-megaphone"
        transform="translate(-3.368 -3.375)"
      >
        <path
          id="Path_8800"
          data-name="Path 8800"
          d="M5.455,11.377a4.542,4.542,0,0,1,2.151-3.9.16.16,0,0,0-.084-.3h0A3.819,3.819,0,0,0,3.858,10.63a.7.7,0,0,0-.49.737A.674.674,0,0,0,3.9,12.1a4.363,4.363,0,0,0,3.026,3.189h0a.216.216,0,0,0,.188-.371A4.927,4.927,0,0,1,5.455,11.377Z"
          transform="translate(0 -1.129)"
          fill={
            isSelected ? theme.palette.colors.brand.primary : theme.palette.colors.brand.secondary
          }
        />
        <path
          id="Path_8801"
          data-name="Path 8801"
          d="M26.055,6.273C26,6.13,25.943,6,25.884,5.866a5.423,5.423,0,0,0-1.162-1.7,2.658,2.658,0,0,0-1.863-.788,2.467,2.467,0,0,0-1.381.413A19.266,19.266,0,0,1,13.234,6.22c-2.232,0-2.109.011-2.109.011a4.94,4.94,0,0,0-2.934,4.5,4.889,4.889,0,0,0,2.377,4.231c.648.534,1.263.465,1.13,1.174s-1.2,5.156-1.338,6.082-.155,1.454.214,1.9a7.962,7.962,0,0,0,3.64,1.259c.99,0,.739-.46.739-2.316s-.5-6.965.883-6.965c2.586,0,3.935.915,5.958,1.676a2.9,2.9,0,0,0,1.076.249,2.654,2.654,0,0,0,1.858-.963,5.477,5.477,0,0,0,1.162-1.7c.059-.138.118-.275.177-.423A12.215,12.215,0,0,0,26.8,10.6,12.223,12.223,0,0,0,26.055,6.273Zm-1.424,8.3c-.343.83-.942,1.491-1.44,1.491s-.953-.661-1.3-1.491a10.787,10.787,0,0,1-.658-3.95A10.827,10.827,0,0,1,21.9,6.664c.343-.825.8-1.322,1.29-1.322s1.087.5,1.435,1.322a10.535,10.535,0,0,1,.011,7.912Z"
          transform="translate(-1.431)"
          fill={
            isSelected ? theme.palette.colors.brand.primary : theme.palette.colors.brand.secondary
          }
        />
      </g>
    </svg>
  );
}

MarketingIcon.defaultProps = {
  isSelected: false,
};

MarketingIcon.propTypes = {
  isSelected: PropTypes.bool,
};

export default MarketingIcon;
